import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";
import { RootState, store } from "../store";
import { api, extractStandardResponseData } from "../../api/api";
import { logout } from "../authSlice";
import { NotificationI } from "src/interfaces/NotificationI";
import {
  getNotifications,
  setNotificationViewed,
} from "src/actions/notificationActions";

export const notificationsAdapter = createEntityAdapter<NotificationI>();

// export const soketNotifications = createAsyncThunk(
//   "notifications/realTime",
//   (notification: NotificationI[]) => {
//     store.dispatch(getNotifications());
//     // return notification;
//   }
// );
export const clearNotifications = createAsyncThunk(
  "notifications/realTime",
  async () => {}
);

export const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: notificationsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      notificationsAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(setNotificationViewed.fulfilled, (state, { payload }) => {
      payload.map((item) => {
        notificationsAdapter.updateOne(state, {
          id: item.id,
          changes: { viewed: true },
        });
      });
    });
    // builder.addCase(soketNotifications.fulfilled, (state, { payload }) => {
    //   // notificationsAdapter.upsertMany(state, payload);
    //   const temp = [...payload, ...Object.values(current(state.entities))];
    //   // console.log(Object.values(current(state.entities)));
    //   console.log(temp);
    //   notificationsAdapter.removeAll(state);
    //   notificationsAdapter.upsertMany(state, temp);
    // });
    builder.addCase(clearNotifications.fulfilled, (state, { payload }) =>
      notificationsAdapter.removeAll(state)
    );
    builder.addCase(logout.fulfilled, () =>
      notificationsAdapter.getInitialState()
    );
  },
});

const { selectById, selectAll } = notificationsAdapter.getSelectors();
const getNotificationsState = (state: RootState) => state.notifications;

export const notificationsSelector = {
  notificationSelector: notificationsAdapter.getSelectors(
    (s: RootState) => s.notifications
  ),
  selectAllNotifications: () =>
    createSelector(getNotificationsState, (state) => selectAll(state)),
};
