import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";
import i18next from "i18next";
import { setDirection } from "src/utils/utils";

export const setLanguage = createAsyncThunk(
  "auth/setLanguage",
  async (lang: string) => {
    const user = await api
      .post("users/languages", { lang })
      .then(extractStandardResponseData);
    // console.log()
    i18next.changeLanguage(user.lang);
    setDirection(user.lang);
    return user;
  }
);
