import "./MentorCard.css";
import { User } from "src/interfaces/User";
import { useAppDispatch } from "src/store/store";
import { setMentorID } from "src/store/uiSlice";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import { useTranslation } from "react-i18next";
import Translation from "src/components/Common/utilities/Translation";

interface InstructorCardPropsI {
  user: User;
}

function MentorCard({ user }: InstructorCardPropsI) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div
      className="card border-0 noselect"
      style={{ height: "300px", padding: "30px 30px 20px" }}
    >
      <div>
        <img
          style={{ height: "120px", width: "120px", objectFit: "cover" }}
          src={
            user?.resources
              ? user?.resources?.attachmentUrl
              : ProfilePlaceHolder
          }
          alt="user_image"
          className="rounded-circle m-auto"
        />
        <h6 className="mt-3" style={{ minHeight: "38px" }}>
          {user?.firstName}&nbsp;{user?.lastName}
        </h6>
        <p className="small">
          <Translation input="resident_mentor" />
        </p>
        <a
          className="btn btn-sm bg-dark text-white font_family_nunito_sans"
          data-bs-toggle="modal"
          data-bs-target="#bookSession"
          onClick={() => dispatch(setMentorID(user.id))}
        >
          {t("book_a_session")}
        </a>
      </div>
    </div>
  );
}

export default MentorCard;
