import { Course } from "src/interfaces/Course";
import { getFileExtention } from "src/utils/utils";
import FileViewer from "react-file-viewer";
import { useHistory } from "react-router-dom";
import "./CourseContentPage.css";

function PDFCourse({ course }: { course: Course }) {
  const history = useHistory();
  return (
    <div className="clearance">
      <div className="container">
        <div className="row pt-5 ">
          <div className="d-flex justify-content-between flex-row">
            <h5
              style={{ display: "flex", alignItems: "center" }}
              className="m-0"
            >
              {course.title}
            </h5>
            <a
              className="btn btn-dark text-white"
              onClick={() => history.goBack()}
            >
              Back
            </a>
          </div>
        </div>

        <div className="row">
          <div style={{ paddingTop: "10px" }} className="">
            {/* <FileViewer
              fileType={`${getFileExtention(
                course.courseAttachments.attachmentUrl
              )}`}
              // filePath={`https://d3r6g7rvl3de1s.cloudfront.net/attachments/1639633526259_customer%20service.pdf`}
              filePath={`${course.courseAttachments.attachmentUrl}`}
              onError={(error) => {
                console.log("error", error);
              }}
            /> */}

            <iframe
              src={`${course.courseAttachments.attachmentUrl}#view=FitH&toolbar=0`}
              style={{ width: "100%", height: "100vh" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PDFCourse;
