import { Card, Avatar, Row, Col, Divider, Modal, Upload, message } from "antd";
import { useSelector } from "react-redux";
import { authSelectors, updateProfile } from "src/store/authSlice";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import InfoItem from "./InfoItem";
import UserTags from "./UserTags";
import { FormOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { useForm } from "antd/lib/form/Form";
import { api, extractStandardResponseData } from "src/api/api";
import { unwrapResult } from "@reduxjs/toolkit";
import { LoadingOutlined } from "@ant-design/icons";

function ProfileInfoCard() {
  const user = useSelector(authSelectors.details);
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const userDetails = useSelector(authSelectors.details);
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const insideUpload = (
    <Row>
      Upload Image
      {loading && (
        <LoadingOutlined style={{ fontSize: "220%", color: "#52c41a" }} />
      )}
    </Row>
  );

  const customRequest = async (e: any) => {
    const fileList = e.file;
    if (!fileList) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", fileList);
    if (e.file.type === "image/jpeg" || e.file.type === "image/png") {
      try {
        const image = await api
          .post("/courses/images", formData)
          .then(extractStandardResponseData);
        dispatch(
          updateProfile({
            id: userDetails.id,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            description: userDetails.description,
            designation: userDetails.designation,
            imageId: image.id,
          })
        )
          .then(unwrapResult)
          .then((res) => {
            setShowModal(false);
            setLoading(false);
            setShowUpdate(false);
          });
      } catch (error) {
        setLoading(false);
        message.error("Image Upload Failed Please try again");
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...userDetails });
    document.title = `Learn Sure . Edit Profile`;
  }, [userDetails]);

  return (
    <Card>
      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <Avatar
            size={{ xs: 100, sm: 100, md: 100, lg: 120, xl: 120, xxl: 120 }}
            src={user?.resources?.metaData?.thumbnail || ProfilePlaceHolder}
          />
        </Col>
        <Col span={8}>
          <Row justify="end">
            <FormOutlined
              style={{ fontSize: 20 }}
              className="cursor_pointer"
              onClick={setShowModal.bind(null, true)}
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <h6>
          {user.firstName} {user.lastName}
        </h6>
      </Row>
      <Row justify="center">
        <p>System Admin</p>
      </Row>
      <Divider />
      <InfoItem label="company" value="Learnsure Ai" />
      <InfoItem label="designation" value="IT Service Executive" />
      <InfoItem label="city" value="Mumbai, India" />
      <InfoItem label="email" value={user.email} />
      <UserTags tags={user.usersTags} />

      <Modal
        destroyOnClose={true}
        title="Edit Profile"
        visible={showModal}
        confirmLoading={loading}
        onCancel={setShowModal.bind(null, false)}
      >
        <Row>
          <Col span={24}>
            {!showUpdate && (
              <Row>
                <Col span={6}></Col>
                <Col span={12}>
                  <Row justify="center">
                    <Avatar
                      shape="circle"
                      size={200}
                      src={user?.resources?.attachmentUrl || ProfilePlaceHolder}
                    />
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <FormOutlined
                      style={{ fontSize: 20 }}
                      className="cursor_pointer"
                      onClick={() => {
                        setShowUpdate(true);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            )}

            {showUpdate && (
              <Row justify="center">
                <Upload
                  name="test"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={customRequest}
                  multiple={false}
                  onPreview={() => {}}
                  openFileDialogOnClick={true}
                >
                  {insideUpload}
                </Upload>
              </Row>
            )}
          </Col>
        </Row>
      </Modal>
    </Card>
  );
}

export default ProfileInfoCard;
