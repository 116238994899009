import { Col, Button, Row, Card, Tooltip, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAppDispatch } from "src/store/store";
import {
  getPathway,
  pathwaysSelectors,
} from "src/store/pathways/pathwaysSlice";
import { useSelector } from "react-redux";
import { PathWayCourse } from "src/interfaces/Course";
import parse from "html-react-parser";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import FormLabel from "src/components/Common/utilities/FormLabel";
import TextHeightWrapper from "src/components/Common/utilities/TextHeightWrapper";

interface Params {
  pathwayId: string;
}

function ViewPathWayPage() {
  const [loading, setLoading] = useState(true);
  const { pathwayId } = useParams<Params>();
  const pathway = useSelector(
    pathwaysSelectors.selectPathwayEntity(parseInt(pathwayId))
  );
  const history = useHistory();
  const dispatch = useAppDispatch();

  const tableHeader = (
    <Row justify="space-between">
      {pathway?.title ? (
        <h5>{pathway?.title}</h5>
      ) : (
        <Skeleton.Input style={{ width: 400 }} />
      )}

      <Space>
        <Button
          type="primary"
          key="new-account"
          onClick={() => history.push(`/admin/${pathwayId}/pathway/edit`)}
        >
          Edit
        </Button>
        <Tooltip title="Go to learner pathway page">
          <Button
            type="primary"
            key="new-account"
            onClick={() =>
              window.open(`/pathway/description/${pathway?.slug}`, "_blank")
            }
          >
            Preview
          </Button>
        </Tooltip>
      </Space>
    </Row>
  );

  useEffect(() => {
    if (pathway) setLoading(false);
    else
      dispatch(getPathway(parseInt(pathwayId))).then(
        setLoading.bind(null, false)
      );
  }, []);
  return (
    <Row>
      <Col sm={24}>
        <Card title={tableHeader}>
          <div style={{ marginBottom: "20px" }}>
            <FormLabel name="Excerpt" />
            <SkeletonLoader loading={loading} rows={0} type="normal">
              <>{pathway?.excerpt}</>
            </SkeletonLoader>
            <FormLabel name="Objectives" />
            <SkeletonLoader loading={loading} rows={4} type="normal">
              <TextHeightWrapper
                maxChars={500}
                inputString={pathway?.objectives || ""}
              />
            </SkeletonLoader>
            <FormLabel name="Description" />
            <SkeletonLoader loading={loading} rows={4} type="normal">
              <TextHeightWrapper
                maxChars={500}
                inputString={pathway?.description || ""}
              />
            </SkeletonLoader>
          </div>

          {pathway?.pathwaysCourses.map(
            (pathwayCourse: Partial<PathWayCourse>, index: number) => {
              if (pathwayCourse.courses !== null) {
                return (
                  <Row
                    onClick={() => {
                      history.push(
                        `/admin/view-course/${pathwayCourse.courseId}`
                      );
                    }}
                  >
                    <Col sm={24}>
                      <Row>
                        <Col sm={2}>
                          <Row justify="center">
                            <div
                              style={{
                                marginTop: "80px",
                                height: "30px",
                                width: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#002766",
                                color: "white",
                              }}
                            >
                              {index + 1}
                            </div>
                          </Row>
                        </Col>
                        <Col sm={22}>
                          <Card
                            hoverable={true}
                            style={{
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Row>
                              <Col sm={5} style={{ height: "150px" }}>
                                <div
                                  style={{
                                    backgroundImage: `url("${pathwayCourse.courses?.resources.attachmentUrl}")`,
                                    height: "100%",
                                    width: "100%",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    borderRadius: "5px",
                                    border: "1px solid #eee",
                                  }}
                                ></div>
                              </Col>
                              <Col sm={19}>
                                <Row>
                                  <h6 style={{ marginLeft: "15px" }}>
                                    {parse(pathwayCourse.courses?.title || "")}
                                  </h6>
                                </Row>
                                <Row>
                                  <div style={{ marginLeft: "15px" }}>
                                    {parse(
                                      pathwayCourse.courses?.excerpt || ""
                                    )}
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }
            }
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default ViewPathWayPage;

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++v

// import { PageHeader, Col, Button, Row, Card, Steps, Timeline } from "antd";
// import { useEffect, useState } from "react";
// import { useHistory, useParams } from "react-router";
// import { useAppDispatch } from "src/store/store";
// import { getPathway, pathwaysSelectors } from "src/store/pathwaysSlice";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";
// import { PathWayCourse } from "src/interfaces/Course";
// import ImageThumbnail from "src/components/Admin-Author-Components/ImageThumbnail";
// import parse from "html-react-parser";
// import SkeletonLoader from "src/components/SkeletonLoader";
// import FormLabel from "src/components/Admin-Author-Components/FormLabel";

// interface Params {
//   pathwayId: string;
// }

// function ViewPathWayPage() {
//   const [loading, setLoading] = useState(true);
//   const { pathwayId } = useParams<Params>();
//   const pathway = useSelector(pathwaysSelectors.pathways)[parseInt(pathwayId)];
//   const history = useHistory();
//   const dispatch = useAppDispatch();

//   const { Step } = Steps;
//   useEffect(() => {
//     dispatch(getPathway(parseInt(pathwayId)))
//       .then(unwrapResult)
//       .then((res) => {
//         setLoading(false);
//         console.log(res);
//       });
//   }, []);
//   return (
//     <div>
//       <Col sm={24}>
//         <PageHeader
//           title="Pathway"
//           onBack={() => {
//             history.goBack();
//           }}
//           extra={[
//             <Button
//               type="primary"
//               key="new-account"
//               onClick={() => {
//                 history.push(`/admin/${pathwayId}/pathway/edit`);
//               }}
//             >
//               Edit
//             </Button>,
//             <Button
//               type="primary"
//               key="new-account"
//               onClick={() => {
//                 // history.push(`/admin/${pathwayId}/pathway/edit`);
//               }}
//             >
//               Preview
//             </Button>,
//           ]}
//         />
//       </Col>

//       <Row>
//         <Col sm={24}>
//           <Card>
//             <div style={{ marginBottom: "20px" }}>
//               <FormLabel name="Excerpt" />
//               <SkeletonLoader loading={loading} rows={0} type="normal">
//                 <>{pathway?.excerpt}</>
//               </SkeletonLoader>
//               <FormLabel name="Objectives" />
//               <SkeletonLoader loading={loading} rows={4} type="normal">
//                 <>{parse(pathway?.objectives || "")}</>
//               </SkeletonLoader>
//               <FormLabel name="Description" />
//               <SkeletonLoader loading={loading} rows={4} type="normal">
//                 <>{parse(pathway?.description || "")}</>
//               </SkeletonLoader>
//             </div>

//             <Timeline>
//               {pathway?.pathways_courses.map(
//                 (pathwayCourse: Partial<PathWayCourse>) => {
//                   return (
//                     <Timeline.Item>
//                       <Card hoverable={true}>
//                         <Row>
//                           <Col sm={5}>
//                             <ImageThumbnail
//                               resources={pathwayCourse.courses?.resources}
//                               height={140}
//                               width={200}
//                             />
//                           </Col>
//                           <Col sm={19}>
//                             <Row>
//                               <h6>
//                                 {parse(pathwayCourse.courses?.title || "")}
//                               </h6>
//                             </Row>
//                             <Row>
//                               {parse(pathwayCourse.courses?.excerpt || "")}
//                             </Row>
//                             <Row></Row>
//                             {/* <Row>
//                               <div style={{ fontSize: "80%" }}>
//                                 {parse(
//                                   pathwayCourse.courses?.description || ""
//                                 )}
//                               </div>
//                             </Row> */}
//                           </Col>
//                         </Row>
//                       </Card>
//                     </Timeline.Item>
//                   );
//                 }
//               )}
//             </Timeline>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default ViewPathWayPage;
