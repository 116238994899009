export enum LanguageCode {
  en = "en",
  ar = "ar",
  hi = "hi",
  es = "es",
  zho = "zho",
}
export enum LanguageName {
  English = "English",
  Arabic = "Arabic",
  Hindi = "Hindi",
  Spanish = "Spanish",
  Chinese = "Chinese",
}
