import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getLearnerPathway } from "src/actions/PathwayActions/LearnerPathwayActions";
import { PathWay } from "src/interfaces/PathWay";
import { pathwaysSelectors } from "src/store/pathways/pathwaysSlice";
import { useAppDispatch } from "src/store/store";
import RelatedPathwayCard from "./RelatedPathwayCard";

// function RelatedPathways() {
//   const pathways = useSelector(pathwaysSelectors.selectAllPathways());
//   const location = useLocation();
//   const dispatch = useAppDispatch();
//   const { t } = useTranslation();

//   useEffect(() => {
//     if (pathways.length === 1) dispatch(getLearnerPathway(0));
//   }, []);

//   return (
//     <>
//       <h5 className="mb-3 fw-bold">{t("related_pathways")}</h5>
//       {pathways
//         .filter(
//           (pathway: PathWay) => pathway.slug !== location.pathname.split("/")[3]
//         )
//         .slice(0, 3)
//         .map((pathway: PathWay) => (
//           <RelatedPathwayCard pathway={pathway} key={pathway.id} />
//         ))}
//     </>
//   );
// }

function RelatedPathways() {
  return <></>;
}

export default RelatedPathways;
