import { useEffect } from "react";
import { Collapse } from "antd";
import { Lesson } from "../../../interfaces/Lesson";
import LessonMediaRender from "../../Common/mediaPlayers/LessonMediaRender";

interface CollapsibleCompAdminPropsI {
  data: any;
}

function CollapsibleCompAdmin({ data }: CollapsibleCompAdminPropsI) {
  const { Panel } = Collapse;

  useEffect(() => {}, [data]);

  return (
    <Collapse>
      <Panel header={data.title} key={data.id}>
        <Collapse>
          {data.lessons.map((lesson: Lesson) => (
            <Panel header={lesson.title} key={lesson.id}>
              <LessonMediaRender
                lessonsAttachments={lesson?.lessonsAttachments || []}
              />
            </Panel>
          ))}
        </Collapse>
      </Panel>
    </Collapse>
  );
}

export default CollapsibleCompAdmin;
