import React, { useState } from "react";
import { Input } from "antd";
import {
  clearCourses,
  searchCourse,
} from "src/store/coursesSlices/coursesSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "src/store/store";
import Translation from "src/components/Common/utilities/Translation";
import { useTranslation } from "react-i18next";
import { getAllCourses } from "src/actions/courseActions/courseActions";

function CoursesTableSearch() {
  const [searchLoading, setSearchLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { Search } = Input;
  const { t } = useTranslation();

  const searchCoursesFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        setSearchLoading(true);
        dispatch(clearCourses()).then(() => {
          dispatch(searchCourse(e.target.value))
            .then(unwrapResult)
            .then((res) => setSearchLoading(false));
        });
      }, 1000);
    } else {
      dispatch(getAllCourses(0));
      setSearchLoading(false);
      setSearchLoading(false);
    }
  };

  return (
    <Search
      placeholder={t("search_by_title")}
      loading={searchLoading}
      onChange={searchCoursesFunction}
    />
  );
}

export default CoursesTableSearch;
