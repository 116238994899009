import { useHistory } from "react-router-dom";
import { Course } from "../../../../interfaces/Course";
import { maxCharsLength } from "src/utils/utils";
import CourseTag from "../CourseTag";
import CourseViewIcon from "../CourseIcons/CourseViewIcon";
import Like from "../CourseIcons/Like";
import CourseCommentIcon from "../CourseIcons/CourseCommentIcon";
import CourseProgressBar from "./CourseProgressBar";
import { CourseFormat } from "src/enums/CourseFormat";
interface Props {
  course: Course;
  showProgressBar?: boolean;
}

function CourseCard({ course, showProgressBar }: Props) {
  const history = useHistory();
  const redirect = () => {
    if (
      course.type === CourseFormat.WEBWATCH ||
      course.type === CourseFormat.INFOGRAPHIA
    ) {
      history.push(`/course-content/${course?.slug}`);
    } else {
      history.push(`/course-description/${course?.slug}`);
    }
  };

  return (
    <div className="mt-1 mb-3 noselect">
      <div className="card w-100 cursor_pointer hover_effect border_radius border_color overflow-hidden">
        <div>
          <div className="position-relative" onClick={() => redirect()}>
            <img
              style={{ objectFit: "cover", height: "171px" }}
              src={course?.resources?.attachmentUrl}
              alt="course-image"
              className="img-fluid w-100"
            />
            <div
              className="position-absolute"
              style={{ bottom: "-0.6rem", left: "1rem" }}>
              <CourseTag courseFormat={course?.type} />
            </div>
          </div>

          <div
            className="card-body fs-6 d-flex flex-column justify-content-between mb-3"
            style={{ height: "123px", padding: "20px" }}>
            <div className="text-start">
              <h6
                onClick={() => redirect()}
                className="mt-2 mb-3 fw-6 overflow-hidden on_text_hover"
                style={{
                  height: "2.5em",
                }}>
                {maxCharsLength(32, course?.title)}
                {/* {course?.title} */}
              </h6>
            </div>
            {showProgressBar ? (
              <CourseProgressBar progress={course?.progress} />
            ) : (
              <div className="d-flex justify-content-between">
                <CourseViewIcon views={course?.views} />
                <Like course={course} />
                <CourseCommentIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
