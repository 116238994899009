import { Row, Card, Col, Space } from "antd";
import { useSelector } from "react-redux";
import Translation from "src/components/Common/utilities/Translation";
import { authSelectors } from "src/store/authSlice";

function AboutCard() {
  const user = useSelector(authSelectors.details);

  return (
    <Card>
      <Space direction="vertical">
        <Row>
          <Col>
            <Row>
              <h6>
                <Translation input="about" />
              </h6>
            </Row>
            <Row>
              {/* <p>{user.designation}</p> */}
              <p>
                sor at Hampden-Sydney College in Virginia, looked up one of the
                more obscure Latin words, consectetur, from a Lorem Ipsum
                passage, and going through the cites of the word in classical
                literature, discovered the undoubtable source. Lorem Ipsum comes
                from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                Malorum" (The Extremes of Good and Evil) by Cicero, written in
                45 BC. This book is a treatise on the theory of ethics, very
                popular during the Renaissance. The first line of Lorem Ipsum,
                "Lorem ipsum dolor sit amet..", comes from a line in section
                1.10.32.
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <h6>
            {/* <Translation input="activity" /> */}
            System Log
          </h6>
        </Row>
        <Row>
          <Col sm={24}>
            <Card style={{ height: "300px" }}></Card>
          </Col>
        </Row>
      </Space>
    </Card>
  );
}

export default AboutCard;
