import { useState } from "react";
import { Form, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Modal from "antd/lib/modal/Modal";
import { uploadCertificateLogo } from "src/actions/certificateActions";
import { useAppDispatch } from "src/store/store";

interface Props {
  onUpload: (e: any) => void;
}
interface FileList {
  url: string;
  name: string;
  status: string;
  uid: number;
}
function UploadCertificates({ onUpload }: Props) {
  const [loading, setLoading] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [showModal, setShowModal] = useState({ visible: false, url: "" });
  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      if (e.file.type !== "image/jpeg" && e.file.type !== "image/png") {
        message.error("please upload image");
        setLoading(false);
        return;
      }

      try {
        const image = await uploadCertificateLogo(formData);

        setFileListArray([
          ...fileListArray,
          {
            url: image.attachmentUrl,
            name: "image.png",
            status: "done",
            uid: image.id,
          },
        ]);

        setLoading(false);
        message.success("image successfully uploaded");

        onUpload([
          ...fileListArray,
          {
            url: image.attachmentUrl,
            name: "image.png",
            status: "done",
            uid: image.id,
          },
        ]);
      } catch (error) {
        message.error("error uploading ");
      }
    }
  };

  const removeHandler = (e: any) => {
    setFileListArray(
      fileListArray.filter((media: FileList) => media.uid !== e.uid)
    );
  };

  const uploadButton = loading ? (
    <LoadingOutlined style={{ fontSize: "250%" }} />
  ) : (
    <PlusOutlined style={{ fontSize: "250%" }} />
  );

  return (
    <>
      <Form.Item label={<FormLabel name="upload_logos" />}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          customRequest={customRequest}
          multiple={true}
          fileList={fileListArray}
          onRemove={removeHandler}
          onPreview={(e) => setShowModal({ visible: true, url: e.url || "" })}
        >
          {fileListArray.length >= 2 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Modal
        title="Image Preview"
        visible={showModal.visible}
        footer={[]}
        onCancel={() => setShowModal({ ...showModal, visible: false })}
      >
        <img src={showModal.url} style={{ width: "100%" }} />
      </Modal>
    </>
  );
}

export default UploadCertificates;
