import { useEffect, useState } from "react";
import {
  Table,
  Button,
  Col,
  Row,
  Menu,
  Dropdown,
  Space,
  Pagination,
} from "antd";
import Column from "antd/lib/table/Column";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState, useAppDispatch } from "src/store/store";
import { Course } from "src/interfaces/Course";
import { Tag as TagA } from "antd";
import {
  approveCourse,
  publishCourse,
  deleteCourse,
  disapproveCourse,
  unpublishCourse,
} from "src/store/coursesSlices/coursesSlice";
import PopUpComp from "src/components/AdminAuthorMentorComponents/Common/PopUpComp";
import StatusTag from "src/components/AdminAuthorMentorComponents/Common/StatusTag";
import ActionButtons from "src/components/AdminAuthorMentorComponents/Common/ActionButtons";
import ImageThumbnail from "src/components/Admin-Author-Components/ImageThumbnail";
import CoursesTableSearch from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/CoursesTableSearch";
import { FILTER_ENUMS } from "src/enums/Filters";
import { filtersFunction } from "src/utils/sortUtils";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import Translation from "src/components/Common/utilities/Translation";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import { InfoCircleOutlined } from "@ant-design/icons";
import CourseExpand from "./CourseExpand";
import { getAllCourses } from "src/actions/courseActions/courseActions";
import { coursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/coursesPaginationSlice";

function CoursesTable() {
  const [page, setPage] = useState(0);
  const role = useSelector(authSelectors.role);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const courses = useSelector((state: RootState) =>
    coursesPaginationSelectors.get(state, page)
  ) as Course[];
  const availablePages = useSelector(
    coursesPaginationSelectors.pages.selectIds
  );

  const totalCourses = useSelector(coursesPaginationSelectors.totalCourses);
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState<any>(
    FILTER_ENUMS.CLEAR_FILTERS
  );
  const { SubMenu } = Menu;

  const tableHeader = () => (
    <Row justify="space-between">
      <Col>
        <Space>
          <h5>
            <Translation input="courses" />
          </h5>
          <CoursesTableSearch />
        </Space>
      </Col>
      <Space>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="primary">
            <Translation input="filters" />
          </Button>
        </Dropdown>
      </Space>
    </Row>
  );

  const menu = (
    <Menu
      onClick={(e) => setSelectedFilter(e.key)}
      defaultSelectedKeys={[`${selectedFilter}`]}
    >
      <Menu.ItemGroup title={<Translation input="filter_by" />}>
        <SubMenu title={<Translation input="course_format" />} key="1">
          <Menu.Item key={FILTER_ENUMS.DEFAULT}>Default</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.VIDEO}>Video</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.XAPI}>Xapi</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.SCROM}>Scrom</Menu.Item>
        </SubMenu>
        <SubMenu title={<Translation input="status" />} key="2">
          <Menu.Item key={FILTER_ENUMS.AWAITING_APPROVAL}>
            Awaiting Approval
          </Menu.Item>
          <Menu.Item key={FILTER_ENUMS.PUBLISHED}>Published</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.DISAPPROVED}>Dissaproved</Menu.Item>
        </SubMenu>
        <Menu.Item key={FILTER_ENUMS.CLEAR_FILTERS}>
          <Translation input="clear_filters" />
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const editLink = (courseId: number): string => {
    if (role === Role.ADMIN) return `/admin/${courseId}/edit`;
    if (role === Role.AUTHOR) return `/author/${courseId}/edit`;
    else return "";
  };

  const redirectToViewCourse = (courseId: number) => {
    if (role === Role.ADMIN) history.push(`/admin/view-course/${courseId}`);
    if (role === Role.AUTHOR) history.push(`/author/view-course/${courseId}`);
  };

  const translateRedirect = (course: Course): string => {
    if (course?.language?.length === 1)
      return `/admin/translation/${course?.id}/en`;
    else
      return `/admin/translation/${course?.id}/en-${
        course?.language ? course?.language[1] : ""
      }`;
  };

  const expandable = {
    expandedRowRender: (course: Course) => <CourseExpand course={course} />,
    expandIcon: ({ expanded, onExpand, record }) => (
      <InfoCircleOutlined onClick={(e) => onExpand(record, e)} />
    ),
  };
  useEffect(() => {
    if (availablePages && !loading && !availablePages.includes(page)) {
      setLoading(true);
      dispatch(getAllCourses(page)).then(setLoading.bind(null, false));
    }
  }, [page, courses]);

  return (
    <>
      <Table
        // expandIconColumnIndex={6}
        dataSource={filtersFunction(courses, selectedFilter)}
        loading={loading}
        rowKey={(s) => s.id}
        title={tableHeader}
        expandable={expandable}
        pagination={false}
      >
        <Column
          title={<TableColTitle title="cover" />}
          dataIndex="actions"
          key="actions"
          render={(_, course: Course) => (
            <div
              className="cursor_pointer"
              onClick={() => redirectToViewCourse(course.id)}
            >
              <ImageThumbnail
                resources={course.resources}
                width={65}
                height={40}
              />
            </div>
          )}
        />

        <Column
          title={<TableColTitle title="title" />}
          dataIndex="title"
          key="title"
          render={(_, course: Course) => (
            <div
              className="hover_color_change cursor_pointer"
              onClick={() => redirectToViewCourse(course.id)}
            >
              {course.title}
            </div>
          )}
        />

        <Column
          title={<TableColTitle title="Languages" />}
          render={(_, course: Course) =>
            course.language?.map((lang: any) => <TagA>{lang}</TagA>)
          }
        />
        <Column
          title={<TableColTitle title="status" />}
          key="actions"
          render={(_, course: Course) => <StatusTag data={course} />}
        />

        <Column
          title={<TableColTitle title="actions" />}
          key="actions"
          render={(_, course: Course) => (
            <ActionButtons
              pathwayOrCourse={course}
              approveFalse={disapproveCourse(course.id)}
              approveTrue={approveCourse(course.id)}
              publishTrue={publishCourse(course.id)}
              publishFalse={unpublishCourse(course.id)}
            />
          )}
        />
        <Column
          title={<TableColTitle title="options" />}
          key="options"
          render={(_, course: Course) => (
            <PopUpComp
              deleteMethod={deleteCourse(course.id)}
              editLink={editLink(course.id)}
              translateLink={translateRedirect(course)}
            />
          )}
        />
      </Table>
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Pagination
          defaultCurrent={1}
          total={totalCourses}
          showSizeChanger={false}
          onChange={(p) => setPage(p - 1)}
        />
      </Row>
    </>
  );
}

export default CoursesTable;
