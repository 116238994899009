import React, { Suspense } from "react";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { authSelectors, checkAuth } from "../../../store/authSlice";
import { useAppDispatch } from "../../../store/store";
import { unwrapResult } from "@reduxjs/toolkit";

import AdminLayout from "src/components/AdminAuthorMentorComponents/AdminComponents/AdminLayout/AdminLayout";
import AuthorLayout from "src/components/AdminAuthorMentorComponents/AuthorComponents/AuthorLayout";
import LearnerLayout from "src/components/LearnerComponents/Layout/LearnerLayout";
import MentorLayout from "src/components/AdminAuthorMentorComponents/MentorComponents/MentorLayout/MentorLayout";
import { Role } from "src/interfaces/User";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PageLoading from "../utilities/PageLoading";
// const AdminLayout = React.lazy(
//   () =>
//     import(
//       "src/components/AdminAuthorMentorComponents/AdminComponents/AdminLayout"
//     )
// );
// const AuthorLayout = React.lazy(
//   () =>
//     import(
//       "src/components/AdminAuthorMentorComponents/AuthorComponents/AuthorLayout"
//     )
// );
// const LearnerLayout = React.lazy(
//   () => import("src/components/LearnerComponents/Layout/LearnerLayout")
// );

// const MentorLayout = React.lazy(
//   () =>
//     import(
//       "src/components/AdminAuthorMentorComponents/MentorComponents/MentorLayout"
//     )
// );

function PrivateRoute({ children, role, bypassRoleCheck, ...rest }: any) {
  const email = useSelector(authSelectors.email);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!email) {
      setLoading(true);
      dispatch(checkAuth())
        .then(unwrapResult)
        .catch(() => {})
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, []);

  const SelectLayout = (roleInput?: Role) => {
    switch (roleInput) {
      case Role.ADMIN:
        return <AdminLayout component={children} />;
      case Role.AUTHOR:
        return <AuthorLayout component={children} />;
      case Role.LEARNER:
        return (
          <LearnerLayout
            component={children}
            bypassRoleCheck={bypassRoleCheck}
          />
        );
      case Role.MENTOR:
        return <MentorLayout component={children} />;
    }
  };

  return loading ? (
    <PageLoading />
  ) : email ? (
    <Route {...rest}>
      <Suspense fallback={<div>Loading...</div>}>{SelectLayout(role)}</Suspense>
    </Route>
  ) : (
    <Redirect to="/login" />
  );
}

export default PrivateRoute;

// ============================================================
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { Route, Redirect } from "react-router-dom";
// import { authSelectors, checkAuth } from "../../store/authSlice";
// import { useAppDispatch } from "../../store/store";
// import { unwrapResult } from "@reduxjs/toolkit";
// import AdminLayout from "src/components/AdminAuthorMentorComponents/AdminComponents/AdminLayout";
// import AuthorLayout from "src/components/AdminAuthorMentorComponents/AuthorComponents/AuthorLayout";
// import LearnerLayout from "src/components/LearnerComponents/Layout/LearnerLayout";
// import MentorLayout from "src/components/AdminAuthorMentorComponents/MentorComponents/MentorLayout";
// import { Role } from "src/interfaces/User";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import PleaseLoginButton from "src/components/Common/PleaseLoginButton";

// function PrivateRoute({
//   children,
//   learnerLayoutMandatory,
//   role,
//   ...rest
// }: any) {
//   const email = useSelector(authSelectors.email);
//   const [loading, setLoading] = useState(true);
//   const dispatch = useAppDispatch();

//   useEffect(() => {
//     if (!email) {
//       setLoading(true);
//       dispatch(checkAuth())
//         .then(unwrapResult)
//         .then((res) => {})
//         .catch(() => {})
//         .finally(() => {
//           setLoading(false);
//         });
//     } else setLoading(false);
//   }, []);

//   const SelectLayout = (roleInput?: Role) => {
//     if (learnerLayoutMandatory) {
//       return <LearnerLayout component={children} />;
//     } else {
//       switch (roleInput) {
//         case Role.ADMIN:
//           return <AdminLayout component={children} />;
//         case Role.AUTHOR:
//           return <AuthorLayout component={children} />;
//         case Role.LEARNER:
//           return <LearnerLayout component={children} />;
//         case Role.MENTOR:
//           return <MentorLayout component={children} />;
//       }
//     }
//   };

//   return (
//     <div>
//       {loading ? (
//         <div className="page_loader">
//           <Loader type="Oval" color="#0077ff" height={70} width={70} />
//         </div>
//       ) : (
//         <div>
//           {email ? (
//             <Route {...rest}>{SelectLayout(role)}</Route>
//           ) : (
//             // <PleaseLoginButton />
//             <Redirect to="/login" />
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

// export default PrivateRoute;
