import { Row, Col } from "antd";
import { useEffect } from "react";
import AnalyticsCard from "src/components/AdminAuthorMentorComponents/AdminComponents/DashBoard/AnalyticsCard";
import UserStatisticsChart from "src/components/AdminAuthorMentorComponents/AdminComponents/DashBoard/UserStatisticsChart";
import CourseStatisticsChart from "src/components/AdminAuthorMentorComponents/AdminComponents/DashBoard/CourseStatisticsChart";
import PopularCoursesTable from "src/components/AdminAuthorMentorComponents/AdminComponents/DashBoard/PopularCoursesTable";
import FrequentLearnersTable from "src/components/AdminAuthorMentorComponents/AdminComponents/DashBoard/FrequentLearnersTable";
import DashboardTopRow from "src/components/AdminAuthorMentorComponents/AdminComponents/DashBoard/DashboardTopRow";

function DashBoardPage() {
  useEffect(() => {
    document.title = `Learnsure Ai - Analytics`;
  }, []);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <DashboardTopRow />
      </Col>
      <Col span={24}>
        <Row gutter={[20, 0]} style={{ height: "58vh" }}>
          <Col span={16}>
            <UserStatisticsChart />
          </Col>
          <Col span={8}>
            <CourseStatisticsChart />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 0]}>
          <Col span={8}>
            <AnalyticsCard title="total_licences" count={500} loading={false} />
          </Col>
          <Col span={8}>
            <AnalyticsCard
              title="activated_licences"
              count={400}
              loading={false}
            />
          </Col>
          <Col span={8}>
            <AnalyticsCard
              title="license_validity"
              count={210}
              loading={false}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <PopularCoursesTable />
          </Col>
          <Col span={12}>
            <FrequentLearnersTable />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default DashBoardPage;
