export enum FileType {
  mp4 = "mp4",
  jpeg = "jpeg",
  jpg = "jpg",
  mp3 = "mp3",
  pdf = "pdf",
  slider = "slider",
  zip = "zip",
  png = "png",
  csv = "csv",
}
