import NavSearchBar from "./Navbar/NavSearchBar/NavSearchBar";
import NavIcons from "./Navbar/NavBarIcons/NavIcons";
function OffCanvas() {
  return (
    <div
      className="offcanvas offcanvas-start clearance bg-dark d-sm-none"
      tabIndex={-1}
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <NavSearchBar />

        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        {/* <NavSearchBar /> */}
        <NavIcons />
      </div>
    </div>
  );
}

export default OffCanvas;
