import React, { useRef } from "react";
import { PathWay } from "src/interfaces/PathWay";
import SkeletonLoaderLearner from "src/components/LearnerComponents/UtilityComponents/SkeletonLoaderLearner";
import { maxCharsLength } from "src/utils/utils";
import PathwayCourseDescriptionSocialIcons from "src/components/LearnerComponents/Pathways/PathwayDescriptionHero/PathwayCourseDescriptionSocialIcons";
import TimeStamp from "src/components/Common/utilities/TimeStamp";
import { useTranslation } from "react-i18next";

interface Props {
  pathway?: PathWay;
  loading: boolean;
  reference?: React.RefObject<HTMLInputElement>;
}

function PathwayDescriptionHero({ pathway, loading, reference }: Props) {
  const { t } = useTranslation();
  // if(ref.current !== null){
  //   const executeScroll = () => ref.current.scrollIntoView();
  // }
  return (
    <div className="container text-white" style={{ marginTop: "38px" }}>
      <p className="small">
        {t("pathway")} &gt; {t("pathway_description")} &gt; {pathway?.title}
      </p>
      <h2 className="mb-3 text-white">
        <SkeletonLoaderLearner
          loading={loading}
          rows={2}
          height={30}
          type="normal"
        >
          {pathway?.title}
        </SkeletonLoaderLearner>
      </h2>
      <p className=" mb-0">
        <SkeletonLoaderLearner
          loading={loading}
          rows={2}
          height={20}
          type="normal"
        >
          <div className="fs-6 font_family_nunito_sans mt-3">
            {maxCharsLength(200, pathway?.excerpt || "")}
          </div>
        </SkeletonLoaderLearner>
      </p>
      <div className="w-25 my-3">
        {pathway ? (
          <PathwayCourseDescriptionSocialIcons pathway={pathway} />
        ) : null}
      </div>
      <div className="d-flex">
        <div className="d-flex">
          <div> {t("last_updated_on")}</div>&nbsp;
          <TimeStamp timestamp={pathway?.updatedAt || ""} />
        </div>
        &nbsp;
      </div>
      <a
        className="btn btn-sm bg-light text-blue font_family_nunito_sans mt-2"
        onClick={() => {
          if (reference) {
            reference.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          } else {
            return;
          }
        }}
      >
        {t("Start Pathway")}
      </a>
      {/* <div style={{ display: "flex" }}>
        <div>{t("last_updated_on")}</div>{" "}
        <TimeStamp timestamp={pathway?.updatedAt || ""} />{" "}
      </div>{" "}
      <span>English, Hindi and Marathi</span> */}
    </div>
  );
}

export default PathwayDescriptionHero;
