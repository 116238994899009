import {
  createSelector,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { logout } from "../authSlice";
import { Certificate } from "src/interfaces/Certificate";
import {
  clearCertificates,
  getAllCertificates,
} from "src/actions/certificateActions";

export const certificatesAdapter = createEntityAdapter<Certificate>();

export const certificatesSlice = createSlice({
  name: "certificates",
  initialState: certificatesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCertificates.fulfilled, (state, { payload }) => {
      certificatesAdapter.upsertMany(state, payload);
    });
    builder.addCase(clearCertificates.fulfilled, (state, { payload }) => {
      certificatesAdapter.upsertMany(state, payload);

      // certificatesAdapter.getInitialState();
    });
    builder.addCase(logout.fulfilled, () =>
      certificatesAdapter.getInitialState()
    );
  },
});

const { selectById, selectAll } = certificatesAdapter.getSelectors();
const getCertificatesState = (state: RootState) => state.certificates;

export const certificateSelectors = {
  // usersSelector: certificatesAdapter.getSelectors((s: RootState) => s.users),
  // certificates: (state: RootState) => state.users.entities,
  selectAllCertificates: () =>
    createSelector(getCertificatesState, (state) => selectAll(state)),
  // selectUserEntity: (id: number) =>
  //   createSelector(getUsersState, (state) => selectById(state, id)),
};
