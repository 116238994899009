import { AiFillLock } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logout } from "src/store/authSlice";
import { useAppDispatch } from "src/store/store";
function UnauthorizedAccess() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <AiFillLock style={{ fontSize: "2.5rem", marginRight: "1rem" }} />
          <h1 style={{ margin: "0" }}>Unauthorized</h1>
        </div>
        <div style={{ marginTop: "1rem", width: "100%", textAlign: "center" }}>
          <h6>You do not have access to this page.</h6>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <button
          className="login_page_submit_button"
          style={{ marginRight: "1rem" }}
          onClick={() => history.goBack()}
        >
          Go Back
        </button>
        <button
          className="login_page_submit_button"
          onClick={() => dispatch(logout())}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default UnauthorizedAccess;
