import { useHistory } from "react-router-dom";
import { Course } from "src/interfaces/Course";
import { PathWay } from "src/interfaces/PathWay";

interface Props {
  courseOrPathway: Course | PathWay;
  isCourse: boolean;
}

function SearchCard({ courseOrPathway, isCourse }: Props) {
  const history = useHistory();

  const onItemClick = () => {
    if (isCourse) history.push(`/course-description/${courseOrPathway?.slug}`);
    else history.push(`/pathway/description/${courseOrPathway?.slug}`);
  };

  return (
    <a
      className="list-group-item list-group-item-action border-0"
      aria-current="true"
      onClick={onItemClick}
    >
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{courseOrPathway.title}</h5>
        <small>{isCourse ? "Course" : "Pathway"}</small>
      </div>
      <div style={{ height: "54px" }} className="overflow-hidden">
        <p className="mb-1">{courseOrPathway.excerpt}</p>
      </div>
    </a>
  );
}

export default SearchCard;
