import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../store";
import { getNotifications } from "src/actions/notificationActions";
import { notificationsSelector } from "./notificationSlice";

const notificationsPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: notificationsPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const notificationsPaginationSlice = createSlice({
  name: "notifications/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      return {
        pages: notificationsPaginationAdapter.upsertOne(
          { ...state.pages },
          {
            id: parseInt(payload.currentPage),
            items: payload?.data?.map((item: any) => item.id),
          }
        ),
        total: payload.pages,
        totalItems: payload.items,
      };
    });

    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = notificationsPaginationAdapter.getSelectors(
  (s: RootState) => s.notificationsPagination.pages
);

export const notificationPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        notificationsSelector.notificationSelector.selectById(state, id)
      ) || [],
  getIds: (state: RootState, page: number) =>
    adapterSelectors.selectById(state, page),
  totalPages: (state: RootState) => state.coursesPagination.total,
  totalNotifications: (state: RootState) => state.coursesPagination.totalItems,
};
