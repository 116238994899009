import { useEffect, useState } from "react";
import FormLabel from "src/components/Common/utilities/FormLabel";
import { Button, Image, Row, Col, Upload, message, Progress } from "antd";
import Translation from "src/components/Common/utilities/Translation";
import { api, extractStandardResponseData } from "src/api/api";
import {
  LoadingOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { useAppDispatch } from "src/store/store";
import { editDetails } from "src/store/coursesSlices/editCourseSlice";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
interface Props {
  urlInput: string;
  onChange: (e) => void;
}

function CourseVideo({ urlInput, onChange }: Props) {
  const [url, setUrl] = useState(urlInput);
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useAppDispatch();

  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (fileList) {
      const formData = new FormData();
      setShowUpload(true);
      formData.append("file", fileList);
      try {
        if (e.file.type === "video/mp4") {
          const image = await api
            .post("/courses/files", formData, config)
            .then(extractStandardResponseData);
          onChange(image.id);
          setUrl(image.attachmentUrl);
          setLoading(false);
          message.success("Image successfully uploaded");
        } else {
          message.error("Upload format should be Image");
        }
      } catch (error) {
        message.error("error uploading ");
      }
    }
  };

  const uploadButton = (
    <div>
      {show ? (
        <div style={{ fontSize: "200%" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </div>
      ) : (
        <>
          {loading ? (
            uploadProgress === 100 ? (
              <LoadingOutlined style={{ fontSize: "220%", color: "#52c41a" }} />
            ) : (
              <Progress percent={uploadProgress} type="circle" width={50} />
            )
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PlusOutlined />
              Upload Video
            </div>
          )}
        </>
      )}
    </div>
  );

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };

  useEffect(() => {
    setUrl(urlInput);
  }, [urlInput]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <FormLabel name="Course Video" />
      {url && !showUpload ? (
        <Col>
          <Row>
            <div style={{ width: "15rem" }}>
              <VideoPlayer url={url} />
            </div>
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => setShowUpload(true)}
              style={{ width: "300px" }}
            >
              <Translation input="update_course_image" />
            </Button>
          </Row>
        </Col>
      ) : (
        <Upload
          name="test"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          customRequest={customRequest}
          multiple={false}
          fileList={fileListArray}
          onPreview={(e) => {}}
          openFileDialogOnClick={true}
        >
          {url ? <VideoPlayer url={url} /> : uploadButton}
        </Upload>
      )}
    </div>
  );
}

export default CourseVideo;
