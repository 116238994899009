import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";
import { useSelector } from "react-redux";
import { getSingleCourse } from "src/actions/courseActions/courseActions";

interface Params {
  course_id: string;
}

function View() {
  const courseId = useParams<Params>().course_id;
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (course) setLoading(false);
    else
      dispatch(getSingleCourse(parseInt(courseId))).then(
        setLoading.bind(null, false)
      );
    document.title = `Learn Sure . ${course?.title}`;
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <iframe
        src={course?.courseAttachments?.attachmentUrl}
        style={{ width: "100%", height: "100%" }}
      ></iframe>
    </div>
  );
}

export default View;
