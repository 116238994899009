import UserIcon from "src/Assets/media/user-icon.svg";
import LogoutIcon from "src/Assets/media/logout-icon.svg";
import HelpIcon from "src/Assets/media/help-icon.svg";
import SettingsIcon from "src/Assets/media/gear-icon.svg";
import EditProfileIcon from "src/Assets/media/user-filled-icon.svg";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import { authSelectors, logout } from "src/store/authSlice";
import { useAppDispatch } from "src/store/store";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavUserIcon() {
  const userDetails = useSelector(authSelectors.details);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <li className="nav-item px-lg-2 dropdown d-menu px-md-5 px-sm-5 px-5">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="dropdown01"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={UserIcon} width="23px" alt="" />
          <span className="text-light mx-3 d-lg-none">{t("profile")}</span>
        </a>
        <div
          className="dropdown-menu  sm-menu dropdown-menu-right py-4 border-0 shadow-sm"
          aria-labelledby="dropdown01"
        >
          <a className="dropdown-item" href="#">
            <div className="d-flex align-items-center mb-2">
              <img
                src={
                  !userDetails?.resources?.attachmentUrl
                    ? ProfilePlaceHolder
                    : userDetails.resources.attachmentUrl
                }
                alt=""
                className="rounded-circle"
                width="48px"
                height="48px"
              />
              <div className="mx-3">
                <h6 className="m-0">
                  {userDetails.firstName} {userDetails.lastName}
                </h6>
                <p className="small m-0">{userDetails.email}</p>
              </div>
            </div>
          </a>
          <div className="dropdown-divider py-0"></div>

          <div
            className="dropdown-item d-flex w-100  cursor_pointer"
            data-bs-toggle="modal"
            data-bs-target="#profilePhotoChangeModal"
          >
            <div className="mx-2">
              <img
                src={EditProfileIcon}
                width="15px"
                height="15px"
                alt=""
                className="mb-1"
              />
            </div>
            <div>{t("edit_profile")}</div>
          </div>
          <div className="dropdown-item d-flex w-100 cursor_pointer">
            <div className="mx-2">
              <img
                src={SettingsIcon}
                width="15px"
                height="15px"
                alt=""
                className="mb-1"
              />
            </div>
            <div>{t("settings")}</div>
          </div>
          <div className="dropdown-item d-flex w-100 cursor_pointer">
            <div className="mx-2">
              <img
                src={HelpIcon}
                width="15px"
                height="15px"
                alt=""
                className="mb-1"
              />
            </div>
            <div>{t("help")}</div>
          </div>
          <div
            className="dropdown-item d-flex w-100 cursor_pointer"
            onClick={() => {
              dispatch(logout()).then(() => history.push("/login"));
            }}
          >
            <div className="mx-2">
              <img
                src={LogoutIcon}
                width="15px"
                height="15px"
                alt=""
                className="mb-1"
              />
            </div>
            <div>{t("log_out")}</div>
          </div>
        </div>
      </li>
    </div>
  );
}

export default NavUserIcon;
