import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Button, Select, Spin, Row, Col, Card, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { searchCourse } from "src/api/searchActions";
import { Course, PathWayCourse } from "src/interfaces/Course";
import { useAppDispatch } from "src/store/store";
import ImageThumbnail from "../../../../components/Admin-Author-Components/ImageThumbnail";

interface Props {
  done: boolean;
  courses?: PathWayCourse[];
}

function CoursesDraggableFormItem({ done, courses }: Props) {
  const [fetching, setFetching] = useState(false);
  const [data, setData]: any = useState([]);
  const [selectedCourses, setSelectedCourses]: any = useState(
    courses ? [...courses] : []
  );
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    let temp = [...selectedCourses];
    const [reorderedItem] = temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, reorderedItem);
    console.log(temp);

    setSelectedCourses([...temp]);
  };

  const fetchCourses = (value: any) => {
    setFetching(true);
    setData([]);
    if (value.length > 3) {
      dispatch(searchCourse(value))
        .then(unwrapResult)
        .then((res) => {
          console.log(res);
          setFetching(false);
          setData(res);
        })
        .catch(() => {
          setFetching(false);
        });
    } else {
      setFetching(false);
    }
  };

  const removeCourseFromPathway = (id: number) => {
    setSelectedCourses(
      selectedCourses.filter(
        (pathwayCourseOrCourse: PathWayCourse & Course) =>
          pathwayCourseOrCourse.id !== id
      )
    );
  };

  useEffect(() => {}, [courses]);

  return (
    <>
      <Select
        showSearch
        labelInValue
        mode="multiple"
        placeholder="Search Course"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={fetchCourses}
        style={{ width: "100%", marginBottom: "30px" }}
        autoClearSearchValue={true}
      >
        {data?.map((course: Course, index: number) => {
          return (
            <Option value={course?.id} key={course?.id}>
              <div
                onClick={() => {
                  setSelectedCourses([...selectedCourses, course]);
                }}
              >
                {course?.title}
              </div>
            </Option>
          );
        })}
      </Select>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="createPathways">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {selectedCourses.map(
                (
                  pathwayCourseOrCourse: PathWayCourse & Course,
                  index: number
                ) => {
                  if (pathwayCourseOrCourse.courses !== null) {
                    return (
                      <Draggable
                        key={pathwayCourseOrCourse?.id}
                        draggableId={`${pathwayCourseOrCourse?.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Card hoverable={true}>
                              <Row>
                                <Col sm={4}>
                                  <ImageThumbnail
                                    resources={
                                      pathwayCourseOrCourse?.courses
                                        ?.resources ||
                                      pathwayCourseOrCourse?.resources
                                    }
                                    width={100}
                                    height={100}
                                  />
                                </Col>
                                <Col sm={18}>
                                  <Row>
                                    <h6>
                                      {pathwayCourseOrCourse?.courses?.title ||
                                        pathwayCourseOrCourse?.title}
                                    </h6>
                                  </Row>
                                  <Row>
                                    {pathwayCourseOrCourse?.courses?.excerpt ||
                                      pathwayCourseOrCourse?.excerpt}
                                  </Row>
                                </Col>
                                <Col sm={2}>
                                  <Row justify="end" align="top">
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      danger
                                      size="small"
                                      onClick={() => {
                                        Modal.confirm({
                                          content:
                                            "Are you sure you want to REMOVE this course from the Pathway",
                                          onOk: () => {
                                            removeCourseFromPathway(
                                              pathwayCourseOrCourse?.id
                                            );
                                          },
                                        });
                                      }}
                                    >
                                      X
                                    </Button>
                                  </Row>
                                </Col>
                              </Row>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {done ? (
        <Form.Item name="courses" initialValue={selectedCourses}>
          <Input type="hidden" />
        </Form.Item>
      ) : null}
    </>
  );
}

export default CoursesDraggableFormItem;
