import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Button,
  Col,
  Row,
  Tag,
  Menu,
  Dropdown,
  Space,
  Avatar,
  Input,
  Pagination,
} from "antd";
import { useSelector } from "react-redux";
import Column from "antd/lib/table/Column";
import {
  getUsersListOldFirst,
  getUsersListNewFirst,
  searchUsers,
  clearUsers,
} from "../../../store/users/userSlice";
import { RootState, useAppDispatch } from "../../../store/store";
import { StatusToggler } from "../../../components/AdminAuthorMentorComponents/Common/StatusToggler";
import { User, Role } from "../../../interfaces/User";
import PopUpComp from "../../../components/AdminAuthorMentorComponents/Common/PopUpComp";
import Tags from "src/components/AdminAuthorMentorComponents/Common/Tags";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import { deleteUser, getUsers } from "src/actions/userActions";
import { setTabTitle } from "src/utils/utils";
import { userPaginationSelectors } from "src/store/users/userPaginationSlice";

function UsersListPage() {
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState(0);

  const pageToSearch = searchTerm === "" ? page : searchPage;

  const users = useSelector((state: RootState) =>
    userPaginationSelectors.get(state, pageToSearch)
  ) as User[];

  const availablePages = useSelector(userPaginationSelectors.pages.selectIds);
  const totalUsers = useSelector(userPaginationSelectors.totalusers);
  const dispatch = useAppDispatch();
  const [selectedFilter, setSelectedFilter] = useState<any>("Clear");
  const { SubMenu } = Menu;
  const { Search } = Input;

  const roleReturnFunction = (user: User) => {
    if (user.role === Role.ADMIN) return <Tag color="red">{user.role}</Tag>;
    if (user.role === Role.AUTHOR) return <Tag color="blue">{user.role}</Tag>;
    if (user.role === Role.LEARNER) return <Tag color="green">{user.role}</Tag>;
    if (user.role === Role.MENTOR) return <Tag color="orange">{user.role}</Tag>;
  };

  const filtersFunction = (users: User[], role: any) => {
    return users?.filter((user: User) => {
      if (role === Role.ADMIN) {
        return user.role === Role.ADMIN;
      } else if (role === Role.AUTHOR) {
        return user.role === Role.AUTHOR;
      } else if (role === Role.LEARNER) {
        return user.role === Role.LEARNER;
      } else if (role === Role.MENTOR) {
        return user.role === Role.MENTOR;
      } else if (role === "Clear") {
        return user;
      }
    });
  };

  useEffect(() => {
    if (searchTerm) {
      if (searchTerm.length > 2) {
        setSearchLoading(true);
        setLoading(true);

        setSearchLoading(true);
        dispatch(clearUsers())
          .then(() =>
            dispatch(searchUsers({ name: searchTerm, page: searchPage }))
          )
          .then(() => {
            setSearchLoading(false);
            setLoading(false);
          });
        return;
      }
      if (searchTerm.length <= 2) {
        setSearchPage(0);
      }
      return;
    } else {
      setLoading(true);
      setSearchPage(0);
      dispatch(getUsers({ page })).then(setLoading.bind(null, false));

      setTabTitle("Users");
    }
  }, [searchPage, searchTerm, page, dispatch, availablePages]);

  const filterMenu = (
    <Menu
      onClick={(e) => {
        setSelectedFilter(e.key);
      }}
    >
      <Menu.ItemGroup title="Filter by">
        <SubMenu title="Role" key="2">
          <Menu.Item key={Role.ADMIN}>{Role.ADMIN}</Menu.Item>
          <Menu.Item key={Role.AUTHOR}>{Role.AUTHOR}</Menu.Item>
          <Menu.Item key={Role.LEARNER}>{Role.LEARNER}</Menu.Item>
          <Menu.Item key={Role.MENTOR}>{Role.MENTOR}</Menu.Item>
        </SubMenu>
        <Menu.Item key={"Clear"}>Clear Filters</Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const tableHeader = () => (
    <Row justify="space-between">
      <Col>
        <Space>
          <h5 style={{ margin: 0 }}>Users</h5>
          <Search
            placeholder="Search by name"
            loading={searchLoading}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Space>
      </Col>
      <Space>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="primary">Sort</Button>
        </Dropdown>
        <Dropdown overlay={filterMenu} trigger={["click"]}>
          <Button type="primary">Filters</Button>
        </Dropdown>
      </Space>
    </Row>
  );

  const menu = (
    <Menu>
      <Menu.ItemGroup title="Sort by">
        <SubMenu title="Time" key="1">
          <Menu.Item onClick={() => dispatch(getUsersListNewFirst())}>
            Latest first
          </Menu.Item>
          <Menu.Item onClick={() => dispatch(getUsersListOldFirst())}>
            Oldest first
          </Menu.Item>
        </SubMenu>
        <Menu.Item>Clear Sort</Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <>
      <Row justify="center" align="middle">
        <Col sm={24}>
          <Table
            title={tableHeader}
            pagination={false}
            dataSource={filtersFunction(users, selectedFilter)}
            loading={loading}
            rowKey={(s) => s.id}
          >
            <Column
              title={<TableColTitle title="Cover" />}
              dataIndex="actions"
              key="actions"
              render={(_, user: User) => (
                <Avatar
                  shape="circle"
                  size={64}
                  src={user?.resources?.attachmentUrl || ProfilePlaceHolder}
                />
              )}
            />

            <Column
              filterDropdownVisible
              title={<TableColTitle title="Name" />}
              key="name"
              render={(_, user: User, index) => (
                <div key={index}>
                  {user.firstName}&nbsp;{user.lastName}
                </div>
              )}
            />
            <Column
              title={<TableColTitle title="Role" />}
              key="role"
              render={(_, user: User, index) => (
                <div key={index}>{roleReturnFunction(user)}</div>
              )}
            />
            <Column
              title={<TableColTitle title="Tags" />}
              dataIndex="actions"
              key="actions"
              render={(_, user: User) => <Tags tags={user.usersTags} />}
            />
            <Column
              title={<TableColTitle title="Status" />}
              render={(_, user: User) => <StatusToggler user={user} />}
            />
            <Column
              title={<TableColTitle title="Options" />}
              key="options"
              dataIndex="actions"
              render={(record, user: User) => (
                <PopUpComp
                  deleteMethod={deleteUser(user.id)}
                  editLink={`/admin/${user.id}/edit-user`}
                />
              )}
            />
          </Table>
          <Row justify="end" style={{ marginTop: "20px" }}>
            {searchTerm ? (
              <Pagination
                current={searchPage + 1}
                total={totalUsers}
                showSizeChanger={false}
                onChange={(p) => {
                  setSearchPage(p - 1);
                }}
              />
            ) : (
              <Pagination
                current={page + 1}
                total={totalUsers}
                showSizeChanger={false}
                onChange={(p) => {
                  setPage(p - 1);
                }}
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default UsersListPage;
