import { useState } from "react";
import { Form, Select, Spin } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../../store/store";
import { searchTags } from "../../../../store/tagsSlice";
import { Tag } from "src/interfaces/Tag";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  onChange?: (e) => void;
  defaultValue?: any;
}

function SearchTags({ name, onChange, defaultValue }: Props) {
  const [searchInput, setSearchInput] = useState("");
  const [fetching, setFetching] = useState(false);
  const [data, setData]: any = useState([]);
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const { t } = useTranslation();

  const showSearchFunction = (data: Tag[], currentSearchValue: string) => {
    if (
      data.filter(
        (tag: Tag) =>
          tag.value.toLocaleLowerCase() ===
          currentSearchValue.toLocaleLowerCase()
      ).length === 1
    )
      return false;
    else return true;
  };
  const fetchTags = (value: any) => {
    setSearchInput(value);
    setFetching(true);
    setData([]);
    if (value.length > 1) {
      dispatch(searchTags(value))
        .then(unwrapResult)
        .then((res) => {
          setData(res);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    } else {
      setFetching(false);
    }
  };

  return (
    <div>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: "Please add tags for the course",
          },
        ]}
      >
        <Select
          showSearch={showSearchFunction(data, searchInput)}
          allowClear
          labelInValue
          mode="tags"
          placeholder={t("add_tags")}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          defaultValue={defaultValue || []}
          // filterOption={(input, option) => {
          //   if (input.toLowerCase() === option?.label?.toString().toLowerCase())
          //     return false;
          //   else return true;
          // }}
          filterOption={(input, option) =>
            input.toLowerCase() === option?.label?.toString().toLowerCase()
              ? false
              : true
          }
          onSearch={fetchTags}
          style={{ width: "100%" }}
          autoClearSearchValue={true}
          onChange={onChange}
        >
          {data?.map((item: any) => (
            <Option value={item.id} key={item.id} label={item.value}>
              {item.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export default SearchTags;
