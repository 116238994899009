import { Card, Table, Avatar } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { analyticsSelectors } from "src/store/analyticSlice";
import { useAppDispatch } from "src/store/store";
import { getMostPopularCourses } from "src/actions/analyticsActions";
import Column from "antd/lib/table/Column";
import { Course } from "src/interfaces/Course";
import { useTranslation } from "react-i18next";

function PopularCoursesTable() {
  const dispatch = useAppDispatch();
  const mostPopularCourses = useSelector(
    analyticsSelectors.selectMostPopularCourses()
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!mostPopularCourses) {
      dispatch(getMostPopularCourses()).then(setLoading.bind(null, false));
    } else setLoading(false);
  }, []);

  return (
    <Card title={<>{t("most_popular_courses")}</>}>
      <Table
        showHeader={false}
        dataSource={mostPopularCourses?.slice(0, 5)}
        pagination={false}
        rowKey={(s) => s.id}
        loading={loading}
      >
        <Column
          title={<>{t("cover")}</>}
          key="cover"
          render={(_, course: Course) => (
            <>
              <Avatar
                shape="circle"
                size={40}
                src={course?.resources?.attachmentUrl}
              />
            </>
          )}
        />
        <Column
          title={<>{t("title")}</>}
          key="title"
          render={(_, course: Course) => course?.title}
        />
        <Column
          title={<>{t("views")}</>}
          key="views"
          render={(_, course: Course) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>{course?.views}</div>&nbsp;
              <div> Visits</div>
            </div>
          )}
        />
      </Table>
    </Card>
  );
}

export default PopularCoursesTable;
