import { useEffect, useState } from "react";
import { Popover, Spin, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import { MoreOutlined } from "@ant-design/icons";
import Translation from "src/components/Common/utilities/Translation";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";

interface PopUpCompPropsI {
  deleteMethod: any;
  editLink: string;
  translateLink?: string;
}

function PopUpComp({ deleteMethod, editLink, translateLink }: PopUpCompPropsI) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const role = useSelector(authSelectors.role);

  useEffect(() => {}, [deleteMethod, editLink]);
  return (
    <Popover
      trigger="click"
      visible={show}
      onVisibleChange={(visible) => setShow(visible)}
      content={
        <>
          <div
            style={{ marginBottom: "8px" }}
            className="cursor_pointer hover_color_change"
            onClick={() => {
              Modal.confirm({
                content: "Are you sure you want to DELETE this entry",
                onOk: () => {
                  setLoading(true);
                  dispatch(deleteMethod).then(() => {
                    setLoading(false);
                    setShow(false);
                    message.success("Deleted Sucessfully");
                  });
                },
              });
            }}>
            {loading ? <Spin /> : <Translation input="delete" />}
          </div>
          <div>
            <div
              style={{ marginBottom: "8px" }}
              className="cursor_pointer hover_color_change"
              onClick={() => history.push(editLink)}>
              <Translation input="edit" />
            </div>
          </div>
          {role === Role.ADMIN && (
            <div>
              {translateLink && (
                <div
                  className="cursor_pointer hover_color_change"
                  onClick={() => history.push(translateLink)}>
                  {/* <Translation input="edit" /> */}
                  Translate
                </div>
              )}
            </div>
          )}
        </>
      }>
      {/* <div style={{ cursor: "pointer" }} onClick={() => setShow((s) => !s)}> */}
      <MoreOutlined style={{ fontSize: 25, color: "black" }} />
      {/* </div> */}
    </Popover>
  );
}

export default PopUpComp;
