import { Form, Button, Input, Row, Modal } from "antd";
import { useLocation } from "react-router-dom";
import EditUploadVideo from "src/components/AdminAuthorMentorComponents/Common/Course/EditCourse/EditUploadVideo";
import Translation from "src/components/Common/utilities/Translation";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import UploadVideo from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadVideo";
import { LanguageCode } from "src/enums/Language";

const VideoLessons = ({
  fieldName,
  form,
  field,
  done,
  moduleId,
}: {
  fieldName: any;
  form: any;
  field: any;
  done: boolean;
  moduleId: number;
}) => {
  const location = useLocation();

  const translatedToLanguage: LanguageCode = location.pathname
    .split("/")[4]
    ?.split("-")[1] as LanguageCode;
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;

  return (
    <Form.List name={[fieldName, "coursesVideos"]}>
      {(beds, { add, remove }) => {
        return (
          <>
            {beds.map((bed, index2) => (
              <div
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                <Row justify="space-between">
                  <Form.Item
                    {...bed}
                    name={[bed.name, "title"]}
                    fieldKey={[bed.fieldKey, "title"]}
                    key={index2}
                    rules={[
                      {
                        required: true,
                        message: "Video title Missing",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  {!isTranslationPath && (
                    <Button
                      onClick={() => {
                        Modal.confirm({
                          content: "Are you sure you want to delete this Video",
                          onOk: () => remove(bed.name),
                        });
                      }}
                      danger
                    >
                      <Translation input="remove_video" />
                    </Button>
                  )}
                </Row>

                {form.getFieldsValue()?.coursesModules[field.name]
                  .coursesVideos[bed.name]?.resources?.attachmentUrl ? (
                  <VideoPlayer
                    url={
                      form.getFieldsValue().coursesModules[field.name]
                        .coursesVideos[bed.name].resources.attachmentUrl
                    }
                  />
                ) : (
                  <EditUploadVideo
                    done={done}
                    field={bed}
                    positionId={bed.name}
                    moduleId={moduleId}
                  />
                )}
              </div>
            ))}
            {!isTranslationPath && (
              <Row justify="center">
                <Button type="dashed" onClick={() => add()}>
                  <Translation input="add_video" />
                </Button>
              </Row>
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default VideoLessons;
