import { authSelectors } from "../../store/authSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function LearnerProfilePage() {
  const userDetails = useSelector(authSelectors.details);
  const history = useHistory();
  document.title = `Learn Sure . Profile`;

  return (
    <div style={{ backgroundColor: "#f5f9ff" }}>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <div
              className="mr-4"
              style={{
                backgroundImage: `url("${userDetails.resources.attachmentUrl}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "400px",
                height: "400px",
                borderRadius: "50%",
              }}
            ></div>
          </div>
          <div className="col-md-8">
            <div className="card" style={{ marginLeft: "15px" }}>
              <div className="card-body">
                <h5 className="card-title">Personal Information</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  <h6>Name:</h6>&nbsp;
                  <h6 className="text-primary">{`${userDetails.firstName} ${userDetails.lastName}`}</h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  <h6>Email:</h6>&nbsp;
                  <h6 className="text-primary">{userDetails.email}</h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  <h6>Designation:</h6>&nbsp;
                  <h6 className="text-primary">{userDetails.designation}</h6>
                </div>
                <div style={{}}>
                  <h6>Description:</h6>&nbsp;
                  <div style={{ marginTop: "-20px" }}>
                    {userDetails.description}
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      history.push("/learner/profile-edit");
                    }}
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnerProfilePage;
