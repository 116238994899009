import { useHistory } from "react-router-dom";
import { PathWay } from "src/interfaces/PathWay";
import PathwayViewIcon from "./PathwayIcons/PathwayViewIcon";
import LikePathwayIcon from "./PathwayIcons/LikePathwayIcon";
import PathwayCommentIcon from "./PathwayIcons/PathwayCommentIcon";

interface Props {
  pathway: PathWay;
}

function PathwayCard({ pathway }: Props) {
  const history = useHistory();

  return (
    <div className="mt-1 mb-3 noselect">
      <div className="card w-100 cursor_pointer hover_effect border_radius border_color overflow-hidden">
        <div>
          <div
            className="position-relative"
            onClick={() =>
              history.push(`/pathway/description/${pathway?.slug}`)
            }
          >
            <img
              style={{ objectFit: "cover", height: "171px" }}
              src={pathway?.resources?.attachmentUrl}
              alt="pathway-image"
              className="img-fluid w-100"
            />
            <div
              className="position-absolute"
              style={{ bottom: "1rem", left: "1rem" }}
            >
              <span className="badge bg-primary p-1">Pathway</span>
            </div>
          </div>

          <div
            className="card-body fs-6 d-flex flex-column justify-content-between mb-3"
            style={{ height: "123px", padding: "20px" }}
          >
            <h6
              className="mt-2 mb-3 fw-6 overflow-hidden"
              style={{
                height: "2.5em",
              }}
              onClick={() =>
                history.push(`/pathway/description/${pathway?.slug}`)
              }
            >
              {pathway?.title}
            </h6>
            <div className="d-flex justify-content-between">
              <PathwayViewIcon views={pathway.views} />
              <LikePathwayIcon pathway={pathway} />
              <PathwayCommentIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PathwayCard;
