import React from "react";
import Loader from "react-loader-spinner";

interface Props {
  loading: boolean;
  name: string | JSX.Element;
  onClick?: () => void;
  // disabled?: boolean;
}

function ButtonWithLoading({ loading, name, onClick }: Props) {
  return (
    <button
      type="submit"
      className="login_page_submit_button"
      onClick={onClick}
    >
      <div>{name}</div>
      {loading ? (
        <div style={{ marginLeft: "10px" }}>
          <Loader type="TailSpin" color="white" height={21} width={21} />
        </div>
      ) : null}
    </button>
  );
}

export default ButtonWithLoading;
