import React, { useEffect } from "react";
import { Course } from "src/interfaces/Course";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import CourseViewIcon from "src/components/LearnerComponents/Course/CourseIcons/CourseViewIcon";
import Like from "src/components/LearnerComponents/Course/CourseIcons/Like";
import CourseCommentIcon from "src/components/LearnerComponents/Course/CourseIcons/CourseCommentIcon";
import parse from "html-react-parser";
interface Props {
  course: Course;
}
function WebWatchContent({ course }: Props) {
  useEffect(() => {
    console.log("course", course);
  });
  return (
    <div className="container clearance">
      <div style={{ width: "50vw", margin: "0 auto" }} className="pt-5">
        <VideoPlayer url={course.videos.attachmentUrl} />
      </div>
      <h2 style={{ textAlign: "center", padding: "20px" }}>{course.title}</h2>
      <div className="d-flex justify-content-center gap-3 mb-3">
        <CourseViewIcon views={course?.views} />
        <Like course={course} />
        <CourseCommentIcon />
      </div>
      <h4 style={{ textAlign: "center", padding: "20px" }}>
        {parse(course.description ? course.description : "")}
      </h4>
    </div>
  );
}

export default WebWatchContent;
