import { Form, Button, Row, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import SectionSelector from "src/components/SectionSelector";
import RenderMedia from "./RenderMedia";

const Sections = ({
  fieldName,
  form,
  field,
  done,
  parentField,
}: {
  fieldName: any;
  form: any;
  field: any;
  done: boolean;
  parentField: any;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;
  return (
    <Form.List name={[fieldName, "lessonsAttachments"]}>
      {(lessonsAttachments, { add, remove }) => {
        return (
          <>
            {lessonsAttachments.map(
              (lessonsAttachment: any, lessAttachIndex) => (
                <div
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {form.getFieldsValue().coursesModules[parentField.name]
                    .lessons[field.name].lessonsAttachments[
                    lessonsAttachment.name
                  ] ? (
                    <RenderMedia
                      field={lessonsAttachment}
                      done={done}
                      sectionData={
                        form.getFieldsValue().coursesModules[parentField.name]
                          .lessons[field.name].lessonsAttachments[
                          lessonsAttachment.name
                        ]
                      }
                    />
                  ) : (
                    <SectionSelector
                      done={done}
                      field={lessonsAttachment}
                      index={lessAttachIndex}
                      edit={true}
                    />
                  )}
                  {!isTranslationPath && (
                    <Row justify="end">
                      <Button
                        onClick={() =>
                          Modal.confirm({
                            content: `${t("confirm.delete_section")}`,
                            onOk: () => remove(lessonsAttachment.name),
                          })
                        }
                        danger
                      >
                        <Translation input="remove_section" />
                      </Button>
                    </Row>
                  )}
                </div>
              )
            )}
            {!isTranslationPath && (
              <Row justify="center">
                <Button type="dashed" onClick={() => add()}>
                  <Translation input="add_section" />
                </Button>
              </Row>
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default Sections;
