import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";

export const getMostFrequentUsers = createAsyncThunk(
  "analytics/getMostFrequentUsers",
  async () =>
    await api
      .get("analytics/getMostFrequentUsers")
      .then(extractStandardResponseData)
);

export const getMostPopularCourses = createAsyncThunk(
  "analytics/getMostPopularCourses",
  async () =>
    await api
      .get("analytics/getMostFrequentCourses")
      .then(extractStandardResponseData)
);

export const getAnalytics = createAsyncThunk(
  "analytics/get",
  async () => await api.get("/admin/count").then((res) => res.data)
);
