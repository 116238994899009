import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { logout } from "../../authSlice";
import { Session } from "src/interfaces/Session";
import { clearPaginations, getSessions } from "src/actions/sessionsActions";
import { Page } from "src/interfaces/Page";
import { sessionSelectors } from "src/store/mentorSlices/sessionsSlices/sessionsSlice";

const sessionsPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: sessionsPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const sessionsPaginationSlice = createSlice({
  name: "sessions/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSessions.fulfilled, (state, { payload }) => ({
      pages: sessionsPaginationAdapter.upsertOne(
        { ...state.pages },
        {
          id: parseInt(payload.currentPage),
          items: payload?.data?.map((session: Session) => session.id),
        }
      ),
      total: payload.pages,
      totalItems: payload.items,
    }));
    builder.addCase(clearPaginations.fulfilled, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = sessionsPaginationAdapter.getSelectors(
  (s: RootState) => s.sessionsPagination.pages
);

export const sessionsPaginationSelectors = {
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        sessionSelectors.sessionsSelector.selectById(state, id)
      ) || [],
  totalSessions: (state: RootState) => state.sessionsPagination.totalItems,
};
