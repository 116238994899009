import { useState } from "react";
import { CourseFormat } from "src/enums/CourseFormat";
import { Course } from "src/interfaces/Course";
import { Button, Modal } from "antd";
import UploadScromCourse from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadScromCourse";
import UploadCourse from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadCourse";
import { getZipFileNameFromUrl } from "src/utils/utils";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";

interface Props {
  course: Course;
  done: boolean;
}

function ZipFields({ course, done }: Props) {
  const [showNewUpload, setShowNewUpload] = useState(false);

  return (
    <>
      <FormLabel name="attachment" />
      {showNewUpload ? (
        <div>
          {course.type === CourseFormat.SCROM ? (
            <UploadScromCourse done={done} />
          ) : null}
          {course.type === CourseFormat.XAPI ? (
            <UploadCourse done={done} />
          ) : null}
          <Button
            onClick={() => {
              Modal.confirm({
                content: "The new zip file uploaded will not be saved",
                onOk: () => setShowNewUpload(false),
              });
            }}
            size="small"
          >
            Cancel
          </Button>
        </div>
      ) : (
        <>
          <div>
            {getZipFileNameFromUrl(course?.courseAttachments?.attachmentUrl)}
          </div>
          <Button
            onClick={() => {
              Modal.confirm({
                content: "Are you sure yout want to upload a new file",
                onOk: () => setShowNewUpload(true),
              });
            }}
          >
            <Translation input="upload_new_zip" />
          </Button>
        </>
      )}
    </>
  );
}

export default ZipFields;
