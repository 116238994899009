interface Props {
  onChange: (any) => void;
  option: string;
}

function QuizOption({ onChange, option }: Props) {
  return (
    <li className="list-group-item d-flex" key={option}>
      <div className="form-check">
        <input
          onChange={(e) =>
            onChange({
              option: e.target.value,
              isChecked: e.target.checked,
            })
          }
          className="form-check-input"
          type="checkbox"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          value={option}
        />
      </div>
      <div>{option}</div>
    </li>
  );
}

export default QuizOption;
