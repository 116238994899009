import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../store";
import { PathWay } from "src/interfaces/PathWay";
import { getPathways } from "src/actions/PathwayActions/pathwayActions";
import {
  createPathway,
  editPathway,
  pathwaysSelectors,
} from "src/store/pathways/pathwaysSlice";
import { getLearnerPathway } from "src/actions/PathwayActions/LearnerPathwayActions";
const pathwaysPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: pathwaysPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const pathwaysPaginationSlice = createSlice({
  name: "pathways/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPathways.fulfilled, (state, { payload }) => ({
      pages: pathwaysPaginationAdapter.upsertOne(
        { ...state.pages },
        {
          id: parseInt(payload.currentPage),
          items: payload?.data?.map((pathway: PathWay) => pathway.id),
        }
      ),
      total: payload.pages,
      totalItems: payload.items,
    }));
    builder.addCase(getLearnerPathway.fulfilled, (state, { payload }) => ({
      pages: pathwaysPaginationAdapter.upsertOne(
        { ...state.pages },
        {
          id: parseInt(payload.currentPage),
          items: payload?.data?.map((pathway: PathWay) => pathway.id),
        }
      ),
      total: payload.pages,
      totalItems: payload.items,
    }));

    builder.addCase(createPathway.fulfilled, () => initialState);
    builder.addCase(editPathway.fulfilled, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = pathwaysPaginationAdapter.getSelectors(
  (s: RootState) => s.pathwaysPagination.pages
);

export const pathwayPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        pathwaysSelectors.pathwaySelector.selectById(state, id)
      ) || [],
  totalPages: (state: RootState) => state.pathwaysPagination.total,
  totalpathways: (state: RootState) => state.pathwaysPagination.totalItems,
};
