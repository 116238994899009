import { LanguageCode } from "src/enums/Language";
import { Client } from "./Client";
import { Resources } from "./Resources";
import { Tag } from "./Tag";

export enum Role {
  ADMIN = "ADMIN",
  LEARNER = "LEARNER",
  MENTOR = "MENTOR",
  AUTHOR = "AUTHOR",
}

export interface User {
  client: Client;
  id: number;
  clientId: 1;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  description: string;
  designation: string;
  attachmentUrl?: string;
  thumbnail?: string;
  usersTags: Tag[];
  resources: Resources;
  lang: LanguageCode;
}
