import { Button, Form, Input, Row, Space, Card, Steps, Col } from "antd";
import { useState } from "react";
import UploadCertificates from "src/components/AdminAuthorMentorComponents/Common/Certificates/UploadCertificates";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import TemplateGrid from "../Templates/TemplateGrid";
import { Template } from "src/interfaces/Template";
import { useAppDispatch } from "src/store/store";
import {
  addCertificate,
  clearCertificates,
} from "src/actions/certificateActions";
import { message } from "antd";
import { useHistory } from "react-router-dom";

function AddCertificateToCourse() {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState<any>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formSubmit = (e) => {
    if (logo.length === 0 || title.length === 0) {
      message.warning("Please fill in rest of details");
      return;
    }
    dispatch(
      addCertificate({
        title,
        logo: logo.map((item) => item.url),
        templateId: selectedTemplate?.id,
      })
    ).then(() => {
      dispatch(clearCertificates()).then(() =>
        history.push("/admin/certificates")
      );
    });
  };

  const header = (
    <h5>
      <Translation input="add_certificate" />
    </h5>
  );

  const onNext = () => {
    if (!selectedTemplate) {
      message.warning("Please select a template");
      return;
    }
    setCurrentStep(1);
  };

  const nextButton = (
    <Button type="primary" onClick={onNext}>
      Next
    </Button>
  );

  const submitButton = (
    <Button type="primary" onClick={formSubmit}>
      Submit
    </Button>
  );

  const onPrevious = () => {
    setCurrentStep(0);
  };

  const previousButton = (
    <Button
      type="primary"
      onClick={onPrevious}
      disabled={currentStep === 0 ? true : false}
    >
      Previous
    </Button>
  );

  return (
    <Card
      title={header}
      actions={[
        <Space>
          {previousButton}
          {currentStep === 0 ? nextButton : submitButton}
        </Space>,
      ]}
    >
      <Row
        style={{
          visibility: currentStep === 1 ? "hidden" : "visible",
          height: currentStep === 1 ? 0 : "auto",
        }}
      >
        <Row style={{ marginBottom: "20px" }}>
          <h6>Select a Template</h6>
        </Row>
        <TemplateGrid
          onTemplateClick={(e: Template) => setSelectedTemplate(e)}
        />
      </Row>
      <Row
        style={{
          visibility: currentStep === 0 ? "hidden" : "visible",
          height: currentStep === 0 ? 0 : "auto",
        }}
      >
        <Row style={{ marginBottom: "20px" }}>
          <h6>Fill in the details</h6>
        </Row>
        <Form
          // onFinish={formSubmit}
          layout="vertical"
          id="certificateForm"
          style={{ width: "100%" }}
        >
          <Form.Item name="title" label={<FormLabel name="title" />}>
            <Input onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
          <UploadCertificates onUpload={(e) => setLogo(e)} />
        </Form>
      </Row>
    </Card>
  );
}

export default AddCertificateToCourse;
