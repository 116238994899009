interface Props {
  totalPages: number;
  currentPage: number;
  onClick: (e: number) => void;
}
function Pagination({ totalPages, onClick, currentPage }: Props) {
  const returnPages = () => {
    const temp = [...Array(totalPages)];
    return Object.keys(temp);
  };

  const setActive = (pageNumber) =>
    pageNumber == currentPage ? "active" : null;

  const previous = () => {
    if (currentPage === 0) return;
    else onClick(currentPage - 1);
  };
  const next = () => {
    if (currentPage === totalPages - 1) return;
    onClick(currentPage + 1);
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item">
          <a className="page-link" aria-label="Previous" onClick={previous}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {returnPages().map((item, i: number) => (
          <li
            className={`page-item ${setActive(item)}`}
            onClick={() => onClick(i)}
            key={item}
          >
            <a className="page-link">{parseInt(item) + 1}</a>
          </li>
        ))}
        <li className="page-item">
          <a className="page-link" aria-label="Next" onClick={next}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
