import {
  Button,
  Input,
  Form,
  Row,
  Card,
  Checkbox,
  Space,
  Col,
  Typography,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { FormInstance } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import Translation from "src/components/Common/utilities/Translation";
import { useForm } from "antd/lib/form/Form";
import { initial } from "lodash";

interface Props {
  onFormSubmit: (e) => void;
  initialValue?: any;
}

function AddCourseQuiz({ onFormSubmit, initialValue }: Props) {
  const [showQuizModal, setShowQuizModal] = useState(false);
  const { TextArea } = Input;
  const { Text } = Typography;
  const [form] = useForm();

  const onSubmit = (e) => {
    onFormSubmit(e);
    setShowQuizModal(false);
  };

  useEffect(() => {
    if (initialValue) form.setFieldsValue(initialValue);
  }, [initialValue]);

  return (
    <Form.Item>
      <Space>
        <Button
          onClick={() => {
            setShowQuizModal(true);
          }}
          size="small"
          type="primary"
        >
          {console.log(form.getFieldsValue(true).quiz?.length)}
          {/* {form.getFieldsValue(true).quiz?.length ? "Edit Quiz" : "Add Quiz"} */}
          {initialValue ? "Edit Quiz" : "Add Quiz"}
        </Button>
        <Text keyboard>
          <Text>
            {initialValue ? (
              // <>{form.getFieldsValue(true).quiz?.length} questions added </>
              <>{initialValue.quiz?.length} questions added </>
            ) : (
              "Quiz Not Added"
            )}
          </Text>
        </Text>
      </Space>
      <Modal
        width={1000}
        visible={showQuizModal}
        title="Quiz"
        onCancel={setShowQuizModal.bind(null, false)}
        footer={[
          <Button type="primary" htmlType="submit" form="quizForm">
            <Translation input="submit" />
          </Button>,
        ]}
      >
        <Form form={form} name="basic" onFinish={onSubmit} id="quizForm">
          <Form.Item name="quizTitle">
            <Input placeholder="Quiz Title" />
          </Form.Item>

          <Row>
            <Col span={10}>
              <Form.Item
                name="attempts"
                label="Number of Attempts"
                rules={[{ required: true }]}
              >
                <Input type="number" placeholder="Number of Attempts" />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={10}>
              <Row>
                <Form.Item
                  name="passing"
                  label="Correct Answers for Passing"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Correct Answers for Passing"
                    type="number"
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Form.List name="quiz">
            {(questions, { add, remove }) => (
              <>
                {questions.map((question) => (
                  <Card
                    hoverable={true}
                    style={{
                      marginBottom: "15px",
                      border: "1px solid #BEBEBE",
                      padding: "0",
                    }}
                  >
                    <Col span={24}>
                      <Row>
                        <Col span={23}>
                          <Form.Item name={[question.name, "question"]}>
                            <TextArea rows={2} placeholder="Add Question" />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Row justify="end">
                            <Button
                              onClick={() => remove(question.name)}
                              size="small"
                              style={{ marginTop: "15px" }}
                              icon={<CloseOutlined />}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Form.List name={[question.name, "answer"]}>
                      {(options, { add, remove }) => (
                        <>
                          {options.map((option) => (
                            <Row>
                              <Space>
                                <Form.Item
                                  name={[option.name, "checked"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox />
                                </Form.Item>
                                <Form.Item name={[option.name, "option"]}>
                                  <Input
                                    placeholder="Option"
                                    style={{ width: "600px" }}
                                  />
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    onClick={() => remove(option.name)}
                                    size="small"
                                    icon={<CloseOutlined />}
                                  />
                                </Form.Item>
                              </Space>
                            </Row>
                          ))}
                          <Row justify="center">
                            <Button onClick={() => add()} type="primary" ghost>
                              Add Option
                            </Button>
                          </Row>
                        </>
                      )}
                    </Form.List>
                  </Card>
                ))}
                <Row justify="center" style={{ marginTop: "10px" }}>
                  <Button onClick={() => add()} type="primary" ghost>
                    Add Question
                  </Button>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </Form.Item>
  );
}

export default AddCourseQuiz;
