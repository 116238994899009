import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAnalytics } from "src/actions/analyticsActions";
import { analyticsSelectors } from "src/store/analyticSlice";
import { useAppDispatch } from "src/store/store";
import AnalyticsCard from "./AnalyticsCard";

function DashboardTopRow() {
  const dispatch = useAppDispatch();
  const analytics = useSelector(analyticsSelectors.selectAnalytics());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getAnalytics()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Row gutter={[20, 0]}>
      <Col span={6}>
        <AnalyticsCard
          title="total_learners"
          count={analytics.learners?.count}
          date={analytics.learners?.date}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <AnalyticsCard
          title="total_courses"
          count={analytics.courses?.count}
          date={analytics.courses?.date}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <AnalyticsCard
          title="pathways"
          count={analytics.pathways?.count}
          date={analytics.pathways?.date}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <AnalyticsCard
          title="mentors"
          count={analytics.mentors?.count}
          date={analytics.mentors?.date}
          loading={loading}
        />
      </Col>
    </Row>
  );
}

export default DashboardTopRow;
