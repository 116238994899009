import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getNotifications,
  setNotificationViewed,
} from "src/actions/notificationActions";
import NavNotification from "src/components/LearnerComponents/Layout/Navbar/NavBarIcons/NavNotificationIcon/NavNotification";
import LoadingWrapper from "src/components/LearnerComponents/UtilityComponents/LoadingWrapper";
import Pagination from "src/components/LearnerComponents/UtilityComponents/Pagination";
import { NotificationI } from "src/interfaces/NotificationI";
import { notificationPaginationSelectors } from "src/store/notificationsSlice/notificationsPaginationSlice";
import { RootState, useAppDispatch } from "src/store/store";
import { topOfPage } from "src/utils/utils";

function NotificationsPage() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const notifications = useSelector((state: RootState) =>
    notificationPaginationSelectors.get(state, page)
  ) as NotificationI[];

  const notificationIds = useSelector((state: RootState) =>
    notificationPaginationSelectors.getIds(state, page)
  )?.items;

  const availablePages = useSelector(
    notificationPaginationSelectors.pages.selectIds
  );

  useEffect(() => {
    if (availablePages && !loading && !availablePages.includes(page)) {
      setLoading(true);
      dispatch(getNotifications(page)).then(setLoading.bind(null, false));
    }
  }, [notifications, page]);

  // useEffect(() => {
  //   dispatch(setNotificationViewed(notificationIds || [])).then(() => {
  //     console.log("test");
  //   });
  // }, []);

  return (
    <div className="clearance container pt-5">
      <div className="row mb-3">
        <h5 className="">Notifications</h5>
      </div>
      <LoadingWrapper loading={loading}>
        <div className="row card">
          {notifications.map((notification: NotificationI) => (
            <NavNotification notification={notification} onPage />
          ))}
        </div>
      </LoadingWrapper>

      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex justify-content-end">
            <Pagination
              totalPages={3}
              currentPage={page}
              onClick={(e) => {
                setPage(e);
                topOfPage();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsPage;
