import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Course } from "../../interfaces/Course";
import { logout } from "../authSlice";
import { api, extractStandardResponseData } from "src/api/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Module } from "src/interfaces/Module";
import { Lesson, LessonsAttachment } from "src/interfaces/Lesson";
import { groupAttachmentsFunction } from "src/utils/utils";
import { Quiz } from "src/interfaces/Quiz";

const initialState = {} as Course;

export const getSingleCourseForEdit = createAsyncThunk(
  `courses/get/single/edit`,
  async (id: number) =>
    await api.get(`/courses/course/${id}`).then(extractStandardResponseData)
);

export const getDraftForEdit = createAsyncThunk(
  "courses/draft/get/edit",
  async () => await api.get("/admin/getDraft").then(extractStandardResponseData)
);

export const editCourseModule = createAsyncThunk(
  "editModulesLocal",
  (modules: Module[]) => ({
    modules,
  })
);

export const editDetails = createAsyncThunk(
  "editDetailsLocal",
  (input: any) => input
);

export const editCourseSlice = createSlice({
  name: "editCourseSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSingleCourseForEdit.fulfilled, (state, { payload }) => {
      return {
        ...payload,
        quiz: payload.coursesQuiz?.map((quiz: Quiz) => ({
          extras: { id: quiz.id, weightage: quiz.weightage },
          question: quiz.question,
          answer: quiz.options.map((option) => ({
            option: option,
            checked: quiz.answers.find((ans) => ans === option) ? true : false,
          })),
        })),
        tags: payload?.coursesTags?.map((item: any) => ({
          label: item.tags.value,
          value: item.tags.id,
        })),
        mentors: payload?.mentorsCourses?.map((item: any) => ({
          label: `${item.users.firstName} ${item.users.lastName}`,
          value: item.users.id,
        })),
        author: {
          label: `${payload.users.firstName} ${payload.users.lastName}`,
          value: payload.users.id,
        },

        coursesModules: payload.coursesModules.map((module: Module) => ({
          ...module,
          lessons: module.lessons.map((lesson: Lesson) => ({
            ...lesson,
            lessonsAttachments: groupAttachmentsFunction(
              lesson.lessonsAttachments as LessonsAttachment[]
            ),
          })),
        })),
      };
    });
    builder.addCase(editCourseModule.fulfilled, (state, { payload }) => ({
      ...state,
      coursesModules: payload.modules,
    }));
    builder.addCase(editDetails.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    });

    builder.addCase(getDraftForEdit.fulfilled, (state, { payload }) => ({
      ...payload,
    }));
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export const courseSelector = {
  course: (state: RootState) => state.editCourse,
};
