import { Course, CourseCompletion } from "src/interfaces/Course";

export const numberOfQuestionsAnsweredCorrect = (course: Course): number =>
  course.courseCompletion.filter(
    (courseComp: CourseCompletion) => courseComp.correct
  ).length;

export const hasPassedQuiz = (course: Course): boolean =>
  numberOfQuestionsAnsweredCorrect(course) >= course.passing ? true : false;

export const totalNumOfQuestions = (course: Course): number =>
  course.courseCompletion.length;

export const exhaustedAttempts = (course: Course) =>
  course.courseCompletion[0].attempts;
