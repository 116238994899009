import { Card } from "antd";
import { Column, Line } from "@ant-design/charts";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { analyticsSelectors } from "src/store/analyticSlice";

function UserStatisticsChart() {
  const { t } = useTranslation();
  const [data, setData] = useState<{ day: string; value: number }[]>([]);
  const [loading, setLoading] = useState(true);
  const userStatisticsData = useSelector(
    analyticsSelectors.selectAnalytics()
  ).dates;

  // const data = [
  //   {
  //     year: "1991",
  //     value: 3,
  //   },
  //   {
  //     year: "1992",
  //     value: 4,
  //   },
  //   {
  //     year: "1993",
  //     value: 3.5,
  //   },
  //   {
  //     year: "1994",
  //     value: 5,
  //   },
  //   {
  //     year: "1995",
  //     value: 4.9,
  //   },
  // ];

  const config = {
    data,
    height: 300,
    xField: "day",
    yField: "value",
    // color: "#001529",
    colorField: "day",
    // color: ["#d62728", "#2ca02c", "#000000", "#2ca02c", "#2ca02c"],
    color: ({ day }: any) => {
      if (
        day === "Sunday" ||
        day === "Tuesday" ||
        day === "Thursday" ||
        day === "Saturday"
      )
        return "#001436";
      return "#0077ff";
    },
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#259",
        lineWidth: 2,
      },
    },
  };

  useEffect(() => {
    if (userStatisticsData) {
      setData(
        userStatisticsData?.map((item) => ({
          day: Object.keys(item)[0],
          value: Object.values(item)[0],
        }))
      );
      setLoading(false);
    }
  }, [userStatisticsData]);

  return (
    <Card title={<>{t("user_statistics")}</>}>
      <Column
        loading={loading}
        {...config}
        // onReady={(plot) => {
        //   plot.on("plot:click", (evt: any) => {
        //     const { x, y } = evt;
        //     const { xField } = plot.options;
        //     const tooltipData = plot.chart.getTooltipItems({ x, y });
        //     console.log(tooltipData);
        //   });
        // }}
      />
    </Card>
  );
}

export default UserStatisticsChart;
