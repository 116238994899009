import { useState } from "react";
import { Form, Select, Spin } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../../store/store";
import { searchMentors } from "../../../../store/coursesSlices/coursesSlice";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  // initialValue: string;
  onChange?: (e) => void;
  defaultValue?: any;
}

function SearchMentors({ name, onChange, defaultValue }: Props) {
  const [fetching, setFetching] = useState(false);
  const [data, setData]: any = useState([]);
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const { t } = useTranslation();

  const fetchTags = (value: any) => {
    setFetching(true);
    setData([]);
    if (value.length > 2) {
      dispatch(searchMentors(value))
        .then(unwrapResult)
        .then((res) => {
          setData(res);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    } else {
      setFetching(false);
    }
  };

  return (
    <div>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: "Please add an Mentors for the course",
          },
        ]}
      >
        <Select
          mode="multiple"
          defaultValue={defaultValue || []}
          labelInValue
          placeholder={t("select_mentors")}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={fetchTags}
          style={{ width: "100%" }}
          onChange={onChange}
        >
          {data?.map((item: any) => (
            <Option value={item.id} key={item.id} label={item.lastName}>
              {item.firstName + " " + item.lastName}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export default SearchMentors;
