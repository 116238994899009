import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "../api/api";
import { endpoints } from "../api/endpoints";

export const addTags = createAsyncThunk(
  "tags/add",
  async (tagsArray: string[]) => {
    const tags = await api
      .post(endpoints.tags.add(), { tags: tagsArray })
      .then(extractStandardResponseData);
    return tags;
  }
);
export const searchTags = createAsyncThunk(
  "tags/search",
  async (tag: any) =>
    await api
      .post(endpoints.tags.search(), { value: tag })
      .then(extractStandardResponseData)
);
