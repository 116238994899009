function QuizProgressBar({ progress }: { progress: number }) {
  return (
    <div className="progress rounded-0" style={{ height: "8px" }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progress}%` }}
        // aria-valuenow="25"
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  );
}

export default QuizProgressBar;
