import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store/store";

interface AuthState {
  videoURL?: string;
  imageIdArray?: any[];
  wideNavBar?: boolean;
  courseAttachmentLink?: string;
  mentorId: number;
}

const initialState = {
  // videoURL: undefined,
  // imageIdArray: undefined,
  // wideNavBar: undefined,
  // courseAttachmentLink: undefined,
  // mentorId: undefined,
} as AuthState;

export const setVideoURL = createAsyncThunk(
  "UI/setVideoURL",
  async (videoURL: string) => {
    return videoURL;
  }
);
export const setImageIdArray = createAsyncThunk(
  "UI/setImageIdArray",
  async (imageIdArray: any[]) => {
    return imageIdArray;
  }
);
export const setWideNavBar = createAsyncThunk(
  "UI/wideNavBar",
  async (wideNavBar: boolean) => {
    return wideNavBar;
  }
);
//book a session
export const setMentorID = createAsyncThunk(
  "UI/mentorId",
  async (mentorId: number) => mentorId
);

export const uiSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setVideoURL.fulfilled, (state, { payload }) => ({
      ...state,
      videoURL: payload,
    }));
    builder.addCase(setImageIdArray.fulfilled, (state, { payload }) => ({
      ...state,
      imageIdArray: payload,
    }));

    builder.addCase(setMentorID.fulfilled, (state, { payload }) => ({
      ...state,
      mentorId: payload,
    }));
  },
});

export const uiSelectors = {
  videoURL: (state: RootState) => state.uiSlice.videoURL,
  imageIdArray: (state: RootState) => state.uiSlice.imageIdArray,
  wideNavBar: (state: RootState) => state.uiSlice.wideNavBar,
  mentorId: (state: RootState) => state.uiSlice.mentorId,
};
