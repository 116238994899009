import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { sessionSelectors } from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import { Button, PageHeader, Col, Row, Card } from "antd";
import { HiDotsHorizontal } from "react-icons/hi";
import { Registrant } from "src/interfaces/Registrant";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import ZoomSessionParticipantCard from "src/components/AdminAuthorMentorComponents/MentorComponents/Sessions/ZoomSessionParticipantCard";
interface Params {
  session_Id: string;
}

function ZoomSession() {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [url, setUrl] = useState("");
  const { session_Id } = useParams<Params>();
  const session = useSelector(
    sessionSelectors.selectSessionEntity(parseInt(session_Id))
  );
  const [showButton, setShowButton] = useState(true);
  const [sessionFullScreen, setSessionFullScreen] = useState(false);

  // const url =
  //   new URL(session?.meeting?.start_url || "").searchParams.get("zak") || "";

  useEffect(() => {
    if (session) {
      const tempUrl =
        new URL(session?.meeting?.start_url || "").searchParams.get("zak") ||
        "";
      setUrl(tempUrl);
      setLoading(false);
    } else history.goBack();
  }, []);

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Row gutter={24}>
          <Col sm={sessionFullScreen ? 24 : 16}>
            <h4>Live Session</h4>
            <div
              style={{ position: "relative", overflow: "hidden" }}
              onMouseEnter={setShowButton.bind(null, false)}
              onMouseLeave={setShowButton.bind(null, true)}
            >
              <Row
                justify="center"
                className={`${
                  showButton
                    ? "zoom_session_full_screen"
                    : "zoom_session_full_screen_show"
                }`}
              >
                <Button
                  type="primary"
                  onClick={setSessionFullScreen.bind(null, (s) => !s)}
                >
                  {sessionFullScreen ? "Minimize" : "Full Width"}
                </Button>
              </Row>
              <iframe
                id="iFrame"
                style={{
                  border: "none",
                  height: `${sessionFullScreen ? "75vh" : "60vh"}`,
                  width: "100%",
                  marginTop: "22px",
                }}
                src={`https://zoom.us/wc/${session?.meetingId}/start?zak=${url}`}
                sandbox="allow-forms allow-scripts allow-same-origin"
                allow="microphone; camera"
              ></iframe>
            </div>

            <Row style={{ marginTop: "22px" }}>
              <Col sm={24}>
                <h4>{session?.meeting.topic}</h4>
              </Col>
            </Row>
          </Col>
          <Col sm={8}>
            <h4 style={{ marginTop: `${sessionFullScreen ? "30px" : 0}` }}>
              Participants
            </h4>
            <div
              style={{ marginTop: "30px", height: "60vh", overflow: "auto" }}
            >
              {session?.meeting?.registrants?.registrants?.map(
                (registrant: Registrant) => (
                  <ZoomSessionParticipantCard registrant={registrant} />
                )
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ZoomSession;
