import { Button } from "antd";
import { LessonFormat } from "src/enums/LessonFormats";
import { LessonsAttachment } from "src/interfaces/Lesson";
import { addTypeToSection } from "src/utils/Rest/EditCourseUtils/EditCourseUtils";

interface Props {
  onChange: (type: LessonFormat) => void;
  lessonsAttachments: LessonsAttachment[];
  moduleId: number | string;
  lessonId: number | string;
  sectionId: number | string;
}

function SelectSection({
  onChange,
  lessonsAttachments,
  moduleId,
  lessonId,
  sectionId,
}: Props) {
  return (
    <>
      {Object.keys(LessonFormat).map((key) => (
        <Button
          onClick={() => {
            onChange(key as LessonFormat);
          }}
        >
          {key}
        </Button>
      ))}
    </>
  );
}

export default SelectSection;
