import { Form, Input } from "antd";
import FormLabel from "src/components/Common/utilities/FormLabel";
const { TextArea } = Input;

function CreateCourseBasicFields() {
  return (
    <>
      <FormLabel name="title" />
      <Form.Item
        name="title"
        rules={[
          {
            required: true,
            message: "Please add a Title for the course",
            type: "string",
          },
          {
            min: 2,
            message: "Title must be minimum 2 characters.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <FormLabel name="excerpt" />
      <Form.Item
        name="excerpt"
        rules={[
          {
            required: true,
            message: "Please add as excerpt for the course",
            type: "string",
          },
          {
            min: 2,
            message: "excerpt must be minimum 2 characters.",
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </>
  );
}

export default CreateCourseBasicFields;
