import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";
import { Course } from "src/interfaces/Course";

export const addDraft = createAsyncThunk(
  "courses/draft/add",
  async (course: Course) =>
    await api.post("/admin/addDraft", course).then(extractStandardResponseData)
);
export const getDraft = createAsyncThunk(
  "courses/draft/get",
  async () => await api.get("/admin/getDraft").then(extractStandardResponseData)
);

export const deleteDraft = createAsyncThunk(
  "courses/draft/delete",
  async (draftId: any) =>
    await api
      .delete(`/admin/deleteDraft/${draftId}`)
      .then(extractStandardResponseData)
);
