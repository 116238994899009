import { Form, Button } from "antd";
import { LessonFormat } from "src/enums/LessonFormats";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import ImageRender from "src/components/Common/mediaPlayers/ImageRender";
import AudioPlayer from "src/components/Common/mediaPlayers/AudioPlayer";
import parse from "html-react-parser";
import ImageSlider from "src/components/Common/mediaPlayers/ImageSlider";
import { LessonsAttachment } from "src/interfaces/Lesson";
import { useEffect, useState } from "react";

interface Props {
  field: any;
  sectionData: LessonsAttachment[];
  done: boolean;
}

function RenderMedia({ field, sectionData, done }: Props) {
  const [finalValue, setFinalValue] = useState<any>();
  const mediaReturnFunction = (data) => {
    if (data[0]?.type === LessonFormat.VIDEO)
      return <VideoPlayer url={data[0].resources.attachmentUrl} />;
    else if (data[0]?.type === LessonFormat.IMAGE)
      return <ImageRender url={data[0].resources.attachmentUrl} />;
    else if (data[0]?.type === LessonFormat.AUDIO)
      return <AudioPlayer url={data[0].resources.attachmentUrl} />;
    else if (data[0]?.type === LessonFormat.TEXT)
      return <div>{parse(data[0].lessonText)}</div>;
    else if (data[0]?.type === LessonFormat.IMAGESLIDER)
      return <ImageSlider lessonsAttachments={data} />;
  };

  // useEffect(() => {
  //   setFinalValue(
  //     sectionData?.map((lessAttach: LessonsAttachment) => ({
  //       ...lessAttach,
  //       sectionId: field.name,
  //     }))
  //   );
  // }, [field]);
  return (
    <>
      {/* <Button
        onClick={() => {
          console.log(
            sectionData?.map((lessAttach: LessonsAttachment) => ({
              ...lessAttach,
              sectionId: field.name,
            }))
          );
          console.log({ sectionData });
        }}
      >
        checl
      </Button> */}
      {/* {done ? (
        <Form.Item
          {...field}
          name={[field.name, "lessonsAttachments"]}
          fieldKey={[field.fieldKey, "lessonsAttachments"]}
          key={field.key}
          initialValue={sectionData?.map((lessAttach: LessonsAttachment) => ({
            ...lessAttach,
            sectionId: field.name,
          }))}
        ></Form.Item>
      ) : null} */}
      <Form.Item
        {...field}
        name={[field.name, "lessonsAttachments"]}
        fieldKey={[field.fieldKey, "lessonsAttachments"]}
        key={field.key}
        // initialValue={sectionData?.map((lessAttach: LessonsAttachment) => ({
        //   ...lessAttach,
        //   sectionId: field.name,
        // }))}
        // initialValue={finalValue}
      ></Form.Item>
      {mediaReturnFunction(sectionData)}
    </>
  );
}

export default RenderMedia;
