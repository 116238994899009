import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store/store";

import {
  api,
  extractStandardResponseData,
  setAxiosToken,
  clearAxiosToken,
} from "../api/api";
import { endpoints } from "../api/endpoints";
import { saveToken, getToken } from "../api/localStorage";
import { Client } from "../interfaces/Client";
import { Role, User } from "src/interfaces/User";
import { Resources } from "src/interfaces/Resources";
import "react-confirm-alert/src/react-confirm-alert.css";
import { customAlert, setDirection } from "src/utils/utils";
import { Tag } from "src/interfaces/Tag";
import i18next from "i18next";
import { setLanguage } from "src/actions/authActions";

export const authAdapter = createEntityAdapter<User>();

interface AuthState {
  id: number;
  email: string;
  token: string;
  role: Role;
  client: Client;
  clientId: 1;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  assignedMentors: any;
  description: string;
  designation: string;
  resources: Resources;
  thumbnail: string;
  usersTags: Tag[];
  lang: string;
}

const initialState = {} as AuthState;

interface LoginI {
  email: string;
  password: string;
}

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }: LoginI) => {
    const user = await api
      .post(
        endpoints.auth.login(),
        {
          email: email,
          password: password,
        },

        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.check) {
          customAlert(res.data.user);
        } else return res.data.data;
      });

    setAxiosToken(user.token);
    saveToken(user.token);
    return user;
  }
);

export const loginSuccess = createAsyncThunk(
  "auth/loginSuccess",
  async (user: User) => {
    const res = await api
      .post(
        "/auth/loginSuccess",
        { user },
        {
          withCredentials: true,
        }
      )
      .then((res: any) => {
        setAxiosToken(res.data.token);
        saveToken(res.data.token);
        return { ...res.data.data, token: res.data.token };
      });

    return res;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await api
    .delete("auth/logout", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      withCredentials: true,
    })
    .then((res) => {
      saveToken("");
      clearAxiosToken();
      i18next.changeLanguage("en");
      extractStandardResponseData(res);
    });
});

export const checkAuth = createAsyncThunk("auth/checkAuth", async () => {
  const user = await api
    .get("auth/checkAuth", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then(extractStandardResponseData);
  setAxiosToken(getToken());
  i18next.changeLanguage(user.lang);
  setDirection(user.lang);
  // saveToken(user.token);
  return user;
});

export const getMentors = createAsyncThunk(
  "auth/mentors",
  async () =>
    await api
      .get("/users/mentors", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(extractStandardResponseData)
);

export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async ({ password, token }: any) => {
    const verifyToken = await api
      .post(
        "users/verify",
        { password: password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(extractStandardResponseData);
    // setAxiosToken(verifyToken.token);
    // saveToken(verifyToken.token);
    return verifyToken;
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email: string) =>
    await api.post("users/forgot", { email }).then(extractStandardResponseData)
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ password, token }: { password: string; token: string }) =>
    await api
      .patch(
        "users/resetPassword",
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(extractStandardResponseData)
);

interface UpdateProfileI extends Partial<User> {
  imageId?: number;
}
export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (userDetails: UpdateProfileI) =>
    await api
      .patch(`users/updateMyProfile`, userDetails)
      .then(extractStandardResponseData)
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(loginSuccess.fulfilled, (state, { payload }) => ({
      ...payload,
    }));
    builder.addCase(checkAuth.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(setLanguage.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    // builder.addCase(verifyUser.fulfilled, (state, { payload }) => ({
    //   state,
    //   ...payload,
    // }));
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => ({
      state,
      ...payload,
    }));

    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(getMentors.fulfilled, (state, { payload }) => ({
      ...state,
      assignedMentors: payload,
    }));
  },
});

export const authSelectors = {
  token: (state: RootState) => state.auth.token,
  email: (state: RootState) => state.auth.email,
  id: (state: RootState) => state.auth.id,
  role: (state: RootState) => state.auth.role,
  clientId: (state: RootState) => state.auth.clientId,
  firstName: (state: RootState) => state.auth.firstName,
  lastName: (state: RootState) => state.auth.lastName,
  details: (state: RootState) => state.auth,
  attachmentUrl: (state: RootState) => state.auth.resources.attachmentUrl,
  assignedMentors: (state: RootState) => state.auth.assignedMentors,
  lang: (state: RootState) => state.auth.lang,
};

// ======================================================================================
// import {
//   createAsyncThunk,
//   createEntityAdapter,
//   createSelector,
//   createSlice,
// } from "@reduxjs/toolkit";
// import { RootState } from "../store/store";

// import {
//   api,
//   extractStandardResponseData,
//   setAxiosToken,
//   clearAxiosToken,
// } from "../api/api";
// import { endpoints } from "../api/endpoints";
// import { saveToken, getToken } from "../api/localStorage";
// import { Client } from "../interfaces/Client";
// import { Role, User } from "src/interfaces/User";

// export const authAdapter = createEntityAdapter<User>();

// interface LoginI {
//   email: string;
//   password: string;
// }

// interface AuthState {
//   id?: number;
//   email?: string;
//   token?: string;
//   role?: Role;
//   client?: Client;
//   clientId?: 1;
//   firstName?: string;
//   lastName?: string;
//   createdAt?: string;
//   updatedAt?: string;
//   assignedMentors?: any;
//   description?: string;
//   designation?: string;
//   attachmentUrl?: string;
//   thumbnail?: string;
// }

// const initialState = {
//   // id: undefined,
//   // email: undefined,
//   // token: undefined,
//   // role: undefined,
//   // client: undefined,
//   // clientId: undefined,
//   // firstName: undefined,
//   // lastName: undefined,
//   // createdAt: undefined,
//   // updatedAt: undefined,
//   // assignedMentors: undefined,
//   // description: undefined,
//   // designation: undefined,
//   // attachmentUrl: undefined,
//   // thumbnail: undefined,
// } as AuthState;

// export const login = createAsyncThunk(
//   "auth/login",
//   async ({ email, password }: LoginI) => {
//     const user = await api
//       .post(
//         endpoints.auth.login(),
//         {
//           email: email,
//           password: password,
//         },

//         {
//           withCredentials: true,
//         }
//       )
//       .then(extractStandardResponseData);
//     setAxiosToken(user.token);
//     saveToken(user.token);
//     console.log(user);
//     return user;
//   }
// );

// export const logout = createAsyncThunk("auth/logout", async () => {
//   api
//     .get("auth/logout", {
//       withCredentials: true,
//     })
//     .then(extractStandardResponseData);
//   saveToken("");
//   clearAxiosToken();
//   // return user;
// });

// export const checkAuth = createAsyncThunk("auth/checkAuth", async () => {
//   const user = await api
//     .get("auth/checkAuth", {
//       headers: {
//         Authorization: `Bearer ${getToken()}`,
//       },
//     })
//     .then(extractStandardResponseData);
//   setAxiosToken(getToken());
//   // saveToken(user.token);
//   return user;
// });

// export const getMentors = createAsyncThunk(
//   "auth/mentors",
//   async () =>
//     await api
//       .get("/users/mentors", {
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//         },
//       })
//       .then(extractStandardResponseData)
// );

// export const verifyUser = createAsyncThunk(
//   "auth/verifyUser",
//   async ({ password, token }: any) => {
//     const verifyToken = await api
//       .post(
//         "users/verify",
//         { password: password },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then(extractStandardResponseData);
//     setAxiosToken(verifyToken.token);
//     saveToken(verifyToken.token);
//     return verifyToken;
//   }
// );

// export const forgotPassword = createAsyncThunk(
//   "auth/forgotPassword",
//   async (email: string) =>
//     await api.post("users/forgot", { email }).then(extractStandardResponseData)
// );

// export const resetPassword = createAsyncThunk(
//   "auth/resetPassword",
//   async ({ password, token }: { password: string; token: string }) =>
//     await api
//       .patch(
//         "users/resetPassword",
//         { password },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then(extractStandardResponseData)
// );

// interface UpdateProfileI extends Partial<User> {
//   imageId?: number;
// }
// export const updateProfile = createAsyncThunk(
//   "auth/updateProfile",
//   async (userDetails: UpdateProfileI) =>
//     await api
//       .patch(`users/updateMyProfile`, userDetails)
//       .then(extractStandardResponseData)
// );

// export const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(login.fulfilled, (state, { payload }) => ({
//       ...payload,
//     }));
//     // builder.addCase(login.fulfilled, (state, { payload }) => ({
//     //   id: payload.id,
//     //   clientId: payload.clientId,
//     //   email: payload.email,
//     //   token: payload.token,
//     //   role: payload.role,
//     //   firstName: payload.firstName,
//     //   lastName: payload.lastName,
//     //   createdAt: payload.createdAt,
//     //   updatedAt: payload.updatedAt,
//     //   client: payload.client,
//     //   description: payload.description || "",
//     //   designation: payload.designation || "",
//     //   attachmentUrl: payload.resources?.attachmentUrl || "",
//     //   thumbnail: payload.resources?.metaData.thumbnail || "",
//     // }));
//     builder.addCase(checkAuth.fulfilled, (state, { payload }) => ({
//       ...state,
//       id: payload?.id,
//       clientId: payload?.clientId,
//       email: payload?.email,
//       token: payload?.token,
//       role: payload?.role,
//       firstName: payload?.firstName,
//       lastName: payload?.lastName,
//       createdAt: payload?.createdAt,
//       updatedAt: payload?.updatedAt,
//       client: payload?.client,
//       description: payload?.description || "",
//       designation: payload?.designation || "",
//       attachmentUrl: payload?.resources?.attachmentUrl || "",
//       thumbnail: payload?.resources?.metaData.thumbnail || "",
//     }));
//     builder.addCase(verifyUser.fulfilled, (state, { payload }) => ({
//       id: payload.id,
//       clientId: payload.clientId,
//       email: payload.email,
//       token: payload.token,
//       role: payload.role,
//       firstName: payload.firstName,
//       lastName: payload.lastName,
//       createdAt: payload.createdAt,
//       updatedAt: payload.updatedAt,
//       client: payload.client,
//     }));
//     builder.addCase(updateProfile.fulfilled, (state, { payload }) => ({
//       ...state,
//       firstName: payload.firstName,
//       lastName: payload.lastName,
//       createdAt: payload.createdAt,
//       updatedAt: payload.updatedAt,
//       description: payload.description || "",
//       designation: payload.designation || "",
//       attachmentUrl: payload.resources?.attachmentUrl || "",
//       thumbnail: payload.resources?.metaData.thumbnail || "",
//     }));

//     builder.addCase(logout.fulfilled, () => ({}));
//     builder.addCase(getMentors.fulfilled, (state, { payload }) => ({
//       ...state,
//       assignedMentors: payload,
//     }));
//   },
// });

// export const authSelectors = {
//   token: (state: RootState) => state.auth.token,
//   email: (state: RootState) => state.auth.email,
//   id: (state: RootState) => state.auth.id,
//   role: (state: RootState) => state.auth.role,
//   clientId: (state: RootState) => state.auth.clientId,
//   firstName: (state: RootState) => state.auth.firstName,
//   lastName: (state: RootState) => state.auth.lastName,
//   details: (state: RootState) => state.auth,
//   attachmentUrl: (state: RootState) => state.auth.attachmentUrl,
//   assignedMentors: (state: RootState) => state.auth.assignedMentors,
// };
