import { LessonsAttachment } from "../../../interfaces/Lesson";
import ImageSlider from "./ImageSlider";
import { LessonFormat } from "src/enums/LessonFormats";
import ImageRender from "./ImageRender";
import AudioPlayer from "./AudioPlayer";
import VideoPlayer from "./VideoPlayer";
import parse from "html-react-parser";
import { groupAttachmentsFunction } from "src/utils/utils";

interface Props {
  lessonsAttachments: LessonsAttachment[];
}

function LessonMediaRender({ lessonsAttachments }: Props) {
  const lessonRender = (lessonsAttachmentsArray: LessonsAttachment[]) => {
    if (lessonsAttachmentsArray[0].type === LessonFormat.IMAGE)
      return (
        <ImageRender url={lessonsAttachmentsArray[0].resources.attachmentUrl} />
      );
    else if (lessonsAttachmentsArray[0].type === LessonFormat.AUDIO)
      return (
        <AudioPlayer
          url={lessonsAttachmentsArray[0].resources?.attachmentUrl}
        />
      );
    else if (lessonsAttachmentsArray[0].type === LessonFormat.VIDEO)
      return (
        <VideoPlayer
          url={lessonsAttachmentsArray[0].resources?.attachmentUrl}
        />
      );
    else if (lessonsAttachmentsArray[0].type === LessonFormat.SPACER)
      return (
        <div
          style={{
            height: `${parseInt(lessonsAttachmentsArray[0].lessonText)}px`,
          }}
        ></div>
      );
    else if (lessonsAttachmentsArray[0].type === LessonFormat.TEXT)
      return <div>{parse(lessonsAttachmentsArray[0].lessonText)}</div>;
    else if (lessonsAttachmentsArray[0].type === LessonFormat.IMAGESLIDER)
      return <ImageSlider lessonsAttachments={lessonsAttachmentsArray} />;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {groupAttachmentsFunction(lessonsAttachments).map(
        (lessonAttachmentArray: LessonsAttachment[]) => (
          <div style={{ margin: "15px 0px" }}>
            {lessonRender(lessonAttachmentArray)}
          </div>
        )
      )}
    </div>
  );
}

export default LessonMediaRender;
