import { LessonsAttachment } from "src/interfaces/Lesson";
import ReactQuill from "react-quill";
import { editSectionText } from "src/utils/Rest/EditCourseUtils/EditCourseUtils";

interface Props {
  lessonsAttachment: LessonsAttachment[];
  moduleId: number | string;
  lessonId: number | string;
  sectionId: number | string;
  onChange: (e: any) => void;
}
function TextSection({
  lessonsAttachment,
  moduleId,
  lessonId,
  sectionId,
  onChange,
}: Props) {
  return (
    <ReactQuill
      onChange={onChange}
      value={lessonsAttachment[0].lessonText || ""}
    />
  );
}

export default TextSection;
