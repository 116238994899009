// import EnglishFont from "src/Assets/styles/Fonts/EnglishFont/EnglishFont";
// import HindiFont from "src/Assets/styles/Fonts/HindiFont/HindiFont";
import { CourseFormat } from "src/enums/CourseFormat";
import { LanguageCode } from "src/enums/Language";
import { Course, CourseVideo } from "src/interfaces/Course";
import { Language } from "src/interfaces/Language";
import { Lesson } from "src/interfaces/Lesson";
import { Module } from "src/interfaces/Module";
import { languages } from "src/locale/languages";

export const getLanguageNameFromLanguageCode = (languageCode: LanguageCode) =>
  languages.find((language: Language) => language.code === languageCode)?.name;

export const removeCourseModuleText = (course: Course | undefined) => {
  if (!course) return;
  if (course.type === CourseFormat.DEFAULT) {
    return {
      modules: course.coursesModules.map((module: Module) => ({
        ...module,
        title: "",
        lessons: module.lessons.map((lesson: Lesson) => ({
          ...lesson,
          title: "",
        })),
      })),
    };
  } else if (course.type === CourseFormat.VIDEO) {
    return {
      modules: course.coursesModules.map((module: Module) => ({
        ...module,
        title: "",
        videos: module.coursesVideos.map((coursesVideo: CourseVideo) => ({
          ...coursesVideo,
          title: "",
        })),
      })),
    };
  } else return [];
};

//TODO:lazy load
// export const getLanguageFont = (language: LanguageCode): JSX.Element => {
//   if (language === LanguageCode.en) return <EnglishFont />;
//   else if (language === LanguageCode.hi) return <HindiFont />;
//   else return <EnglishFont />;
// };
