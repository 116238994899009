import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Collapse,
  Modal,
  Typography,
  Space,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useAppDispatch } from "src/store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import {
  clearCourses,
  coursesSelector,
  createCourse,
  editVideoCourse,
} from "src/store/coursesSlices/coursesSlice";
import { Course } from "src/interfaces/Course";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import SearchFields from "../EditCoursePageFormFields/SearchFields";
import BasicFields from "../EditCoursePageFormFields/BasicFields";
import CourseFormatTag from "src/components/AdminAuthorMentorComponents/Common/CourseFormatTag";
import { Module } from "src/interfaces/Module";
import { Lesson } from "src/interfaces/Lesson";
import CoursePreviewVideo from "../EditCoursePageFormFields/CoursePreviewVideo";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "src/utils/utils";
import {
  addLanguageToCourse,
  getSingleCourse,
} from "src/actions/courseActions/courseActions";
import { getLanguageNameFromLanguageCode } from "src/utils/Rest/translationUtils";
import { LanguageCode } from "src/enums/Language";
import AddCourseQuiz from "src/components/AdminAuthorMentorComponents/Common/Course/CourseQuiz/AddCourseQuiz";
import { Quiz } from "src/interfaces/Quiz";
import { getDraft } from "src/actions/DraftActions";
import SearchTags from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import SearchAuthors from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchAuthors";
import CourseImage from "src/components/AdminAuthorMentorComponents/Common/Course/CourseForm/CourseImage";

interface Params {
  courseId: string;
}
interface Props {
  courseType: any;
}

function EditWebwatchAndInfographiaCourse({ courseType }: Props) {
  console.log("type is", courseType);
  const [courseQuiz, setCourseQuiz] = useState<any>();
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState<string | null>();
  const { t } = useTranslation();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { courseId } = useParams<Params>();
  const [form] = useForm();
  const location = useLocation();
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const { pathname } = useLocation();
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;
  const [imageId, setImageId] = useState<number>();
  const translatedToLanguage: LanguageCode = location.pathname
    .split("/")[4]
    ?.split("-")[1] as LanguageCode;
  const onFormSubmit = () => {
    setSubmitLoading(true);
    setDone(true);
    const editedCourse = {
      title: form.getFieldsValue().title,
      description: description,
      imageId: imageId,
      videoId: form.getFieldsValue().previewVideo,
      id: parseInt(courseId),
      courseAttachment: null,
      tags: form.getFieldsValue().tags?.map((item: any) => item.value),
      authorId: form.getFieldsValue().authorId?.value,
      modules: [],
      quiz: [],
      mentors: [],
    };
    if (!form.getFieldsValue().previewVideo) {
      delete editedCourse.videoId;
    }

    if (!isTranslationPath) {
      if (pathname.split("/")[2] === "draft") {
        dispatch(createCourse(editedCourse));
        console.log(editedCourse);
      } else {
        dispatch(editVideoCourse(editedCourse))
          .then(unwrapResult)
          .then(() => {
            role === Role.ADMIN
              ? history.push("/admin/getWebwatchAndInfographia")
              : history.push("/author");
            setSubmitLoading(false);
          })
          .catch(() => setSubmitLoading(true));
      }
    } else {
      delete editedCourse.authorId;
      delete editedCourse.tags;
      dispatch(
        addLanguageToCourse({
          ...editedCourse,
          completed: true,
          language: [location.pathname.split("/")[4].split("-")[1]],
        })
      ).then(() => {
        role === Role.ADMIN
          ? history.push("/admin/courses")
          : history.push("/author");
        setSubmitLoading(false);
        // dispatch(clearCourses()).then(() => {
        //   role === Role.ADMIN
        //     ? history.push("/admin/courses")
        //     : history.push("/author");
        //   setSubmitLoading(false);
        // });
      });
    }
  };
  const onFinishFailed = (e) => {
    message.warning({
      content: "Please check for missing fields in the form",
    });
  };

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Typography.Title level={5}>
        {isTranslationPath
          ? getLanguageNameFromLanguageCode(translatedToLanguage)
          : "Edit Course"}
      </Typography.Title>
      {course && (
        <Space>
          {isTranslationPath ? (
            <Button type="primary" loading={saveDraftLoading}>
              Save Draft
            </Button>
          ) : (
            <CourseFormatTag course={course} />
          )}
        </Space>
      )}
    </Row>
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getSingleCourse(parseInt(courseId)))
      .then(unwrapResult)
      .then((res: Course) => {
        setImageId(res.imageId);
        setTabTitle(res.title);
        const lang = location.pathname?.split("/")[4]?.split("-")[1];
        const course = isTranslationPath
          ? res.languages.find((course: Course) => course.language[0] === lang)
          : res;
        if (!course) return;
        if (!isTranslationPath) {
          setImageUrl(course?.resources.attachmentUrl || "");
          setVideoUrl(course?.videos?.attachmentUrl || "");
          form.setFieldsValue({
            authorId: {
              label: `${course?.users.firstName} ${course?.users.lastName}`,
              value: course?.users.id,
            },
          });
        }
        form.setFieldsValue({
          ...course,
          tags: course?.coursesTags?.map((item: any) => ({
            label: item.tags.value,
            value: item.tags.id,
          })),
          //   authorId: course?.mentorsCourses?.map((item: any) => ({
          //     label: `${item.users.firstName} ${item.users.lastName}`,
          //     value: item.users.id,
          //   })),
          authorId: {
            label: `${course?.users?.firstName} ${course?.users?.lastName}`,
            value: course?.users?.id,
          },
        });
        setDescription(course.description);

        setLoading(false);
      });
  }, [location.pathname]);

  return (
    <Row>
      <Col sm={24}>
        <Card title={cardTitle}>
          <Form
            name="basic"
            form={form}
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
            scrollToFirstError>
            <FormLabel name="title" />
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title",
                  type: "string",
                },
                {
                  min: 2,
                  message: "Title must be minimum 2 characters.",
                },
              ]}>
              <Input />
            </Form.Item>
            <FormLabel name="description" />
            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setDescription(value)}
                  value={description}
                />
              </Form.Item>
            </SkeletonLoader>
            {pathname.split("/")[2] !== "translation" && (
              <>
                <CourseImage
                  urlInput={course?.resources?.attachmentUrl || ""}
                  onChange={(e) => setImageId(e)}
                />
                {courseType == "WEBWATCH" && (
                  <CoursePreviewVideo done={done} videoUrl={videoUrl} />
                )}
                <FormLabel name="tags" />
                <SearchTags name="tags" />
                <FormLabel name="Author" />
                <SearchAuthors visible={false} />
              </>
            )}

            <Row justify="end" style={{ marginTop: "20px" }}>
              <Button type="primary" htmlType="submit" loading={submitLoading}>
                <Translation input="submit" />
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default EditWebwatchAndInfographiaCourse;
