import "antd/dist/antd.css";
import { Layout, Menu } from "antd";
import { DesktopOutlined, ProfileOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { authSelectors } from "../../../store/authSlice";
import { Role } from "src/interfaces/User";
import { useSelector } from "react-redux";
import UnauthorizedAccess from "src/components/Common/authorization/UnauthorizedAccess";
import NavLogo from "../Common/Layout/NavLogo";
import React, { Suspense } from "react";
import HeaderContentFooter from "../Common/Layout/HeaderContentFooter";
import PageLoading from "src/components/Common/utilities/PageLoading";
import Translation from "src/components/Common/utilities/Translation";
import { ConfigProvider } from "antd";
import { getDirection } from "src/utils/utils";
import {
  FaChartPie,
  FaListUl,
  FaRoad,
  FaUsers,
  FaGraduationCap,
  FaUserCog,
} from "react-icons/fa";
const AdminAuthorMentorStyles = React.lazy(
  () => import("src/Assets/styles/AdminAuthorMentorStyles")
);
const { Sider } = Layout;

interface AuthorLayoutI {
  component: JSX.Element;
}

function AuthorLayout({ component }: AuthorLayoutI) {
  const history = useHistory();
  const role = useSelector(authSelectors.role);
  const { SubMenu } = Menu;

  const onMenuItemSelect = (e) => history.push(e.key);
  const iconStyle = { marginBottom: "-3px", fontSize: "18px", color: "white" };
  const fontStyle = { color: "white", fontSize: "16px" };
  return (
    <Suspense fallback={<PageLoading />}>
      <AdminAuthorMentorStyles />
      {role === Role.AUTHOR ? (
        <ConfigProvider direction={getDirection()}>
          <Layout style={{ minHeight: "100vh" }}>
            <Sider width={250} collapsible>
              <NavLogo />
              <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                <SubMenu
                  key="1"
                  icon={
                    <FaListUl
                      style={{ ...iconStyle, marginRight: "10px" }}
                      className="ant-menu-item-icon"
                    />
                  }
                  className="font_family_nunito"
                  style={{ ...fontStyle }}
                  title={<Translation input="course" />}
                >
                  <Menu.Item key="/author" onClick={onMenuItemSelect}>
                    <Translation input="courses" />
                  </Menu.Item>
                  <Menu.Item
                    key="/author/create-course"
                    onClick={onMenuItemSelect}
                  >
                    <Translation input="upload_course" />
                  </Menu.Item>
                </SubMenu>

                <Menu.Item
                  key="/author/profile"
                  icon={<FaUserCog style={{ ...iconStyle }} />}
                  className="font_family_nunito"
                  style={fontStyle}
                  onClick={onMenuItemSelect}
                >
                  <Translation input="account" />
                </Menu.Item>
              </Menu>
            </Sider>
            <HeaderContentFooter component={component} />
          </Layout>
        </ConfigProvider>
      ) : (
        <UnauthorizedAccess />
      )}
    </Suspense>
  );
}

export default AuthorLayout;
