import LanguageIcon from "src/Assets/media/language-icon.svg";
import { languages } from "src/locale/languages";
import "flag-icon-css/css/flag-icon.min.css";
import { useAppDispatch } from "src/store/store";
import { setLanguage } from "src/actions/authActions";
import { useLocation } from "react-router-dom";
function NavLanguageIcon() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  return (
    <li className="nav-item px-lg-2 dropdown d-menu px-md-5 px-sm-5 px-5">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="dropdown01"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src={LanguageIcon} width="22px" alt="" />
        <span className="text-light mx-3 d-lg-none">Language</span>
      </a>
      <div
        className="dropdown-menu shadow-sm sm-menu dropdown-menu-right"
        aria-labelledby="dropdown01"
      >
        {languages.map(({ code, name, country_code }, i: number) => (
          <button
            key={i}
            className="dropdown-item"
            onClick={() => {
              dispatch(setLanguage(code)).then(
                () => (window.location.href = `${pathname}`)
              );
            }}
          >
            <span className={`flag-icon flag-icon-${country_code}`}></span>
            <span className="mx-2">{name}</span>
          </button>
        ))}

        {/* <a className="dropdown-item" href="#">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/1200px-Bandera_de_Espa%C3%B1a.svg.png"
            className="img-fluid rounded-circle mx-2"
            alt="..."
            style={{ width: "20px", height: "20px", objectFit: "cover" }}
          />
          Spanish
        </a>
        */}
      </div>
    </li>
  );
}

export default NavLanguageIcon;
