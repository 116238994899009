import { LanguageName, LanguageCode } from "src/enums/Language";

export const languages = [
  { code: LanguageCode.en, name: LanguageName.English, country_code: "gb" },
  { code: LanguageCode.hi, name: LanguageName.Hindi, country_code: "in" },
  { code: LanguageCode.zho, name: LanguageName.Chinese, country_code: "cn" },
  {
    code: LanguageCode.ar,
    name: LanguageName.Arabic,
    country_code: "sa",
    dir: "rtl",
  },
  { code: LanguageCode.es, name: LanguageName.Spanish, country_code: "es" },
];
