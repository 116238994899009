import { Pie } from "@ant-design/charts";
import { Card } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { analyticsSelectors } from "src/store/analyticSlice";

function CourseStatisticsChart() {
  const { t } = useTranslation();
  const langData = useSelector(analyticsSelectors.selectAnalytics()).language;
  const [data, setData] = useState<{ type: string; value: number }[]>([]);

  var dataTest = [
    {
      type: "分类一",
      value: 85.13513513513513,
    },
    {
      type: "分类二",
      value: 0,
    },
    {
      type: "分类三",
      value: 4.054054054054054,
    },
    {
      type: "分类四",
      value: 10.81081081081081,
    },
  ];

  var config = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    // color: ({ type }: any) => {
    //   if (type === "English" || type === "Arabic") return "#0077ff";
    //   return "#001436";
    // },
    radius: 1,
    label: { type: "outer" },
    interactions: [{ type: "element-active" }],
  };
  const getPercentage = (input: number, total: number) => (input / total) * 100;

  useEffect(() => {
    const total = Object.values(langData || {}).reduce(
      (accumulator: number, a: any) => accumulator + a,
      0
    );

    setData([
      { type: "English", value: getPercentage(langData?.en, total) },
      { type: "Spanish", value: getPercentage(langData?.es, total) },
      { type: "Arabic", value: getPercentage(langData?.ar, total) },
      { type: "Hindi", value: getPercentage(langData?.hi, total) },
    ]);
  }, [langData]);

  return (
    <Card title={<>{t("course_statistics")}</>}>
      <Pie
        {...config}
        style={{ height: "43vh" }}
        loading={!langData ? true : false}
      />
    </Card>
  );
}

export default CourseStatisticsChart;
