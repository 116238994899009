import { Button, Image, Row } from "antd";
import { LessonsAttachment } from "src/interfaces/Lesson";
import { useEffect, useState } from "react";
import { Form, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Modal from "antd/lib/modal/Modal";
import { uploadCertificateLogo } from "src/actions/certificateActions";
import { useAppDispatch } from "src/store/store";
import {
  addMedia,
  addSliderImage,
  removeSliderImage,
} from "src/utils/Rest/EditCourseUtils/EditCourseUtils";
import { LessonFormat } from "src/enums/LessonFormats";
import { uploadCourseImage } from "src/api/mediaUploadApi";
interface Props {
  lessonsAttachment: LessonsAttachment[];
  moduleId: number | string;
  lessonId: number | string;
  sectionId: number | string;
  onChange: (e: any) => void;
}

function ImageSection({
  lessonsAttachment,
  moduleId,
  lessonId,
  sectionId,
  onChange,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      if (e.file.type !== "image/jpeg" && e.file.type !== "image/png") {
        message.error("please upload image");
        setLoading(false);
        return;
      }

      uploadCourseImage(formData)
        .then((image) => {
          onChange({
            attachmentId: image.id,
            resources: { attachmentUrl: image.attachmentUrl },
          });
          setLoading(false);
        })
        .catch((e) => {
          message.error("error uploading");
        });

      // try {
      //   const image = await uploadCourseImage(formData);
      //   console.log(image);
      //   onChange({
      //     attachmentId: image.id,
      //     resources: { attachmentUrl: image.attachmentUrl },
      //   });

      //   // setFileListArray([
      //   //   ...fileListArray,
      //   //   {
      //   //     url: image.attachmentUrl,
      //   //     name: "image.png",
      //   //     status: "done",
      //   //     uid: image.id,
      //   //   },
      //   // ]);

      //   setLoading(false);
      //   message.success("image successfully uploaded");
      // } catch (error) {
      //   message.error("error uploading");
      // }
    }
  };

  const removeHandler = (e: any) => {
    // removeSliderImage(moduleId, lessonId, sectionData[0].sectionId, e.uid);
  };

  const uploadButton = loading ? (
    <LoadingOutlined style={{ fontSize: "250%" }} />
  ) : (
    <PlusOutlined style={{ fontSize: "250%" }} />
  );

  // useEffect(() => {
  //   setFileListArray([
  //     {
  //       url: lessonsAttachment[0].resources.attachmentUrl,
  //       name: "image.png",
  //       status: "done",
  //       uid: lessonsAttachment[0].attachmentId,
  //     },
  //   ]);
  // }, []);

  return (
    <>
      {/* <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
        customRequest={customRequest}
        multiple={true}
        fileList={fileListArray}
        // onRemove={removeHandler}
      >
        {fileListArray.length >= 1 ? null : uploadButton}
      </Upload> */}
      {lessonsAttachment[0].resources && !edit ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Image
            width={300}
            src={lessonsAttachment[0].resources.attachmentUrl}
          />
          {/* <Button type="primary" onClick={() => setEdit(true)}>
            Edit
          </Button> */}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={true}
            customRequest={customRequest}
            multiple={true}
            fileList={fileListArray}
            onRemove={removeHandler}
          >
            {fileListArray.length >= 1 ? null : uploadButton}
          </Upload>
          {/* <Button type="primary" onClick={() => setEdit(false)}>
            Cancel
          </Button> */}
        </div>
      )}
    </>
  );
}

export default ImageSection;
