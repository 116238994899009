import { Form, Modal, Button, Input, Row, Card } from "antd";
import AddLesson from "../../../components/AddLesson";
import { GrAdd } from "react-icons/gr";

interface Props {
  done: boolean;
}

function DefaultCourseFormComp({ done }: Props) {
  return (
    <>
      <h6 className="form_label" style={{ marginBottom: "10px" }}>
        Add Modules
      </h6>
      <Form.List name="modules">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, i) => (
                <Card
                  style={{
                    marginBottom: "20px",
                    border: "1px solid #BEBEBE",
                  }}
                  hoverable={true}
                >
                  <Form.Item
                    required={false}
                    key={field.key}
                    style={{
                      border: "1px solid #eee",
                      padding: "15px",
                      borderRadius: "10px",
                    }}
                  >
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form_label">Module Title</div>
                        <Button
                          onClick={() => {
                            Modal.confirm({
                              content:
                                "Are you sure you want to remove this module",
                              onOk: () => {
                                remove(field.name);
                              },
                            });
                          }}
                        >
                          Remove Module
                        </Button>
                      </div>
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "title"]}
                      fieldKey={[field.fieldKey, "title"]}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please add as Title",
                          type: "string",
                        },
                        {
                          min: 2,
                          message: "Must be minimum 2 characters.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <AddLesson done={done} field={field} />
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Row justify="center">
                  <Button onClick={() => add()}>Add Module</Button>
                </Row>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
}

export default DefaultCourseFormComp;
