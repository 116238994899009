import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "src/store/store";
import { api, extractStandardResponseData } from "src/api/api";
import { logout } from "../authSlice";
import { SessionRequest } from "src/interfaces/SessionRequest";

export const learnerSessionsAdapter = createEntityAdapter<SessionRequest>();

//get all learner sessions
export const getLearnerSessions = createAsyncThunk(
  "session/learner/get",
  async () =>
    await api.get("users/learnerSessions").then(extractStandardResponseData)
);

//book a session
export const bookSession = createAsyncThunk(
  "session/learner/book",
  async (session: {
    courseId: number;
    mentorId: number;
    startTime: string;
    topic: string;
  }) =>
    await api
      .post("users/bookSession", session)
      .then(extractStandardResponseData)
);

// search courses linked to user and mentor
export const searchCourses = createAsyncThunk(
  "session/learner/book/courses",
  async ({ title, mentorId }: { title: string; mentorId: number }) =>
    await api
      .post("users/searchMentorCourse", { title, mentorId })
      .then(extractStandardResponseData)
);

export const learnerSessionRequestsSlice = createSlice({
  name: "learnerSessions",
  initialState: learnerSessionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLearnerSessions.fulfilled, (state, { payload }) =>
      learnerSessionsAdapter.upsertMany(state, payload)
    );
    builder.addCase(bookSession.fulfilled, (state, { payload }) =>
      learnerSessionsAdapter.upsertOne(state, payload)
    );
    builder.addCase(logout.fulfilled, () =>
      learnerSessionsAdapter.getInitialState()
    );
  },
});

const { selectById, selectAll } = learnerSessionsAdapter.getSelectors();
const getLearnerSessionsState = (state: RootState) =>
  state.learnerSessionRequests;

export const learnerSessionRequestsSelectors = {
  selectAllSessionRequests: () =>
    createSelector(getLearnerSessionsState, (state) => selectAll(state)),
  selectSessionEntity: (id: number) =>
    createSelector(getLearnerSessionsState, (state) => selectById(state, id)),
};
