import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "src/store/store";
import { Course } from "src/interfaces/Course";
import StatusTag from "src/components/AdminAuthorMentorComponents/Common/StatusTag";
import { Table, Row, Menu, Button, Dropdown, Pagination } from "antd";
import Column from "antd/lib/table/Column";
import ImageThumbnail from "src/components/Admin-Author-Components/ImageThumbnail";
import { useHistory } from "react-router-dom";
import { FILTER_ENUMS } from "src/enums/Filters";
import { filtersFunction } from "src/utils/sortUtils";
import Tags from "src/components/AdminAuthorMentorComponents/Common/Tags";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import { setTabTitle } from "src/utils/utils";
import { getAllCoursesForMentor } from "src/actions/courseActions/courseActions";
import { coursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/coursesPaginationSlice";

function MentorCoursesTablePage() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const courses = useSelector((state: RootState) =>
    coursesPaginationSelectors.get(state, page)
  ) as Course[];
  const availablePages = useSelector(
    coursesPaginationSelectors.pages.selectIds
  );
  const totalCourses = useSelector(coursesPaginationSelectors.totalCourses);

  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState<any>(
    FILTER_ENUMS.CLEAR_FILTERS
  );

  const { SubMenu } = Menu;
  useEffect(() => {
    setTabTitle("Courses");
    if (availablePages && !loading && !availablePages.includes(page)) {
      setLoading(true);
      dispatch(getAllCoursesForMentor(page)).then(setLoading.bind(null, false));
    }
  }, [courses, page]);

  const menu = (
    <Menu
      onClick={(e) => setSelectedFilter(e.key)}
      defaultSelectedKeys={[`${selectedFilter}`]}
    >
      <Menu.ItemGroup title="Filter by">
        <SubMenu title="Course Format" key="1">
          <Menu.Item key={FILTER_ENUMS.DEFAULT}>Default</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.VIDEO}>Video</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.XAPI}>Xapi</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.SCROM}>Scrom</Menu.Item>
        </SubMenu>
        <SubMenu title="Status" key="2">
          <Menu.Item key={FILTER_ENUMS.AWAITING_APPROVAL}>
            Awaiting Approval
          </Menu.Item>
          <Menu.Item key={FILTER_ENUMS.PUBLISHED}>Published</Menu.Item>
          <Menu.Item key={FILTER_ENUMS.DISAPPROVED}>Dissaproved</Menu.Item>
        </SubMenu>
        <Menu.Item key={FILTER_ENUMS.CLEAR_FILTERS}>Clear Filters</Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <>
      <Table
        pagination={false}
        dataSource={filtersFunction(courses, selectedFilter)}
        loading={loading}
        rowKey="id"
        title={() => (
          <Row justify="space-between">
            <h5>Courses</h5>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="primary">Filters</Button>
            </Dropdown>
          </Row>
        )}
      >
        <Column
          title={<TableColTitle title="cover" />}
          dataIndex="actions"
          key="actions"
          render={(_, course: Course) => (
            <div
              className="cursor_pointer"
              onClick={() =>
                history.push(`/mentor/courses/view-course/${course.id}`)
              }
            >
              <ImageThumbnail
                resources={course.resources}
                width={65}
                height={65}
              />
            </div>
          )}
        />
        <Column
          title={<TableColTitle title="title" />}
          dataIndex="title"
          key="title"
          render={(_, course: Course) => (
            <div
              className="cursor_pointer hover_color_change"
              onClick={() =>
                history.push(`/mentor/courses/view-course/${course.id}`)
              }
            >
              {course.title}
            </div>
          )}
        />
        <Column
          title={<TableColTitle title="course_tags" />}
          render={(_, course: Course) => <Tags tags={course.coursesTags} />}
        />
        <Column
          title={<TableColTitle title="status" />}
          key="actions"
          render={(_, course: Course) => <StatusTag data={course} />}
        />
      </Table>
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Pagination
          defaultCurrent={1}
          total={totalCourses}
          showSizeChanger={false}
          onChange={(p) => setPage(p - 1)}
        />
      </Row>
    </>
  );
}

export default MentorCoursesTablePage;
