import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";
import { LanguageCode } from "src/enums/Language";
import { Course } from "src/interfaces/Course";
import { Module } from "src/interfaces/Module";
import { Quiz } from "src/interfaces/Quiz";

export const getAllCourses = createAsyncThunk(
  "courses/get/all",
  async (page: number) =>
    await api.get(`/admin?page=${page}`).then((res) => res.data)
);

//mentor actions
export const getAllCoursesForMentor = createAsyncThunk(
  "courses/mentor/get/all",
  async (page: number) =>
    await api.get(`/mentor?page=${page}`).then((res) => res.data)
);

export const getSingleCourse = createAsyncThunk(
  `courses/get/single`,
  async (id: number) =>
    await api.get(`/courses/course/${id}`).then(extractStandardResponseData)
);

//FIXME: put learner action in learsercourseactions filr
//add course view
export const addCourseView = createAsyncThunk(
  "searchCoursesPathway",
  async (courseId: number) =>
    await api
      .post(`analytics/addViews/${courseId}`, { type: "course" })
      .then(extractStandardResponseData)
);

//add language to course
export const addLanguageToCourse = createAsyncThunk(
  "addCourseLanguage",
  async (course: any) =>
    await api
      .post(`admin/courseLanguage/${course.id}`, course)
      .then((res) => res.data)
);

//lesson completed
export const completeLesson = createAsyncThunk(
  "completeLesson",
  async ({
    lessonId,
    weightage,
    courseId,
  }: {
    lessonId: number;
    weightage: number;
    courseId: number;
  }) =>
    await api
      .post(`users/lessonSubmit/${courseId}`, { lessonId, weightage })
      .then((res) => res.data)
);

// ========================================================================================
//submit quiz answer
export const submitQuizAnswer = createAsyncThunk(
  "submitAns",
  async (answer: any) =>
    await api
      .post(`users/courseCompletion/${answer.courseId}`, { quiz: answer.quiz })
      .then(extractStandardResponseData)
);

// get webwatch and infographia
export const getWebwatchAndInfographiaCourses = createAsyncThunk(
  "courses/getWebwatchAndInfographiaCourses",
  async (page: number) =>
    await api
      .get(`/admin/getWebwatchAndInfographiaCourses?page=${page}`)
      .then((res) => res.data)
);

// export const editCourseModule = createAsyncThunk(
//   "editModulesLocal",
//   (modules: Module[]) => {
//     console.log(modules);
//     return {
//       id: modules[0].courseId,
//       modules,
//     };
//   }
// );
