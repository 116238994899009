import { Course, CourseVideo } from "src/interfaces/Course";
import { FiBookmark } from "react-icons/fi";
import { BsQuestionSquare } from "react-icons/bs";

import { useEffect, useState } from "react";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { Module } from "src/interfaces/Module";
import LearnerLayoutFooter from "src/components/LearnerComponents/Layout/LearnerLayoutFooter";
import Translation from "src/components/Common/utilities/Translation";
import QuizRender from "src/components/LearnerComponents/Course/QuizRender/QuizRender";
import { useAppDispatch } from "src/store/store";
import { completeLesson } from "src/actions/courseActions/courseActions";

interface Props {
  course: Course;
}

function VideoCourse({ course }: Props) {
  const [showQuiz, setShowQuiz] = useState(false);
  const [lesson, setLesson] = useState<CourseVideo>(
    course?.coursesModules[0].coursesVideos[0]
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("video course");
    dispatch(
      completeLesson({
        courseId: course.id,
        lessonId: lesson.id,
        weightage: lesson.weightage,
      })
    ).then(() => {});
  }, [lesson]);

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex flex-column justify-content-between"
    >
      <div className="container py-5 clearance">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4 order-1 order-sm-1 order-md-1 order-lg-0">
            <div className="card border_color border_radius h-100">
              <div className="p-4">
                <div className="row border-bottom mb-4">
                  <h5 className="mb-3 px-4">
                    <Translation input="course_content" />
                  </h5>
                </div>

                <div className="px-2 overflow-auto">
                  {course?.coursesModules?.map((module: Module) => (
                    <div className="mb-3">
                      <div className="d-flex">
                        <div>
                          <FiBookmark />
                        </div>
                        <div style={{ width: "8px" }}></div>
                        <div className="d-flex flex-column">
                          <div className="fs-6 fw-bold">{module.title}</div>
                          <ul style={{ listStyle: "none" }} className="p-0">
                            {module?.coursesVideos?.map(
                              (courseVideo: CourseVideo) => (
                                <li
                                  className="cursor_pointer fs-6 translate_effect"
                                  onClick={() => setLesson(courseVideo)}
                                >
                                  {courseVideo.title}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                  {course?.coursesQuiz?.length === 0 ? null : (
                    <div className="mb-3">
                      <div className="d-flex">
                        <div>
                          <BsQuestionSquare />
                        </div>
                        <div style={{ width: "8px" }}></div>
                        <ul style={{ listStyle: "none" }} className="p-0">
                          <li
                            className="cursor_pointer fs-6 translate_effect fw-bold"
                            onClick={() => setShowQuiz((s) => !s)}
                          >
                            Quiz
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 pb-sm-4 pb-lg-0">
            {showQuiz ? (
              <QuizRender quiz={course.coursesQuiz} />
            ) : (
              <VideoPlayer url={lesson.resources.attachmentUrl} />
            )}
          </div>
        </div>
      </div>

      <LearnerLayoutFooter />
    </div>
  );
}

export default VideoCourse;
