import { useEffect, useState } from "react";
import { Button, Col, Table, Row, Space, Pagination } from "antd";
import Column from "antd/lib/table/Column";
import { clearSessionsStore } from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import { RootState, useAppDispatch } from "src/store/store";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { Session } from "src/interfaces/Session";
import ImageThumbnail from "src/components/Admin-Author-Components/ImageThumbnail";
import StartSessionButton from "./StartSessionButton";
import PopUpComp from "src/components/AdminAuthorMentorComponents/Common/PopUpComp";
import { deleteSession } from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import TimeStamp from "src/components/Common/utilities/TimeStamp";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import Translation from "src/components/Common/utilities/Translation";
import { clearPaginations, getSessions } from "src/actions/sessionsActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { sessionsPaginationSelectors } from "src/store/mentorSlices/sessionsSlices/sessionsPaginationSlice";
import { setTabTitle } from "src/utils/utils";

function SessionsPage() {
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const mentorId = useSelector(authSelectors.id);
  const sessions = useSelector((state: RootState) =>
    sessionsPaginationSelectors.get(state, page)
  ) as Session[];
  const totalSessions = useSelector(sessionsPaginationSelectors.totalSessions);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");

  const tableHeader = (
    <Row justify="space-between">
      <Space>
        <h5>
          <Translation input="sessions" />
        </h5>
      </Space>

      <Space>
        <Button
          onClick={() => filterFunction("")}
          type={filter === "" ? "primary" : "default"}
        >
          <Translation input="all" />
        </Button>
        <Button
          onClick={() => filterFunction("Archived")}
          type={filter === "" ? "default" : "primary"}
        >
          <Translation input="archive" />
        </Button>
      </Space>
    </Row>
  );

  useEffect(() => {
    setTabTitle("Sessions");
    if (sessions.length === 0) {
      setLoading(true);
      dispatch(getSessions({ mentorId, filter, page }))
        .then(unwrapResult)
        .then(setLoading.bind(null, false));
    } else {
      setLoading(false);
    }
  }, [filter, page]);

  const filterFunction = (filt: string) => {
    setLoading(true);
    dispatch(clearPaginations());
    setFilter(filt);
  };

  return (
    <Col span={24}>
      <Table
        dataSource={sessions}
        loading={loading}
        rowKey={(s) => s.id}
        title={() => tableHeader}
        pagination={false}
      >
        <Column
          title={<TableColTitle title="cover" />}
          key="actions"
          render={(_, session: Session) => (
            <ImageThumbnail
              resources={session?.resources}
              width={65}
              height={65}
            />
          )}
        />
        <Column
          title={<TableColTitle title="session_name" />}
          key="title"
          render={(_, session: Session) => session.meeting.topic}
        />
        <Column
          title={<TableColTitle title="participants" />}
          key="Participants"
          render={(_, session: Session) =>
            session?.meeting?.registrants?.registrants?.length
          }
        />
        <Column
          title={<TableColTitle title="time" />}
          key="time"
          render={(_, session: Session) => (
            <TimeStamp timestamp={session?.meeting?.start_time} />
          )}
        />
        <Column
          title={<TableColTitle title="actions" />}
          key="action"
          render={(_, session: Session) => (
            <StartSessionButton
              sessionId={session.id}
              deactivated={filter === "Archived" ? true : false}
            />
          )}
        />
        <Column
          title={<TableColTitle title="options" />}
          key="Options"
          render={(_, session: Session) => (
            <PopUpComp
              deleteMethod={deleteSession({
                id: session.id,
                meetingId: session.meetingId,
              })}
              editLink={`/mentor/edit-session/${session.id}`}
            />
          )}
        />
      </Table>
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Pagination
          defaultCurrent={1}
          total={totalSessions}
          showSizeChanger={false}
          onChange={(p) => setPage(p - 1)}
        />
      </Row>
    </Col>
  );
}

export default SessionsPage;
