import { useHistory } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { Course } from "src/interfaces/Course";
import {
  exhaustedAttempts,
  hasPassedQuiz,
  numberOfQuestionsAnsweredCorrect,
  totalNumOfQuestions,
} from "src/utils/Learner/quizUtils";
interface Props {
  course: Course;
  retake: () => void;
}

function QuizResult({ course, retake }: Props) {
  const hasPassed = hasPassedQuiz(course);
  const totalQuestions = totalNumOfQuestions(course);
  const finishedAttempts = exhaustedAttempts(course);
  const hasFinishedAttempts = finishedAttempts === course.attempts;
  const history = useHistory();

  return (
    <div>
      <div className="card text-center">
        <div className="card-header fw-bold">Title : {course.quizTitle}</div>
        <div className="card-body">
          <h5 className="card-title">{hasPassed ? "Passed" : "Failed"}</h5>
          <p className="card-text">
            Correct answers required for passing {course.passing}
          </p>
          <p className="card-text">
            You answered {numberOfQuestionsAnsweredCorrect(course)} out of{" "}
            {totalQuestions} correct
          </p>
          <p className="card-text">
            Attempts {finishedAttempts}/{course.attempts}
          </p>
          {!hasPassed && !hasFinishedAttempts ? (
            <a className="btn btn-dark text-white" onClick={() => retake()}>
              <Translation input="Retake Quiz" />
            </a>
          ) : null}

          <a
            className="btn btn-dark text-white mx-2"
            onClick={() => history.push(`/course-description/${course.slug}`)}
          >
            <Translation input="back" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default QuizResult;
