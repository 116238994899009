import {
  createAsyncThunk,
  createSelector,
  createSlice,
  createEntityAdapter,
  current,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { logout } from "../authSlice";
import { api, extractStandardResponseData } from "../../api/api";
import { User } from "../../interfaces/User";
import { updateProfile } from "src/store/authSlice";
import {
  getUsers,
  editUser,
  activateDeativateUser,
  deleteUser,
  createNewUser,
} from "src/actions/userActions";

export const userListAdapter = createEntityAdapter<any>();

// ------sort newest first -----
export const getUsersListNewFirst = createAsyncThunk(
  "admin/usersList/newest",
  async () =>
    await api
      .get("/admin/users?filter=newest")
      .then(extractStandardResponseData)
);
// ------sort oldest first -----
export const getUsersListOldFirst = createAsyncThunk(
  "admin/usersList/oldest",
  async () =>
    await api
      .get("/admin/users?filter=oldest")
      .then(extractStandardResponseData)
);
// ------search users -----

// export const searchUsers = createAsyncThunk(
//   "admin/usersList/search",
//   async (name: string) =>
//     await api.get(`/admin/users?search=${name}`).then((res) => {
//       console.log(res.data);
//       return res.data;
//     })
// );

export const searchUsers = createAsyncThunk(
  "admin/usersList/search",
  async ({ name, page }: { name: string; page: number }) =>
    await api.get(`/admin/users?search=${name}&page=${page}`).then((res) => {
      return res.data;
    })
);
// ------clear users -----
export const clearUsers = createAsyncThunk(
  "admin/usersList/clear",
  async () => {}
);

export const usersSlice = createSlice({
  name: "admin/usersList",
  initialState: userListAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      userListAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(getUsersListNewFirst.fulfilled, (state, { payload }) => {
      userListAdapter.setAll(state, payload);
    });
    builder.addCase(getUsersListOldFirst.fulfilled, (state, { payload }) => {
      userListAdapter.upsertMany(state, payload);
    });
    builder.addCase(searchUsers.fulfilled, (state, { payload }) => {
      userListAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(createNewUser.fulfilled, (state, { payload }) => {
      userListAdapter.upsertOne(state, payload);
    });
    builder.addCase(
      editUser.fulfilled,
      (state, { payload }: { payload: User }) => {
        const test = [
          payload,
          ...Object.values(current(state.entities)).filter(
            (user: User) => user.id !== payload.id
          ),
        ];
        userListAdapter.removeAll(state);
        userListAdapter.upsertMany(state, test);
      }
    );
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      userListAdapter.removeOne(state, payload);
    });
    builder.addCase(activateDeativateUser.fulfilled, (state, { payload }) => {
      userListAdapter.upsertOne(state, payload);
    });
    builder.addCase(clearUsers.fulfilled, (state, { payload }) => {
      userListAdapter.removeAll(state);
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      userListAdapter.upsertOne(state, payload);
    });
    builder.addCase(logout.fulfilled, () => userListAdapter.getInitialState());
  },
});

const { selectById, selectAll } = userListAdapter.getSelectors();
const getUsersState = (state: RootState) => state.users;

export const userListSelectors = {
  usersSelector: userListAdapter.getSelectors((s: RootState) => s.users),
  userList: (state: RootState) => state.users.entities,
  selectAllUsers: () =>
    createSelector(getUsersState, (state) => selectAll(state)),
  selectUserEntity: (id: number) =>
    createSelector(getUsersState, (state) => selectById(state, id)),
};
