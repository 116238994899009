import { useState, useEffect } from "react";
import { useAppDispatch } from "../../store/store";
import { UpCircleOutlined } from "@ant-design/icons";
import {
  Space,
  Button,
  Modal,
  Col,
  Row,
  Card,
  BackTop,
  Tooltip,
  Typography,
} from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import VideosCollapse from "./ViewCoursePageFields/VideosCollapse ";
import {
  approveCourse,
  disapproveCourse,
  coursesSelector,
} from "../../store/coursesSlices/coursesSlice";
import CollapsibleCompAdmin from "../../components/AdminAuthorMentorComponents/Common/CollapsibleCompAdmin";
import { useSelector } from "react-redux";
import { authSelectors } from "src/store/authSlice";
import { Role } from "../../interfaces/User";
import SkeletonLoader from "../../components/Common/utilities/SkeletonLoader";
import TextHeightWrapper from "src/components/Common/utilities/TextHeightWrapper";
import { CourseFormat } from "src/enums/CourseFormat";
import { setTabTitle } from "src/utils/utils";
import FormLabel from "src/components/Common/utilities/FormLabel";
import EditCourseButton from "src/components/AdminAuthorMentorComponents/Common/Course/ViewCourse/EditCourseButton";
import PreviewCourseButton from "src/components/AdminAuthorMentorComponents/Common/Course/ViewCourse/PreviewCourseButton";
import { getSingleCourse } from "src/actions/courseActions/courseActions";

// interface Params {
//   course_id: string;
// }
interface Params {
  courseId: string;
  translationString: string;
}

function ViewCoursePage() {
  // const courseId = useParams<Params>().course_id;
  const courseId = useParams<Params>().courseId;

  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const [loading, setLoading] = useState(true);
  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { Title } = Typography;

  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;

  const pageHeader = (
    <Row justify="space-between">
      <SkeletonLoader loading={loading} rows={0} type="button" width={300}>
        <Title level={5}>{isTranslationPath ? "English" : course?.title}</Title>
      </SkeletonLoader>
      {!isTranslationPath && (
        <Space>
          <EditCourseButton courseId={parseInt(courseId)} />
          <PreviewCourseButton slug={course?.slug || ""} />
        </Space>
      )}
    </Row>
  );

  useEffect(() => {
    if (course) setLoading(false);
    else
      dispatch(getSingleCourse(parseInt(courseId))).then(
        setLoading.bind(null, false)
      );
    setTabTitle(course?.title || "");
  }, []);

  return (
    <Row>
      <Col sm={24}>
        <Card title={pageHeader} style={{ minHeight: "50vh" }}>
          <FormLabel name="title" />
          <div style={{ marginBottom: "10px" }}>
            <SkeletonLoader loading={loading} rows={0} type="normal">
              <>{course?.title}</>
            </SkeletonLoader>
          </div>

          <FormLabel name="excerpt" />
          <div style={{ marginBottom: "10px" }}>
            <SkeletonLoader loading={loading} rows={0} type="normal">
              <>{course?.excerpt}</>
            </SkeletonLoader>
          </div>
          <FormLabel name="objectives" />
          <SkeletonLoader loading={loading} rows={4} type="normal">
            <TextHeightWrapper
              maxChars={500}
              inputString={course?.objectives || ""}
            />
          </SkeletonLoader>

          <FormLabel name="description" />
          <SkeletonLoader loading={loading} rows={4} type="normal">
            <TextHeightWrapper
              maxChars={500}
              inputString={course?.description || ""}
            />
          </SkeletonLoader>

          <FormLabel name="course_content" />
          {course?.type === CourseFormat.DEFAULT && (
            <SkeletonLoader loading={loading} rows={2} type="normal">
              <>
                {course?.coursesModules.map((module: any) => (
                  <CollapsibleCompAdmin data={module} />
                ))}
              </>
            </SkeletonLoader>
          )}
          {course?.type === CourseFormat.VIDEO && (
            <VideosCollapse courseModules={course.coursesModules || []} />
          )}

          <Col sm={24}>
            <Row justify="center" align="middle">
              {course?.type === CourseFormat.XAPI ||
              course?.type === CourseFormat.SCROM ? (
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    role === Role.ADMIN
                      ? history.push(`/admin/view/${courseId}`)
                      : history.push(`/author/view/${courseId}`);
                  }}
                >
                  View Complete Course
                </Button>
              ) : null}
            </Row>
          </Col>
        </Card>
      </Col>
      <BackTop>
        <div style={{ fontSize: "200%" }}>
          <UpCircleOutlined />
        </div>
      </BackTop>
    </Row>
  );
}

export default ViewCoursePage;
