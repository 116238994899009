import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { api, extractStandardResponseData } from "../../../api/api";
import { logout } from "../../authSlice";
import { Session } from "src/interfaces/Session";
import { getSessions } from "src/actions/sessionsActions";
export const sessionsAdapter = createEntityAdapter<Session>();

// //get all sessions
// interface GetAllSession {
//   mentorId: number | undefined;
//   filter: string;
// }
// export const getAllSessions = createAsyncThunk(
//   "sessions/get",
//   async ({ mentorId, filter }: GetAllSession) =>
//     await api
//       .post(`mentor/getSessions?filter=${filter}`, { mentorId })
//       .then(extractStandardResponseData)
// );

//create new session
export const createSession = createAsyncThunk(
  "session/create",
  async (session: any) =>
    await api
      .post("mentor/createSessions", session)
      .then(extractStandardResponseData)
);

//delete session
export const deleteSession = createAsyncThunk(
  "session/delete",
  async ({ id, meetingId }: { id: number; meetingId: number }) => {
    await api
      .delete("mentor/deleteSession", { data: { id, meetingId } })
      .then(extractStandardResponseData);
    return id;
  }
);

//edit session
export const editSession = createAsyncThunk(
  "session/edit",
  async (session: any) =>
    await api
      .patch("mentor/editSession", session)
      .then(extractStandardResponseData)
);

//learner join meeitng
export const joinSession = createAsyncThunk(
  "session/learner/join",
  async ({ id }: { id: number }) =>
    await api
      .post("mentor/registrants", { id })
      .then((res) => ({ ...res.data.data, ...res.data.keys }))
);

//get zoom signature
export const getZoomSignature = createAsyncThunk(
  "session/getZoomSignature",
  async ({ meetingNumber, role }: { meetingNumber: number; role: number }) =>
    await api
      .post("mentor/zoomSignature", { meetingNumber, role })
      .then((res) => res.data)
);

//get learners for session
export const getLearners = createAsyncThunk(
  "mentor/getLearners",
  async (courseId: number) =>
    await api
      .post(`/mentor/searchLearners/${courseId}`)
      .then(extractStandardResponseData)
);

export const clearSessionsStore = createAsyncThunk("sessions/clear", () => {});

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState: sessionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSessions.fulfilled, (state, { payload }) =>
      sessionsAdapter.upsertMany(state, payload.data)
    );
    builder.addCase(deleteSession.fulfilled, (state, { payload }) =>
      sessionsAdapter.removeOne(state, payload)
    );
    builder.addCase(editSession.fulfilled, (state, { payload }) => {
      console.log(payload);
      // sessionsAdapter.upsertOne(state, payload);
    });

    builder.addCase(clearSessionsStore.fulfilled, () =>
      sessionsAdapter.getInitialState()
    );

    builder.addCase(logout.fulfilled, () => sessionsAdapter.getInitialState());
  },
});

const { selectById, selectAll } = sessionsAdapter.getSelectors();
const getSessionsState = (state: RootState) => state.sessions;

export const sessionSelectors = {
  sessionsSelector: sessionsAdapter.getSelectors((s: RootState) => s.sessions),
  selectAllSessions: () =>
    createSelector(getSessionsState, (state) => selectAll(state)),
  selectSessionEntity: (id: number) =>
    createSelector(getSessionsState, (state) => selectById(state, id)),
};
