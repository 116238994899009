import { useState } from "react";
import { Form, Upload, message, Input, Col, Row, Progress, Button } from "antd";
import {
  LoadingOutlined,
  InboxOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { LessonFormat } from "src/enums/LessonFormats";
import { api, extractStandardResponseData } from "src/api/api";
import { Video } from "src/interfaces/Media";
import FormItemCustom from "../../FormItemCustom";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { LessonsAttachment } from "src/interfaces/Lesson";
import { apiCall } from "src/utils/utils";

interface Props {
  done: boolean;
  field?: any;
  positionId: number;
  moduleId: number;
}

function EditUploadVideo({ field, done, positionId, moduleId }: Props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState<{
    positionId: number;
    attachmentId: number;
    type: LessonFormat;
    moduleId: number;
  }>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { Dragger } = Upload;
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const uploadButton = (
    <div>
      {show ? (
        <div style={{ fontSize: "200%" }}>
          {/* <CheckCircleTwoTone twoToneColor="#52c41a" /> */}

          <VideoPlayer url={attachmentUrl} />
        </div>
      ) : (
        <>
          {loading ? (
            uploadProgress === 100 ? (
              <LoadingOutlined />
            ) : (
              <Progress percent={uploadProgress} type="circle" width={50} />
            )
          ) : (
            <PlusOutlined />
          )}
        </>
      )}
    </div>
  );

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };

  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (!fileList) return;
    if (e.file.type !== "video/mp4") {
      message.error("Please upload video");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", fileList);

    const [data, error] = await apiCall(
      await api
        .post("/courses/files", formData, config)
        .then(extractStandardResponseData)
    );
    if (error) {
      message.error("error uploading");
      return;
    }

    setAttachment({
      attachmentId: data.id,
      type: LessonFormat.VIDEO,
      positionId: positionId,
      moduleId,
    });
    console.log(data);
    setAttachmentUrl(data.attachmentUrl);
    setLoading(false);
    setShow(true);
    message.success("video successfully uploaded");

    // try {
    //   if (e.file.type === "video/mp4" || e.file.type === "audio/mpeg") {
    //     const video: Video = await api
    //       .post("/courses/files", formData, config)
    //       .then(extractStandardResponseData);
    //     setAttachment({
    //       attachmentId: video.id,
    //       type: LessonFormat.VIDEO,
    //       positionId: positionId,
    //     });
    //     setAttachmentUrl(video.attachmentUrl);
    //     console.log(video);
    //     setLoading(false);
    //     setShow(true);
    //     message.success("video successfully uploaded");
    //   } else {
    //     setLoading(false);
    //     message.success("Upload format should be Video");
    //   }
    // } catch (error) {
    //   message.error("error uploading ");
    // }
  };

  const removeHandler = (e: any) => {};

  return (
    <Col sm={24}>
      <Row justify="center">
        <Dragger
          name="avatar"
          showUploadList={false}
          customRequest={customRequest}
          multiple={false}
          onRemove={removeHandler}
          style={{ width: "100%" }}
        >
          <div>
            <p className="ant-upload-drag-icon">{uploadButton}</p>
            <p className="ant-upload-text">
              {show
                ? "Video Uploaded Sucessfully"
                : "Click or drag file to this area to upload video"}
            </p>
          </div>
        </Dragger>
      </Row>

      <FormItemCustom
        fieldName={field.name}
        initialValue={attachment}
        formItemName="lessonsAttachments"
      />

      {/* {done ? (
        <Form.Item
          name={[field.name, "lessonsAttachments"]}
          initialValue={attachmentIdArray}
        >
          <Input type="hidden" />
        </Form.Item>
      ) : null} */}
    </Col>
  );
}

export default EditUploadVideo;
