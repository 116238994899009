import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import UnauthorizedAccess from "./UnauthorizedAccess";

interface Props {
  roles: Role[];
  children: JSX.Element;
}

function AuthorizedRoles({ roles, children }: Props) {
  const authRole = useSelector(authSelectors.role);

  const roleArrayCheck = (roles: Role[]) =>
    roles.find((role: Role) => role === authRole);

  return <>{roleArrayCheck(roles) ? children : <UnauthorizedAccess />}</>;
}

export default AuthorizedRoles;
