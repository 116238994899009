export const endpoints = {
  auth: {
    login: () => "auth/login",
  },

  tags: {
    add: () => "/tags",
    search: () => "tags/searchTags",
  },
};
