import { Form, Input } from "antd";
import { useEffect, useState } from "react";

interface Props {
  fieldName: any;
  initialValue: any;
  formItemName: string;
}

function FormItemCustom({ fieldName, initialValue, formItemName }: Props) {
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(key + 1);
  }, [initialValue]);

  return (
    <>
      <Form.Item
        name={[fieldName, formItemName]}
        initialValue={initialValue}
        key={initialValue?.attachmentId || 0}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
}

export default FormItemCustom;
