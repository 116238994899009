import { useState } from "react";
import { Form, Upload, message, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { uploadCourseImage } from "../../../../api/mediaUploadApi";
import { Image } from "../../../../interfaces/Media";
import { LessonFormat } from "src/enums/LessonFormats";

interface Props {
  done: boolean;
  field?: any;
  sectionId: number;
}
interface FileList {
  url: string;
  name: string;
  status: string;
  uid: number;
}
function UploadSlider({ field, done, sectionId }: Props) {
  const [loading, setLoading] = useState(false);
  // const [fileListArray, setFileListArray] = useState<FileList[]>([]);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [attachmentIdArray, setAttachmentIdArray] = useState<any>([]);
  const customRequest = async (e: any) => {
    setLoading(true);
    console.log("e.file.type:", e.file.type);

    const fileList = e.file;

    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      // console.log(e.file.type);
      try {
        if (e.file.type === "image/jpeg" || e.file.type === "image/png") {
          const image: Image = await uploadCourseImage(formData);
          console.log(image, "image");
          setFileListArray([
            ...fileListArray,
            {
              url: image.metaData.thumbnail,
              name: "image.png",
              status: "done",
              uid: image.id,
            },
          ]);
          setAttachmentIdArray([
            ...attachmentIdArray,
            {
              attachmentId: image.id,
              type: LessonFormat.IMAGESLIDER,
              sectionId: sectionId,
            },
          ]);
          setLoading(false);
          message.success("image successfully uploaded");
        } else {
          message.error("please upload image");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        message.error("error uploading ");
      }
    }
  };

  const removeHandler = (e: any) => {
    setFileListArray(
      fileListArray.filter((media: FileList) => {
        if (media.uid !== e.uid) {
          return media;
        }
      })
    );

    setAttachmentIdArray(
      attachmentIdArray.filter((item: any) => {
        if (item.attachmentId !== e.uid) {
          return item;
        }
      })
    );
  };
  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
        customRequest={customRequest}
        multiple={true}
        fileList={fileListArray}
        onRemove={removeHandler}
        // beforeUpload={beforeUpload}
      >
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </Upload>
      {done ? (
        <div>
          <Form.Item
            name={[field.name, "lessonsAttachments"]}
            initialValue={attachmentIdArray}
          >
            <Input type="hidden" />
          </Form.Item>
        </div>
      ) : null}
    </div>
  );
}

export default UploadSlider;
