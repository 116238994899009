import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "src/store/store";
import { api, extractStandardResponseData } from "src/api/api";
import { logout } from "../authSlice";
import { SessionRequest } from "src/interfaces/SessionRequest";

export const sessionsRequestsAdapter = createEntityAdapter<SessionRequest>();

//get all session requests
export const getSessionRequests = createAsyncThunk(
  "session/requests/get",
  async () =>
    await api.get("mentor/requestedSessions").then(extractStandardResponseData)
);

//accept reject request
export const acceptRejectSessionRequests = createAsyncThunk(
  "session/request/accept/reject",
  async ({
    sessionRequestId,
    approved,
  }: {
    sessionRequestId: number;
    approved: boolean;
  }) =>
    await api
      .patch(`mentor/approveSession/${sessionRequestId}`, { approved })
      .then(extractStandardResponseData)
);

export const sessionRequestsSlice = createSlice({
  name: "sessionRequests",
  initialState: sessionsRequestsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSessionRequests.fulfilled, (state, { payload }) =>
      sessionsRequestsAdapter.upsertMany(state, payload)
    );
    builder.addCase(
      acceptRejectSessionRequests.fulfilled,
      (state, { payload }) => sessionsRequestsAdapter.upsertOne(state, payload)
    );

    builder.addCase(logout.fulfilled, () =>
      sessionsRequestsAdapter.getInitialState()
    );
  },
});

const { selectById, selectAll } = sessionsRequestsAdapter.getSelectors();
const getSessionRequestsState = (state: RootState) => state.sessionRequests;

export const sessionRequestsSelectors = {
  selectAllSessionRequests: () =>
    createSelector(getSessionRequestsState, (state) => selectAll(state)),
  selectSessionEntity: (id: number) =>
    createSelector(getSessionRequestsState, (state) => selectById(state, id)),
};
