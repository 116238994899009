import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";
import CourseCardCarousel from "../../../components/LearnerComponents/Course/CourseCardCarousel/CourseCardCarousel";
import DownloadApp from "../../../components/LearnerComponents/DownloadApp";
import AssignedMentorsCarousel from "../../../components/LearnerComponents/AssignedMentorsCarousel/AssignedMentorsCarousel";
import UpcommingEvents from "../../../components/LearnerComponents/Sessions/UpcommingSessions";
import { useSelector } from "react-redux";
import { learnerSessionSelectors } from "src/store/learnerSlices/learnerSessionSlice";
import { useTranslation } from "react-i18next";
import { Filters } from "src/enums/Filters";
import { setTabTitle, topOfPage } from "src/utils/utils";
import PathwayCarousel from "src/components/LearnerComponents/Pathways/PathwaysCarousel/PathwayCarousel";
import PeopleToMeet from "./Components/PeopleToMeet/PeopleToMeet";

function HomePage() {
  const upcommingSessions = useSelector(
    learnerSessionSelectors.selectAllSessions()
  );
  const { t } = useTranslation();

  useEffect(() => {
    setTabTitle("");
    topOfPage();
  }, []);

  return (
    <div className="container py-5 clearance">
      <div className="row gx-4">
        <div className="col-lg-9 col-md-12 col-sm-12">
          <PathwayCarousel />
          <CourseCardCarousel
            title={t("continue_learning")}
            filter={Filters.CONTINUE_LEARNING}
            showProgressBar={true}
          />

          <CourseCardCarousel
            title={t("wats_new")}
            filter={Filters.WHATS_NEW}
          />
          <CourseCardCarousel
            title={t("popular_courses")}
            filter={Filters.POPULAR}
          />
          <CourseCardCarousel title={t("Webwatch")} filter={Filters.WEBWATCH} />
          <CourseCardCarousel
            title={t("Infographia")}
            filter={Filters.INFOGRAPHIA}
          />
          {/* <CourseCardCarousel
            title={t("scrom_xapi_courses")}
            filter={Filters.XAPI_SCROM}
          /> */}

          {/* <CourseCardCarousel
            title={t("scrom_xapi_courses")}
            courses={courses.filter(
              (course: Course) =>
                course.type === CourseFormat.XAPI ||
                course.type === CourseFormat.SCROM
            )}
            loading={loading}
            filter={CourseFilter.XAPI}
          /> */}
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <div className="mb-5">
            <AssignedMentorsCarousel />
          </div>
          <div className="pt-2">
            {/* <UpcommingEvents learnerSessions={upcommingSessions} /> */}
            <PeopleToMeet />
          </div>

          <div className="mt-5">
            <DownloadApp />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
