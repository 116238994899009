import { useEffect, useState, useRef, useLayoutEffect } from "react";
import "./CourseContentPage.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { Course } from "src/interfaces/Course";
import { Lesson } from "src/interfaces/Lesson";
import { Module } from "src/interfaces/Module";
import LessonMediaRender from "src/components/Common/mediaPlayers/LessonMediaRender";
import { useHistory } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { useAppDispatch } from "src/store/store";
import { useSelector } from "react-redux";
import { completeLesson } from "src/actions/courseActions/courseActions";

interface Props {
  course: Course;
}

function DefaultCourseContent({ course }: Props) {
  useLayoutEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 500) {
      setSideBarOpen(false);
      setIsMobile(true);
    } else {
      setSideBarOpen(true);
      setIsMobile(false);
    }
  }, []);

  const [isMobile, setIsMobile] = useState<Boolean>();
  const [sideBarOpen, setSideBarOpen] = useState<Boolean>();
  const [selectedLesson, setSelectedLesson] = useState<Lesson>(
    course.coursesModules[0].lessons[0]
  );
  const [selectedNextLesson, setSelectedNextLesson] = useState<Lesson>(
    course.coursesModules[0].lessons[1]
  );

  const [currentCourse, setCurrentCourse] = useState(0);

  const dispatch = useAppDispatch();
  const lessonList: Lesson[] = course?.coursesModules
    ?.map((module: Module) => module.lessons)
    .flat();

  const history = useHistory();

  const setNextLesson = () => {
    if (lessonList.length === currentCourse - 1) return;
    setSelectedLesson(lessonList[currentCourse + 1]);
    setSelectedNextLesson(lessonList[currentCourse + 2]);
    setCurrentCourse(currentCourse + 1);
  };

  const setPreviousLesson = () => {
    if (currentCourse === 0) return;
    setSelectedLesson(lessonList[currentCourse - 1]);
    setSelectedNextLesson(lessonList[currentCourse]);
    setCurrentCourse(currentCourse - 1);
  };

  const isModuleChanged =
    selectedLesson.moduleId !== selectedNextLesson?.moduleId;

  const shouldFinishButtonRender = lessonList.length - 1 === currentCourse;

  const lessonStatus = (
    <h5 className="font_family_nunito_sans fw-bold m-0">
      <Translation input="lesson" /> {currentCourse + 1}{" "}
      <Translation input="of" /> {lessonList?.length} : {selectedLesson?.title}
    </h5>
  );

  const gotoQuiz =
    course?.coursesQuiz?.length === 0 ? null : (
      <a
        className="btn btn-dark text-white"
        onClick={() => history.push(`/learner/quiz/${course.slug}`)}
      >
        <Translation input="Go to Quiz" />
      </a>
    );

  const nextButton = () => {
    window.scrollTo(0, 0);

    if (lessonList.length === currentCourse + 1) return;

    return (
      <div
        style={{ width: "123px" }}
        className="btn btn-dark "
        onClick={setNextLesson}
      >
        {isModuleChanged ? (
          <Translation input="Next Module" />
        ) : (
          <Translation input="next" />
        )}
      </div>
    );
  };

  const previousButton = () => {
    if (!currentCourse) return;
    return (
      <div
        className="btn btn-dark"
        onClick={setPreviousLesson}
        style={{ width: "123px" }}
      >
        Previous
      </div>
    );
  };

  const finishButton = () => {
    return (
      <div
        className="btn btn-dark"
        onClick={() => window.history.go(-2)}
        style={{ width: "123px" }}
      >
        Finish
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      completeLesson({
        courseId: course.id,
        lessonId: selectedLesson?.id,
        weightage: selectedLesson?.weightage,
      })
    ).then(() => {});
  }, [selectedLesson]);
  return (
    <div className="overflow-hidden d-flex " style={{ marginTop: "65px" }}>
      {/* ------------------- slider --------------------------------------- */}
      <div className={`${sideBarOpen ? "sidebar_open" : "sidebar_closed"}`}>
        <div
          id="mySidebar"
          className="sidebar "
          style={{
            width: `${sideBarOpen ? "320px" : "0px"}`,
            marginTop: "50px",
          }}
        >
          <div
            style={{
              backgroundImage: `url("${course?.resources.attachmentUrl}")`,
              height: `200px`,
              width: `auto`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>

          <button
            type="button"
            className="btn-close closebtn mt-3 fs-5 btn-close-white bg-light"
            aria-label="Close"
            onClick={setSideBarOpen.bind(null, false)}
          ></button>
          <div
            className={`px-3 pt-4 border-bottom  ${
              sideBarOpen ? "d-block" : "d-none"
            }`}
          >
            <div
              style={{ color: "white" }}
              className="font_family_poppins pb-1 fs-6 fw-bold"
            >
              <Translation input="course_content" />
            </div>

            <div className="accordion" id="accordionPanelsStayOpenExample">
              {course.coursesModules?.map((module: Module, index: number) => (
                <div className="accordion-item my-2 border-0">
                  <div
                    className="accordion-header fs-6"
                    id={`panelsStayOpen-heading-${module.id}`}
                  >
                    <div
                      className="fs-6 bg-dark text-light d-flex fw-normal align-items-start"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapse-${module.id}`}
                      aria-expanded="true"
                      aria-controls={`panelsStayOpen-collapse-${module.id}`}
                    >
                      <div>
                        <GiHamburgerMenu className="cursor_pointer noselect" />
                      </div>
                      <div className="mx-2 noselect cursor_pointer">
                        {module.title}
                      </div>
                    </div>
                  </div>
                  <div
                    id={`panelsStayOpen-collapse-${module.id}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : null
                    }`}
                    aria-labelledby={`panelsStayOpen-heading-${module.id}`}
                  >
                    <div className="accordion-body bg-dark text-light py-1">
                      {module.lessons.map((lesson: Lesson) => (
                        <div
                          className="font_size_14 mx-1 cursor_pointer noselect"
                          onClick={(e: any) => {
                            const clickedCourseIndex = lessonList.findIndex(
                              (currentCourse) =>
                                currentCourse.title === e.target.innerHTML
                            );

                            setCurrentCourse(clickedCourseIndex);
                            setSelectedLesson(lessonList[clickedCourseIndex]);
                            setSelectedNextLesson(
                              lessonList[clickedCourseIndex + 1]
                            );
                            setSelectedLesson(lesson);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {lesson.title}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className={`w-100 text-center pb-5 pt-4 mb-2  ${
              sideBarOpen ? "d-block" : "d-none"
            }`}
          >
            <button
              className="btn btn-light"
              onClick={() => window.history.go(-2)}
            >
              <Translation input="Back To Pathways" />
            </button>
          </div>
        </div>
      </div>
      {/* ------------------- content --------------------------------------- */}

      <div className="container w-100" style={{}}>
        {/* ------------------- header --------------------------------------- */}

        {isMobile ? (
          <div className="row border-bottom py-4">
            <div className="col-2 align-self-center">
              <div
                onClick={() => setSideBarOpen((s) => !s)}
                className="fs-6 cursor_pointer d-flex justify-content-center"
              >
                <GiHamburgerMenu />
              </div>
            </div>
            <div className="col-7 d-flex align-items-center px-0">
              {lessonStatus}
            </div>
            <div className="col-3 text-center d-flex align-items-center justify-content-end">
              <div
                className="btn btn-dark text-white"
                onClick={() => history.goBack()}
              >
                <Translation input="back" />
              </div>
            </div>
          </div>
        ) : (
          <div className="row border-bottom py-4">
            <div className="col-1 align-self-center">
              <div
                onClick={() => setSideBarOpen((s) => !s)}
                className="fs-6 cursor_pointer d-flex justify-content-center"
              >
                <GiHamburgerMenu />
              </div>
            </div>
            <div className="col-10 d-flex align-items-center px-0">
              {lessonStatus}
            </div>
            <div className="col-1 text-center d-flex align-items-center">
              <div
                className="btn btn-dark text-white"
                onClick={() => history.goBack()}
              >
                <Translation input="back" />
              </div>
            </div>
          </div>
        )}
        {/* ------------------- content --------------------------------------- */}

        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 py-5 px-0">
            <LessonMediaRender
              lessonsAttachments={selectedLesson?.lessonsAttachments || []}
            />
          </div>
          <div className="col-1"></div>
        </div>
        {/* ------------------- footer --------------------------------------- */}

        {isMobile ? (
          <div className="row py-4">
            <div className="d-flex justify-content-center border-top py-4 mb-2">
              <div className="">
                {/*
                 {currentCourse + 1 === lessonList.length
                  ? gotoQuiz
                  : lessonStatus} 
                */}                
                {lessonStatus}
              </div>
            </div>
            <div className=" w-100 ">
              <div className="d-flex justify-content-between">
                <div className="">{previousButton()}</div>

                <div
                  className=""
                  onClick={() => setCurrentCourse(currentCourse + 1)}
                >
                  {shouldFinishButtonRender ? (
                    <div className="">{finishButton()}</div>
                  ) : (
                    nextButton()
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between border-top py-4">
            <div className="">{previousButton()}</div>

            <div className="">
              {/*             {currentCourse + 1 === lessonList.length
                ? gotoQuiz
                : lessonStatus}*/}
              {lessonStatus}
            </div>

            <div
              className=""
              onClick={() => setCurrentCourse(currentCourse + 1)}
            >
              {shouldFinishButtonRender ? (
                <div className="">{finishButton()}</div>
              ) : (
                nextButton()
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DefaultCourseContent;
