import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32.14 32.28"
    height={20}
    width={20}
    {...props}
  >
    <path d="M32.14 32.28H0V0h32.14z" fill="#001436" />
    <path
      d="M17.74 22.1a1.2 1.2 0 0 0 .87.36 1.24 1.24 0 0 0 .88-.36 1.26 1.26 0 0 0 0-1.76L15.15 16l4.34-4.34a1.245 1.245 0 0 0-1.76-1.76l-5.22 5.22a1.24 1.24 0 0 0 0 1.75z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
