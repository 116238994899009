import "antd/dist/antd.css";
import { Layout } from "antd";
import "src/components/Components.css";
import { authSelectors } from "../../../../store/authSlice";
import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";
import UnauthorizedAccess from "src/components/Common/authorization/UnauthorizedAccess";
import NavLogo from "../../Common/Layout/NavLogo";
import React, { Suspense, useState } from "react";
import HeaderContentFooter from "../../Common/Layout/HeaderContentFooter";
import PageLoading from "src/components/Common/utilities/PageLoading";
import { ConfigProvider } from "antd";
import { getDirection } from "src/utils/utils";
import AdminMenu from "./AdminMenu";
// import hi_IN from "antd/lib/locale/hi_IN";

const AdminAuthorMentorStyles = React.lazy(
  () => import("src/Assets/styles/AdminAuthorMentorStyles")
);

const { Sider } = Layout;

interface AdminLayoutI {
  component: JSX.Element;
}

function AdminLayout({ component }: AdminLayoutI) {
  const role = useSelector(authSelectors.role);
  const [sliderOpen, setSliderOpen] = useState(true);
  return (
    <Suspense fallback={<PageLoading />}>
      <AdminAuthorMentorStyles />
      {role === Role.ADMIN ? (
        <ConfigProvider direction={getDirection()}>
          <Layout style={{ minHeight: "100vh" }}>
            <Sider collapsible width={250} onCollapse={setSliderOpen}>
              <NavLogo />
              <AdminMenu sliderOpen={sliderOpen} />
            </Sider>
            <HeaderContentFooter component={component} />
          </Layout>
        </ConfigProvider>
      ) : (
        <UnauthorizedAccess />
      )}
    </Suspense>
  );
}

export default AdminLayout;
