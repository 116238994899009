function CourseProgressBar({ progress }: { progress: number }) {
  const progressFunction = (inputProgress: number) => {
    // if (inputProgress === null || !inputProgress) return "0 % Complete";
    // else if (inputProgress >= 100) return "Complete";
    // else return `${inputProgress}% Complete`;
    if (inputProgress === null || !inputProgress) return <>0 % Complete</>;
    else if (inputProgress >= 100)
      return <div className="text-primary mx-3">Completed</div>;
    else return `${inputProgress}% Complete`;
  };

  return (
    <div className="row align-items-center">
      <div className="col-7">
        <div className="progress" style={{ height: "3.7px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
      <div className="col-5 ">
        <p
          className="m-0 text-lightGrey text-nowrap"
          style={{ fontSize: "14px" }}
        >
          {/* {progress} */}
          {progressFunction(progress)}
        </p>
      </div>
    </div>
  );
}

export default CourseProgressBar;

const sample = [
  { header: "sample", subtext: "sam", pricing: 10 },
  { header: "sample 2", subtext: "sam 2", pricing: 20 },
  { header: "sample 3", subtext: "sam 2", pricing: 20 },
];

sample.forEach(() => {});
