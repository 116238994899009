import {
  Button,
  PageHeader,
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Switch,
  Result,
  Typography,
  Space,
  message,
  Modal,
  Table,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory, useParams } from "react-router-dom";
import SearchCourses from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchCourses";
import SearchLearners from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchLearners";
import {
  createSession,
  sessionSelectors,
  editSession,
} from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import { useAppDispatch } from "src/store/store";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Registrant } from "src/interfaces/Registrant";
import SwitchFormItem from "src/components/AdminAuthorMentorComponents/MentorComponents/SwitchFormItem";
import { User } from "src/interfaces/User";
import { getLearners } from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import Column from "antd/lib/table/Column";

interface Params {
  session_Id: string;
}
interface SelectProps {
  label: string;
  value: string | number;
  key: string | number;
}

function EditSessionPage() {
  const { session_Id } = useParams<Params>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const mentorId = useSelector(authSelectors.id);
  const session = useSelector(
    sessionSelectors.selectSessionEntity(parseInt(session_Id))
  );
  const [tableLoading, setTableLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [learners, setLearners] = useState<User[]>();
  const [selectedLearners, setSelectedLearners] = useState<User[]>([]);

  const [timestamp, setTimeStamp] = useState("");
  const { Text, Link } = Typography;

  const formSubmitHandler = () => {
    setLoading(true);
    const editedSession = {
      id: session?.id,
      meetingId: session?.meetingId,
      courseId: form.getFieldsValue().courses.key,
      mentorId,
      meeting: {
        topic: form.getFieldsValue().topic,
        type: 2,
        // start_time: form.getFieldsValue().date.toISOString(),
        start_time: session?.meeting.start_time,
        timezone: "",
        settings: {
          host_video: form.getFieldsValue().host_video,
          participant_video: form.getFieldsValue().participant_video,
          approval_type: 0,
          registration_type: 2,
          registrants_email_notification: false,
        },
      },
      registrants: selectedLearners?.map((user: User) => ({
        id: user.id,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
      })),
    };
    console.log(editedSession);

    dispatch(editSession(editedSession))
      .then(unwrapResult)
      .then(() => {
        setShowSuccess(true);
        setLoading(false);
      })
      .catch();
  };

  useEffect(() => {
    console.log(session);
    setTimeStamp(session?.meeting?.start_time || "");
    if (session) {
      setSelectedLearners(
        session?.meeting?.registrants?.registrants.map(
          (registrant: Registrant) => registrant.user
        )
      );
    }

    form.setFieldsValue({
      topic: session?.meeting.topic,
      courses: {
        label: session?.courses.title,
        key: session?.courses.id,
        value: session?.courses.id,
      },
    });
  }, []);

  return (
    <>
      <Col sm={24}>
        <PageHeader
          title="Edit Session"
          onBack={() => {
            history.goBack();
          }}
          extra={[]}
        />
      </Col>
      {showSuccess ? (
        <Result
          status="success"
          title="Successfully Created Session"
          subTitle="Session has been created "
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/mentor/sessions")}
            >
              Go To Sessions
            </Button>,
          ]}
        />
      ) : (
        <>
          <Col sm={16} style={{ marginTop: "50px" }}>
            <Form
              form={form}
              onFinish={formSubmitHandler}
              labelCol={{ span: 4, offset: 1 }}
              scrollToFirstError
            >
              <Form.Item name="topic" label={<strong>Session Name</strong>}>
                <Input placeholder="Name of Session" size="large" />
              </Form.Item>
              <SearchCourses />

              {/* <Form.Item
                name="date"
                label={<strong>Date</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  showTime
                  value={timestamp||''}
                  onChange={() => {}}
                  onOk={() => {}}
                />
              </Form.Item> */}

              <Form.Item
                // name="registrants"
                label={<strong>Add Participants</strong>}
              >
                <Space>
                  <Button
                    size="small"
                    loading={tableLoading}
                    onClick={() => {
                      if (form.getFieldsValue().courses) {
                        setTableLoading(true);
                        if (learners?.length) {
                          setTableLoading(false);
                          setShowModal(true);
                          return;
                        }
                        dispatch(getLearners(form.getFieldsValue().courses.key))
                          .then(unwrapResult)
                          .then((res) => {
                            // setLearners(res.map((item: any) => item.users));
                            setLearners(res);
                            setTableLoading(false);
                            setShowModal(true);
                          });
                      } else message.error("Please Select Course");
                    }}
                  >
                    {!selectedLearners.length ? "Add" : "Edit"} participants
                  </Button>
                  <Text keyboard>
                    <Text>{selectedLearners.length} Learners Selected</Text>
                  </Text>
                </Space>
              </Form.Item>

              <SwitchFormItem
                initialValue={session?.meeting.settings.host_video}
                label={<strong>Host Video On</strong>}
                wrapperCol={{ span: 0, offset: 0 }}
                name="host_video"
              />
              <SwitchFormItem
                initialValue={session?.meeting.settings.participant_video}
                label={<strong>Participant Video On</strong>}
                wrapperCol={{ span: 0, offset: 0 }}
                name="participant_video"
              />

              <Row justify="end">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#002766",
                      border: "1px solid #002766",
                      borderRadius: "8px",
                    }}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </>
      )}

      <Modal
        maskClosable={false}
        style={{ padding: "0" }}
        title="Select Learners"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button type="primary" key="back" onClick={() => setShowModal(false)}>
            Done
          </Button>,
        ]}
      >
        <Table
          scroll={{ y: 320 }}
          pagination={{ pageSize: 100 }}
          rowSelection={{
            onChange: (_, selectedRows: any) => {
              setSelectedLearners(selectedRows);
            },
            selectedRowKeys: selectedLearners.map((user: User) => user.id),
          }}
          dataSource={learners}
          loading={tableLoading}
          rowKey={(s) => s.id}
        >
          <Column
            title="Title"
            dataIndex="title"
            key="title"
            render={(_, user: User) => (
              <div className="hover_color_change cursor_pointer">
                {user.firstName} {user.lastName}
              </div>
            )}
          />

          <Column title="Email" dataIndex="email" key="actions" />
        </Table>
      </Modal>
    </>
  );
}

export default EditSessionPage;
