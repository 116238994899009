import Spinner from "./Spinner";

interface Props {
  loading: boolean;
  children: JSX.Element;
}
function LoadingWrapper({ loading, children }: Props) {
  return loading ? (
    <div style={{ minHeight: "60vh" }}>
      <Spinner />
    </div>
  ) : (
    children
  );
}

export default LoadingWrapper;
