import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";
import { User } from "src/interfaces/User";

//create new user
export const createNewUser = createAsyncThunk(
  "admin/create-user",
  async (user: User) =>
    await api.post("/users", user).then(extractStandardResponseData)
);

//bulk upload users
export const createMultipleUsers = createAsyncThunk(
  "admin/create/multiple",
  async (formData: FormData) =>
    await api.post("/admin/uploadCsv", formData).then((res) => res.data)
);

//get users page
export const getUsers = createAsyncThunk(
  "admin/users",
  async ({ page }: { page: number }) =>
    await api.get(`admin/users?search=&page=${page}`).then((res) => res.data)
);

//edit a user
export const editUser = createAsyncThunk(
  "admin/edit-user",
  async (user: User) =>
    await api.patch(`/users/${user.id}`, user).then(extractStandardResponseData)
);

// activate or deactivate user
export const activateDeativateUser = createAsyncThunk(
  "admin/activate-user",
  async ({ userId, active }: any) =>
    await api
      .patch(`/users/deactivate/${userId}`, { value: active })
      .then(extractStandardResponseData)
);

//delete user
export const deleteUser = createAsyncThunk(
  "admin/delete-user",
  async (userId: number) => {
    await api.delete(`/users/${userId}`).then(extractStandardResponseData);
    return userId;
  }
);

export const clearPaginations = createAsyncThunk(
  "session/paginations/clear",
  () => {}
);
