import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  message,
  Select,
  Checkbox,
  Switch,
  Modal,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useAppDispatch } from "../../store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  createCourse,
  createVideoCourse,
} from "../../store/coursesSlices/coursesSlice";
import UploadImage from "../../components/AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";
import UploadCourse from "../../components/AdminAuthorMentorComponents/Common/UploadComponents/UploadCourse";
import { Module } from "src/interfaces/Module";
import { Lesson } from "src/interfaces/Lesson";
import UploadScromCourse from "../../components/AdminAuthorMentorComponents/Common/UploadComponents/UploadScromCourse";
import { CourseFormat } from "src/enums/CourseFormat";
import DefaultCourseFormComp from "./CreateCoursePageFormFields/DefaultCourseFormComp";
import CreateCourseSearchFields from "./CreateCoursePageFormFields/CreateCourseSearchFields";
import CreateCourseBasicFields from "./CreateCoursePageFormFields/CreateCourseBasicFields";
import VideoCourseFormComp from "./CreateCoursePageFormFields/VideoCourseFormComp/VideoCourseFormComp";
import CoursePreviewVideoUpload from "src/components/AdminAuthorMentorComponents/Common/Course/CoursePreviewVideoUpload";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";
import UploadPdfCourse from "src/components/AdminAuthorMentorComponents/Common/UploadPdfCourse";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import { setTabTitle } from "src/utils/utils";
import AddCourseQuiz from "src/components/AdminAuthorMentorComponents/Common/Course/CourseQuiz/AddCourseQuizForUpload";
import AddCertificateToCourse from "src/components/AdminAuthorMentorComponents/Common/Course/AddCertificateToCourse";
import SearchCertificates from "src/components/AdminAuthorMentorComponents/Common/Certificates/SearchCertificates";
import { addDraft, deleteDraft, getDraft } from "src/actions/DraftActions";

interface innerQuiz1 {
  question: string;
  sectionId: number;
  attachmentId: number;
  options: string[];
  answers: string[];
}

interface innerQuiz {
  attempts: string;
  passing: string;
  quiz: innerQuiz1[];
  quizTitle: string;
}

interface courseQuiz {
  quiz: innerQuiz[];
  passing: number;
  attempts: number;
  quizTitle: string;
}

function CreateCoursePage() {
  const [courseQuiz, setCourseQuiz] = useState<courseQuiz>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [courseFormat, setCourseFormat] = useState<CourseFormat>();
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [previewVideo, setPreviewVideo] = useState(false);
  const [microsite, setMicrosite] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [done, setDone] = useState(false);
  const [draftId, setDraftId] = useState<number>();
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const role = useSelector(authSelectors.role);

  const courseFormReturnFy = (done: boolean, courseFormat?: CourseFormat) => {
    if (courseFormat === CourseFormat.DEFAULT)
      return <DefaultCourseFormComp done={done} />;
    else if (courseFormat === CourseFormat.VIDEO)
      return <VideoCourseFormComp done={done} form={form} />;
    else if (courseFormat === CourseFormat.XAPI)
      return <UploadCourse done={done} />;
    else if (courseFormat === CourseFormat.SCROM)
      return <UploadScromCourse done={done} />;
    else if (courseFormat === CourseFormat.PDF)
      return <UploadPdfCourse done={done} />;
  };

  const onQuizFormSubmit = (e) => {
    console.log("from", e);
    setCourseQuiz({
      ...e,
      quiz: e.quiz.map((item, i) => ({
        sectionId: i,
        attachmentId: null,
        question: item.question,
        options: item.answer.map((item) => item.option),
        answers: item.answer
          .filter((item) => item.checked === true)
          .map((item) => item.option),
      })),
    });
  };

  const courseQuizCheck = () => {
    console.log("From course quiz check", courseQuiz);
    if (courseQuiz) return courseQuiz;
    else {
      return {
        quiz: [],
        passing: null,
        attempts: null,
        quizTitle: null,
      };
    }
  };

  const getCourseFromForm = () => {
    console.log("1.from getCourseFromForm", courseQuiz);
    console.log(
      "2.from getCourseFromForm after running the coursequizCheck function",
      courseQuizCheck()
    );

    console.log("4.coursequiz is spreded", { ...courseQuiz });
    console.log(
      "3.from getCourseFromForm after spreading the coursequizCheckfunction",
      { ...courseQuizCheck() }
    );

    return {
      ...form.getFieldsValue(),
      videoId: form.getFieldsValue().previewVideo,
      modules:
        courseFormat === CourseFormat.VIDEO
          ? form.getFieldsValue().modules?.map((item: any, index) => ({
              ...item,
              positionId: index,
              lessons: [],
              coursesVideos: item.coursesVideos?.map(
                (videos: any, i: number) => ({
                  title: videos.title,
                  attachmentId: videos?.lessonsAttachments.attachmentId,
                  positionId: i,
                })
              ),
            }))
          : form.getFieldsValue().modules?.map((module: Module) => ({
              ...module,
              lessons: module.lessons?.map((lesson: Lesson) => ({
                ...lesson,
                lessonsAttachments: lesson?.lessonsAttachments
                  ?.map((item: any) => item?.lessonsAttachments)
                  .flat(),
              })),
            })) || [],
      courseAttachment: form.getFieldsValue()?.courseAttachment || null,
      tags: form.getFieldsValue()?.tags?.map((item: any) => item?.value),
      mentors:
        form.getFieldsValue()?.mentors?.map((item: any) => item?.value) || null,
      authorId: form.getFieldsValue().authorId?.value || null,
      certificate: form.getFieldsValue().certificate?.value || null,
      ...courseQuizCheck(),
      microsite,
      description,
      objectives,
    };
  };

  const onFormSubmit = () => {
    setDone(true);
    if (
      !form.getFieldsValue().modules &&
      courseFormat !== CourseFormat.XAPI &&
      courseFormat !== CourseFormat.SCROM &&
      courseFormat !== CourseFormat.VIDEO &&
      courseFormat !== CourseFormat.PDF
    ) {
      message.error("Please add atleast one module");
    } else if (!form.getFieldsValue().imageId) {
      setDone(false);
      message.error("Please add a image for the course");
    } else if (
      !getCourseFromForm().certificate &&
      courseFormat === CourseFormat.VIDEO
    ) {
      message.error("Please add certificate for course");
    }
    // else if(!getCourseFromForm().quiz.length ){
    //   message.error("Please add certificate for course");
    // }
    else {
      setSubmitLoading(true);
      const course = getCourseFromForm();
      console.log(
        "From onFormSubmit this is the course that is sent to the server",
        course
      );
      dispatch(
        courseFormat === CourseFormat.VIDEO
          ? createVideoCourse(course)
          : createCourse(course)
      )
        .then(unwrapResult)
        .then(() => {
          setSubmitLoading(false);
          if (role === Role.AUTHOR) history.push("/author");
          if (role === Role.ADMIN) history.push("/admin/courses");
        })
        .catch(() => setSubmitLoading(false));
    }
  };

  const onSaveDraft = () => {
    setDraftLoading(true);

    setDone(true);
    if (
      !getCourseFromForm().title ||
      getCourseFromForm().title.length < 2 ||
      !courseFormat
    ) {
      message.warning(
        "Title with minimun 2 characters is required to save draft."
      );
      setDraftLoading(false);
    } else {
      setTimeout(() => {
        setDraftLoading(false);
        dispatch(addDraft(getCourseFromForm())).then(() => {
          setDraftLoading(false);
          setDone(false);
        });
      }, 1000);
    }
  };

  const formHeader = (
    <Row justify="space-between" align="middle">
      <Translation input="add_new_course" />
      {/* <Button
        type="primary"
        onClick={onSaveDraft}
        loading={draftLoading}
        disabled={draftLoading}
      >
        <Translation input="save_draft" />
      </Button> */}
    </Row>
  );

  const onDraftDiscard = () => {
    dispatch(deleteDraft(draftId));
    setShowModal(false);
  };
  const onDraftKeep = () => history.push(`/admin/draft`);

  useEffect(() => {
    setTabTitle("Create Course");
    // dispatch(getDraft())
    //   .then(unwrapResult)
    //   .then((res) => {
    //     if (res) {
    //       setShowModal(true);
    //       setDraftId(res.id);
    //     }
    //   });
  }, []);

  return (
    <Row>
      <Col sm={24}>
        <Card title={formHeader} loading={false}>
          <Form
            form={form}
            name="basic"
            onFinish={onFormSubmit}
            scrollToFirstError={true}
          >
            <CreateCourseBasicFields />
            <FormLabel name="description" />
            <Form.Item>
              <ReactQuill
                onChange={(e) => setDescription(e)}
                value={description}
              />
            </Form.Item>

            <FormLabel name="objectives" />
            <Form.Item>
              <ReactQuill
                onChange={(e) => setObjectives(e)}
                value={objectives}
              />
            </Form.Item>

            <FormLabel name="course_image" />
            <UploadImage done={done} name="imageId" nested={false} />
            {previewVideo ? (
              <div style={{ marginBottom: "15px" }}>
                <CoursePreviewVideoUpload done={done} />
                <Button
                  type="primary"
                  size="small"
                  onClick={setPreviewVideo.bind(null, false)}
                >
                  Remove preview video
                </Button>
              </div>
            ) : (
              <Checkbox
                onChange={setPreviewVideo.bind(null, true)}
                style={{ marginBottom: "15px" }}
              >
                <Translation input="add_course_preview_video" />
              </Checkbox>
            )}

            <CreateCourseSearchFields showAuthor={false} />
            <FormLabel name="micro_learning" />
            <Form.Item name="microsite">
              <Switch
                onChange={(value) => setMicrosite(value)}
                checked={microsite}
              />
            </Form.Item>

            {courseFormat === CourseFormat.DEFAULT ||
            courseFormat === CourseFormat.VIDEO ? (
              <>
                <FormLabel name="Course Quiz" />
                <AddCourseQuiz onFormSubmit={onQuizFormSubmit} />
                <FormLabel name="Add Certificate" />
                <SearchCertificates />
              </>
            ) : null}

            <FormLabel name="course_format" />
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select a course format",
                },
              ]}
            >
              <Select
                placeholder={<Translation input="select_a_course_format" />}
                onChange={(value: CourseFormat) => setCourseFormat(value)}
              >
                <Option value={CourseFormat.DEFAULT}>Default Course</Option>
                <Option value={CourseFormat.XAPI}>XAPI</Option>
                <Option value={CourseFormat.SCROM}>SCROM</Option>
                <Option value={CourseFormat.VIDEO}>Video Course</Option>
                <Option value={CourseFormat.PDF}>PDF</Option>
              </Select>
            </Form.Item>
            {courseFormReturnFy(done, courseFormat)}
            <Row>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}
                >
                  <Translation input="submit" />
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </Col>
      <Modal
        title={"Draft"}
        visible={showModal}
        onCancel={setShowModal.bind(null, false)}
        footer={[
          <Button type="primary" onClick={onDraftDiscard}>
            Discard
          </Button>,
          <Button type="primary" onClick={onDraftKeep}>
            Edit Previous Draft
          </Button>,
        ]}
      >
        A Draft Already Exists, Do you want to discard the previous draft?
      </Modal>
    </Row>
  );
}
export default CreateCoursePage;
