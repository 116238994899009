import { Tag as TagA, Popover } from "antd";
import { Tag } from "src/interfaces/Tag";

function Tags({ tags }: { tags: Tag[] }) {
  return (
    <Popover
      content={
        <>
          {tags?.map((tag: Tag, index: number) => (
            <TagA key={index}>{tag?.tags?.value}</TagA>
          ))}
        </>
      }
    >
      <div
        style={{
          display: "inline-block",
        }}
        className="cursor_pointer"
      >
        {tags?.map((tag: Tag, index: number) =>
          index < 3 ? <TagA key={index}>{tag?.tags.value}</TagA> : null
        )}
        {tags?.length > 3 ? (
          <div
            style={{
              display: "inline-block",
            }}
          >
            . . .
          </div>
        ) : null}
      </div>
    </Popover>
  );
}

export default Tags;
