import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32.14 32.28"
    height={20}
    width={20}
    {...props}
  >
    <path d="M0 0h32.14v32.28H0z" fill="#001436" />
    <path
      d="M14.4 10.18a1.24 1.24 0 0 0-.87-.36 1.28 1.28 0 0 0-.88.36 1.26 1.26 0 0 0 0 1.76L17 16.28l-4.34 4.34a1.245 1.245 0 0 0 1.76 1.76l5.22-5.22a1.24 1.24 0 0 0 .36-.87 1.27 1.27 0 0 0-.36-.88z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
