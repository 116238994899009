import {
  UploadOutlined,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Upload,
  Button,
  message,
  Typography,
  Table,
  Divider,
  Row,
  Col,
  Dropdown,
  Tabs,
  Space,
  Tag,
  Alert,
} from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { uploadCSV } from "src/api/mediaUploadApi";
import { FileType } from "src/enums/FileTypes";
import { getFileExtention, getFileNameFromUrl, apiCall } from "src/utils/utils";
import { clearUsers } from "src/store/users/userSlice";

import { useAppDispatch } from "src/store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { Role } from "src/interfaces/User";
import { createMultipleUsers, getUsers } from "src/actions/userActions";
function UploadCSVFile() {
  const [file, setFile] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [error, setError] = useState<{ error: boolean; message: string }>();
  const [success, setSuccess] = useState(false);
  const { Dragger } = Upload;
  const dispatch = useAppDispatch();
  const { Text } = Typography;
  const { TabPane } = Tabs;

  const columns = [
    {
      title: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "lastName",
      dataIndex: "lastName",
    },

    {
      title: "email",
      dataIndex: "email",
    },
    {
      title: "role",
      dataIndex: "role",
    },
    {
      title: "tags",
      dataIndex: "tags",
    },
  ];

  const data = [
    {
      key: "1",
      firstName: "John",
      lastName: "Brown",
      email: "john@gmail.com",
      role: "LEARNER",
      tags: "Insurance, Medical",
    },
    {
      key: "2",
      firstName: "Jim",
      lastName: "Green",
      email: "jim@gmail.com",
      role: "ADMIN",
      tags: "Insurance, Medical",
    },
    {
      key: "3",
      firstName: "Joe",
      lastName: "Black",
      email: "joe@gmail.com",
      role: "AUTHOR",
      tags: "Insurance, Medical",
    },
  ];

  const customRequest = async (e: any) => {
    setLoading(true);
    setError({ error: false, message: "" });
    if (!e.file) return;
    if (getFileExtention(e.file.name) !== FileType.csv) {
      setError({ error: true, message: "Please add .csv file" });
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", e.file);
    dispatch(createMultipleUsers(formData))
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        if (res.error) setError({ error: true, message: res.error });
        else {
          dispatch(getUsers({ page: 1 }));
          setSuccess(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError({ error: true, message: "Error adding users" });
        setLoading(false);
      });
  };

  return (
    <>
      {success ? (
        <Alert
          message="Users added successfully"
          type="success"
          style={{ marginTop: "10px" }}
        />
      ) : (
        <Dragger
          listType="picture"
          showUploadList={false}
          customRequest={customRequest}
          style={{ width: "100%", marginBottom: "20px" }}
        >
          <Button
            icon={loading ? <LoadingOutlined spin /> : <UploadOutlined />}
          >
            Click or Drag and Drop file to upload
          </Button>
        </Dragger>
      )}
      <p>
        Upload a <Text code>.csv</Text> file to create multiple users together.
      </p>

      {error?.error ? (
        <Alert
          message={error.message}
          type="error"
          style={{ marginTop: "10px" }}
        />
      ) : null}

      <Row style={{ marginTop: "15px" }}>
        <Text
          strong
          className="on_text_hover cursor_pointer"
          onClick={() => setShowInstructions((s) => !s)}
        >
          {showInstructions ? "Hide" : "Show"} Instructions
        </Text>
      </Row>
      {showInstructions ? (
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane tab="Example" key="1">
            <Table
              bordered
              columns={columns}
              dataSource={data}
              size="small"
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Roles" key="2">
            <Tag>{Role.ADMIN}</Tag>
            <Tag>{Role.AUTHOR}</Tag>
            <Tag>{Role.LEARNER}</Tag>
            <Tag>{Role.MENTOR}</Tag>
          </TabPane>
        </Tabs>
      ) : null}
    </>
  );
}

export default UploadCSVFile;
