import { CourseFormat } from "src/enums/CourseFormat";
import { FILTER_ENUMS } from "src/enums/Filters";
import { Course } from "src/interfaces/Course";

export const filtersFunction = (courses: Course[], filter: any) =>
  courses.filter((course: Course) => {
    if (filter === FILTER_ENUMS.DEFAULT)
      return course.type === CourseFormat.DEFAULT;
    else if (filter === FILTER_ENUMS.VIDEO)
      return course.type === CourseFormat.VIDEO;
    else if (filter === FILTER_ENUMS.SCROM)
      return course.type === CourseFormat.SCROM;
    else if (filter === FILTER_ENUMS.XAPI)
      return course.type === CourseFormat.XAPI;
    else if (filter === FILTER_ENUMS.AWAITING_APPROVAL)
      return course.approved === null;
    else if (filter === FILTER_ENUMS.DISAPPROVED)
      return course.approved === false;
    else if (filter === FILTER_ENUMS.PUBLISHED) return course.published;
    else if (filter === FILTER_ENUMS.CLEAR_FILTERS) return course;
  });
