import { Tag } from "antd";
import { Course } from "src/interfaces/Course";
import { PathWay } from "src/interfaces/PathWay";

interface Props {
  data: Course | PathWay;
}

function StatusTag({ data }: Props) {
  const tagFunction = (courseData: Course | PathWay) => {
    if (courseData.approved === null) {
      return <Tag color="orange">Awaiting Approval</Tag>;
    } else if (courseData.approved === true && courseData.published === true) {
      return <Tag color="green"> Published</Tag>;
    } else if (courseData.approved === false) {
      return <Tag color="red"> Disapproved</Tag>;
    } else if (courseData.approved === true) {
      return <Tag color="lime"> Approved</Tag>;
    }
  };

  return <div>{tagFunction(data)}</div>;
}

export default StatusTag;
