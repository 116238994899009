import { useState } from "react";
import { Form, Upload, message, Input, Col, Row, Progress, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { api, extractStandardResponseData } from "src/api/api";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { apiCall } from "src/utils/utils";
import FormItemCustom from "../FormItemCustom";

interface Props {
  done: boolean;
}

function CoursePreviewVideoUpload({ done }: Props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState<{
    attachmentId: number;
  }>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { Dragger } = Upload;
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const uploadButton = (
    <div>
      {show ? (
        <div style={{ fontSize: "200%" }}>
          {/* <CheckCircleTwoTone twoToneColor="#52c41a" /> */}

          <VideoPlayer url={attachmentUrl} />
        </div>
      ) : (
        <>
          {loading ? (
            uploadProgress === 100 ? (
              <LoadingOutlined />
            ) : (
              <Progress percent={uploadProgress} type="circle" width={50} />
            )
          ) : (
            <PlusOutlined />
          )}
        </>
      )}
    </div>
  );

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };

  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (!fileList) return;
    if (e.file.type !== "video/mp4") {
      message.error("Please upload video");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", fileList);

    const [data, error] = await apiCall(
      await api
        .post("/courses/files", formData, config)
        .then(extractStandardResponseData)
    );
    if (error) {
      message.error("error uploading");
      return;
    }

    setAttachment({
      attachmentId: data.id,
    });
    console.log(data);
    setAttachmentUrl(data.attachmentUrl);
    setLoading(false);
    setShow(true);
    message.success("video successfully uploaded");
  };

  const removeHandler = (e: any) => {};

  return (
    <Col sm={4}>
      <Dragger
        name="avatar"
        showUploadList={false}
        customRequest={customRequest}
        multiple={false}
        onRemove={removeHandler}
        style={{ marginBottom: "15px" }}
      >
        <div>
          <p className="ant-upload-drag-icon">{uploadButton}</p>
          <p className="ant-upload-text">
            {show
              ? "Video Uploaded Sucessfully"
              : "Click or drag file to this area to upload video"}
          </p>
        </div>
      </Dragger>

      {/* <FormItemCustom
        fieldName={field.name}
        initialValue={attachment}
        formItemName="lessonsAttachments"
      /> */}
      {done ? (
        <Form.Item name="previewVideo" initialValue={attachment?.attachmentId}>
          <Input type="hidden" />
        </Form.Item>
      ) : null}
    </Col>
  );
}

export default CoursePreviewVideoUpload;
