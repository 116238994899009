import Translation from "src/components/Common/utilities/Translation";
import { LearnerSession } from "src/interfaces/LearnerSession";

interface Props {
  learnerSession: LearnerSession;
  alwaysVertical: boolean;
}

function SessionCard({ learnerSession, alwaysVertical }: Props) {
  const dateReturnFn = (isoDate: string) => {
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(isoDate);
    return `${date.getDate()} ${monthArray.filter(
      (_, index) => date.getUTCMonth() === index
    )} ${date.getUTCFullYear()}  - ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };

  return (
    <div className="mb-3 d-flex bg-white border_radius overflow-hidden hover_effect w-100">
      <div
        className={`d-flex flex-column ${
          !alwaysVertical ? "flex-sm-column flex-md-column flex-lg-row" : ""
        }`}
      >
        <div className="w-100">
          <img
            style={{ objectFit: "cover", maxHeight: "220px" }}
            className="img-fluid h-100 w-100"
            src={learnerSession.session.resources.attachmentUrl}
            alt="session-image"
          />
        </div>
        <div className="w-100 position-relative">
          {alwaysVertical ? (
            <span
              className="ls-badge badge bg-primary position-absolute"
              style={{ top: "-5%", left: "5%" }}
            >
              {dateReturnFn(learnerSession.startTime)}
            </span>
          ) : null}
          <div className="card-body">
            {!alwaysVertical ? (
              <p className="text-muted small mb-1">
                <span className="small">
                  {dateReturnFn(learnerSession.startTime)}
                </span>
              </p>
            ) : null}

            <h6 className={` ${alwaysVertical ? "my-2" : "mb-2"}`}>
              {learnerSession.topic}
            </h6>
            <p className="small text-muted fst-italic m-0 py-2">
              Hosted By {learnerSession.mentor.firstName}{" "}
              {learnerSession.mentor.lastName}
            </p>
            {/* <p className="small text-muted">
              {learnerSession.mentor.firstName} &nbsp;
              {learnerSession.mentor.lastName}
            </p> */}
            <a
              className="btn btn-secondary btn-sm text-center mb-3"
              onClick={() => window.open(learnerSession.joinUrl, "_blank")}
            >
              <Translation input="join_the_session" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionCard;
