import { useEffect, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { learnerSessionRequestsSelectors } from "src/store/learnerSlices/LearnerSessionRequestsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import SessionCard from "src/components/LearnerComponents/Sessions/SessionCard";
import {
  getAllLearnerSessions,
  learnerSessionSelectors,
  getLearnerSessionsFilter,
  getTodaysSessions,
} from "src/store/learnerSlices/learnerSessionSlice";
import { LearnerSession } from "src/interfaces/LearnerSession";
import Spinner from "src/components/LearnerComponents/UtilityComponents/Spinner";

enum TimeFilter {
  Upcoming = "Upcoming",
  Previous = "Previous",
}

function UpcommingSessionsPage() {
  const [sessionsLoading, setSessionsLoading] = useState(true);
  const [todatsSessionsLoading, setTodaysSessionsLoading] = useState(true);
  const [todaysSessions, setTodaysSessions] = useState<LearnerSession[]>();
  const dispatch = useAppDispatch();

  const learnerSessions = useSelector(
    learnerSessionSelectors.selectAllSessions()
  );

  const [selectedFilter, setSelectedFilter] = useState<TimeFilter>(
    TimeFilter.Upcoming
  );

  const checkIfSelected = (option: string) => {
    if (option === selectedFilter) return "text-dark";
    else return "text-muted";
  };

  const todaysSessionsReturnFn = () => {
    if (!todaysSessions?.length) {
      return <h6 className="mt-5 text-muted">No sessions for today</h6>;
    }
    return todatsSessionsLoading ? (
      <Spinner />
    ) : (
      todaysSessions?.map((learnerSession: LearnerSession, index: number) => (
        <div className="col-12 col-sm-6 col-md-6 col-lg-12" key={index}>
          <SessionCard learnerSession={learnerSession} alwaysVertical={true} />
        </div>
      ))
    );
  };

  const onTabSelect = (item: any) => {
    setSelectedFilter(item);
    setSessionsLoading(true);
    if (item === TimeFilter.Upcoming)
      dispatch(getAllLearnerSessions()).then(
        setSessionsLoading.bind(null, false)
      );
    else if (item === TimeFilter.Previous)
      dispatch(getLearnerSessionsFilter({ filter: "Archived" })).then(
        setSessionsLoading.bind(null, false)
      );
  };

  useEffect(() => {
    // dispatch(getLearnerSessions())
    //   .then(unwrapResult)
    //   .then((res) => {});

    window.scrollTo(0, 0);
    dispatch(getAllLearnerSessions())
      .then(unwrapResult)
      .then((res) => {
        setSessionsLoading(false);
      });
    dispatch(getTodaysSessions())
      .then(unwrapResult)
      .then((res) => {
        setTodaysSessions(res);
        setTodaysSessionsLoading(false);
      });
  }, []);

  return (
    <div className="clearance container pt-5">
      <div className="row">
        <div className="col-12 col-lg-9">
          <div className="d-flex justify-content-between">
            <h5 className="mb-3">Sessions</h5>
            <div>
              {Object.values(TimeFilter).map(
                (filter: string, index: number) => (
                  <span
                    className={`fw-bolder cursor_pointer ${
                      index ? "" : "px-3"
                    } ${checkIfSelected(filter)}`}
                    onClick={() => onTabSelect(filter)}
                  >
                    {filter}
                  </span>
                )
              )}
            </div>
          </div>
          <div className="row">
            {sessionsLoading ? (
              <Spinner />
            ) : (
              learnerSessions?.map(
                (learnerSession: LearnerSession, index: number) => (
                  <div className="col-md-6" key={index}>
                    <SessionCard
                      learnerSession={learnerSession}
                      alwaysVertical={false}
                    />
                  </div>
                )
              )
            )}
          </div>
        </div>
        <div className="col-12 col-lg-3">
          <h5 className="mb-3">Today's Sessions</h5>
          <div className="row">{todaysSessionsReturnFn()}</div>
        </div>
      </div>
    </div>
  );
}

export default UpcommingSessionsPage;

// ==========================================================================================
// import { useEffect, useState } from "react";
// import { useAppDispatch } from "src/store/store";
// import {
//   getLearnerSessions,
//   learnerSessionRequestsSelectors,
// } from "src/store/learnerSlices/LearnerSessionRequestsSlice";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";
// import { SessionRequest } from "src/interfaces/SessionRequest";
// import SessionCard from "src/components/LearnerComponents/Sessions/SessionCard";
// import { TimeFilter } from "src/enums/FilterEnum";
// import SessionRequestCard from "./SessionRequestCard";
// import {
//   getAllLearnerSessions,
//   learnerSessionSelectors,
//   getLearnerSessionsFilter,
// } from "src/store/learnerSlices/learnerSessionSlice";
// import { LearnerSession } from "src/interfaces/LearnerSession";
// import PeopleToMeet from "src/components/LearnerComponents/PeopleToMeet/PeopleToMeet";
// import SessionRequestsComponent from "src/components/LearnerComponents/Sessions/SessionRequestsComponent";

// function UpcommingSessionsPage() {
//   const dispatch = useAppDispatch();
//   const sessionRequests = useSelector(
//     learnerSessionRequestsSelectors.selectAllSessionRequests()
//   );
//   const sessions = useSelector(learnerSessionSelectors.selectAllSessions());

//   const [selectedFilter, setSelectedFilter] = useState<any>(TimeFilter.ALL);

//   const checkIfSelected = (option: string) => {
//     if (option === selectedFilter) return "text-dark";
//     else return "text-muted";
//   };

//   const onOptionSelect = (e: any) => {
//     setSelectedFilter(e.target.value);
//     if (e.target.value === TimeFilter.TODAY)
//       dispatch(getLearnerSessionsFilter({ filter: "Today" }));
//     else if (e.target.value === TimeFilter.THIS_MONTH)
//       dispatch(getLearnerSessionsFilter({ filter: "Month" }));
//     else if (e.target.value === TimeFilter.THIS_WEEK)
//       dispatch(getLearnerSessionsFilter({ filter: "Week" }));
//     else if (e.target.value === TimeFilter.ALL)
//       dispatch(getAllLearnerSessions());
//     else if (e.target.value === TimeFilter.ARCHIVED)
//       dispatch(getLearnerSessionsFilter({ filter: "Archived" }));
//   };
//   const onTabSelect = (item: any) => {
//     setSelectedFilter(item);
//     if (item === TimeFilter.TODAY)
//       dispatch(getLearnerSessionsFilter({ filter: "Today" }));
//     else if (item === TimeFilter.THIS_MONTH)
//       dispatch(getLearnerSessionsFilter({ filter: "Month" }));
//     else if (item === TimeFilter.THIS_WEEK)
//       dispatch(getLearnerSessionsFilter({ filter: "Week" }));
//     else if (item === TimeFilter.ALL) dispatch(getAllLearnerSessions());
//     else if (item === TimeFilter.ARCHIVED)
//       dispatch(getLearnerSessionsFilter({ filter: "Archived" }));
//   };

//   useEffect(() => {
//     dispatch(getLearnerSessions())
//       .then(unwrapResult)
//       .then((res) => {});
//     dispatch(getAllLearnerSessions())
//       .then(unwrapResult)
//       .then((res) => {});
//   }, []);

//   return (
//     <div className="clearance container py-5">
//       <div className="row">
//         <div className="col-sm-12 col-md-12 col-lg-9">
//           <div className="d-flex justify-content-between mb-3">
//             <h5 className="noselect m-0">Session Invitations</h5>
//             <div className="d-flex">
//               {Object.values(TimeFilter)?.map((item, index) => (
//                 <div
//                   key={index}
//                   onClick={() => onTabSelect(item)}
//                   className={`fw-bolder mx-1 fw-bolder cursor_pointer noselect d-none d-lg-block ${checkIfSelected(
//                     item
//                   )}`}
//                 >
//                   {item}
//                 </div>
//               ))}
//             </div>
//             {/* -------- drop down for small screen -------- */}
//             <div className="d-lg-none">
//               <select
//                 className="form-select-sm"
//                 aria-label="Default select example"
//                 value={selectedFilter}
//                 onChange={onOptionSelect}
//               >
//                 {Object.values(TimeFilter)?.map((item, index) => (
//                   <option value={item} key={index}>
//                     {item}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>

//           <div className="row gy-4">
//             {sessions.length === 0 ? (
//               <div className="container">
//                 <div className="row text-center mt-5">
//                   <h3 className="text-muted">No sessions found</h3>
//                 </div>
//               </div>
//             ) : (
//               sessions?.map((learnerSession: LearnerSession, index: number) => (
//                 <div className="col-sm-6 col-md-4" key={index}>
//                   <SessionCard learnerSession={learnerSession} key={index} />
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//         <div className="col-sm-12 col-md-12 col-lg-3">
//           <SessionRequestsComponent sessionRequests={sessionRequests} />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default UpcommingSessionsPage;

// ==============================================================================================================
// import { useEffect } from "react";
// import { useAppDispatch } from "src/store/store";
// import {
//   getLearnerSessions,
//   learnerSessionSelectors,
// } from "src/store/learnerSlices/LearnerSessionsSlice";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";
// import { SessionRequest } from "src/interfaces/SessionRequest";
// import TimeStamp from "../../components/Common/TimeStamp";
// import ApprovalStatusTag from "src/components/LearnerComponents/ApprovalStatusTag";

// function LearnerUpcommingSessionsPage() {
//   const dispatch = useAppDispatch();
//   const sessions = useSelector(learnerSessionSelectors.selectAllSessions());

//   useEffect(() => {
//     dispatch(getLearnerSessions())
//       .then(unwrapResult)
//       .then((res) => {});
//   }, []);

//   return (
//     <div className="clearance container py-5">
//       <h5 className="mb-3 noselect">Session Requests</h5>

//       <div className="card">
//         <table className="table table-striped">
//           <thead>
//             <tr>
//               <th scope="col"></th>
//               <th scope="col">Topic</th>
//               <th scope="col">Date</th>
//               <th scope="col">Mentor</th>
//               <th scope="col">Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             {sessions?.map((session: SessionRequest, index: number) => (
//               <tr>
//                 <th scope="row">{index + 1}</th>
//                 <td>{session?.topic}</td>
//                 <td>
//                   <TimeStamp timestamp={session?.startTime} />
//                 </td>
//                 <td>
//                   {session?.mentor?.firstName} {session?.mentor?.lastName}
//                 </td>
//                 <td>
//                   <ApprovalStatusTag approved={session?.approved} />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default LearnerUpcommingSessionsPage;
