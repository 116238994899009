import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Course } from "src/interfaces/Course";
import { authSelectors } from "src/store/authSlice";
import DatePicker from "react-datepicker";

import {
  bookSession,
  searchCourses,
} from "src/store/learnerSlices/LearnerSessionRequestsSlice";
import { useAppDispatch } from "src/store/store";
import { uiSelectors } from "src/store/uiSlice";
import ButtonWithLoading from "../../Common/utilities/ButtonWithLoading";
function BookSessionModal() {
  const mentorId = useSelector(uiSelectors.mentorId);

  const [startDate, setStartDate] = useState(new Date());
  const [formDetails, setFormDetails] = useState({
    topic: "",
    mentorId: mentorId,
    courseId: 23,
    startTime: startDate?.toISOString(),
  });
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();
  const [searchResults, setSearchResults] = useState<any>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitLoading(true);
    dispatch(bookSession(formDetails))
      .then(unwrapResult)
      .then(() => {
        setShowSuccess(true);
      })
      .catch(setSubmitLoading.bind(null, false));
  };

  const searchCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value.length < 3) return;
    dispatch(searchCourses({ title: e.target.value, mentorId: mentorId }))
      .then(unwrapResult)
      .then((res) => setSearchResults([...res]));
  };
  return (
    <div
      className="modal fade"
      id="bookSession"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="bookSessionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="bookSessionLabel">
              Book a session
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {showSuccess ? (
              <h5>Success! Session request sent to mentor.</h5>
            ) : (
              <>
                <div className="row mt-5">
                  <div className="col">
                    <form onSubmit={formSubmitHandler}>
                      <div className="mb-3">
                        <label htmlFor="sessionName" className="form-label">
                          Session Name
                        </label>
                        <input
                          required
                          className="form-control"
                          id="sessionName"
                          name="email"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFormDetails({
                              ...formDetails,
                              topic: e.target.value,
                            })
                          }
                          value={formDetails.topic}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          Course
                        </label>

                        {/* ====================================== Search ========================================================== */}
                        <div className="mb-3">
                          <input
                            type="text"
                            value={searchValue}
                            onChange={searchCourse}
                            className="form-control"
                          />
                          <div className="d-flex flex-column">
                            <div style={{ backgroundColor: "white" }}>
                              {searchResults.map((course: Course) => (
                                <div
                                  onClick={() => {
                                    setFormDetails({
                                      ...formDetails,
                                      courseId: course.id,
                                    });
                                    setSearchValue(course.title);
                                    setSearchResults([]);
                                  }}
                                  className="p-3 border-bottom"
                                >
                                  <div className="d-flex flex-row  py-1">
                                    <img
                                      src={`${course?.resources?.attachmentUrl}`}
                                      alt=""
                                      className="rounded"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div className="px-3">{course.title}</div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="date" className="form-label">
                          Date
                        </label>
                        <div>
                          <DatePicker
                            selected={startDate}
                            onChange={(date: any) => {
                              const ISOdate = date?.toISOString();
                              console.log({ ISOdate });
                              setStartDate(date);
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="d MMMM, yyyy h:mm aa"
                          />
                        </div>
                      </div>
                      {/* <div data-bs-dismiss="modal"> */}
                      <ButtonWithLoading
                        loading={submitLoading}
                        name="Submit"
                        onClick={() => {}}
                      />
                      {/* </div> */}
                    </form>
                  </div>
                  <div className="col-2"></div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {/* <button type="button" className="btn btn-primary">
              Understood
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookSessionModal;
