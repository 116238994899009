import { CourseFormat } from "./CourseFormat";
import { Status } from "./Status";
import {
  getAllLearnerCourses,
  getContinueLearningCourses,
  getInfographiaCourses,
  getPopularCourses,
  getVideoCourses,
  getWebwatchCourses,
  getXapiAndScromCourses,
} from "src/actions/courseActions/learnerCourseActions";
import { coursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/coursesPaginationSlice";
import { popularCoursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/popularCoursesPagination";
import { videoCoursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/videoCoursesPaginationSlice";
import { scromAndXapiPaginationSelectors } from "src/store/coursesSlices/coursePaginations/ScromXapiCoursesPaginationSlice";
import { continueLearningPaginationSelectors } from "src/store/coursesSlices/coursePaginations/continueLearningPaginationSlice";
import { getLearnerPathway } from "src/actions/PathwayActions/LearnerPathwayActions";
import { pathwayPaginationSelectors } from "src/store/pathways/pathwaysPaginationSlice";
import { webwatchCoursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/webwatchCoursePagginationSlice";
import { infographiaCoursesPaginationSelectors } from "src/store/coursesSlices/coursePaginations/infographiaCoursePagginationSlice";

enum Options {
  CLEAR_FILTERS = "CLEAR_FILTERS",
}

export const FILTER_ENUMS = { ...Status, ...CourseFormat, ...Options };

export enum TimeFilter {
  ALL = "all",
  TODAY = "today",
  THIS_WEEK = "this week",
  THIS_MONTH = "this month",
  ARCHIVED = "archived",
}

//course listing page
export enum Filters {
  CONTINUE_LEARNING = "Continue-Learning",
  WHATS_NEW = "What's-New",
  VIDEO = "Video-Courses",
  POPULAR = "Popular-Courses",
  SCROM = "Scrom",
  XAPI = "Xapi",
  MICRO_LEARNING = "Micro-Learning",
  XAPI_SCROM = "Xapi-SCROM-Courses",
  COURSES = "Courses",
  PATHWAYS = "Pathways",
  WEBWATCH = "WebWatch",
  INFOGRAPHIA = "InfoGraphia",
}

export const FilterMethods = {
  [Filters.CONTINUE_LEARNING]: {
    action: getContinueLearningCourses,
    selector: continueLearningPaginationSelectors,
  },
  [Filters.WHATS_NEW]: {
    action: getAllLearnerCourses,
    selector: coursesPaginationSelectors,
  },
  [Filters.POPULAR]: {
    action: getPopularCourses,
    selector: popularCoursesPaginationSelectors,
  },
  [Filters.VIDEO]: {
    action: getVideoCourses,
    selector: videoCoursesPaginationSelectors,
  },
  [Filters.XAPI_SCROM]: {
    action: getXapiAndScromCourses,
    selector: scromAndXapiPaginationSelectors,
  },
  [Filters.COURSES]: {
    action: getAllLearnerCourses,
    selector: coursesPaginationSelectors,
  },
  [Filters.PATHWAYS]: {
    action: getLearnerPathway,
    selector: pathwayPaginationSelectors,
  },
  [Filters.WEBWATCH]: {
    action: getWebwatchCourses,
    selector: webwatchCoursesPaginationSelectors,
  },
  [Filters.INFOGRAPHIA]: {
    action: getInfographiaCourses,
    selector: infographiaCoursesPaginationSelectors,
  },
};
