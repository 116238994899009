import { Button, PageHeader, Col, Row, Card, Image } from "antd";
import { HiDotsHorizontal } from "react-icons/hi";
import { Registrant } from "src/interfaces/Registrant";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";

interface Props {
  registrant: Registrant;
}
function ZoomSessionParticipantCard({ registrant }: Props) {
  return (
    <Card bordered={false} style={{ marginBottom: "10px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={
            registrant.user.resources
              ? registrant?.user?.resources.attachmentUrl
              : ProfilePlaceHolder
          }
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "15px",
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h5 style={{ margin: 0 }}>
            {registrant.first_name} {registrant.last_name}
          </h5>
          <HiDotsHorizontal style={{ fontSize: "170%", color: "#002766" }} />
        </div>
      </div>
    </Card>
  );
}

export default ZoomSessionParticipantCard;
