import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { authSelectors, checkAuth } from "../../../store/authSlice";
import { useAppDispatch } from "../../../store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import PageLoading from "../utilities/PageLoading";

function CheckAuthentication({ children, ...rest }: any) {
  const email = useSelector(authSelectors.email);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!email) {
      setLoading(true);
      dispatch(checkAuth())
        .then(unwrapResult)
        .catch(() => {})
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, []);

  return loading ? (
    <PageLoading />
  ) : email ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to="/login" />
  );
}

export default CheckAuthentication;
