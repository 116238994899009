import { Course, MentorCourse } from "src/interfaces/Course";
import { Col, Row, Space, Avatar, Typography } from "antd";
import Translation from "src/components/Common/utilities/Translation";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import { Tag } from "src/interfaces/Tag";
import { Tag as TagA } from "antd";
import CourseFormatTag from "../../CourseFormatTag";

function CourseExpand({ course }: { course: Course }) {
  const { Paragraph, Text } = Typography;

  return (
    <Row style={{ margin: "0px 25px" }}>
      <Col>
        <Row>
          <Text strong>
            <Translation input="excerpt" />
          </Text>
          &nbsp;
          <Paragraph>{course.excerpt}</Paragraph>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col span={12}>
            <Text strong>Tags :</Text>&nbsp;
            {course.coursesTags.map((tag: Tag, index: number) => (
              <Row style={{ display: "inline" }} key={index}>
                <TagA key={index} color="blue">
                  {tag?.tags.value}
                </TagA>
              </Row>
            ))}
          </Col>
          <Col span={12}>
            <Text strong>Course Format :</Text>&nbsp;
            <TagA key={course.id} color="blue">
              {course.type}
            </TagA>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text strong style={{ marginBottom: "10px" }}>
              Mentors
            </Text>
            <Row>
              {course.mentorsCourses.map((mentorCourse: MentorCourse) => (
                <Space>
                  <Avatar
                    src={
                      mentorCourse?.users?.resources?.metaData?.thumbnail ||
                      ProfilePlaceHolder
                    }
                  />
                  <Paragraph style={{ margin: 0 }}>
                    {mentorCourse?.users?.firstName}{" "}
                    {mentorCourse?.users?.lastName}
                  </Paragraph>
                </Space>
              ))}
            </Row>
          </Col>
          <Col span={12}>
            <Text strong>Author</Text>
            <Row>
              <Space>
                <Avatar
                  src={
                    course.users?.resources?.metaData?.thumbnail ||
                    ProfilePlaceHolder
                  }
                />
                <Paragraph style={{ margin: 0 }}>
                  {course?.users.firstName} {course?.users.lastName}
                </Paragraph>
              </Space>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CourseExpand;
