import { PageHeader, Col, Row, Card, Form, Input, Button, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import CoursesDraggableFormItem from "./CreatePathwayPage/CoursesDraggableFormItem";
import SearchTags from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import UploadImage from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/store";
import { Course, PathWayCourse } from "src/interfaces/Course";
import {
  pathwaysSelectors,
  getPathway,
  editPathway,
} from "src/store/pathways/pathwaysSlice";
import SkeletonLoader from "../../../components/Common/utilities/SkeletonLoader";
import { unwrapResult } from "@reduxjs/toolkit";
import ShowSuccess from "src/components/Admin-Author-Components/ShowSuccess";
import FormLabel from "src/components/Common/utilities/FormLabel";

interface Params {
  pathwayId: string;
}

function EditPathwayPage() {
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showUploadCourse, setShowUploadCourse] = useState(false);

  const history = useHistory();
  const { pathwayId } = useParams<Params>();
  const pathway = useSelector(
    pathwaysSelectors.selectPathwayEntity(parseInt(pathwayId))
  );
  const [submitSucessfull, setSubmitSucessfull] = useState(false);
  const { TextArea } = Input;
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const cardHeader = <h5>Edit Pathway</h5>;

  const onFormSubmit = () => {
    setButtonLoading(true);
    setDone(true);
    const pathway = {
      ...form.getFieldsValue(),
      description: description,
      objectives: objectives,
      imageId: form.getFieldsValue().image,
      pathwaysTags: form
        .getFieldsValue()
        .pathwaysTags?.map((item: any) => item.value),
      pathwaysCourses: form
        .getFieldsValue()
        .courses?.map(
          (pathwayCourseOrCourse: PathWayCourse & Course, index: number) => {
            if (pathwayCourseOrCourse.courseId) {
              return {
                id: pathwayCourseOrCourse.id,
                courseId: pathwayCourseOrCourse.courseId,
                pathwayId: pathwayCourseOrCourse.pathwayId,
                positionId: index,
              };
            } else {
              return {
                courseId: pathwayCourseOrCourse.id,
                pathwayId: parseInt(pathwayId),
                positionId: index,
              };
            }
          }
        ),
    };

    dispatch(editPathway({ pathwayId: parseInt(pathwayId), pathway: pathway }))
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        setSubmitSucessfull(true);
        setButtonLoading(false);
      })
      .catch(() => {
        setButtonLoading(false);
        message.error("Error creating pathway");
      });
  };

  useEffect(() => {
    if (pathway) setLoading(false);
    else
      dispatch(getPathway(parseInt(pathwayId))).then(
        setLoading.bind(null, false)
      );
    form.setFieldsValue({
      ...pathway,
      pathwaysTags: pathway?.pathwaysTags.map((tag: any) => ({
        value: tag.tags.id,
        label: tag.tags.value,
      })),
    });
    setDescription(pathway?.description || "");
    setObjectives(pathway?.objectives || "");
    setImageUrl(pathway?.resources.attachmentUrl || "");
    document.title = `Learnsure Ai . ${pathway?.title}`;
  }, [pathway]);

  return (
    <>
      {submitSucessfull ? (
        <ShowSuccess
          message="Successfully updated pathway"
          buttons={[
            { name: " Go To Pathways", onClickLink: "/admin/pathways" },
          ]}
        />
      ) : (
        <Row>
          <Col sm={24}>
            <Card title={cardHeader}>
              <Form
                form={form}
                name="basic"
                onFinish={onFormSubmit}
                scrollToFirstError={true}
              >
                <div>
                  <FormLabel name="Title" />
                  <SkeletonLoader loading={loading} rows={0} type="normal">
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please add a Title for the course",
                          type: "string",
                        },
                        {
                          min: 2,
                          message: "Title must be minimum 2 characters.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </SkeletonLoader>

                  <FormLabel name="Excerpt" />
                  <SkeletonLoader loading={loading} rows={0} type="normal">
                    <Form.Item
                      name="excerpt"
                      rules={[
                        {
                          required: true,
                          message: "Please add as excerpt for the course",
                          type: "string",
                        },
                        {
                          min: 2,
                          message: "excerpt must be minimum 2 characters.",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </SkeletonLoader>
                  <FormLabel name="Description" />
                  <SkeletonLoader loading={loading} rows={4} type="normal">
                    <Form.Item>
                      <ReactQuill
                        onChange={(value) => {
                          setDescription(value);
                        }}
                        value={description}
                      />
                    </Form.Item>
                  </SkeletonLoader>
                  <FormLabel name="Objectives" />
                  <SkeletonLoader loading={loading} rows={4} type="normal">
                    <Form.Item>
                      <ReactQuill
                        onChange={(value) => {
                          setObjectives(value);
                        }}
                        value={objectives}
                      />
                    </Form.Item>
                  </SkeletonLoader>
                  <FormLabel name="Tags" />
                  <SkeletonLoader loading={loading} rows={0} type="normal">
                    <SearchTags name="pathwaysTags" />
                  </SkeletonLoader>
                  <FormLabel name="Course Image" />

                  <div style={{ marginBottom: "20px" }}>
                    {showUploadCourse ? (
                      <div>
                        <UploadImage done={done} name="image" nested={false} />
                        <Button onClick={setShowUploadCourse.bind(null, false)}>
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={imageUrl}
                            alt="course-image"
                            style={{ width: "15rem" }}
                          />
                          <Button
                            type="primary"
                            onClick={setShowUploadCourse.bind(null, true)}
                            style={{ width: "15rem" }}
                          >
                            Update Course Image
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>

                  <FormLabel name="Courses" />
                  {console.log(pathway?.pathwaysCourses)}
                  <CoursesDraggableFormItem
                    done={done}
                    courses={pathway?.pathwaysCourses}
                  />
                </div>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "100px",
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={buttonLoading}
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default EditPathwayPage;
