// import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import "react-quill/dist/quill.snow.css";
// import "./pages/pages.css";
// import LoginPage from "./pages/CommonPages/LoginPage";
// import UserSetPasswordPage from "./pages/CommonPages/UserSetPasswordPage";
// import ResetPasswordPage from "src/pages/CommonPages/ResetPasswordPage/ResetPasswordPage";
// import ForgotPassword from "src/pages/CommonPages/ForgotPassword/ForgotPassword";
// import ZoomAuthorizationCheckPage from "./pages/CommonPages/ZoomAuthorizationCheckPage";
// import NotFoundPage from "./pages/CommonPages/NotFoundPage";
// import "src/Assets/styles/common.scss";
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import HttpApi from "i18next-http-backend";
// import AdminRoutes from "./routes/AdminRoutes";
// import AuthorRoutes from "./routes/AuthorRoutes";
// import MentorRoutes from "./routes/MentorRoutes";
// import LearnerRoutes from "./routes/LearnerRoutes";

// //FIXME:move to seperate file
// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     supportedLngs: ["en", "hi", "ar", "es"],
//     fallbackLng: "en",
//     detection: {
//       order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
//       caches: ["cookie"],
//     },
//     backend: { loadPath: "/assets/locales/{{lng}}/translation.json" },
//     react: { useSuspense: false },
//   });

// function App() {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route exact path="/">
//           <Redirect to="/login" />
//         </Route>
//         <Route exact path="/login">
//           <LoginPage />
//         </Route>
//         <Route exact path="/welcome">
//           <UserSetPasswordPage />
//         </Route>
//         <Route exact path="/forgot-password">
//           <ForgotPassword />
//         </Route>
//         <Route exact path="/reset-password">
//           <ResetPasswordPage />
//         </Route>
//         <Route exact path="/authorization">
//           <ZoomAuthorizationCheckPage />
//         </Route>
//         <Route path="/admin">
//           <AdminRoutes />
//         </Route>
//         <Route path="/author">
//           <AuthorRoutes />
//         </Route>
//         <Route path="/mentor">
//           <MentorRoutes />
//         </Route>
//         <Route path="/">
//           <LearnerRoutes />
//         </Route>
//         <Route exact path="">
//           <NotFoundPage />
//         </Route>
//       </Switch>
//     </BrowserRouter>
//   );
// }

// export default App;

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import "./pages/pages.css";
import LoginPage from "./pages/CommonPages/LoginPage";
import HomePage from "./pages/LearnerPages/Homepage/HomePage";
import CourseDescriptionPage from "./pages/LearnerPages/CourseDescriptionPage/CourseDescriptionPage";
import PrivateRoute from "./components/Common/authorization/PrivateRoute";
import UsersListPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/UsersListPage";
import AdminCoursesTablePage from "./pages/Admin-Author-Mentor-Pages/AdminPages/AdminCoursesTablePage";
import CreateCoursePage from "./pages/CreateCoursePage/CreateCoursePage";
import CourseListingPage from "./pages/LearnerPages/CourseListingPage";
import AuthorCoursesTablePage from "./pages/Admin-Author-Mentor-Pages/AuthorPages/AuthorCoursesTablePage";
import EditCoursePage from "./pages/EditCoursePage/EditCoursePage";
import UserFormPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/UserFormPage/UserFormPage";
import UserSetPasswordPage from "./pages/CommonPages/UserSetPasswordPage";
import ViewCoursePage from "./pages/ViewCoursePage/ViewCoursePage";
import LearnerProfilePage from "./pages/LearnerPages/LearnerProfilePage";
import ProfilePage from "./pages/Admin-Author-Mentor-Pages/CommonPages/ProfilePage";
import EditLearnerProfilePage from "./pages/LearnerPages/EditLearnerProfilePage";
import CourseContentPage from "./pages/LearnerPages/CourseContentPage/CourseContentPage";
import View from "./pages/Admin-Author-Mentor-Pages/CommonPages/View";
import PathwaysPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/PathwaysPage";
import CreatePathwayPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/CreatePathwayPage/CreatePathwayPage";
import EditPathwayPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/EditPathWayPage";
import ViewPathWayPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/ViewPathWayPage";
import PathwayDescriptionPage from "./pages/LearnerPages/PathwayDescriptionPage/PathwayDescriptionPage";
import Mylearning from "./pages/LearnerPages/MylearningPage";
import MentorCoursesTablePage from "./pages/Admin-Author-Mentor-Pages/MentorPages/MentorCoursesTablePage";
import ViewCourseMentorPage from "./pages/Admin-Author-Mentor-Pages/MentorPages/ViewCourseMentorPage";
import DashBoardPage from "./pages/Admin-Author-Mentor-Pages/AdminPages/DashBoardPage";
import ViewAttachmentPage from "./pages/Admin-Author-Mentor-Pages/MentorPages/ViewAttachmentPage";
import SessionsPage from "./pages/Admin-Author-Mentor-Pages/MentorPages/SessionsPage/SessionsPage";
import CreateSessionPage from "./pages/Admin-Author-Mentor-Pages/MentorPages/CreateSessionPage";
import ZoomSession from "src/pages/Admin-Author-Mentor-Pages/MentorPages/ZoomSession";
import LearnerSession from "src/pages/LearnerPages/LearnerSessionPage/LearnerSessionPage";
import EditSessionPage from "src/pages/Admin-Author-Mentor-Pages/MentorPages/EditSessionPage";
import { Role } from "./interfaces/User";
import ResetPasswordPage from "src/pages/CommonPages/ResetPasswordPage/ResetPasswordPage";
import ForgotPassword from "src/pages/CommonPages/ForgotPassword/ForgotPassword";
import BookSessionPage from "src/pages/LearnerPages/BookSessionPage";
import UpcommingSessionsPage from "src/pages/LearnerPages/UpcommingSessionsPage/UpcommingSessionsPage";
import SessionRequestsPage from "src/pages/Admin-Author-Mentor-Pages/MentorPages/SessionRequestsPage";
import ZoomAuthorizationCheckPage from "./pages/CommonPages/ZoomAuthorizationCheckPage";
import NotFoundPage from "./pages/CommonPages/NotFoundPage";
import SearchResultsPage from "./pages/LearnerPages/SearchResultsPage/SearchResultsPage";
import NotificationsPage from "./pages/LearnerPages/NotificationsPage";
import "src/Assets/styles/common.scss";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import CheckAuthentication from "./components/Common/authorization/CheckAuthentication";
import TranslationPage from "./pages/Admin-Author-Mentor-Pages/CommonPages/TranslationPage";
import TranslationDashBoardLayout from "./components/AdminAuthorMentorComponents/Common/Translation/TranslationDashBoardLayout";
import QuizPage from "./pages/LearnerPages/QuizPage";
import CreateCertificate from "./pages/Admin-Author-Mentor-Pages/CommonPages/CreateCertificatePage";
import CertificatesPage from "./pages/Admin-Author-Mentor-Pages/CommonPages/CertificatesPage";
import AdminRoutes from "./routes/AdminRoutes";
import CommingSoonPage from "src/pages/CommonPages/CommingSoonPage";
import WebWatchFormPage from "./pages/Admin-Author-Mentor-Pages/CommonPages/WebWatchFormPage";
import InfographiaFormPage from "./pages/Admin-Author-Mentor-Pages/CommonPages/InfographiaFormPage";
import WebwatchAndInfographiaTable from "./components/AdminAuthorMentorComponents/Common/Tables/WebWatchAndInfographia/WebwatchAndInfographia";
import Pilot from "./pages/Pilot";

//FIXME:move to seperate file
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "hi", "ar", "es", "zho"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: { loadPath: "/assets/locales/{{lng}}/translation.json" },
    react: { useSuspense: false },
  });

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/pilot">
            <Pilot />
          </Route>


          {/* ========= Common Routes =============== */}
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/welcome">
            <UserSetPasswordPage />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordPage />
          </Route>
          <Route exact path="/authorization">
            <ZoomAuthorizationCheckPage />
          </Route>

          {/* ========= Admin Routes =============== */}

          {/* <AdminRoutes /> */}

          <PrivateRoute exact path="/admin/dashboard" role={Role.ADMIN}>
            <DashBoardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/courses" role={Role.ADMIN}>
            <AdminCoursesTablePage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/create-course" role={Role.ADMIN}>
            <CreateCoursePage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/users" role={Role.ADMIN}>
            <UsersListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/create-user" role={Role.ADMIN}>
            <UserFormPage edit={false} />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/:userId/edit-user" role={Role.ADMIN}>
            <UserFormPage edit={true} />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/:courseId/edit" role={Role.ADMIN}>
            <EditCoursePage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/draft" role={Role.ADMIN}>
            <EditCoursePage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/view-course/:courseId"
            role={Role.ADMIN}>
            <ViewCoursePage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/view/:course_id" role={Role.ADMIN}>
            <View />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/profile" role={Role.ADMIN}>
            <ProfilePage />
          </PrivateRoute>

          <PrivateRoute exact path="/admin/pathways" role={Role.ADMIN}>
            <PathwaysPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/create-pathway" role={Role.ADMIN}>
            <CreatePathwayPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/:pathwayId/pathway/edit"
            role={Role.ADMIN}>
            <EditPathwayPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/:pathwayId/pathway/view"
            role={Role.ADMIN}>
            <ViewPathWayPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/view/:course_id" role={Role.ADMIN}>
            <View />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/certificates/create"
            role={Role.ADMIN}>
            <CreateCertificate />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/certificates" role={Role.ADMIN}>
            <CertificatesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/add-webwatch" role={Role.ADMIN}>
            <WebWatchFormPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/add-infographia" role={Role.ADMIN}>
            <InfographiaFormPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/getWebwatchAndInfographia"
            role={Role.ADMIN}>
            <WebwatchAndInfographiaTable />
          </PrivateRoute>
          <CheckAuthentication
            exact
            path="/admin/translation/:courseId/:translationString">
            <TranslationDashBoardLayout>
              <TranslationPage />
            </TranslationDashBoardLayout>
          </CheckAuthentication>
          {/* ========= Author Routes =============== */}
          <PrivateRoute role={Role.AUTHOR} exact path="/author/create-course">
            <CreateCoursePage />
          </PrivateRoute>
          <PrivateRoute role={Role.AUTHOR} exact path="/author">
            <AuthorCoursesTablePage />
          </PrivateRoute>
          <PrivateRoute role={Role.AUTHOR} exact path="/author/:courseId/edit">
            <EditCoursePage />
          </PrivateRoute>
          <PrivateRoute
            role={Role.AUTHOR}
            exact
            path="/author/view-course/:courseId">
            <ViewCoursePage />
          </PrivateRoute>
          <PrivateRoute role={Role.AUTHOR} exact path="/author/view/:course_id">
            <View />
          </PrivateRoute>
          <PrivateRoute role={Role.AUTHOR} exact path="/author/profile">
            <ProfilePage />
          </PrivateRoute>

          {/* ========= Mentor Routes =============== */}
          <PrivateRoute exact path="/mentor/courses" role={Role.MENTOR}>
            <MentorCoursesTablePage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/mentor/courses/view-course/:course_id"
            role={Role.MENTOR}>
            <ViewCourseMentorPage />
          </PrivateRoute>
          <PrivateRoute exact path="/mentor/profile" role={Role.MENTOR}>
            <ProfilePage />
          </PrivateRoute>

          <PrivateRoute exact path="/mentor/sessions" role={Role.MENTOR}>
            <SessionsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/mentor/create-session" role={Role.MENTOR}>
            <CreateSessionPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/mentor/edit-session/:session_Id"
            role={Role.MENTOR}>
            <EditSessionPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/mentor/session-requests"
            role={Role.MENTOR}>
            <SessionRequestsPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/mentor/zoom-session/:session_Id"
            role={Role.MENTOR}>
            <ZoomSession />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/mentor/:course_Id/:attachment_Id">
            <ViewAttachmentPage />
          </PrivateRoute> */}
          {/* ========= Learner Routes =============== */}
          <PrivateRoute exact path="/home" role={Role.LEARNER}>
            <HomePage />
          </PrivateRoute>
          <PrivateRoute
            role={Role.LEARNER}
            exact
            path="/course-description/:slug"
            bypassRoleCheck={true}>
            <CourseDescriptionPage />
          </PrivateRoute>
          <PrivateRoute
            role={Role.LEARNER}
            exact
            path="/course-listing/:filter">
            <CourseListingPage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/learner/profile">
            <LearnerProfilePage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/learner/profile-edit">
            <EditLearnerProfilePage />
          </PrivateRoute>
          <PrivateRoute
            role={Role.LEARNER}
            exact
            path="/course-content/:slug"
            bypassRoleCheck={true}>
            <CourseContentPage />
          </PrivateRoute>
          <PrivateRoute
            role={Role.LEARNER}
            exact
            path="/pathway/description/:slug"
            bypassRoleCheck={true}>
            <PathwayDescriptionPage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/my-learning/:filter">
            <Mylearning />
          </PrivateRoute>
          <PrivateRoute
            role={Role.LEARNER}
            exact
            path="/learner/book-session/:mentorId">
            <BookSessionPage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/learner/sessions">
            <UpcommingSessionsPage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/learner/search/:query">
            <SearchResultsPage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/learner/notifications">
            <NotificationsPage />
          </PrivateRoute>
          <PrivateRoute role={Role.LEARNER} exact path="/learner/quiz/:slug">
            <QuizPage />
          </PrivateRoute>

          <Route exact path="/help">
            <CommingSoonPage />
          </Route>
          <Route exact path="">
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
