import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CourseCard from "src/components/LearnerComponents/Course/CourseCard/CourseCard";
import { Course } from "src/interfaces/Course";
import { RootState, useAppDispatch } from "src/store/store";
import PathwayCard from "src/components/LearnerComponents/Pathways/PathwayCard";
import { PathWay } from "src/interfaces/PathWay";
import { useTranslation } from "react-i18next";
import Pagination from "src/components/LearnerComponents/UtilityComponents/Pagination";
import { FilterMethods, Filters } from "src/enums/Filters";
import { useHistory, useParams } from "react-router-dom";
import { removeHypenAddSpace, topOfPage } from "src/utils/utils";
import LoadingWrapper from "src/components/LearnerComponents/UtilityComponents/LoadingWrapper";

interface Params {
  filter: Filters;
}

function MylearningPage() {
  const { filter } = useParams<Params>();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const data = useSelector((state: RootState) =>
    FilterMethods[filter].selector.get(state, page)
  );
  const totalPages = useSelector(
    FilterMethods[filter].selector.totalPages
  ) as number;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onFilterTabSelect = (filt: Filters) => {
    setPage(0);
    history.push(`/my-learning/${filt}`);
  };

  const filterTab = (filt: Filters, translationString: string) => (
    <div
      className={`fw-bolder mx-1 fw-bolder cursor_pointer noselect d-none d-lg-block ${
        filt === filter ? "text-dark" : "text-muted"
      }`}
      onClick={() => {
        // if (filt === Filters.INFOGRAPHIA || filt === Filters.WEBWATCH) return;
        onFilterTabSelect(filt);
      }}
      key={filt}>
      {/* {removeHypenAddSpace(filt)} */}
      {t(translationString)}
    </div>
  );

  useEffect(() => {
    setLoading(true);
    topOfPage();
    if (data.length === 0)
      dispatch(FilterMethods[filter].action(page)).then(
        setLoading.bind(null, false)
      );
    else setLoading(false);
  }, [filter, page]);

  return (
    <div className="py-5 clearance">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <h5 className="mb-3 noselect">{t("my_learning")}</h5>
              {/* -------- tabs for large screen -------- */}
              <div className="d-flex ">
                {filterTab(Filters.COURSES, "courses")}
                {filterTab(Filters.PATHWAYS, "pathways")}
                {filterTab(Filters.VIDEO, "video")}
                {filterTab(Filters.WEBWATCH, "webwatch")}
                {filterTab(Filters.INFOGRAPHIA, "infographia")}
              </div>

              {/* -------- drop down for small screen -------- */}
              <div className="d-lg-none">
                <select
                  className="form-select-sm"
                  aria-label="Default select example"
                  onChange={(e) =>
                    onFilterTabSelect(e.target.value as Filters)
                  }>
                  <option value={Filters.COURSES} key={Filters.COURSES}>
                    {t("course")}
                  </option>
                  <option value={Filters.PATHWAYS} key={Filters.PATHWAYS}>
                    {t("pathways")}
                  </option>
                  <option value={Filters.VIDEO} key={Filters.VIDEO}>
                    {t("video_courses")}
                  </option>
                </select>
              </div>
            </div>
            <LoadingWrapper loading={loading}>
              <div className="row">
                {data?.slice(0, 8).map((item: Course | PathWay) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-6"
                    key={item.id}>
                    {filter === Filters.PATHWAYS ? (
                      <PathwayCard pathway={item as PathWay} />
                    ) : (
                      <CourseCard course={item as Course} />
                    )}
                  </div>
                ))}
              </div>
            </LoadingWrapper>

            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={page}
                    onClick={(e) => setPage(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MylearningPage;
