import { Tag } from "antd";
import { CourseFormat } from "src/enums/CourseFormat";
import { Course } from "src/interfaces/Course";

interface Props {
  course: Course;
}

function CourseFormatTag({ course }: Props) {
  const courseFormatTagCheck = (course: Course) => {
    switch (course.type) {
      case CourseFormat.DEFAULT:
        return <Tag>{CourseFormat.DEFAULT}</Tag>;
      case CourseFormat.SCROM:
        return <Tag>{CourseFormat.SCROM}</Tag>;
      case CourseFormat.XAPI:
        return <Tag>{CourseFormat.XAPI}</Tag>;
      case CourseFormat.VIDEO:
        return <Tag>{CourseFormat.VIDEO}</Tag>;
    }
  };

  return <div>{courseFormatTagCheck(course)}</div>;
}

export default CourseFormatTag;
