import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BookIcon from "src/Assets/media/book-icon.svg";
import { Filters } from "src/enums/Filters";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";

function NavMyLearningIcon() {
  const history = useHistory();
  const role = useSelector(authSelectors.role);

  return (
    <li className="nav-item px-lg-2 px-md-5 px-sm-5 px-5">
      <a
        className="nav-link"
        onClick={() => {
          if (role === Role.LEARNER)
            history.push(`/my-learning/${Filters.COURSES}`);
        }}
      >
        <img src={BookIcon} width="24px" alt="" />
        <span className="text-light mx-3 d-lg-none">My Learning</span>
      </a>
    </li>
  );
}

export default NavMyLearningIcon;
