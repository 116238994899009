import { Result, Button, Card } from "antd";
import { useHistory } from "react-router";

interface Button {
  name: string;
  onClickLink: string;
}

interface Props {
  message: string;
  subTitle?: string;
  buttons: Button[];
}

function ShowSuccess({ message, subTitle, buttons }: Props) {
  const history = useHistory();

  return (
    <Card>
      <Result
        status="success"
        title={message}
        subTitle={subTitle}
        extra={[
          ...buttons.map((button: Button) => {
            return (
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  history.push(button.onClickLink);
                }}
              >
                {button.name}
              </Button>
            );
          }),
        ]}
      />
    </Card>
  );
}

export default ShowSuccess;
