import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../../store";
import { coursesSelector } from "../coursesSlice";
import { getInfographiaCourses } from "src/actions/courseActions/learnerCourseActions";

const infographiaCoursesPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: infographiaCoursesPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const infographiaCoursesPaginationSlice = createSlice({
  name: "infographiaCourses/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInfographiaCourses.fulfilled, (state, { payload }) => {
      return {
        pages: infographiaCoursesPaginationAdapter.upsertOne(
          { ...state.pages },
          {
            id: parseInt(payload.currentPage),
            items: payload?.data?.map((item: any) => item.courses.id),
          }
        ),
        total: payload.pages,
        totalItems: payload.items,
      };
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = infographiaCoursesPaginationAdapter.getSelectors(
  (s: RootState) => s.inforgraphiaCoursePagination.pages
);

export const infographiaCoursesPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        coursesSelector.coursesSelector.selectById(state, id)
      ) || [],
  totalPages: (state: RootState) => state.inforgraphiaCoursePagination.total,

  // total: (state: RootState) => state.continueLearning.totalItems,
};
