import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";

//get all sessions
interface GetAllSession {
  mentorId: number | undefined;
  filter: string;
  page: number;
}
export const getSessions = createAsyncThunk(
  "sessions/get",
  async ({ mentorId, filter, page }: GetAllSession) =>
    await api
      .post(`mentor/getSessions?filter=${filter}&page=${page}`, { mentorId })
      .then((res) => res.data)
);

export const clearPaginations = createAsyncThunk(
  "session/paginations/clear",
  () => {}
);
