import { Quiz } from "src/interfaces/Quiz";
import QuizOption from "./QuizOption";

interface Props {
  question: Quiz;
  onChange: (any) => void;
}

function QuizQuestionSlide({ question, onChange }: Props) {
  return (
    <ul className="list-group list-group-flush text-start">
      <li className="list-group-item">
        <h5>{question.question}</h5>
      </li>
      {question.options.map((option, i) => (
        <QuizOption
          key={i}
          option={option}
          onChange={(e) =>
            onChange({
              id: question.id,
              attachmentId: null,
              weightage: question.weightage,
              e: e,
            })
          }
        />
      ))}
    </ul>
  );
}

export default QuizQuestionSlide;
