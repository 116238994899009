import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";

import { uploadImage } from "../../api/mediaUploadApi";
import { useAppDispatch } from "../../store/store";
import { authSelectors, updateProfile } from "../../store/authSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function LearnerProfilePage() {
  const userDetails = useSelector(authSelectors.details);

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(userDetails.description || "");
  const [designation, setDesignation] = useState(userDetails.designation || "");
  const [firstName, setFirstName] = useState(userDetails.firstName);
  const [lastName, setLastName] = useState(userDetails.lastName);
  const [imageId, setImageId] = useState<number>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleImageUpload = (e: any) => {
    setLoading(true);
    const fileList = e.target.files && e.target.files[0];

    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      dispatch(uploadImage(formData))
        .then(unwrapResult)
        .then((res) => {
          setImageId(res.id);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      updateProfile({
        id: userDetails.id,
        firstName: firstName,
        lastName: lastName,
        description: description,
        designation: designation,
        imageId: imageId,
      })
    )
      .then(unwrapResult)
      .then(() => {
        history.goBack();
      });
  };
  return (
    <div style={{ height: "82.5vh" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div
              style={{
                backgroundImage: `url("${userDetails.resources.attachmentUrl}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "400px",
                height: "400px",
                borderRadius: "50%",
                marginTop: "20%",
              }}
              onClick={() => {
                // history.push("/learner/profile");
              }}
            ></div>
          </div>
          <div className="col-md-8">
            <form
              onSubmit={onFormSubmit}
              style={{ marginTop: "10%" }}
              className="mr-5 ml-5"
            >
              <div className="mb-3">
                <div className="text-primary">First Name</div>
                <input
                  type="string"
                  className="form-control"
                  id="firstName"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                />
              </div>
              <div className="mb-3">
                <div className="text-primary">Last Name</div>
                <input
                  type="string"
                  className="form-control"
                  id="lastName"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastName}
                />
              </div>
              <div className="mb-3">
                <div className="text-primary">Description</div>
                <input
                  type="string"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                />
              </div>
              <div className="mb-3">
                <div className="text-primary">Designation</div>
                <input
                  type="string"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                  value={designation}
                />
              </div>
              <div className="mb-3">
                <div className="text-primary">Profile Picture</div>
                <input
                  type="file"
                  className="form-control"
                  id="exampleInputPassword1"
                  onChange={handleImageUpload}
                />
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : null}
              </div>
              <div className="w-100 d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnerProfilePage;
