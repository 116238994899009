import React, { useState } from "react";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { LessonsAttachment } from "src/interfaces/Lesson";
import { Form, Upload, message } from "antd";
import { uploadCourseImage, uploadVideo } from "src/api/mediaUploadApi";
import { addMedia } from "src/utils/Rest/EditCourseUtils/EditCourseUtils";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

interface Props {
  lessonsAttachment: LessonsAttachment[];
  onChange: (e: any) => void;
  moduleId: number | string;
  lessonId: number | string;
  sectionId: number | string;
}

function VideoSection({
  lessonsAttachment,
  onChange,
  moduleId,
  lessonId,
  sectionId,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);

  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      if (e.file.type !== "video/mp4" && e.file.type !== "audio/mpeg") {
        message.error("please upload image");
        setLoading(false);
        return;
      }
      uploadVideo(formData)
        .then((res) => {
          onChange({
            attachmentId: res.id,
            resources: { attachmentUrl: res.attachmentUrl },
          });
          setLoading(false);
        })
        .catch((e) => {
          message.error("error uploading");
        });

      // try {
      //   const image = await uploadVideo(formData);

      //   // setFileListArray([
      //   //   ...fileListArray,
      //   //   {
      //   //     url: image.attachmentUrl,
      //   //     name: "image.png",
      //   //     status: "done",
      //   //     uid: image.id,
      //   //   },
      //   // ]);

      //   setLoading(false);
      //   message.success("image successfully uploaded");
      // } catch (error) {
      //   message.error("error uploading ");
      // }
    }
  };
  const removeHandler = (e: any) => {
    // removeSliderImage(moduleId, lessonId, sectionData[0].sectionId, e.uid);
  };

  const uploadButton = loading ? (
    <LoadingOutlined style={{ fontSize: "250%" }} />
  ) : (
    <PlusOutlined style={{ fontSize: "250%" }} />
  );
  return lessonsAttachment[0].resources ? (
    <VideoPlayer url={lessonsAttachment[0].resources?.attachmentUrl} />
  ) : (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={true}
      customRequest={customRequest}
      multiple={true}
      fileList={fileListArray}
      onRemove={removeHandler}
    >
      {fileListArray.length >= 1 ? null : uploadButton}
    </Upload>
  );
}

export default VideoSection;
