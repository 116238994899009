import { Col, Row, Card, Button, Form } from "antd";
import { useSelector } from "react-redux";
import { Course } from "src/interfaces/Course";
import { authSelectors } from "src/store/authSlice";
import { getFileNameFromUrl } from "src/utils/utils";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import AddEditNotes from "./AddEditNotes";
import { editCourseNotesMentor } from "src/store/coursesSlices/coursesSlice";
import CourseNotesAttachment from "./CourseNotesAttachment/CourseNotesAttachment";
import { useForm } from "antd/lib/form/Form";
import { useAppDispatch } from "src/store/store";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";

interface Props {
  course?: Course;
}

function ShowNotes({ course }: Props) {
  const mentorId = useSelector(authSelectors.id);
  const [showEditForm, setShowEditForm] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [done, setDone] = useState(false);
  const [notes, setNotes] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();

  const onFormSubmit = () => {
    setDone(true);
    const attachment = {
      ...form.getFieldsValue(),
      courseId: course?.id,
      mentorId: mentorId,
      content: notes,
      id: course?.mentorsCourses?.filter(
        (item) => item.mentorId === mentorId
      )[0].id,
    };
    setDone(false);
    dispatch(editCourseNotesMentor(attachment)).then(() => {
      setShowEditForm(false);
      setDone(false);
    });
  };

  useEffect(() => {
    setNotes(
      course?.mentorsCourses?.filter((item) => item.mentorId === mentorId)[0]
        .content || ""
    );
  }, [course]);
  return (
    <>
      {showEditForm ? (
        // <AddEditNotes edit={true} course={course} />
        <Card title="Edit Notes" hoverable={true}>
          <h6 className="form_label">Note</h6>
          <Form onFinish={onFormSubmit} form={form}>
            <Form.Item>
              <ReactQuill
                onChange={(e) => {
                  setNotes(e);
                }}
                value={notes}
              />
            </Form.Item>
            <h6 className="form_label">Attachment</h6>
            <Form.Item>
              <CourseNotesAttachment
                done={done}
                edit={true}
                notesAttachment={
                  course?.mentorsCourses?.filter(
                    (item) => item.mentorId === mentorId
                  )[0].coursesNotes
                }
              />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}
                >
                  Submit
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      ) : (
        <>
          <Card
            title={
              <Col md={24}>
                <Row justify="space-between">
                  <div>Course Notes</div>
                  <div>
                    <Button
                      type="primary"
                      onClick={setShowEditForm.bind(null, true)}
                    >
                      Edit
                    </Button>
                  </div>
                </Row>
              </Col>
            }
          >
            {parse(
              course?.mentorsCourses?.filter(
                (item) => item.mentorId === mentorId
              )[0].content || ""
            )}
            <Col md={24}>
              {course?.mentorsCourses
                ?.filter((item) => item.mentorId === mentorId)[0]
                .coursesNotes.map((item: any) => {
                  return (
                    <Row
                      key={item.id}
                      align="middle"
                      style={{
                        margin: "20px 0px",
                        border: "1px dashed grey",
                        padding: "10px",
                      }}
                      onClick={() => {
                        // history.push(
                        //   `/mentor/${course.id}/${item.attachmentId}`
                        // );
                        window.open(
                          `/mentor/${course.id}/${item.attachmentId}`,
                          "_blank"
                        );
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url("${item.resources.attachmentUrl}")`,
                          height: "40px",
                          width: `40px`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          border: "1px solid #eee",
                        }}
                      ></div>
                      <div>
                        {getFileNameFromUrl(item.resources.attachmentUrl)}
                      </div>
                    </Row>
                  );
                })}
            </Col>
          </Card>
        </>
      )}
    </>
  );
}

export default ShowNotes;
