import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import CourseMentorsCarousel from "../../../components/LearnerComponents/CourseMentorsCarousel/CourseMentorsCarousel";
import SkeletonLoaderLearner from "../../../components/LearnerComponents/UtilityComponents/SkeletonLoaderLearner";
import { useSelector } from "react-redux";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import HeroComponent from "src/components/LearnerComponents/Course/HeroComponent";
import CourseContent from "src/pages/LearnerPages/CourseDescriptionPage/CourseContent";
import Certificates from "../../../components/LearnerComponents/Certificates/Certificates";
import DownloadApp from "src/components/LearnerComponents/DownloadApp";
import RelatedCourses from "src/components/LearnerComponents/Course/RelatedCourses/RelatedCourses";
import CourseContentForVideo from "./CourseContentForVideo";
import { CourseFormat } from "src/enums/CourseFormat";
import CoursePathwayDetail from "src/components/LearnerComponents/UtilityComponents/CoursePathwayDetail";
import NotFoundPage from "src/pages/CommonPages/NotFoundPage";
import { useTranslation } from "react-i18next";
import RestrictAccessByRole from "src/components/Common/authorization/RestrictAccessByRole";
import { setTabTitle } from "src/utils/utils";
import { getCourse } from "src/actions/courseActions/learnerCourseActions";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";

interface Params {
  slug: string;
}

function CourseDescriptionPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const slug = useParams<Params>().slug;
  const course = useSelector(coursesSelector.selectCourseEntityWithSlug(slug));

  const role = useSelector(authSelectors.role);
  const { t } = useTranslation();

  useEffect(() => {
    // if (
    //   course.type == CourseFormat.WEBWATCH ||
    //   course.type == CourseFormat.INFOGRAPHIA
    // ) {
    //   history.push(`/course-content/${course.slug}`);
    // }
    dispatch(getCourse(slug)).then(setLoading.bind(null, false));
    setTabTitle(`${course?.title}`);
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => setTabTitle(`${course?.title}`), [course]);

  return (
    <div className="clearance">
      {loading ? (
        <div
          className="d-flex w-100 justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ height: "3rem", width: "3rem" }}
          ></div>
        </div>
      ) : (
        <>
          {course?.published || role === Role.ADMIN || role === Role.AUTHOR ? (
            <>
              <div>
                <div
                  style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .90), rgba(0, 0, 0, .90)), url("${course?.resources.attachmentUrl}")`,
                  }}
                  className="hero_section"
                >
                  <div className="container p-0">
                    <div className="row py-5">
                      <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="w-lg-75">
                          <HeroComponent loading={loading} course={course} />
                        </div>

                        <div
                          style={{ width: "259px" }}
                          className="d-none d-lg-block"
                        >
                          <SkeletonLoaderLearner
                            loading={loading}
                            rows={1}
                            height={200}
                            width={400}
                            type="box"
                          >
                            <CourseMentorsCarousel
                              mentors={course?.mentorsCourses || []}
                            />
                          </SkeletonLoaderLearner>
                        </div>
                      </div>
                      <div className="row py-2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  {/* --------------------------------------- left col ------------------------------------------------- */}
                  <div className="col-md-12 col-sm-12 col-lg-8">
                    <div className="mt-5">
                      <CoursePathwayDetail
                        title={t("course_description")}
                        contentString={course?.description || ""}
                      />
                    </div>
                    <div className="mt-4">
                      <CoursePathwayDetail
                        title={t("learning_goals")}
                        contentString={course?.objectives || ""}
                      />
                    </div>
                    <div className="mt-4 mb-5">
                      <Certificates course={course} />
                    </div>
                  </div>
                  {/* --------------------------------------- right col ------------------------------------------------- */}
                  <div className="col-md-12 col-sm-12 col-lg-4">
                    <div className="mt-5">
                      {course?.type === CourseFormat.DEFAULT && (
                        <CourseContent modules={course?.coursesModules || []} />
                      )}
                    </div>

                    {course?.type === CourseFormat.VIDEO && (
                      <CourseContentForVideo
                        modules={course?.coursesModules || []}
                      />
                    )}

                    <RestrictAccessByRole
                      role={Role.LEARNER}
                      component={
                        <div className="mt-5">
                          <RelatedCourses />
                        </div>
                      }
                    />
                    <div className="mt-5 mb-5">
                      <DownloadApp />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NotFoundPage />
          )}
        </>
      )}
    </div>
  );
}

export default CourseDescriptionPage;
