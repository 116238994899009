import React from "react";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import SearchTags from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import SearchAuthors from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchAuthors";
import SearchMentors from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchMentors";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";
import FormLabel from "src/components/Common/utilities/FormLabel";

interface Props {
  loading: boolean;
}

function SearchFields({ loading }: Props) {
  const role = useSelector(authSelectors.role);
  return (
    <>
      <FormLabel name="tags" />

      <SkeletonLoader loading={loading} rows={0} type="normal">
        <SearchTags name="tags" />
      </SkeletonLoader>
      {role === Role.ADMIN ? (
        <>
          <FormLabel name="mentors" />
          <SkeletonLoader loading={loading} rows={0} type="normal">
            <SearchMentors name="mentors" />
          </SkeletonLoader>
          <FormLabel name="author" />
          <SkeletonLoader loading={loading} rows={0} type="normal">
            <SearchAuthors visible={true} />
          </SkeletonLoader>
        </>
      ) : null}
    </>
  );
}

export default SearchFields;
