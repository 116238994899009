import { Button, Space } from "antd";
import UploadSlider from "./AdminAuthorMentorComponents/Common/UploadComponents/UploadSlider";
import UploadImage from "./AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";
import UploadVideo from "./AdminAuthorMentorComponents/Common/UploadComponents/UploadVideo";
import UploadAudio from "./AdminAuthorMentorComponents/Common/UploadComponents/UploadAudio";
import UploadDocument from "./AdminAuthorMentorComponents/Common/UploadComponents/UploadDocument";
import { useState } from "react";
import { LessonFormat } from "../enums/LessonFormats";
import TextSection from "./TextSection";
import SpacerSection from "./SpacerSection";
import QuizSection from "./AdminAuthorMentorComponents/Common/Course/CreateCourse/QuizSection";

interface Props {
  done: boolean;
  edit: boolean;
  field?: any;
  index: number;
}

function SectionSelector({ field, done, edit, index }: Props) {
  const [current, setCurrent] = useState("");
  const [visible, setVisible] = useState(true);

  const sectionUploadType = () => {
    if (current === LessonFormat.VIDEO)
      return (
        <UploadVideo
          done={done}
          field={field}
          sectionId={edit ? field.name : field.key}
        />
      );
    else if (current === LessonFormat.IMAGESLIDER)
      return (
        <UploadSlider
          done={done}
          field={field}
          sectionId={edit ? field.name : field.key}
        />
      );
    else if (current === LessonFormat.IMAGE)
      return (
        <UploadImage
          done={done}
          field={field}
          nested={true}
          name="lessonsAttachments"
          sectionId={edit ? field.name : field.key}
        />
      );
    else if (current === LessonFormat.AUDIO)
      return (
        <UploadAudio
          done={done}
          field={field}
          sectionId={edit ? field.name : field.key}
        />
      );
    else if (current === LessonFormat.TEXT)
      return (
        <TextSection
          done={done}
          field={field}
          sectionId={edit ? field.name : field.key}
        />
      );
    else if (current === LessonFormat.SPACER)
      return (
        <SpacerSection
          done={done}
          field={field}
          sectionId={edit ? field.name : field.key}
        />
      );
    // else if (current === LessonFormat.QUIZ)
    //   return (
    //     <QuizSection
    //       field={field}
    //       sectionId={edit ? field.name : field.key}
    //       done={done}
    //     />
    //   );
  };

  return (
    <>
      {visible && (
        <Space>
          {Object.keys(LessonFormat).map((key) => (
            <Button
              onClick={() => {
                setCurrent(key);
                setVisible(false);
              }}
            >
              {key}
            </Button>
          ))}
        </Space>
      )}
      {sectionUploadType()}
    </>
  );
}

export default SectionSelector;
