import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Collapse,
  message,
  Modal,
  Typography,
  Space,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useAppDispatch } from "src/store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import {
  clearCourses,
  coursesSelector,
  editVideoCourse,
} from "src/store/coursesSlices/coursesSlice";
import { Course } from "src/interfaces/Course";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import CourseImage from "../EditCoursePageFormFields/CourseImage";
import SearchFields from "../EditCoursePageFormFields/SearchFields";
import SearchCertificates from "src/components/AdminAuthorMentorComponents/Common/Certificates/SearchCertificateEditVideoCourse";
import BasicFields from "../EditCoursePageFormFields/BasicFields";
import CourseFormatTag from "src/components/AdminAuthorMentorComponents/Common/CourseFormatTag";
import VideoLessons from "./VideoLessons";
import CoursePreviewVideo from "../EditCoursePageFormFields/CoursePreviewVideo";
import FormLabel from "src/components/Common/utilities/FormLabel";
import { useTranslation } from "react-i18next";
import Translation from "src/components/Common/utilities/Translation";
import {
  addLanguageToCourse,
  getSingleCourse,
} from "src/actions/courseActions/courseActions";
import { setTabTitle } from "src/utils/utils";
import { LanguageCode } from "src/enums/Language";
import { getLanguageNameFromLanguageCode } from "src/utils/Rest/translationUtils";
import { Quiz } from "src/interfaces/Quiz";
import AddCourseQuiz from "src/components/AdminAuthorMentorComponents/Common/Course/CourseQuiz/AddCourseQuiz";
import { Certificate } from "../../../interfaces/Certificate";
interface Params {
  courseId: string;
}

function EditVideoCourse() {
  const [courseQuiz, setCourseQuiz] = useState<any>();
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string | null>();
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [certificates, setCertificates] = useState<any>();
  const [quizForm] = useForm();

  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { courseId } = useParams<Params>();
  const { Panel } = Collapse;
  const [form] = useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const translatedToLanguage: LanguageCode = location.pathname
    .split("/")[4]
    ?.split("-")[1] as LanguageCode;
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;

  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );

  const onQuizFormSubmit = (e) => {
    setCourseQuiz(e);
  };

  const onFormSubmit = () => {
    setSubmitLoading(true);
    setDone(true);

    const editedCourse = {
      ...form.getFieldsValue(),
      videoId: form.getFieldsValue().previewVideo,
      courseAttachment: null,
      type: "VIDEO",
      id: parseInt(courseId),
      description: description,
      objectives: objectives,
      authorId: form.getFieldsValue().authorId?.value,
      tags: form.getFieldsValue().tags?.map((item: any) => item.value),
      mentors: form.getFieldsValue().mentors?.map((item: any) => item.value),
      ...courseQuiz,
      quiz:
        courseQuiz.quiz?.map((item, i) => ({
          ...item.extras,
          id: item?.extras?.id ? item.extras.id : "idPla",
          sectionId: i,
          attachmentId: null,
          question: item.question,
          options: item.answer?.map((item) => item.option),
          answers: item.answer
            .filter((item) => item.checked === true)
            .map((item) => item.option),
        })) || [],
      modules: form
        .getFieldsValue()
        ?.coursesModules?.map((module: any, index: number) => ({
          ...module,
          positionId: index,
          id: module.id ? module.id : `anfkanf${index}`,
          coursesVideos: module?.coursesVideos?.map(
            (coursesVideo: any, ind: number) => {
              const item = {
                ...coursesVideo,
                ...coursesVideo.lessonsAttachments,
                id: coursesVideo.id ? coursesVideo.id : `laks${ind}`,
              };
              delete item.lessonsAttachments;
              return item;
            }
          ),
        })),
    };

    delete editedCourse.coursesModules;
    if (!form.getFieldsValue().previewVideo) {
      delete editedCourse.videoId;
    }

    if (!isTranslationPath) {
      dispatch(editVideoCourse(editedCourse))
        .then(unwrapResult)
        .then(() => {
          role === Role.ADMIN
            ? history.push("/admin/courses")
            : history.push("/author");
          setSubmitLoading(false);

          // dispatch(clearCourses()).then(() => {
          //   role === Role.ADMIN
          //     ? history.push("/admin/courses")
          //     : history.push("/author");
          //   setSubmitLoading(false);
          // });
        })
        .catch(() => setSubmitLoading(true));
    } else {
      delete editedCourse.mentors;
      delete editedCourse.authorId;
      delete editedCourse.tags;
      dispatch(
        addLanguageToCourse({
          ...editedCourse,
          completed: true,
          language: [location.pathname.split("/")[4].split("-")[1]],
        })
      ).then(() => {
        dispatch(clearCourses()).then(() => {
          role === Role.ADMIN
            ? history.push("/admin/courses")
            : history.push("/author");
          setSubmitLoading(false);
        });
      });
    }
  };

  const onSave = () => {
    setSaveDraftLoading(true);
    dispatch(
      addLanguageToCourse({
        title: form.getFieldsValue().title,
        language: [location.pathname.split("/")[4].split("-")[1]],
        id: parseInt(courseId),
        description: description,
        objectives: objectives,
        modules: form
          .getFieldsValue()
          ?.coursesModules?.map((module: any, index: number) => ({
            ...module,
            positionId: index,
            id: module.id ? module.id : `anfkanf${index}`,
            coursesVideos: module?.coursesVideos?.map(
              (coursesVideo: any, ind: number) => {
                const item = {
                  ...coursesVideo,
                  ...coursesVideo.lessonsAttachments,
                  id: coursesVideo.id ? coursesVideo.id : `laks${ind}`,
                };
                delete item.lessonsAttachments;
                return item;
              }
            ),
          })),
      })
    ).then(() => {
      setSaveDraftLoading(false);
      message.success({
        content: `Saved`,
      });
    });
  };

  const onFinishFailed = (e) => {
    message.warning({
      content: "Please check for missing fields in the form",
    });
  };
  const cardHeader = (
    <Row justify="space-between" align="middle">
      <Typography.Title level={5}>
        {isTranslationPath
          ? getLanguageNameFromLanguageCode(translatedToLanguage)
          : "Edit Course"}
      </Typography.Title>
      {course && (
        <Space>
          {isTranslationPath ? (
            <Button type="primary" onClick={onSave} loading={saveDraftLoading}>
              Save Draft
            </Button>
          ) : (
            <CourseFormatTag course={course} />
          )}
        </Space>
      )}
    </Row>
  );
  useEffect(() => {
    dispatch(getSingleCourse(parseInt(courseId)))
      .then(unwrapResult)
      .then((res: Course) => {
        console.log("res", res);
        setTabTitle(res.title);
        const lang = location.pathname?.split("/")[4]?.split("-")[1];
        const course = isTranslationPath
          ? res.languages.find((course: Course) => course.language[0] === lang)
          : res;
        if (!course) return;
        if (!isTranslationPath) {
          setVideoUrl(course?.videos?.attachmentUrl);
          setImageUrl(course.resources.attachmentUrl);
          form.setFieldsValue({
            authorId: {
              label: `${course.users.firstName} ${course.users.lastName}`,
              value: course.users.id,
            },
          });
        }
        setLoading(false);
        setDescription(course.description);
        setObjectives(course.objectives);
        setCourseQuiz({
          quizTitle: course.title,
          attempts: course.attempts,
          passing: course.passing,
          quiz: course.coursesQuiz?.map((quiz: Quiz) => ({
            extras: { id: quiz.id, weightage: quiz.weightage },
            question: quiz.question,
            answer: quiz.options.map((option) => ({
              option: option,
              checked: quiz.answers.find((ans) => ans === option)
                ? true
                : false,
            })),
          })),
        });
        setCertificates(res.certificate);

        form.setFieldsValue({
          title: course.title,
          excerpt: course.excerpt,
          tags: course.coursesTags?.map((item: any) => ({
            label: item.tags.value,
            value: item.tags.id,
          })),
          mentors: course.mentorsCourses?.map((item: any) => ({
            label: `${item.users.firstName} ${item.users.lastName}`,
            value: item.users.id,
          })),
          coursesModules:
            course[isTranslationPath ? "modules" : "coursesModules"],
        });
      });
  }, [location.pathname]);

  return (
    <Row>
      <Col sm={24}>
        <Card title={cardHeader}>
          <Form
            name="basic"
            form={form}
            onFinish={onFormSubmit}
            scrollToFirstError
            onFinishFailed={onFinishFailed}
          >
            <BasicFields loading={loading} />
            <FormLabel name="description" />
            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setDescription(value)}
                  value={description}
                />
              </Form.Item>
            </SkeletonLoader>{" "}
            <FormLabel name="objectives" />
            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setObjectives(value)}
                  value={objectives}
                />
              </Form.Item>
            </SkeletonLoader>
            {!isTranslationPath && (
              <>
                <CourseImage done={done} imageUrl={imageUrl} />
                <CoursePreviewVideo done={done} videoUrl={videoUrl} />
                <SearchFields loading={loading} />{" "}
              </>
            )}
            <>
              <FormLabel name="Course Quiz" />
              <AddCourseQuiz
                onFormSubmit={onQuizFormSubmit}
                initialValue={courseQuiz}
              />
              <FormLabel name="Add Certificate" />

              {certificates && (
                <SearchCertificates certificate={certificates} />
              )}
            </>
            <FormLabel name="course_content" />
            <Form.List name="coursesModules">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Collapse accordion>
                      {fields.map((field, index) => (
                        <Panel
                          header={
                            <Row>
                              <Col sm={20}>
                                <Form.Item
                                  style={{ marginBottom: "0px" }}
                                  {...field}
                                  name={[field.name, "title"]}
                                  fieldKey={[field.fieldKey, "title"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Module Title Missing",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              {!isTranslationPath && (
                                <Col sm={4}>
                                  <Row justify="end">
                                    <Button
                                      onClick={() =>
                                        Modal.confirm({
                                          content: `${t(
                                            "confirm.delete_module"
                                          )}`,
                                          onOk: () => remove(field.name),
                                        })
                                      }
                                      danger
                                    >
                                      <Translation input="remove_module" />
                                    </Button>
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          }
                          key={index}
                          forceRender={true}
                        >
                          <VideoLessons
                            fieldName={field.name}
                            form={form}
                            field={field}
                            done={done}
                            moduleId={
                              form.getFieldsValue()?.coursesModules[field.name]
                                ?.id
                            }
                          />
                        </Panel>
                      ))}
                    </Collapse>
                    {!isTranslationPath && (
                      <Row justify="center">
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ marginTop: "10px" }}
                        >
                          <Translation input="add_module" />
                        </Button>
                      </Row>
                    )}
                  </>
                );
              }}
            </Form.List>
            <Row justify="end" style={{ marginTop: "20px" }}>
              <Button type="primary" htmlType="submit" loading={submitLoading}>
                <Translation input="submit" />
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default EditVideoCourse;

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// import {
//   Button,
//   Form,
//   Input,
//   Row,
//   Col,
//   Card,
//   PageHeader,
//   Collapse,
//   Modal,
//   Upload,
//   message,
// } from "antd";
// import { useForm } from "antd/lib/form/Form";
// import { useHistory } from "react-router-dom";
// import ReactQuill from "react-quill";
// import { useAppDispatch } from "src/store/store";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { authSelectors } from "src/store/authSlice";
// import { Role } from "src/interfaces/User";
// import { coursesSelector, editVideoCourse } from "src/store/coursesSlice";
// import { getCourse } from "src/store/learnerSlices/learnerCoursesSlice";
// import { Course, CourseVideo } from "src/interfaces/Course";
// import { Module } from "src/interfaces/Module";
// import { getFileExtention } from "src/utils/utils";
// import SkeletonLoader from "src/components/SkeletonLoader";
// import { uploadVideo } from "src/api/mediaUploadApi";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import CourseImage from "../EditCoursePageFormFields/CourseImage";
// import SearchFields from "../EditCoursePageFormFields/SearchFields";
// import BasicFields from "../EditCoursePageFormFields/BasicFields";
// import { moduleTitleChange } from "../EditDefaultCourse/EditCourseUtils/ChangeTitle";
// import {
//   lessonDrag,
//   sectionDrag,
// } from "../EditDefaultCourse/EditCourseUtils/OnDrag";
// import { apiCall } from "src/utils/utils";
// import CourseFormatTag from "src/components/AdminAuthorMentorComponents/Common/CourseFormatTag";
// import VideoPlayer from "src/components/VideoPlayer";
// import {
//   addDeleteVideo,
//   addVideo,
//   videoTitleChange,
//   addDeleteModule,
// } from "./EditVideoCourseUtils/EditVideoCourseUtils";

// interface Params {
//   courseId: string;
// }
// function EditVideoCourse() {
//   const [description, setDescription] = useState("");
//   const [objectives, setObjectives] = useState("");
//   const [submitLoading, setSubmitLoading] = useState(false);
//   const [imageUrl, setImageUrl] = useState("");
//   const [courseDetails, setCourseDetails]: any = useState<Course>();
//   const [done, setDone] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [disableSubmit, setDisableSubmit] = useState(false);
//   const role = useSelector(authSelectors.role);
//   const dispatch = useAppDispatch();
//   const history = useHistory();
//   const { courseId } = useParams<Params>();
//   const { Panel } = Collapse;
//   const [form] = useForm();

//   const course = useSelector(
//     coursesSelector.selectCourseEntity(parseInt(courseId))
//   );

//   const onFormSubmit = () => {
//     setSubmitLoading(true);
//     setDone(true);

//     const editedCourse = {
//       ...form.getFieldsValue(),
//       courseAttachment:
//         form.getFieldsValue().courseAttachment ||
//         courseDetails.courseAttachment,
//       id: parseInt(courseId),
//       description: description,
//       objectives: objectives,
//       authorId: form.getFieldsValue().authorId?.value,
//       tags: form.getFieldsValue().tags?.map((item: any) => item.value),
//       mentors: form.getFieldsValue().mentors?.map((item: any) => item.value),
//       modules: courseDetails?.coursesModules?.map(
//         (module: Module, index: number) => {
//           return {
//             ...module,
//             positionId: index,
//           };
//         }
//       ),
//     };

//     dispatch(editVideoCourse(editedCourse))
//       .then(unwrapResult)
//       .then(() => {
//         role === Role.ADMIN
//           ? history.push("/admin/courses")
//           : history.push("/author");
//         setSubmitLoading(false);
//       })
//       .catch(() => {
//         setSubmitLoading(true);
//       });
//   };

//   const onLessonDragHandler = (result: any, moduleId: number) => {
//     if (!result.destination) return;
//     setCourseDetails(lessonDrag(courseDetails, result, moduleId));
//   };

//   const onModuleDrag = (result: any) => {
//     if (!result.destination) return;
//     let temp = [...courseDetails.coursesModules];
//     const [reorderedItem] = temp.splice(result.source.index, 1);
//     temp.splice(result.destination.index, 0, reorderedItem);
//     setCourseDetails({
//       ...courseDetails,
//       coursesModules: temp,
//     });
//   };

//   const customRequest = async (
//     e: any,
//     moduleId: number | string,
//     videoId: number | string
//   ) => {
//     const fileList = e.file;
//     console.log(fileList);
//     if (fileList) {
//       const formData = new FormData();
//       formData.append("file", fileList);
//       const ext = getFileExtention(e.file.name);
//       setDisableSubmit(true);
//       const [data, error] = await apiCall(uploadVideo(formData));

//       setDisableSubmit(false);
//       if (data) {
//         console.log(addVideo(courseDetails, moduleId, videoId, data.id));
//         setCourseDetails(addVideo(courseDetails, moduleId, videoId, data.id));

//         message.success("Upload successfull");
//       }
//       if (error) message.error("Error Uploading");
//     }
//   };

//   const moduleVideoRender = (moduleId: number, video: CourseVideo) => {
//     if (video.resources) {
//       return <VideoPlayer url={video.resources.attachmentUrl} />;
//     } else
//       return (
//         <Upload
//           name="test"
//           listType="picture-card"
//           className="avatar-uploader"
//           showUploadList={false}
//           customRequest={(e) => {
//             customRequest(e, moduleId, video.id);
//           }}
//           multiple={false}
//           // fileList={fileListArray}
//           //   onRemove={removeHandler}
//         >
//           + ADD Video
//         </Upload>
//       );
//   };

//   useEffect(() => {
//     if (!courseDetails) {
//       dispatch(getCourse(parseInt(courseId)))
//         .then(unwrapResult)
//         .then((course: Course) => {
//           document.title = `Learn Sure . ${course.title}`;
//           setCourseDetails({ ...course });
//           setLoading(false);
//           setDescription(course.description);
//           setObjectives(course.objectives);
//           setImageUrl(course.resources.attachmentUrl);
//           form.setFieldsValue({
//             title: course.title,
//             excerpt: course.excerpt,
//             tags: course.coursesTags?.map((item: any) => {
//               return { label: item.tags.value, value: item.tags.id };
//             }),
//             mentors: course.mentorsCourses?.map((item: any) => {
//               return {
//                 label: `${item.users.firstName} ${item.users.lastName}`,
//                 value: item.users.id,
//               };
//             }),
//             authorId: {
//               label: `${course.users.firstName} ${course.users.lastName}`,
//               value: course.users.id,
//             },
//           });
//         });
//     }
//   }, [course]);

//   return (
//     <div>
//       <Col sm={24}>
//         <PageHeader
//           title="Edit Course"
//           onBack={() => {
//             history.goBack();
//           }}
//           extra={[course ? <CourseFormatTag course={course} /> : null]}
//         />
//       </Col>

//       <Row>
//         <Col sm={24}>
//           <Card hoverable={true}>
//             <Form name="basic" form={form} onFinish={onFormSubmit}>
//               <div>
//                 <BasicFields loading={loading} />
//                 {/* --------- Description --------- */}
//                 <h6 className="form_label">Description</h6>
//                 <SkeletonLoader loading={loading} rows={5} type="normal">
//                   <Form.Item>
//                     <ReactQuill
//                       onChange={(value) => {
//                         setDescription(value);
//                       }}
//                       value={description}
//                     />
//                   </Form.Item>
//                 </SkeletonLoader>
//                 {/* --------- Objectives --------- */}
//                 <h6 className="form_label">Objectives</h6>
//                 <SkeletonLoader loading={loading} rows={5} type="normal">
//                   <Form.Item>
//                     <ReactQuill
//                       onChange={(value) => {
//                         setObjectives(value);
//                       }}
//                       value={objectives}
//                     />
//                   </Form.Item>
//                 </SkeletonLoader>
//                 {/* ------------Course Image Upload ----------- */}
//                 <CourseImage done={done} imageUrl={imageUrl} />
//                 {/* ------------Search Tags -  Search Mentors - Search Authors ----------- */}
//                 <SearchFields loading={loading} />
//               </div>
//               <div style={{ marginBottom: "10px" }}>
//                 <div>
//                   <h6 className="form_label">Modules</h6>

//                   <DragDropContext onDragEnd={onModuleDrag}>
//                     <Droppable droppableId="editModulesDrag">
//                       {(provided) => (
//                         <div
//                           {...provided.droppableProps}
//                           ref={provided.innerRef}
//                         >
//                           {courseDetails?.coursesModules?.map(
//                             (module: Module, index: number) => {
//                               return (
//                                 <Draggable
//                                   key={module.id}
//                                   draggableId={`${module?.id}`}
//                                   index={index}
//                                 >
//                                   {(provided) => (
//                                     <div
//                                       {...provided.draggableProps}
//                                       {...provided.dragHandleProps}
//                                       ref={provided.innerRef}
//                                     >
//                                       <Collapse
//                                         style={{
//                                           backgroundColor: "#f0f0f0",
//                                         }}
//                                       >
//                                         <Panel
//                                           header={
//                                             <div style={{ height: "20px" }}>
//                                               {module.title}
//                                             </div>
//                                           }
//                                           key={module.id}
//                                         >
//                                           <div
//                                             style={{
//                                               display: "flex",
//                                               marginBottom: "10px",
//                                             }}
//                                           >
//                                             <Input
//                                               onChange={(e) => {
//                                                 setCourseDetails(
//                                                   moduleTitleChange(
//                                                     courseDetails,
//                                                     module.id,
//                                                     e.target.value
//                                                   )
//                                                 );
//                                               }}
//                                               value={module.title}
//                                             />
//                                             <Button
//                                               danger
//                                               onClick={() => {
//                                                 Modal.confirm({
//                                                   content:
//                                                     "Are you sure you want to DELETE this module",
//                                                   onOk: () => {
//                                                     setCourseDetails(
//                                                       addDeleteModule(
//                                                         courseDetails,
//                                                         false,
//                                                         module.id
//                                                       )
//                                                     );
//                                                   },
//                                                 });
//                                               }}
//                                               style={{
//                                                 marginLeft: "15px",
//                                               }}
//                                             >
//                                               Delete Module
//                                             </Button>
//                                           </div>

//                                           <DragDropContext
//                                             onDragEnd={(e) => {
//                                               onLessonDragHandler(e, module.id);
//                                             }}
//                                           >
//                                             <Droppable droppableId="createCourseLessons">
//                                               {(provided) => (
//                                                 <div
//                                                   {...provided.droppableProps}
//                                                   ref={provided.innerRef}
//                                                 >
//                                                   {module?.coursesVideos?.map(
//                                                     (
//                                                       video: CourseVideo,
//                                                       index: number
//                                                     ) => {
//                                                       return (
//                                                         <Draggable
//                                                           key={video?.id}
//                                                           draggableId={`${video?.id}`}
//                                                           index={index}
//                                                         >
//                                                           {(provided) => (
//                                                             <div
//                                                               {...provided.draggableProps}
//                                                               {...provided.dragHandleProps}
//                                                               ref={
//                                                                 provided.innerRef
//                                                               }
//                                                             >
//                                                               <Collapse
//                                                                 style={{
//                                                                   backgroundColor:
//                                                                     "#fafafa",
//                                                                 }}
//                                                               >
//                                                                 <Panel
//                                                                   header={
//                                                                     <div
//                                                                       style={{
//                                                                         height:
//                                                                           "20px",
//                                                                       }}
//                                                                     >
//                                                                       {
//                                                                         video.title
//                                                                       }
//                                                                     </div>
//                                                                   }
//                                                                   key={video.id}
//                                                                 >
//                                                                   <div
//                                                                     style={{
//                                                                       display:
//                                                                         "flex",
//                                                                       marginBottom:
//                                                                         "10px",
//                                                                     }}
//                                                                   >
//                                                                     <Input
//                                                                       onChange={(
//                                                                         e
//                                                                       ) => {
//                                                                         setCourseDetails(
//                                                                           videoTitleChange(
//                                                                             courseDetails,
//                                                                             module.id,
//                                                                             video.id,
//                                                                             e
//                                                                               .target
//                                                                               .value
//                                                                           )
//                                                                         );
//                                                                         console.log(
//                                                                           videoTitleChange(
//                                                                             courseDetails,
//                                                                             module.id,
//                                                                             video.id,
//                                                                             e
//                                                                               .target
//                                                                               .value
//                                                                           )
//                                                                         );
//                                                                       }}
//                                                                       value={
//                                                                         video.title
//                                                                       }
//                                                                     />
//                                                                     <Button
//                                                                       danger
//                                                                       onClick={() => {
//                                                                         Modal.confirm(
//                                                                           {
//                                                                             content:
//                                                                               "Are you sure you want to DELETE this lesson",
//                                                                             onOk: () => {
//                                                                               setCourseDetails(
//                                                                                 addDeleteVideo(
//                                                                                   courseDetails,
//                                                                                   false,
//                                                                                   module.id,
//                                                                                   video.id
//                                                                                 )
//                                                                               );
//                                                                             },
//                                                                           }
//                                                                         );
//                                                                       }}
//                                                                       style={{
//                                                                         marginLeft:
//                                                                           "15px",
//                                                                       }}
//                                                                     >
//                                                                       Delete
//                                                                       Video
//                                                                     </Button>
//                                                                   </div>
//                                                                   <div>
//                                                                     {moduleVideoRender(
//                                                                       module.id,
//                                                                       video
//                                                                     )}
//                                                                   </div>
//                                                                 </Panel>
//                                                               </Collapse>
//                                                             </div>
//                                                           )}
//                                                         </Draggable>
//                                                       );
//                                                     }
//                                                   )}
//                                                   {provided.placeholder}
//                                                 </div>
//                                               )}
//                                             </Droppable>
//                                           </DragDropContext>
//                                           <div
//                                             style={{
//                                               width: "100%",
//                                               display: "flex",
//                                               flexDirection: "row",
//                                               justifyContent: "center",
//                                               marginTop: "10px",
//                                             }}
//                                           >
//                                             <Button
//                                               type="primary"
//                                               onClick={() => {
//                                                 setCourseDetails(
//                                                   addDeleteVideo(
//                                                     courseDetails,
//                                                     true,
//                                                     module.id
//                                                   )
//                                                 );
//                                               }}
//                                               shape="round"
//                                             >
//                                               + Add Video
//                                             </Button>
//                                           </div>
//                                         </Panel>
//                                       </Collapse>
//                                     </div>
//                                   )}
//                                 </Draggable>
//                               );
//                             }
//                           )}
//                           {provided.placeholder}
//                         </div>
//                       )}
//                     </Droppable>
//                   </DragDropContext>

//                   <Row
//                     align="middle"
//                     justify="center"
//                     style={{ marginTop: "10px" }}
//                   >
//                     <Button
//                       type="primary"
//                       onClick={() => {
//                         console.log(addDeleteModule(courseDetails, true));

//                         setCourseDetails(addDeleteModule(courseDetails, true));
//                       }}
//                       shape="round"
//                     >
//                       + Add Module
//                     </Button>
//                   </Row>
//                 </div>

//                 <Row justify="end">
//                   <Form.Item>
//                     <Button
//                       type="primary"
//                       htmlType="submit"
//                       loading={submitLoading}
//                       disabled={disableSubmit}
//                     >
//                       Submit
//                     </Button>
//                   </Form.Item>
//                 </Row>
//               </div>
//             </Form>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default EditVideoCourse;
