import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { searchCoursePathway } from "src/api/searchActions";
import Spinner from "src/components/LearnerComponents/UtilityComponents/Spinner";
import { Course } from "src/interfaces/Course";
import { PathWay } from "src/interfaces/PathWay";
import { useAppDispatch } from "src/store/store";
import SearchCard from "./SearchCard";

interface Params {
  query: string;
}
function SearchResultsPage() {
  const dispatch = useAppDispatch();
  const { query } = useParams<Params>();
  const [searchResults, setSearchResults] = useState<Course[] & PathWay[]>();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    dispatch(searchCoursePathway(query))
      .then(unwrapResult)
      .then((result) => {
        setSearchResults(result);
        setLoading(false);
      });
  }, []);

  return (
    <div className="clearance container pt-5">
      <div className="row mb-3">
        <div className="col d-flex flex-row">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => history.goBack()}
          ></button>
          <h5 className="m-0 mx-2">Search results for : {query} </h5>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {!searchResults?.length ? (
            <div className="text-muted">No results found</div>
          ) : null}
          <div className="list-group">
            {searchResults?.map((courseOrPathway: any) => (
              <SearchCard
                courseOrPathway={courseOrPathway}
                isCourse={courseOrPathway.type ? true : false}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default SearchResultsPage;
