import parse from "html-react-parser";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Translation from "./Translation";
interface Props {
  inputString: string;
  maxChars: number;
}
function TextHeightWrapper({ inputString, maxChars }: Props) {
  const [viewMore, setViewMore] = useState(false);
  const location = useLocation();
  return (
    <div className="description">
      {inputString?.length < maxChars ||
      location.pathname.split("/")[2] === "translation" ? (
        parse(inputString || "")
      ) : (
        <div>
          {viewMore
            ? parse(inputString || "")
            : parse(inputString?.substring(0, maxChars) || "")}
          <div
            style={{
              color: "blue",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={() => setViewMore((s) => !s)}
          >
            <Translation input={viewMore ? "view_less" : "view_more"} />
          </div>
        </div>
      )}
    </div>
  );
}
export default TextHeightWrapper;
