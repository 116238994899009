import NavUserIcon from "./NavUserIcon";
import NavNotificationIcon from "./NavNotificationIcon/NavNotificationIcon";
import NavLanguageIcon from "./NavLanguageIcon";
import NavMyLearningIcon from "./NavMyLearningIcon";
import NavHomeIcon from "./NavHomeIcon";

function NavIcons() {
  return (
    <ul className="navbar-nav ml-auto py-md-3 py-sm-3 py-lg-0">
      <NavHomeIcon />
      <NavMyLearningIcon />
      <NavNotificationIcon />
      <NavLanguageIcon />
      <NavUserIcon />
    </ul>
  );
}

export default NavIcons;
