import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState, useRef } from "react";
import PathwayCarouselCard from "./PathwayCarouselCard";
import { useAppDispatch } from "src/store/store";
import { pathwaysSelectors } from "src/store/pathways/pathwaysSlice";
import SkeletonLoaderLearner from "../../UtilityComponents/SkeletonLoaderLearner";
import { useSelector } from "react-redux";
import { PathWay } from "src/interfaces/PathWay";
import Loader from "./Loader";
import { getLearnerPathway } from "src/actions/PathwayActions/LearnerPathwayActions";
import NextSvg from "../../../../Assets/svgs/Next";
import PrevSvg from "../../../../Assets/svgs/Prev";
import "./pathwayCarousel.css";

function PathwayCarousel() {
  const pathways = useSelector(pathwaysSelectors.selectAllPathways());
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [slideChanged, setSlideChanged] = useState(0);

  const onChangeHandler = () => setSlideChanged((current) => current + 1);

  useEffect(() => {
    if (pathways.length !== 0 && pathways.length !== 1) setLoading(false);
    else dispatch(getLearnerPathway(0)).then(setLoading.bind(null, false));
  }, []);

  return (
    <div className="mb-5" dir="ltr">
      <SkeletonLoaderLearner
        loading={loading}
        rows={1}
        height={300}
        type="normal"
      >
        {pathways.length !== 0 && (
          <div className="parent">
            <div className="gallery-carousel">
              <Carousel
                onChange={onChangeHandler}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                autoPlay={true}
                interval={6000}
                infiniteLoop={true}
                showIndicators={false}
                renderArrowPrev={(clickHandler) => {
                  return (
                    <div
                      className="prevArrow"
                      onClick={() => {
                        clickHandler();
                      }}
                    >
                      <PrevSvg />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler) => {
                  return (
                    <div
                      className="nextArrow"
                      onClick={() => {
                        clickHandler();
                      }}
                    >
                      <NextSvg />
                    </div>
                  );
                }}
              >
                {pathways?.slice(0, 4)?.map((pathway: PathWay) => (
                  <PathwayCarouselCard pathway={pathway} key={pathway.id} />
                ))}
              </Carousel>
            </div>

            <Loader trigger={slideChanged} />
          </div>
        )}
      </SkeletonLoaderLearner>
    </div>
  );
}

export default PathwayCarousel;
