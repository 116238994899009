import { LessonsAttachment } from "../../../interfaces/Lesson";
import { Carousel } from "react-responsive-carousel";

interface ImageSliderPropsI {
  lessonsAttachments: LessonsAttachment[];
}

function ImageSlider({ lessonsAttachments }: ImageSliderPropsI) {
  return (
    <Carousel autoPlay dynamicHeight>
      {lessonsAttachments?.map((lessonsAttachment: LessonsAttachment) => (
        <img
          src={lessonsAttachment?.resources?.attachmentUrl}
          alt="image"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      ))}
    </Carousel>
  );
}

export default ImageSlider;
