import { useEffect, useRef, useState } from "react";

interface Props {
  trigger: number;
}
function Loader({ trigger }: Props) {
  const [p, setp] = useState(100);
  // const [animate, setAnimate] = useState(true);
  const ref = useRef(false);
  // const [trigger, setTrigger] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTrigger((o) => o + 1);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }

    setp((p) => (p == 100 ? 0 : 100));
  }, [trigger]);

  return (
    <div>
      <div
        className="progress"
        style={{
          height: "3px",
          ...(p === 0 && { height: 0 }),
        }}
      >
        <div
          style={{
            width: `${p}%`,
            transition: "width 6s ease",
          }}
          className="progress-bar"
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>

      <div
        className="progress"
        style={{
          height: "3px",

          ...(p === 100 && { height: 0 }),
        }}
      >
        <div
          style={{
            width: `${100 - p}%`,
            transition: "width 6s ease",
          }}
          className="progress-bar"
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    </div>
  );
}

export default Loader;
