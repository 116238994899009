import { useTranslation } from "react-i18next";

function DownloadApp() {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mb-3 fw-bold">{t("available_on_apps")}</h5>
      <div className="">
        <div className="d-flex">
          <div className="w-30 mr-1 cursor-pointer">
            <a>
              <img
                style={{ width: "140px", height: "auto" }}
                src="/images/google-play-btn.png"
                alt="google play button"
                onClick={() =>
                  (window.location.href =
                    "https://play.google.com/store/apps/details?id=com.learnsure.ai")
                }
              />
            </a>
          </div>
          &nbsp;
          <div className="w-30">
            <a>
              <img
                style={{ width: "140px", height: "auto" }}
                src="/images/app-store-btn.png"
                alt="apple store button"
                onClick={() =>
                  (window.location.href =
                    "https://apps.apple.com/in/app/learnsure/id1581361239")
                }
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadApp;
