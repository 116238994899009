import { Modal, Button, Space, Popover } from "antd";
import { Course } from "src/interfaces/Course";
import { PathWay } from "src/interfaces/PathWay";
import { useAppDispatch } from "src/store/store";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import socketClient from "socket.io-client";
import { useRef } from "react";
import { baseUrl } from "src/api/api";
import Translation from "src/components/Common/utilities/Translation";
import { getPathways } from "src/actions/PathwayActions/pathwayActions";
import { Role } from "src/interfaces/User";
import RestrictAccessByRole from "src/components/Common/authorization/RestrictAccessByRole";

interface Props {
  pathwayOrCourse: Course | PathWay;
  approveFalse: any;
  approveTrue: any;
  publishTrue: any;
  publishFalse: any;
}

function ActionButtons({
  pathwayOrCourse,
  approveFalse,
  approveTrue,
  publishTrue,
  publishFalse,
}: Props) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const role = useSelector(authSelectors.role);

  // const SERVER = "http://localhost:4000";
  // const SERVER = baseUrl;
  // var connectionOptions: any = {
  //   forceNew: true,
  //   reconnectionAttempts: "Infinity",
  //   timeout: 10000,
  //   transports: ["websocket"],
  // };

  // const socketRef: any = useRef();
  // socketRef.current = socketClient(SERVER, connectionOptions);

  // const call = () => {
  //   socketRef.current.emit("courseNotification", [
  //     { name: "akash", message: "hello" },
  //     { name: "sriharsh", message: "hello" },
  //   ]);
  // };

  const buttonReturnFunction = (inputData: Course | PathWay) => {
    if (inputData.approved === null) {
      return (
        <RestrictAccessByRole
          role={Role.ADMIN}
          component={
            <Space size="middle">
              <Popover content={<Translation input="disapprove" />}>
                <Button
                  size="small"
                  shape="circle"
                  icon={<CloseOutlined />}
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      content: "Are you sure you want to Disapprove",
                      onOk: () => dispatch(approveFalse),
                    });
                  }}
                  danger
                />
              </Popover>
              <Popover content={<Translation input="approve" />}>
                <Button
                  size="small"
                  shape="circle"
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      content: "Are you sure you want to Approve",
                      onOk: () => dispatch(approveTrue),
                    });
                  }}
                />
              </Popover>
            </Space>
          }
        />
      );
    } else if (inputData.published === true) {
      return (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            Modal.confirm({
              content: "Are you sure you want to unpublish the course",
              onOk: () => {
                dispatch(publishFalse).then(() => {
                  if (location.pathname === "/admin/courses") {
                    dispatch(getPathways({ page: 0 }));
                  }
                });
              },
            });
          }}
          danger
        >
          <Translation input="unpublish" />
        </Button>
      );
    } else if (inputData.approved === true) {
      return (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            dispatch(publishTrue)
              .then(unwrapResult)
              .then((res: any) => {
                // socketRef.current.emit("courseNotification", res.notifications);

                if (location.pathname === "/admin/courses") {
                  dispatch(getPathways({ page: 0 }));
                }
              });
          }}
        >
          <Translation input="publish" />
        </Button>
      );
    }
  };

  return <div>{buttonReturnFunction(pathwayOrCourse)}</div>;
}

export default ActionButtons;
