import { useEffect, useState } from "react";
import { Template } from "src/interfaces/Template";
import { useAppDispatch } from "src/store/store";
import { getCertificatesTemplates } from "src/actions/certificateActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Row, Spin, Image, Card, Button } from "antd";
import { FaCheckCircle } from "react-icons/fa";
interface Props {
  onTemplateClick: (template: Template) => void;
}

function TemplateGrid({ onTemplateClick }: Props) {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [templates, setTemplates] = useState<Template[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();

  const getCardSelectionBorrder = (inputTemplate: Template) =>
    // inputTemplate.id === selectedTemplate?.id ? "1px solid #002766" : "none";
    inputTemplate.id === selectedTemplate?.id ? (
      <FaCheckCircle
        style={{
          fontSize: "300%",
          color: "green",
        }}
      />
    ) : (
      <></>
    );

  useEffect(() => {
    dispatch(getCertificatesTemplates())
      .then(unwrapResult)
      .then((res: Template[]) => {
        setLoading(false);
        setTemplates(res);
      });
  }, []);

  return loading ? (
    <Col span={24}>
      <Row justify="center">
        <Spin />
      </Row>
    </Col>
  ) : (
    <Col span={24}>
      <Row justify="center">
        <Col span={22}>
          <Row gutter={[24, 24]}>
            {templates?.map((template: Template) => (
              <Col span={6}>
                <Card
                  bodyStyle={{ padding: "0px" }}
                  style={{
                    padding: "0px !important",
                    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                    position: "relative",
                    // backgroundColor: "#f0f0f0",
                    // border: getCardSelectionBorrder(template),
                  }}
                  hoverable={true}
                >
                  <Image
                    src={template.resources.attachmentUrl}
                    // preview={false}
                  />
                  <div
                    style={{ position: "absolute", top: "35%", left: "43%" }}
                  >
                    {getCardSelectionBorrder(template)}
                  </div>

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ padding: "4px" }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        margin: 4,
                      }}
                    >
                      {template.title}
                    </p>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        onTemplateClick(template);
                        setSelectedTemplate(template);
                      }}
                    >
                      Add
                    </Button>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default TemplateGrid;
