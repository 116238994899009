import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { authSlice } from "../store/authSlice";
import { popularCoursesSlice } from "./learnerSlices/popularCourses";
import { coursesSlice } from "./coursesSlices/coursesSlice";
import { usersSlice } from "./users/userSlice";
import { usersPaginationSlice } from "src/store/users/userPaginationSlice";
import { uiSlice } from "../store/uiSlice";
import { pathwaysSlice } from "./pathways/pathwaysSlice";
import { pathwaysPaginationSlice } from "src/store/pathways/pathwaysPaginationSlice";
import { sessionsSlice } from "./mentorSlices/sessionsSlices/sessionsSlice";
import { learnerSessionRequestsSlice } from "./learnerSlices/LearnerSessionRequestsSlice";
import { sessionRequestsSlice } from "./mentorSlices/sessionRequestsSlice";
import { learnerSessionsSlice } from "src/store/learnerSlices/learnerSessionSlice";
import { notificationsSlice } from "src/store/notificationsSlice/notificationSlice";
import { analyticsSlice } from "src/store/analyticSlice";
import { sessionsPaginationSlice } from "src/store/mentorSlices/sessionsSlices/sessionsPaginationSlice";
import { continueLearningPaginationSlice } from "./coursesSlices/coursePaginations/continueLearningPaginationSlice";
import { coursesPaginationSlice } from "./coursesSlices/coursePaginations/coursesPaginationSlice";
import { popularCoursesPaginationSlice } from "./coursesSlices/coursePaginations/popularCoursesPagination";
import { videoCoursesPaginationSlice } from "./coursesSlices/coursePaginations/videoCoursesPaginationSlice";
import { ScromXapiCoursesPaginationSlice } from "./coursesSlices/coursePaginations/ScromXapiCoursesPaginationSlice";
import { certificatesSlice } from "./certificatesSlices/certificatesSlice";
import { editCourseSlice } from "./coursesSlices/editCourseSlice";
import { notificationsPaginationSlice } from "./notificationsSlice/notificationsPaginationSlice";
import { webwatchCoursesPaginationSlice } from "./coursesSlices/coursePaginations/webwatchCoursePagginationSlice";
import { infographiaCoursesPaginationSlice } from "./coursesSlices/coursePaginations/infographiaCoursePagginationSlice";
import { webwatchAndInfographiaPaginationSlice } from "./coursesSlices/coursePaginations/webwatchAndInfographiaSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    courses: coursesSlice.reducer,
    users: usersSlice.reducer,
    usersPagination: usersPaginationSlice.reducer,
    uiSlice: uiSlice.reducer,
    pathways: pathwaysSlice.reducer,
    pathwaysPagination: pathwaysPaginationSlice.reducer,
    popularCourses: popularCoursesSlice.reducer,
    sessions: sessionsSlice.reducer,
    sessionsPagination: sessionsPaginationSlice.reducer,
    learnerSessionRequests: learnerSessionRequestsSlice.reducer,
    sessionRequests: sessionRequestsSlice.reducer,
    learnerSessions: learnerSessionsSlice.reducer,
    notifications: notificationsSlice.reducer,
    notificationsPagination: notificationsPaginationSlice.reducer,
    analytics: analyticsSlice.reducer,
    continueLearning: continueLearningPaginationSlice.reducer,
    coursesPagination: coursesPaginationSlice.reducer,
    popularCoursesPagination: popularCoursesPaginationSlice.reducer,
    videoCoursePagination: videoCoursesPaginationSlice.reducer,
    ScromXapiCoursesPagination: ScromXapiCoursesPaginationSlice.reducer,
    webwatchCoursePagination: webwatchCoursesPaginationSlice.reducer,
    inforgraphiaCoursePagination: infographiaCoursesPaginationSlice.reducer,
    webwatchAndInfographiaPagination:
      webwatchAndInfographiaPaginationSlice.reducer,
    certificates: certificatesSlice.reducer,
    editCourse: editCourseSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch: () => AppDispatch = useDispatch;
