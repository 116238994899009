import { useState, useEffect } from "react";
import { Button, Form, Input, Select, Row, Col, Card, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Role } from "../../../../interfaces/User";
import { useAppDispatch } from "../../../../store/store";
import { userListSelectors } from "../../../../store/users/userSlice";
import { useSelector } from "react-redux";
import SearchTags from "../../../../components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import { setTabTitle } from "src/utils/utils";
import { createNewUser, editUser } from "src/actions/userActions";

interface Params {
  userId: string;
}

interface UserFromPageProps {
  edit: boolean;
}

function UserFormPage({ edit }: UserFromPageProps) {
  const [loading, setLoading] = useState(false);
  const { userId } = useParams<Params>();
  const user = useSelector(
    userListSelectors.selectUserEntity(parseInt(userId))
  );
  const { Option } = Select;
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const cardHeader = <h5>{edit ? "Edit User" : "Add New User Account"}</h5>;
  const location = useLocation();
  const createUserFormSubmitHandler = () => {
    setLoading(true);
    dispatch(
      edit
        ? editUser({
            ...form.getFieldsValue(),
            id: userId,
            tags: form.getFieldsValue().tags.map((item: any) => item.value),
          })
        : createNewUser({
            ...form.getFieldsValue(),
            tags: form.getFieldsValue().tags.map((item: any) => item.value),
          })
    )
      .then(unwrapResult)
      .then(() => {
        setLoading(false);
        history.push("/admin/users");
        message.success(`User ${edit ? "updated" : "added"}  successfully`);
      })
      .catch(() => {
        setLoading(false);
        message.error(`error ${edit ? "updated" : "added"} user`);
      });
  };

  useEffect(() => {
    setTabTitle(edit ? "Edit User" : "Create User");
    if (location.pathname.split("/")[3] === "edit-user") {
      if (user) {
        form.setFieldsValue({
          ...user,
          tags: user?.usersTags?.map((item: any) => ({
            value: item.tags.id,
            label: item.tags.value,
          })),
        });
      }
    } else form.resetFields();

    // if (!edit) return;
    // if (user) {
    //   form.setFieldsValue({
    //     ...user,
    //     tags: user?.usersTags?.map((item: any) => ({
    //       value: item.tags.id,
    //       label: item.tags.value,
    //     })),
    //   });
    // }
  }, [user, location]);

  return (
    <Row>
      <Col sm={24}>
        <Card title={cardHeader}>
          <Form form={form} onFinish={createUserFormSubmitHandler}>
            <FormLabel name="first_name" />
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name",
                  type: "string",
                },
                {
                  min: 2,
                  message: "First name must be minimum 2 characters.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <FormLabel name="last_name" />
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name",
                  type: "string",
                },
                {
                  min: 2,
                  message: "Last name must be minimum 2 characters.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <FormLabel name="email" />
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email",
                  type: "email",
                },
                {
                  min: 2,
                  message: "Last name must be minimum 2 characters.",
                },
              ]}
            >
              <Input disabled={edit} />
            </Form.Item>

            <FormLabel name="tags" />
            <SearchTags name="tags" />

            <FormLabel name="role" />
            <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select a role",
                },
              ]}
            >
              <Select placeholder={<Translation input="select_role" />}>
                <Option value={Role.ADMIN}>Admin</Option>
                <Option value={Role.AUTHOR}>Author</Option>
                <Option value={Role.LEARNER}>Learner</Option>
                <Option value={Role.MENTOR}>Mentor</Option>
              </Select>
            </Form.Item>
            <Row>
              <Button type="primary" htmlType="submit" loading={loading}>
                <Translation input="submit" />
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default UserFormPage;
