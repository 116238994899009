import { Skeleton } from "antd";

interface Props {
  loading: boolean;
  children: any;
  rows?: number;
  type: string;
  width?: number;
}

function SkeletonLoader({ loading, children, rows, type, width }: Props) {
  const skeletonType = () => {
    if (type === "normal")
      return <Skeleton active paragraph={{ rows: rows }} />;
    else if (type === "button")
      return <Skeleton.Button active style={{ width: `${width}px` }} />;
  };

  return <>{loading ? skeletonType() : children}</>;
}

export default SkeletonLoader;
