import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import SiteIcon from "../../../../Assets/media/site-logo-icon.svg";

function NavBrand() {
  const history = useHistory();
  const role = useSelector(authSelectors.role);

  return (
    <a
      className="navbar-brand d-flex align-items-center"
      onClick={() => {

        if (role === Role.LEARNER) history.push("/home")
          

      }}
    >
      <div style={{ width: "0.2rem" }}></div>

      <svg
        xmlns={SiteIcon}
        width="49.248"
        height="36.024"
        viewBox="0 0 43.33 50.1"
        // shape-rendering="geometricPrecision"
        // image-rendering="optimizeQuality"
        // fill-rule="evenodd"
        fill="#fff"
      >
        <path d="M5.45 12.42v16.3c1.24 5.66 5.54 9.41 10.93 12.48h0 .01c1.71.97 3.52 1.88 5.39 2.75l.02-.01 6.16 3.13c-1.97 1.05-4.04 2.05-6.14 3.03C11.99 44.96 1.85 40 .02 30.22V8.4l5.45 4.02z"></path>
        <path d="M12.45 7.19L25.83.5c1.32-.66.93-.67 2.25-.02l14.71 7.25c.34.2.49.52.55.9v.55 9.17 5.03l-5.45-2.13v-9.71c-.04-.33-.23-.32-1.02-.68l-8.8-4.3c-.67-.4-1.37-.58-2.12-.05l-8.59 4.33c-1.11.56-.96.25-.98 1.46l-.05 4.58c8.89 3.74 17.87 8.74 27 11.78-1.34 9.95-10.04 15.24-11.07 15.07-7.45-2.64-18.29-8.49-21.02-12.44-.36-.51-.34-.5-.34-1.1v-4.7l5.49 2.31v1.17c.25.55-.07.94.41 1.35 3.42 2.93 12.64 6.94 15.1 7.89 2.41-1.2 4.03-3.21 5.11-5.98l-26.1-11.94V9.37c.11-.9.57-1.6 1.55-2.17z"></path>
      </svg>
      <div style={{ width: "0.5rem" }}></div>
      <span className="ml-3 text-white" style={{ fontWeight: 800 }}>
        LEARNSURE
      </span>
    </a>
  );
}

export default NavBrand;
