import { ArrowRightOutlined, GlobalOutlined } from "@ant-design/icons";
import { Menu, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  addLanguageToCourse,
  getSingleCourse,
} from "src/actions/courseActions/courseActions";
import { LanguageCode } from "src/enums/Language";
import { Language } from "src/interfaces/Language";
import { languages } from "src/locale/languages";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";
import { useAppDispatch } from "src/store/store";
import { removeCourseModuleText } from "src/utils/Rest/translationUtils";

interface Params {
  courseId: string;
  translationString: string;
}

function LanguagesMenu() {
  const { courseId } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { Option } = Select;
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>();
  const location = useLocation();
  const translatedToLanguage: LanguageCode = location.pathname
    .split("/")[4]
    ?.split("-")[1] as LanguageCode;

  const onMenuClick = (e) =>
    history.push(`/admin/translation/${courseId}/en-${e.key}`);

  const onModalOk = () => {
    if (!selectedLanguage) return;
    setConfirmLoading(true);
    console.log({
      authorId: course?.authorId,
      title: "",
      description: "",
      objectives: "",
      excerpt: "",
      language: [selectedLanguage],
      id: parseInt(courseId),
      ...removeCourseModuleText(course),
    });
    dispatch(
      addLanguageToCourse({
        authorId: course?.authorId,
        title: "",
        description: "",
        objectives: "",
        excerpt: "",
        language: [selectedLanguage],
        id: parseInt(courseId),
        ...removeCourseModuleText(course),
      })
    ).then(() => {
      setConfirmLoading(false);
      setShowModal(false);
      window.location.href = `/admin/translation/${courseId}/en-${selectedLanguage}`;
    });
  };

  useEffect(() => {
    if (course) setLoading(false);
    else
      dispatch(getSingleCourse(parseInt(courseId))).then(
        setLoading.bind(null, false)
      );
  }, []);

  useEffect(() => {}, [location]);

  return !loading ? (
    <Menu
      theme="dark"
      defaultSelectedKeys={[translatedToLanguage]}
      mode="inline"
    >
      {course?.language
        ?.map((lang) =>
          languages.find((language: Language) => language.code === lang)
        )
        .map((language: Language | undefined, i: number) => {
          return (
            language?.code !== LanguageCode.en && (
              <Menu.Item onClick={onMenuClick} key={language?.code}>
                English <ArrowRightOutlined />
                {language?.name}
              </Menu.Item>
            )
          );
        })}

      <Menu.Item onClick={() => setShowModal(true)} key={"add"}>
        <GlobalOutlined /> Add Language
      </Menu.Item>

      <Modal
        destroyOnClose
        maskClosable={false}
        title="Add Language"
        visible={showModal}
        onOk={onModalOk}
        onCancel={setShowModal.bind(null, false)}
        confirmLoading={confirmLoading}
      >
        <p>Select a the language you want to translate the course to</p>
        <Select
          placeholder="Select a Language"
          style={{ width: "100%" }}
          onChange={(e: LanguageCode) => setSelectedLanguage(e)}
        >
          {languages.map((language: Language, i: number) => {
            return (
              !course?.language?.includes(language.code) && (
                <Option value={language.code} key={i}>
                  {language.name}
                </Option>
              )
            );
          })}
        </Select>
      </Modal>
    </Menu>
  ) : null;
}

export default LanguagesMenu;
