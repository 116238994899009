import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";

//get pathway page
export const getPathways = createAsyncThunk(
  "pathway/get",
  async ({ page }: { page: number }) =>
    await api.get(`admin/pathway?page=${page}`).then((res) => res.data)
);

//like pathway
export const likePathway = createAsyncThunk(
  "pathway/like",
  async (pathwayId: number) =>
    await api
      .post(`likes/likePathway`, { pathwayId })
      .then(extractStandardResponseData)
);
//unlike pathway
export const unlikePathway = createAsyncThunk(
  "pathway/unlike",
  async ({ pathwayId, likeId }: { pathwayId: number; likeId: number }) =>
    await api
      .delete(`likes/unlikePathway/${likeId}?pathwayId=${pathwayId}`)
      .then(extractStandardResponseData)
);

//add pathway view
export const addPathwayView = createAsyncThunk(
  "pathway/view",
  async (pathwayId: number) =>
    await api
      .post(`analytics/addViews/${pathwayId}`, { type: "pathway" })
      .then(extractStandardResponseData)
);
