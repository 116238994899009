import { useState } from "react";
import { useAppDispatch } from "src/store/store";
import { useSelector } from "react-redux";
import { uploadImage } from "src/api/mediaUploadApi";
import { unwrapResult } from "@reduxjs/toolkit";
import { authSelectors, updateProfile } from "src/store/authSlice";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import Translation from "src/components/Common/utilities/Translation";

function ProfilePhotoChangeModal() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector(authSelectors.details);
  const handleImageUpload = (e: any) => {
    const fileList = e.target.files && e.target.files[0];
    console.log("triggred");
    if (!fileList) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", fileList);
    dispatch(uploadImage(formData))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        console.log(res);
        dispatch(
          updateProfile({
            id: userDetails.id,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            description: userDetails.description,
            designation: userDetails.designation,
            imageId: res.id,
          })
        );
      })
      .catch((err) => setLoading(false));
  };

  return (
    <div>
      <div
        className="modal fade"
        id="profilePhotoChangeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="profilePhotoChangeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="profilePhotoChangeModalLabel">
                <Translation input="edit_profile" />
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={loading}
              ></button>
            </div>
            <div className="modal-body" style={{ backgroundColor: "#f6f9fc" }}>
              <div className="row w-100 text-center">
                <div className="col d-flex justify-content-center">
                  {loading ? (
                    <div style={{ width: "200px", height: "200px" }}>
                      <div
                        className="spinner-border mt-5"
                        role="status"
                        style={{ width: "70px", height: "70px" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={
                        !userDetails?.resources?.attachmentUrl
                          ? ProfilePlaceHolder
                          : userDetails.resources.attachmentUrl
                      }
                      alt="profile-photo"
                      className="rounded-circle"
                      width="200px"
                      height="200px"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <p className="small mr-3">
                <Translation input="upload_size" /> : 200px&nbsp;
                <Translation input="by" />
                &nbsp;200px
              </p>
              <div className="">
                <label
                  className="btn btn-secondary btn-sm mx-1"
                  style={{ width: "110px", height: "31px" }}
                >
                  <Translation input="upload_photo" />
                  <input
                    style={{ visibility: "hidden" }}
                    type="file"
                    id="exampleInputPassword1"
                    onChange={handleImageUpload}
                  />
                </label>
                <button
                  disabled={loading}
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  <Translation input="close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePhotoChangeModal;
