import { useState } from "react";
import { Form, Select, Spin } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";

import { useAppDispatch } from "../../../../store/store";
import { searchAuthors } from "../../../../store/coursesSlices/coursesSlice";
import { useTranslation } from "react-i18next";

interface SearchAuthorsPropsI {
  visible: boolean;
  onChange?: (e) => void;
}

function SearchAuthors({ visible, onChange }: SearchAuthorsPropsI) {
  const [fetching, setFetching] = useState(false);
  const [data, setData]: any = useState([]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { Option } = Select;

  const fetchTags = (value: any) => {
    setFetching(true);
    setData([]);

    if (value.length > 2) {
      dispatch(searchAuthors(value))
        .then(unwrapResult)
        .then((res) => {
          setFetching(false);
          setData(res);
        })
        .catch(() => {
          setFetching(false);
        });
    } else {
      setFetching(false);
    }
  };

  return (
    <Form.Item
      name="authorId"
      rules={[
        {
          required: true,
          message: "Please add an Author for the course",
        },
      ]}
    >
      <Select
        disabled={visible}
        showSearch
        labelInValue
        placeholder={t("select_author")}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={fetchTags}
        style={{ width: "100%" }}
        autoClearSearchValue={true}
        onChange={onChange}
      >
        {data?.map((item: any) => (
          <Option value={item.id} key={item.id}>
            {item.firstName + " " + item.lastName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SearchAuthors;
