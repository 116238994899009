import { useEffect } from "react";
import { Button, Col, Row, PageHeader, Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../store/authSlice";
import { Role } from "src/interfaces/User";
import "../../pages.css";
import ProfileInfoCard from "src/components/AdminAuthorMentorComponents/Common/Account/ProfileInfoCard/ProfileInfoCard";
import AboutCard from "src/components/AdminAuthorMentorComponents/Common/Account/AboutCard";
function ProfilePage() {
  const userDetails = useSelector(authSelectors.details);

  const history = useHistory();
  const customRequest = (e: any) => {
    console.log(e);
  };
  useEffect(() => {
    document.title = `Learnsure Ai - Profile`;
  }, [userDetails]);

  return (
    <Row gutter={20}>
      <Col span={8}>
        <ProfileInfoCard />
      </Col>
      <Col span={16}>
        <AboutCard />
      </Col>
    </Row>
  );
}

export default ProfilePage;

// ==============================================
// import { useEffect } from "react";
// import { Button, Col, Row, PageHeader, Card, Avatar } from "antd";
// import { UserOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router";
// import { useSelector } from "react-redux";
// import { authSelectors } from "../../../store/authSlice";
// import { Role } from "src/interfaces/User";
// import "../../pages.css";
// function ProfilePage() {
//   const userDetails = useSelector(authSelectors.details);

//   const history = useHistory();
//   const customRequest = (e: any) => {
//     console.log(e);
//   };
//   useEffect(() => {
//     document.title = `Learnsure Ai - Profile`;
//   }, [userDetails]);
//   return (
//     <div>
//       <Col md={24}>
//         <PageHeader
//           title="Profile"
//           extra={[
//             <Button
//               type="primary"
//               key="new-account"
//               onClick={() => {
//                 if (userDetails.role === Role.ADMIN) {
//                   history.push("/admin/edit-profile");
//                 }
//                 if (userDetails.role === Role.AUTHOR) {
//                   history.push("/author/edit-profile");
//                 }
//                 if (userDetails.role === Role.MENTOR) {
//                   history.push("/mentor/edit-profile");
//                 }
//               }}
//             >
//               Edit Profile
//             </Button>,
//           ]}
//         />
//       </Col>
//       <Row>
//         <Col sm={24} md={24} lg={22} xl={8}>
//           {userDetails?.resources?.attachmentUrl ? (
//             <div className="profile_page_profile_pic">
//               <Avatar
//                 style={{
//                   backgroundColor: "lightgray",
//                   verticalAlign: "middle",
//                   width: "100%",
//                   height: "100%",
//                 }}
//               >
//                 <div className="profile_page_avatar_icon">
//                   <UserOutlined />
//                 </div>
//               </Avatar>
//             </div>
//           ) : (
//             <div
//               className="mr-4 profile_page_profile_pic"
//               style={{
//                 backgroundImage: `url(${userDetails?.resources?.attachmentUrl})`,
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",

//                 borderRadius: "50%",
//               }}
//             ></div>
//           )}
//         </Col>
//         <Col sm={24} md={24} lg={22} xl={16}>
//           <Card title="Personal Information">
//             <div style={{ marginLeft: "15px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   marginBottom: "20px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <h6>Name:</h6>&nbsp;
//                 <h6 className="text-primary">{`${userDetails.firstName} ${userDetails.lastName}`}</h6>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   marginBottom: "20px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <h6>Email:</h6>&nbsp;
//                 <h6 className="text-primary">{userDetails.email}</h6>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   marginBottom: "20px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <h6>Designation:</h6>&nbsp;
//                 <h6 className="text-primary">{userDetails.designation}</h6>
//               </div>
//               <div style={{}}>
//                 <h6>Description:</h6>&nbsp;
//                 <div style={{ marginTop: "-20px" }}>
//                   {userDetails.description}
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         {/* <UploadCourse /> */}
//       </Row>
//     </div>
//   );
// }

// export default ProfilePage;
