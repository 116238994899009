import { useEffect, useState } from "react";
import { Form, Upload, Input, message, Progress, Button } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { api } from "../../../../api/api";
import { getFileExtention } from "../../../../utils/utils";
import { FileType } from "src/enums/FileTypes";

interface Props {
  done: boolean;
}

function UploadCourse({ done }: Props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [attachmentIdArray, setAttachmentIdArray] = useState<any>([]);
  const [xapiAttachment, setXapiAttachment] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadButton = (
    <div>
      {loading ? (
        uploadProgress === 100 ? (
          <LoadingOutlined />
        ) : (
          <Progress percent={uploadProgress} type="circle" width={50} />
        )
      ) : (
        <PlusOutlined />
      )}

      <div style={{ marginTop: 8 }}>Upload XAPI</div>
    </div>
  );

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };

  const customRequest = async (e: any) => {
    const fileList = e.file;
    console.log(getFileExtention(e.file.name));
    if (getFileExtention(e.file.name) === FileType.zip) {
      setLoading(true);
      if (fileList) {
        const formData = new FormData();
        formData.append("file", fileList);

        try {
          const zip = await api.post("/courses/zip", formData, config);
          console.log(zip.data);
          setXapiAttachment(zip.data.data.id);

          message.success("XAPI uploaded successfully");
          setLoading(false);
          setShow(true);
        } catch (error) {
          message.error("XAPI upload error");
          setLoading(false);
        }
      }
    } else {
      message.error("please add a zip file");
    }
  };

  const removeHandler = (e: any) => {};
  useEffect(() => {}, [done]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          customRequest={customRequest}
          multiple={false}
          fileList={fileListArray}
          onRemove={removeHandler}
        >
          {show ? (
            <div style={{ fontSize: "200%" }}>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </div>
          ) : (
            uploadButton
          )}
        </Upload>
      </div>

      {done ? (
        <div>
          <Form.Item name="courseAttachment" initialValue={xapiAttachment}>
            <Input type="hidden" />
          </Form.Item>
        </div>
      ) : null}
    </div>
  );
}

export default UploadCourse;
