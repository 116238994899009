import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";

//get all Learner courses from
export const getAllLearnerCourses = createAsyncThunk(
  "courses/learner/get/all",
  async (page: number) =>
    await api.get(`/courses/?page=${page}`).then((res) => res.data)
);
//get single course with slug
export const getCourse = createAsyncThunk(
  `courses/learner/get/single`,
  async (slug: string) =>
    await api.get(`/courses/${slug}`).then(extractStandardResponseData)
);

//get popular courses
export const getPopularCourses = createAsyncThunk(
  "courses/learner/popular/get",
  async (page: number) =>
    await api.get(`/courses/getMostUsed/?page=${page}`).then((res) => res.data)
);

//get continue learning courses
export const getContinueLearningCourses = createAsyncThunk(
  "getContinueLearning",
  async (page: number) =>
    await api.get(`courses/lastViewed?page=${page}`).then((res) => res.data)
);

//get video courses
export const getVideoCourses = createAsyncThunk(
  "getVideoCourses",
  async (page: number) =>
    await api
      .get(`courses/otherCourses?page=${page}&filter=VIDEO`)
      .then((res) => res.data)
);
//get xapi and scrom courses
export const getXapiAndScromCourses = createAsyncThunk(
  "getXapiAndScromCourses",
  async (page: number) =>
    await api
      .get(`courses/otherCourses?page=${page}&filter=XAPI`)
      .then((res) => res.data)
);
// get webwatch courses
export const getWebwatchCourses = createAsyncThunk(
  "getWebwatchCourses",
  async (page: number) =>
    await api.get(`courses/getWebwatch?page=${page}`).then((res) => res.data)
);

// get infographia
export const getInfographiaCourses = createAsyncThunk(
  "getInfographiaCourses",
  async (page: number) =>
    await api.get(`courses/getInfographia?page=${page}`).then((res) => res.data)
);

// like course
export const likeCourse = createAsyncThunk(
  "courses/learner/like",
  async (courseId: number) =>
    await api.post("/likes", { courseId }).then(extractStandardResponseData)
);

// unlike course
export const unlikeCourse = createAsyncThunk(
  "courses/learner/unlike",
  async ({ courseId, likeId }: { courseId: number; likeId: number }) =>
    await api
      .delete(`/likes/${likeId}?courseId=${courseId}`)
      .then(extractStandardResponseData)
);
