import ViewIcon from "src/Assets/media/view-icon.svg";

function CourseViewIcon({ views }: { views: number }) {
  return (
    <div className="d-flex align-items-center">
      <img src={ViewIcon} width="16px" height="16px" alt="" />
      <div style={{ width: "0.4rem" }}></div>
      <div className="fs-7">{(typeof views === "string" || typeof views === "number") && views}</div>
    </div>
  );
}

export default CourseViewIcon;
