import { useState } from "react";
import { Form, Switch } from "antd";

interface Props {
  initialValue: boolean;
  rest: any;
}
function SwitchFormItem({ initialValue, ...rest }: any) {
  const [currentValue, setCurrentValue] = useState<boolean>(initialValue);
  return (
    <div>
      <Form.Item {...rest} initialValue={initialValue}>
        <Switch
          checked={currentValue}
          onChange={() => {
            setCurrentValue((s) => !s);
          }}
        />
      </Form.Item>
    </div>
  );
}

export default SwitchFormItem;
