import { Button, Checkbox } from "antd";
import { useState } from "react";
import CoursePreviewVideoUpload from "src/components/AdminAuthorMentorComponents/Common/Course/CoursePreviewVideoUpload";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";

interface Props {
  done: boolean;
  videoUrl: string | null | undefined;
}

function CoursePreviewVideo({ done, videoUrl }: Props) {
  const [showUploadVideo, setShowUploadVideo] = useState(false);

  return (
    <>
      <FormLabel name="course_preview_video" />
      <div style={{ marginBottom: "20px" }}>
        {showUploadVideo ? (
          <CoursePreviewVideoUpload done={done} />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {videoUrl ? (
              <>
                <div style={{ width: "15rem" }}>
                  <VideoPlayer url={videoUrl} />
                </div>
                <Button
                  type="primary"
                  onClick={() => setShowUploadVideo(true)}
                  style={{ width: "15rem" }}
                >
                  Update Course Video
                </Button>
              </>
            ) : (
              <Checkbox
                onChange={setShowUploadVideo.bind(null, true)}
                style={{ marginBottom: "15px" }}
              >
                <Translation input="add_course_preview_video" />
              </Checkbox>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default CoursePreviewVideo;
