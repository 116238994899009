import { Card, Col, Row } from "antd";

function NoTranslationAdded({ title }: { title: string }) {
  return (
    <Row>
      <Col span={24}>
        <Card title={title}>
          <p>No translations have been added for this course.</p>
          <p>
            To add a new course translation click Add Language on the sidebar
          </p>
        </Card>
      </Col>
    </Row>
  );
}

export default NoTranslationAdded;
