import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Role } from "../../interfaces/User";
import { useHistory } from "react-router-dom";

import { authSelectors, verifyUser, logout } from "../../store/authSlice";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { getToken } from "src/api/localStorage";
import { customAlert, customAlertUserOnboarding } from "src/utils/utils";

function UserSetPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();
  const [showLoginPage, setShowLoginPage] = useState(true);
  const email = useSelector(authSelectors.email);
  const query = useQuery().get("token") || "";
  function useQuery() {
    return new URLSearchParams(location.search ? location.search : "");
  }

  const history = useHistory();

  const dispatch = useAppDispatch();
  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password === confirmPassword) {
      console.log("pass word match");

      console.log("token", getToken());

      if (getToken()) {
        console.log("check");
        dispatch(logout()).then(() => {
          dispatch(verifyUser({ password: password, token: query }))
            .then(unwrapResult)
            .then((res) => {
              console.log(res);
              // if (res.role === Role.ADMIN) history.push("/admin/courses");
              // if (res.role === Role.LEARNER) history.push("/home");
              // if (res.role === Role.AUTHOR) history.push("/author");
              // history.push("/login");
              setShowLoginPage(false);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        dispatch(verifyUser({ password: password, token: query }))
          .then(unwrapResult)
          .then((res) => {
            // history.push("/login");
            setShowLoginPage(false);
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    } else {
      console.log("passwords do not match");
    }
  };

  useEffect(() => {
    console.log(query, "check");
  }, []);
  return (
    <div className="login_page">
      <div className="login_left_col" style={{ overflow: "hidden" }}></div>
      <div className="login_right_col">
        <div className="">
          <div style={{ display: "flex" }}>
            <span>
              <svg
                width="49.248"
                height="36.024"
                viewBox="0 0 70 50.1"
                shape-rendering="geometricPrecision"
                image-rendering="optimizeQuality"
                fill-rule="evenodd"
                fill="#0077ff"
              >
                <path d="M5.45 12.42v16.3c1.24 5.66 5.54 9.41 10.93 12.48h0 .01c1.71.97 3.52 1.88 5.39 2.75l.02-.01 6.16 3.13c-1.97 1.05-4.04 2.05-6.14 3.03C11.99 44.96 1.85 40 .02 30.22V8.4l5.45 4.02z"></path>
                <path d="M12.45 7.19L25.83.5c1.32-.66.93-.67 2.25-.02l14.71 7.25c.34.2.49.52.55.9v.55 9.17 5.03l-5.45-2.13v-9.71c-.04-.33-.23-.32-1.02-.68l-8.8-4.3c-.67-.4-1.37-.58-2.12-.05l-8.59 4.33c-1.11.56-.96.25-.98 1.46l-.05 4.58c8.89 3.74 17.87 8.74 27 11.78-1.34 9.95-10.04 15.24-11.07 15.07-7.45-2.64-18.29-8.49-21.02-12.44-.36-.51-.34-.5-.34-1.1v-4.7l5.49 2.31v1.17c.25.55-.07.94.41 1.35 3.42 2.93 12.64 6.94 15.1 7.89 2.41-1.2 4.03-3.21 5.11-5.98l-26.1-11.94V9.37c.11-.9.57-1.6 1.55-2.17z"></path>
              </svg>
            </span>
            <div
              style={{
                fontSize: "20.8px",
                fontWeight: 800,
                marginLeft: "-3px",
              }}
              className="font_family_nunito"
            >
              LEARNSURE
            </div>
          </div>
          {showLoginPage ? (
            <>
              <h4
                style={{
                  fontSize: "20.4px",
                  fontWeight: 700,
                  marginTop: "48px",
                  marginBottom: "8px",
                }}
              >
                Please set a password
              </h4>
              <form onSubmit={formSubmitHandler}>
                <div style={{ marginTop: "24px", marginBottom: "16px" }}>
                  <div style={{ marginBottom: "16px" }}>
                    <input
                      type="password"
                      // className="form-control"
                      className="login_page_input"
                      id="exampleInputPassword1"
                      style={{ width: "20rem" }}
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <input
                      type="password"
                      // className="form-control"
                      className="login_page_input"
                      id="exampleInputPassword1"
                      style={{ width: "20rem" }}
                      placeholder="Confirm password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <button type="submit" className="login_page_submit_button">
                    <div>Submit</div>
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <h4
                style={{
                  fontSize: "20.4px",
                  fontWeight: 700,
                  marginTop: "48px",
                  marginBottom: "8px",
                }}
              >
                Password set successfully
              </h4>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button type="submit" className="login_page_submit_button">
                  <div onClick={() => history.push("/login")}>Login</div>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserSetPasswordPage;

// =============================

// import { unwrapResult } from "@reduxjs/toolkit";
// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { Role } from "../interfaces/User";
// import { useHistory } from "react-router-dom";

// import { verifyUser } from "../store/authSlice";
// import { useAppDispatch } from "../store/store";

// function UserSetPasswordPage() {
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const location = useLocation();

//   const query = useQuery().get("token") || "";
//   function useQuery() {
//     return new URLSearchParams(location.search ? location.search : "");
//   }

//   const history = useHistory();

//   const dispatch = useAppDispatch();
//   const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();

//     if (password === confirmPassword) {
//       console.log("pass word match");
//       dispatch(verifyUser({ password: password, token: query }))
//         .then(unwrapResult)
//         .then((res) => {
//           console.log(res);
//           if (res.role === Role.ADMIN) history.push("/admin/courses");
//           if (res.role === Role.LEARNER) history.push("/home");
//           if (res.role === Role.AUTHOR) history.push("/author");
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     } else {
//       console.log("passwords do not match");
//     }
//   };

//   useEffect(() => {
//     console.log(query, "check");
//   }, []);
//   return (
//     <div
//       className="d-flex  flex-column align-items-center"
//       style={{
//         width: "100vw",
//         height: "100vh",
//         paddingTop: "15%",
//         backgroundImage:
//           "url(https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
//       }}
//     >
//       <div
//         className="d-flex flex-column align-items-center"
//         style={{ backgroundColor: "white", borderRadius: "10px" }}
//       >
//         <div>
//           <h2 className="text-primary pt-3">Welcome</h2>
//         </div>
//         <div>
//           <form className="pl-4 pr-4 pt-5 pb-5" onSubmit={formSubmitHandler}>
//             <div className="mb-3">
//               <div className="mb-2 text-primary">Password</div>
//               <input
//                 type="password"
//                 className="form-control"
//                 id="exampleInputPassword1"
//                 style={{ width: "20rem" }}
//                 onChange={(e) => {
//                   setPassword(e.target.value);
//                 }}
//               />
//               <div className="mb-2 text-primary">Confirm Password</div>
//               <input
//                 type="password"
//                 className="form-control"
//                 id="exampleInputPassword1"
//                 style={{ width: "20rem" }}
//                 onChange={(e) => {
//                   setConfirmPassword(e.target.value);
//                 }}
//               />
//             </div>
//             <div className="d-flex align-items-baseline justify-content-end">
//               <button type="submit" className="btn btn-primary">
//                 Submit
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default UserSetPasswordPage;
