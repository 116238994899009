import { AiFillLock } from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import { logout } from "src/store/authSlice";
import { baseUrl } from "src/api/api";

enum AuthStatus {
  LOGGED_IN = "loggedout",
  UNAUTHORIZED = "unauthorized",
}

function ZoomAuthorizationCheckPage() {
  const history = useHistory();
  const location = useLocation();
  function queryFunction() {
    return new URLSearchParams(location.search ? location.search : "");
  }

  const dispatch = useAppDispatch();
  const queryDetail = {
    user: queryFunction().get("user"),
    meetingId: queryFunction().get("meetingId"),
    registrantId: queryFunction().get("registrantId"),
    userName: queryFunction().get("userName"),
  };

  return (
    <div>
      {queryDetail.user === AuthStatus.UNAUTHORIZED ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <AiFillLock style={{ fontSize: "2.5rem", marginRight: "1rem" }} />
            <h1 style={{ margin: "0" }}>Unauthorized</h1>
          </div>

          <button
            className="login_page_submit_button"
            style={{ marginTop: "1rem" }}
            onClick={() =>
              dispatch(logout()).then(
                () =>
                  history.push(
                    "/login",
                    `https://app-api.learnsure.ai/join-session?registrantId=${queryDetail.registrantId}&meetingId=${queryDetail.meetingId}&user=${queryDetail.userName}`
                  )
                // history.push(
                //   "/login",
                //   `${baseUrl}/join-session?registrantId=${queryDetail.registrantId}&meetingId=${queryDetail.meetingId}&user=${queryDetail.userName}`
                // );
              )
            }
          >
            Logout
          </button>
        </div>
      ) : null}
      {queryDetail.user === AuthStatus.LOGGED_IN ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <AiFillLock style={{ fontSize: "2.5rem", marginRight: "1rem" }} />
            <h1 style={{ margin: "0" }}>Please login to continue</h1>
          </div>

          <button
            className="login_page_submit_button"
            style={{ marginTop: "1rem" }}
            onClick={() => {
              history.push(
                "/login",
                `https://app-api.learnsure.ai/join-session?registrantId=${queryDetail.registrantId}&meetingId=${queryDetail.meetingId}&user=${queryDetail.userName}`
              );
              // history.push(
              //   "/login",
              //   `${baseUrl}/join-session?registrantId=${queryDetail.registrantId}&meetingId=${queryDetail.meetingId}&user=${queryDetail.userName}`
              // );
            }}
          >
            Login
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default ZoomAuthorizationCheckPage;
