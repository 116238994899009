import { useTranslation } from "react-i18next";
import { openNewTab } from "src/utils/utils";

function LearnerLayoutFooter() {
  const { t } = useTranslation();

  const infoArray = [
    { name: "privacy_policy", link: "https://learnsure.ai/privacy-policy/" },
    { name: "|", link: "" },
    {
      name: "terms_and_conditions",
      link: "https://learnsure.ai/terms-conditions/",
    },
    { name: "|", link: "" },
    { name: "about_us", link: "" },
  ];

  const socialArray = [
    {
      name: "linkedin",
      link: "https://www.linkedin.com/company/learnsure",
    },

    {
      name: "facebook",
      link: "https://www.facebook.com/Learnsure-Ai-100683478556557/",
    },
    ,
    {
      name: "youtube",
      link: "",
    },
  ];

  return (
    <footer>
      <hr className="m-0" />
      <div className="container text-center text-muted py-3">
        <div className="row">
          <div className="col-md-4">
            <p className="fw-bolder small font_family_nunito">
              Learnsure Ai Pvt Ltd @2021
            </p>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="fw-bolder small font_family_nunito d-flex justify-content-center">
                {infoArray.map(({ name, link }: any, i: number) => (
                  <div
                    onClick={() => openNewTab(link)}
                    className="cursor_pointer"
                    key={i}
                  >
                    {/* {t(name)} {i !== 2 && "|"}&nbsp; */}
                    {t(name)}&nbsp;
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="row">
              <div className="fw-bolder small font_family_nunito d-flex justify-content-center">
                {socialArray.map(({ name, link }: any, i: number) => (
                  <div
                    onClick={() => openNewTab(link)}
                    className="cursor_pointer"
                    key={i}
                  >
                    {t(name)} {i !== 3 && "|"}&nbsp;
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default LearnerLayoutFooter;
