import { useState } from "react";
import { Lesson, LessonsAttachment } from "src/interfaces/Lesson";
import { Module } from "src/interfaces/Module";
import LessonMediaRender from "src/components/Common/mediaPlayers/LessonMediaRender";
import { useTranslation } from "react-i18next";

interface Props {
  modules: Module[];
}

function CourseContent({ modules }: Props) {
  const [lessonsAttach, setlessonsAttach] = useState<LessonsAttachment[]>();
  const [lessonsTitle, setlessonsTitle] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <h5 className="mb-3 fw-bold">{t("course_content")}</h5>

      <div
        className="card overflow-auto border_color"
        style={{ maxHeight: "412px" }}
      >
        {modules?.map((module: Module, index: number) => {
          return (
            <div
              className={`px-4 py-3 ${
                index + 1 === modules?.length ? null : "border-bottom"
              }`}
              key={module.id}
            >
              <div className="d-flex">
                <div className="font_family_poppins fw-bold px-1">
                  {index + 1}.
                </div>
                <div className="font_family_poppins fw-bold">
                  <div className="mb-3">{module.title}</div>

                  <div>
                    {module?.lessons?.map((lesson: Lesson) => {
                      return (
                        <div
                          key={lesson.id}
                          className="font_family_nunito mb-2 "
                          style={{ fontSize: "14.4px" }}
                          onClick={() => {
                            setlessonsTitle(lesson?.title);
                            setlessonsAttach(lesson?.lessonsAttachments);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#courseContentModal"
                        >
                          {lesson.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* -----------------------------modal-----------------------------  */}

        {/* <div
          className="modal fade"
          id="courseContentModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {lessonsTitle}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <LessonMediaRender lessonsAttachments={lessonsAttach || []} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default CourseContent;
