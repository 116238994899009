import { LessonsAttachment } from "src/interfaces/Lesson";
// import SectionSelector from "./SectionSelector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Card, Button, message, Col, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import SectionSelector from "./SectionSelector";
import { LessonFormat } from "src/enums/LessonFormats";
import { useLocation } from "react-router";

interface Props {
  onChange: (lessonsAttachments: any, lessonId: number | string) => void;
  lessonsAttachments: any;
  moduleId: number | string;
  lessonId: number | string;
}

function Sections({ onChange, lessonsAttachments, moduleId, lessonId }: Props) {
  const { pathname } = useLocation();

  const isTranslationPath =
    pathname.split("/")[2] === "translation" ? true : false;

  const onSectionDragEnd = (e) => {
    let temp = [...lessonsAttachments];

    const [reorderedItem] = temp.splice(e?.source?.index, 1);
    temp.splice(e?.destination?.index, 0, reorderedItem);
    onChange(
      temp
        .map((lessonsAttachmentsArray: LessonsAttachment[], index: number) =>
          lessonsAttachmentsArray.map(
            (lessonsAttachment: LessonsAttachment) => ({
              ...lessonsAttachment,
              sectionId: index,
            })
          )
        )
        .flat(),
      lessonId
    );
  };

  const isAnySectionEmpty = () => {
    const lastSection = lessonsAttachments[lessonsAttachments.length - 1];
    if (lastSection[0].type === null) {
      message.warning("Please Populate the sections");
      return true;
    }
    return false;
  };

  const onSectionAdd = () => {
    if (isAnySectionEmpty()) return;
    const newSectionAdded = [
      ...lessonsAttachments,
      [{ type: null, sectionId: lessonsAttachments.length }],
    ];
    onChange(newSectionAdded.flat(), lessonId);
  };

  const onSectionDelete = (sectionId: number) => {
    const deletedSectionArray = lessonsAttachments
      .filter(
        (lessonsAttachmentArray: LessonsAttachment[]) =>
          lessonsAttachmentArray[0].sectionId !== sectionId
      )
      .map((lessonsAttachmentsArray: LessonsAttachment[], index: number) =>
        lessonsAttachmentsArray.map((lessonsAttachment: LessonsAttachment) => ({
          ...lessonsAttachment,
          sectionId: index,
        }))
      )
      .flat();
    onChange(deletedSectionArray, lessonId);
  };

  const onSectionSelect = (
    e: any,
    lessonId: number | string,
    sectionId: number
  ) => {
    onChange(
      lessonsAttachments
        .map((lessonsAttachmentArray: LessonsAttachment[]) =>
          lessonsAttachmentArray[0].sectionId === sectionId
            ? [{ ...lessonsAttachmentArray[0], ...e }]
            : lessonsAttachmentArray
        )
        .flat(),
      lessonId
    );
  };

  const onMediaAdd = (e: any) => {
    const newArray = lessonsAttachments
      .map((lessonsAttachmentArray: LessonsAttachment[]) =>
        lessonsAttachmentArray[0].sectionId === e[0].sectionId
          ? e
          : lessonsAttachmentArray
      )
      .flat();
    onChange(newArray, lessonId);
  };

  return (
    <>
      <DragDropContext onDragEnd={onSectionDragEnd}>
        <Droppable droppableId="lessons">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {lessonsAttachments?.map(
                (lessonAttachment: LessonsAttachment[], i: number) => (
                  <Draggable key={i} draggableId={`${i}`} index={i}>
                    {(provided) => (
                      <div {...provided.draggableProps} ref={provided.innerRef}>
                        <Card hoverable={true} style={{ marginBottom: "15px" }}>
                          <Row justify="space-between">
                            <Col>
                              <SectionSelector
                                onSectionSelect={(e) => {
                                  onSectionSelect(
                                    e,
                                    lessonId,
                                    lessonAttachment[0].sectionId
                                  );
                                }}
                                onChange={onMediaAdd}
                                lessonsAttachment={lessonAttachment}
                                moduleId={moduleId}
                                lessonId={lessonId}
                                sectionId={lessonAttachment[0].sectionId}
                              />
                            </Col>

                            <Col>
                              {!isTranslationPath && (
                                <Space>
                                  <Button
                                    onClick={() =>
                                      onSectionDelete(
                                        lessonAttachment[0].sectionId
                                      )
                                    }
                                  >
                                    Remove Section
                                  </Button>
                                  <span {...provided.dragHandleProps}>
                                    <MenuOutlined />
                                  </span>
                                </Space>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isTranslationPath && (
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Button type="primary" onClick={onSectionAdd}>
            Add Section
          </Button>
        </Row>
      )}
    </>
  );
}

export default Sections;
