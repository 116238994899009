import { QuestionCircleOutlined } from "@ant-design/icons";
import Translation from "src/components/Common/utilities/Translation";
import { FaQuestionCircle } from "react-icons/fa";
import { Row } from "antd";
import { useHistory } from "react-router-dom";

function HelpOption() {
  const history = useHistory();

  return (
    <Row
      align="middle"
      className="cursor_pointer"
      onClick={() => history.push("/help")}
    >
      <FaQuestionCircle style={{ color: "#C0C0C0", fontSize: "16px" }} />
      <div style={{ width: "5px" }}></div>
      <span style={{ color: "#C0C0C0" }} className="fw_normal fs_16">
        <Translation input="help" />
      </span>
    </Row>
  );
}

export default HelpOption;
