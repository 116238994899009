import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../../store";
import { User } from "src/interfaces/User";
import { coursesSelector } from "../coursesSlice";
import { getContinueLearningCourses } from "src/actions/courseActions/learnerCourseActions";
import { completeLesson } from "src/actions/courseActions/courseActions";

const continueLearningPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: continueLearningPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const continueLearningPaginationSlice = createSlice({
  name: "continueLearning/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getContinueLearningCourses.fulfilled,
      (state, { payload }) => {
        return {
          pages: continueLearningPaginationAdapter.upsertOne(
            { ...state.pages },
            {
              id: parseInt(payload.currentPage),
              items: payload?.data?.map((item: any) => item.courses.id),
            }
          ),
          total: payload.pages,
          totalItems: payload.items,
        };
      }
    );
    builder.addCase(completeLesson.fulfilled, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = continueLearningPaginationAdapter.getSelectors(
  (s: RootState) => s.continueLearning.pages
);

export const continueLearningPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        coursesSelector.coursesSelector.selectById(state, id)
      ) || [],
  total: (state: RootState) => state.continueLearning.totalItems,
  totalPages: (state: RootState) => state.continueLearning.total,
};
