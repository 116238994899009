import { CourseVideo } from "src/interfaces/Course";
import { Collapse } from "antd";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { Module } from "src/interfaces/Module";
interface Props {
  courseModules: Module[];
}
const { Panel } = Collapse;
function VideosCollapse({ courseModules }: Props) {
  return (
    <>
      {courseModules?.map((courseModule: Module) => {
        return (
          <Collapse>
            <Panel header={`${courseModule.title}`} key={`${courseModule.id}`}>
              {courseModule.coursesVideos.map((courseVideo: CourseVideo) => {
                return (
                  <div style={{ marginBottom: "20px" }}>
                    <h5>{courseVideo.title}</h5>
                    {courseVideo?.resources?.attachmentUrl ? (
                      <VideoPlayer url={courseVideo.resources.attachmentUrl} />
                    ) : null}
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        );
      })}
    </>
  );
}

export default VideosCollapse;
