import { Button, Card, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import FormLabel from "src/components/Common/utilities/FormLabel";
import { Course } from "src/interfaces/Course";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import ReactQuill from "react-quill";
import Translation from "src/components/Common/utilities/Translation";
import DefaultCourseContent from "./DefaultCourseContent/DefaultCourseContent";
import { Module } from "src/interfaces/Module";
import { CourseFormat } from "src/enums/CourseFormat";
import { useForm } from "antd/lib/form/Form";
import SearchFields from "src/pages/EditCoursePage/EditCoursePageFormFields/SearchFields";
import SearchTags from "../../SearchComponents/SearchTags";
import SearchMentors from "../../SearchComponents/SearchMentors";
import SearchAuthors from "../../SearchComponents/SearchAuthors";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import CourseImage from "./CourseImage";
import CourseVideo from "./CourseVideo";
import VideoCourseContent from "./VideoCourseContent/VideoCourseContent";
import { Quiz } from "src/interfaces/Quiz";
import AddCourseQuiz from "../CourseQuiz/AddCourseQuiz";

function CourseForm({
  onSubmit,
  inputCourse,
  loading = false,
  onSave,
  submitLoading = false,
}: {
  onSubmit: (e: any) => void;
  submitLoading?: boolean;
  inputCourse?: Course | any;
  loading?: boolean;
  onSave?: (e: any) => void;
}) {
  const [course, setCourse] = useState<Partial<Course>>();
  const [courseQuiz, setCourseQuiz] = useState<any>();
  const [objectives, setObjectives] = useState("");
  const [description, setDescription] = useState("");
  const { TextArea } = Input;
  const [form] = useForm();
  const { pathname } = useLocation();
  const role = useSelector(authSelectors.role);

  const isTranslationPath =
    pathname.split("/")[2] === "translation" ? true : false;

  const onFormSubmit = () => {
    onSubmit({
      ...course,
      objectives,
      description,
      tags: form.getFieldsValue().tags,
      mentors: form.getFieldsValue().mentors,
      authorId: form.getFieldsValue().authorId,
      ...courseQuiz,
      quiz:
        courseQuiz.quiz?.map((item, i) => ({
          ...item.extras,
          id: item?.extras?.id ? item.extras.id : "idPla",
          sectionId: i,
          attachmentId: null,
          question: item.question,
          options: item.answer?.map((item) => item.option),
          answers: item.answer
            .filter((item) => item.checked === true)
            .map((item) => item.option),
        })) || [],
    });
  };

  const onCourseSave = () => {
    if (!onSave) return;

    onSave({
      ...course,
      objectives,
      description,
      tags: form.getFieldsValue().tags,
      mentors: form.getFieldsValue().mentors,
      authorId: form.getFieldsValue().authorId,
      ...courseQuiz,
      quiz:
        courseQuiz.quiz?.map((item, i) => ({
          ...item.extras,
          id: item?.extras?.id ? item.extras.id : "idPla",
          sectionId: i,
          attachmentId: null,
          question: item.question,
          options: item.answer?.map((item) => item.option),
          answers: item.answer
            .filter((item) => item.checked === true)
            .map((item) => item.option),
        })) || [],
    });
  };

  const formHeader = (
    <Row justify="space-between">
      <div>Edit Course</div>
      <Button type="primary" onClick={onCourseSave} loading={submitLoading}>
        Save
      </Button>
    </Row>
  );

  useEffect(() => {
    if (!course) {
      setCourse(inputCourse);
    } else {
      setCourseQuiz({
        quizTitle: course.title,
        attempts: course.attempts,
        passing: course.passing,
        quiz: course.coursesQuiz?.map((quiz: Quiz) => ({
          extras: { id: quiz.id, weightage: quiz.weightage },
          question: quiz.question,
          answer: quiz.options.map((option) => ({
            option: option,
            checked: quiz.answers.find((ans) => ans === option) ? true : false,
          })),
        })),
      });
      setObjectives(course?.objectives || "");
      setDescription(course?.description || "");
      form.setFieldsValue({
        tags: course?.coursesTags?.map((item: any) => ({
          label: item.tags.value,
          value: item.tags.id,
        })),
        mentors: course?.mentorsCourses?.map((item: any) => ({
          label: `${item?.users?.firstName} ${item?.users?.lastName}`,
          value: item?.users?.id,
        })),
        authorId: {
          label: `${course?.users?.firstName} ${course?.users?.lastName}`,
          value: course?.users?.id,
        },
      });
    }
    // }, [inputCourse, course, objectives, description]);
  }, [inputCourse, course]);

  return (
    <Card title={formHeader}>
      {/* <Button onClick={() => console.log(description)}>test</Button> */}
      <Form form={form}>
        <FormLabel name="title" />
        <SkeletonLoader loading={loading} rows={0} type="normal">
          <Form.Item>
            <Input
              value={course?.title}
              onChange={(e) => setCourse({ ...course, title: e.target.value })}
            />
          </Form.Item>
        </SkeletonLoader>
        <FormLabel name="excerpt" />
        <SkeletonLoader loading={loading} rows={2} type="normal">
          <Form.Item>
            <TextArea
              rows={4}
              value={course?.excerpt}
              onChange={(e) =>
                setCourse({ ...course, excerpt: e.target.value })
              }
            />
          </Form.Item>
        </SkeletonLoader>
        <FormLabel name="objectives" />
        <SkeletonLoader loading={loading} rows={5} type="normal">
          <Form.Item>
            <ReactQuill
              // onChange={(e) => setCourse({ ...course, objectives: e })}
              // value={course?.objectives}
              onChange={(e) => setObjectives(e)}
              value={objectives}
            />
          </Form.Item>
        </SkeletonLoader>
        <FormLabel name="description" />
        <SkeletonLoader loading={loading} rows={5} type="normal">
          <Form.Item>
            <ReactQuill
              // onChange={(e) => setCourse({ ...course, description: e })}
              // value={course?.description}
              onChange={(e) => setDescription(e)}
              value={description}
            />
          </Form.Item>
        </SkeletonLoader>
        {!isTranslationPath && (
          <>
            <CourseImage
              urlInput={course?.resources?.attachmentUrl || ""}
              onChange={(e) => setCourse({ ...course, imageId: e })}
            />
            <CourseVideo
              urlInput={course?.videos?.attachmentUrl || ""}
              onChange={(e) => {
                setCourse({ ...course, videoId: e });
              }}
            />
          </>
        )}

        {pathname.split("/")[2] !== "translation" && (
          <>
            <FormLabel name="tags" />
            <SkeletonLoader loading={loading} rows={0} type="normal">
              <SearchTags name="tags" />
            </SkeletonLoader>
            {role === Role.ADMIN ? (
              <>
                <FormLabel name="mentors" />
                <SkeletonLoader loading={loading} rows={0} type="normal">
                  <SearchMentors name="mentors" />
                </SkeletonLoader>
                <FormLabel name="author" />
                <SkeletonLoader loading={loading} rows={0} type="normal">
                  <SearchAuthors
                    visible={pathname.split("/")[3] === "edit" ? true : false}
                  />
                </SkeletonLoader>
              </>
            ) : null}
          </>
        )}
        {!isTranslationPath && (
          <AddCourseQuiz
            onFormSubmit={(e) => setCourseQuiz(e)}
            initialValue={courseQuiz}
          />
        )}

        <FormLabel name="course_content" />
        {course?.type === CourseFormat.VIDEO && (
          <VideoCourseContent
            modules={course?.coursesModules || course?.modules || []}
            onChange={(modules: Module[]) =>
              setCourse({ ...course, coursesModules: modules })
            }
          />
        )}

        {course?.type === CourseFormat.DEFAULT && (
          <DefaultCourseContent
            modules={course?.coursesModules || course?.modules || []}
            onChange={(modules: Module[]) =>
              setCourse({ ...course, coursesModules: modules })
            }
          />
        )}

        <Row justify="start" style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onFormSubmit}
            loading={submitLoading}
          >
            <Translation input="submit" />
          </Button>
        </Row>
      </Form>
    </Card>
  );
}

export default CourseForm;
