import { CourseFormat } from "src/enums/CourseFormat";

interface Props {
  courseFormat: CourseFormat;
}

function CourseTag({ courseFormat }: Props) {
  const tagReturn = () => {
    switch (courseFormat) {
      case CourseFormat.DEFAULT:
        return <span className="badge bg-primary p-1">Course</span>;
      case CourseFormat.SCROM:
        return <span className="badge bg-primary p-1">Course</span>;
      case CourseFormat.XAPI:
        return <span className="badge bg-primary p-1">Course</span>;
      case CourseFormat.PDF:
        return <span className="badge bg-primary p-1">Course</span>;
      case CourseFormat.VIDEO:
        return <span className="badge bg-danger p-1">Video</span>;
    }
  };

  return <div className="pl-2 pr-2 rounded-3 fw-bold">{tagReturn()}</div>;
}

export default CourseTag;
