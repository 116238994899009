import { Form, Row, Col, Input, Button, Modal } from "antd";
import LessonContent from "./LessonContent";

interface Props {
  field: any;
  done: boolean;
}

function AddLesson({ field, done }: Props) {
  return (
    <Form.Item>
      <Form.List name={[field.fieldKey, "lessons"]}>
        {(fields, { add, remove }) => {
          return (
            <div
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="form_label">Lessons</div>
                  <Button onClick={() => add()}>+ Add Lesson</Button>
                </div>
              </Form.Item>

              <div>
                {fields.map((field, index) => (
                  <Col
                    sm={24}
                    style={{
                      border: "2px dashed #bfbfbf",
                      marginBottom: "20px",
                    }}
                  >
                    <Row justify="center" style={{ padding: "10px" }}>
                      <Col sm={20}>
                        <Form.Item
                          {...field}
                          name={[field.name, "title"]}
                          fieldKey={[field.fieldKey, "title"]}
                          key={index}
                          rules={[
                            {
                              required: true,
                              message: "Please add as Title",
                              type: "string",
                            },
                            {
                              min: 2,
                              message: "Must be minimum 2 characters.",
                            },
                          ]}
                        >
                          <Input placeholder="Lesson Title" />
                        </Form.Item>
                      </Col>
                      <Col sm={4}>
                        <Row justify="center">
                          <Button
                            onClick={() => {
                              Modal.confirm({
                                content:
                                  "Are you sure you want to remove this module",
                                onOk: () => remove(field.name),
                              });
                            }}
                            danger
                          >
                            Remove Lesson
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24}>
                        <LessonContent done={done} field={field} />
                      </Col>
                    </Row>
                  </Col>
                ))}
              </div>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
}

export default AddLesson;
