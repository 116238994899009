import ReactAudioPlayer from "react-audio-player";
interface Props {
  url: string;
}

function AudioPlayer({ url }: Props) {
  return <ReactAudioPlayer src={url} autoPlay={false} controls />;
}

export default AudioPlayer;
