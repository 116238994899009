import { useEffect, useState } from "react";
import { Table } from "antd";
import Column from "antd/lib/table/Column";
import { useAppDispatch } from "src/store/store";
import { useSelector } from "react-redux";
import TimeStamp from "src/components/Common/utilities/TimeStamp";
import {
  getSessionRequests,
  sessionRequestsSelectors,
} from "src/store/mentorSlices/sessionRequestsSlice";
import { SessionRequest } from "src/interfaces/SessionRequest";
import RequestActionButtons from "src/components/AdminAuthorMentorComponents/MentorComponents/SessionRequests/RequestActionButtons";
import RequestStatusTag from "src/components/AdminAuthorMentorComponents/MentorComponents/SessionRequests/RequestStatusTag";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import Translation from "src/components/Common/utilities/Translation";

function SessionRequestsPage() {
  const dispatch = useAppDispatch();
  const sessionRequests = useSelector(
    sessionRequestsSelectors.selectAllSessionRequests()
  );
  const [loading, setLoading] = useState(false);

  const tableHeader = () => (
    <h5>
      <Translation input="session_requests" />
    </h5>
  );

  useEffect(() => {
    document.title = `Learnsure Ai - Session Requests`;
  }, []);

  useEffect(() => {
    dispatch(getSessionRequests());
  }, []);

  return (
    <Table
      dataSource={sessionRequests}
      loading={loading}
      rowKey={(s) => s.id}
      title={tableHeader}
    >
      <Column
        title={<TableColTitle title="sr_no" />}
        key="sr"
        render={(_, __, index: number) => index + 1}
      />
      <Column
        title={<TableColTitle title="session_name" />}
        key="title"
        render={(_, sessionRequest: SessionRequest) => sessionRequest.topic}
      />
      <Column
        title={<TableColTitle title="time" />}
        key="time"
        render={(_, sessionRequest: SessionRequest) => (
          <TimeStamp timestamp={sessionRequest.startTime} />
        )}
      />
      <Column
        title={<TableColTitle title="status" />}
        key="actions"
        render={(record, sessionRequest: SessionRequest) => (
          <RequestStatusTag approved={sessionRequest.approved} />
        )}
      />
      <Column
        title={<TableColTitle title="actions" />}
        key="actions"
        render={(record, sessionRequest: SessionRequest) => (
          <RequestActionButtons
            sessionRequest={sessionRequest}
            approved={sessionRequest.approved}
            sessionRequestId={sessionRequest.id}
          />
        )}
      />
    </Table>
  );
}

export default SessionRequestsPage;
