import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QuizRender from "src/components/LearnerComponents/Course/QuizRender/QuizRender";
import { useAppDispatch } from "src/store/store";
import { setTabTitle } from "src/utils/utils";
import PageLoading from "src/components/Common/utilities/PageLoading";
import { getCourse } from "src/actions/courseActions/learnerCourseActions";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";

interface Params {
  slug: string;
}

function QuizPage() {
  const { slug } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const course = useSelector(coursesSelector.selectCourseEntityWithSlug(slug));
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (course) setLoading(false);
    else dispatch(getCourse(slug)).then(setLoading.bind(null, false));
    setTabTitle("Quiz");
  }, []);

  return (
    <div className="clearance container">
      {loading ? (
        <PageLoading />
      ) : (
        <div className="my-5 py-5">
          <div className="row">
            <div className="col-2"></div>
            <div className="col">
              <QuizRender quiz={course.coursesQuiz} />
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizPage;
