import { Form, Row, Col, Input, Button, Modal, Card } from "antd";
import UploadVideo from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadVideo";
import AddVideoQuiz from "./AddVideoQuiz";
import VideoLesson from "./VideoLesson";
import VideoQuiz from "./VideoQuiz";

interface Props {
  field: any;
  done: boolean;
}

function AddVideoLesson({ field, done }: Props) {
  return (
    <Form.List name={[field.name, "coursesVideos"]}>
      {(lessons, { add, remove, move }) => (
        <>
          {lessons.map((lesson, i) => (
            <Card hoverable={true} style={{ marginBottom: "10px" }}>
              <Row>
                <Col span={20}>
                  <Form.Item name={[lesson.name, "title"]}>
                    <Input placeholder="Video Title"></Input>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Row justify="center">
                    <Button onClick={() => remove(lesson.name)} danger>
                      Remove
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={24}>
                  <VideoLesson field={lesson} sectionId={i} />
                </Col>
              </Row>
              {/* for lesson level quiz */}
              {/* <Row>
                <Col sm={24}>
                  <AddVideoQuiz field={lesson} done={done} />
                </Col>
              </Row> */}
            </Card>
          ))}
          <Row justify="center">
            <Button onClick={() => add()}>Add Lesson</Button>
          </Row>
        </>
      )}
    </Form.List>
  );
}

export default AddVideoLesson;

// =======================================================================================
// import { Form, Row, Col, Input, Button, Modal } from "antd";
// import UploadVideo from "src/components/UploadVideo";

// interface Props {
//   field: any;
//   done: boolean;
// }

// function AddVideoLesson({ field, done }: Props) {
//   return (
//     <div>
//       <Form.Item>
//         <Form.List name={[field.fieldKey, "coursesVideos"]}>
//           {(fields, { add, remove }) => {
//             return (
//               <div
//                 style={{
//                   marginTop: "10px",
//                   width: "100%",
//                 }}
//               >
//                 <Form.Item>
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <div className="form_label">Lessons</div>
//                     <Button
//                       onClick={() => {
//                         add();
//                       }}
//                     >
//                       + Add Video Lesson
//                     </Button>
//                   </div>
//                 </Form.Item>

//                 <div>
//                   {fields.map((field, index) => (
//                     <Col
//                       sm={24}
//                       style={{
//                         border: "2px dashed #bfbfbf",
//                         marginBottom: "20px",
//                       }}
//                     >
//                       <Row justify="center" style={{ padding: "10px" }}>
//                         <Col sm={20}>
//                           <Form.Item
//                             {...field}
//                             name={[field.name, "title"]}
//                             fieldKey={[field.fieldKey, "title"]}
//                             key={index}
//                             rules={[
//                               {
//                                 required: true,
//                                 message: "Please add as Title",
//                                 type: "string",
//                               },
//                               {
//                                 min: 2,
//                                 message: "Must be minimum 2 characters.",
//                               },
//                             ]}
//                           >
//                             <Input placeholder="Video Title" />
//                           </Form.Item>
//                         </Col>
//                         <Col sm={4}>
//                           <Row justify="center">
//                             <Button
//                               onClick={() => {
//                                 Modal.confirm({
//                                   content:
//                                     "Are you sure you want to remove this module",
//                                   onOk: () => {
//                                     remove(field.name);
//                                   },
//                                 });
//                               }}
//                               danger
//                             >
//                               Remove Video Lesson
//                             </Button>
//                           </Row>
//                         </Col>
//                       </Row>
//                       <Row>
//                         <Col sm={24}>
//                           <UploadVideo
//                             done={done}
//                             field={field}
//                             sectionId={index}
//                           />
//                         </Col>
//                       </Row>
//                     </Col>
//                   ))}
//                 </div>
//               </div>
//             );
//           }}
//         </Form.List>
//       </Form.Item>
//     </div>
//   );
// }

// export default AddVideoLesson;
