function EmailSent({ email }: { email: string }) {
  return (
    <div>
      <h4
        style={{
          fontSize: "20.4px",
          fontWeight: 700,
          marginTop: "48px",
          marginBottom: "8px",
        }}
      >
        Check your email
      </h4>
      <p>An email with a reset password link has been sent to {email}</p>
    </div>
  );
}

export default EmailSent;
