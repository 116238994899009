import Translation from "./Translation";

interface Props {
  name: string;
}
function FormLabel({ name }: Props) {
  return (
    <h6 className="fw_normal font_family_nunito">
      <Translation input={name} />
    </h6>
  );
}
export default FormLabel;
