import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { logout } from "../authSlice";

import { api, extractStandardResponseData } from "../../api/api";
import { Course, CourseVideo } from "../../interfaces/Course";
import { Module } from "src/interfaces/Module";
import { Resources } from "src/interfaces/Resources";
import { Like } from "src/interfaces/Like";
export const popularCoursesAdapter = createEntityAdapter<Course>();

//TODO: delete this slice

interface GetAllCourseResponseI {
  id: number;
  courses: Course;
  coursesModules: Module[];
  courseAttachments: Resources;
  resources: Resources;
  mentorsCourses: [];
  coursesVideos: CourseVideo[];
  likes: Like[];
  myLike: Like[];
  views: number;
}

export const popularCoursesSlice = createSlice({
  name: "popularCourses",
  initialState: popularCoursesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () =>
      popularCoursesAdapter.getInitialState()
    );
  },
});

const { selectById, selectAll } = popularCoursesAdapter.getSelectors();

export const getPopularCoursesState = (state: RootState) =>
  state.popularCourses;

export const popularCoursesSelectors = {
  selectCourseEntity: (courseId: number) =>
    createSelector(getPopularCoursesState, (state) =>
      selectById(state, courseId)
    ),
  selectAllPopularCourses: () =>
    createSelector(getPopularCoursesState, (state) => selectAll(state)),
};
