import { useState } from "react";
import { Form, Select, Spin } from "antd";
import { useAppDispatch } from "src/store/store";
import { searchCourse } from "../../../../api/searchActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { Course } from "src/interfaces/Course";

function SearchCourses() {
  const [fetching, setFetching] = useState(false);
  const [data, setData]: any = useState([]);
  const dispatch = useAppDispatch();
  const { Option } = Select;

  const fetchCourses = (value: any) => {
    setFetching(true);
    setData([]);
    if (value.length > 3) {
      dispatch(searchCourse(value))
        .then(unwrapResult)
        .then((res) => {
          console.log(res);
          setFetching(false);
          setData(res);
        })
        .catch(() => {
          setFetching(false);
        });
    } else {
      setFetching(false);
    }
  };

  return (
    <Form.Item
      name="courses"
      label={<strong>Add Course</strong>}
      rules={[
        {
          required: true,
          message: "Please add a Course",
        },
      ]}
    >
      <Select
        size="large"
        showSearch
        labelInValue
        placeholder="Select Course"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={fetchCourses}
        style={{ width: "100%" }}
        autoClearSearchValue={true}
      >
        {data?.map((course: Course) => (
          <Option value={course.id} key={course.id}>
            {course.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SearchCourses;
