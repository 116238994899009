import React from "react";
import LearnsureLogo from "src/components/Common/utilities/LearnsureLogo";
import NotFound from "src/Assets/media/404-not-found.png";
import { useHistory } from "react-router";

function CommingSoonPage() {
  const history = useHistory();

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <LearnsureLogo />
            <div style={{ height: "25px" }}></div>
            <h5
              style={{
                fontSize: "20.4px",
                fontWeight: 500,
              }}
            >
              Coming Soon
            </h5>
          </div>
          {/* <img src={NotFound} alt="not-found" style={{ height: "50vh" }} /> */}

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          ></div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <button
              type="submit"
              className="login_page_submit_button"
              onClick={() => history.goBack()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommingSoonPage;
