import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import MentorCard from "../MentorCard/MentorCard";
import { MentorCourse } from "src/interfaces/Course";
import BookSessionModal from "../AssignedMentorsCarousel/BookSessionModal";

interface CourseMentorsCarouselPropsI {
  mentors: MentorCourse[];
}

function CourseMentorsCarousel({ mentors }: CourseMentorsCarouselPropsI) {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {}, [mentors]);
  return (
    <div style={{ maxWidth: "300px" }} dir="ltr">
      <Carousel
        showArrows={true}
        showStatus={false}
        selectedItem={currentSlide}
        showIndicators={false}
        showThumbs={false}
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
      >
        {mentors?.map((mentor: MentorCourse) => (
          <MentorCard user={mentor.users} key={mentor.id} />
        ))}
      </Carousel>
      <BookSessionModal />
    </div>
  );
}

export default CourseMentorsCarousel;
