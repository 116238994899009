import TextHeightWrapper from "src/components/Common/utilities/TextHeightWrapper";

interface props {
  title: string;
  contentString: string;
}
function CoursePathwayDetail({ title, contentString }: props) {
  return contentString.length > 11 ? (
    <div>
      <h5 className="mb-3 fw-bold">{title}</h5>
      <div className="card p-4 border_color border_radius fs-6">
        <TextHeightWrapper maxChars={500} inputString={contentString} />
      </div>
    </div>
  ) : (
    <></>
  );
}

export default CoursePathwayDetail;
