import React from "react";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { CourseVideo } from "src/interfaces/Course";

interface IProps {
  video: CourseVideo;
}

function Video({ video }: IProps) {
  return (
    <div>
      <VideoPlayer url={video.resources.attachmentUrl} />
    </div>
  );
}

export default Video;
