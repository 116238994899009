import NavIcons from "./NavBarIcons/NavIcons";
import NavSearchBar from "./NavSearchBar/NavSearchBar";
import "./NavigationBar.scss";
import NavBrand from "./NavBrand";

function NavigationBar() {
  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-dark fixed-top`}>
      <div className="container p-0">
        <NavBrand />
        <button
          className="navbar-toggler cursor_pointer text-light bg-light d-none d-sm-block d-md-block d-lg-none"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: "white" }}
            data-bs-target="#offcanvasExample"
          ></span>
        </button>
        {/* ======================================= */}
        <button
          className="navbar-toggler bg-light 	d-block d-sm-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: "white" }}
            data-bs-target="#offcanvasExample"
          ></span>
        </button>
        <div
          className="collapse navbar-collapse text-light"
          id="navbarSupportedContent"
        >
          <NavSearchBar />
          <NavIcons />
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
