import { Row, Collapse, Input, Col, Space, Button, Modal, message } from "antd";
import { Module } from "src/interfaces/Module";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation } from "react-router";
import Translation from "src/components/Common/utilities/Translation";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import VideoLessons from "./VideoLessons";
import { Lesson } from "src/interfaces/Lesson";
import { useEffect } from "react";

interface IProps {
  modules: Module[];
  onChange: (modules: Module[]) => void;
}

function VideoCourseContent({ modules, onChange }: IProps) {
  const { Panel } = Collapse;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isTranslationPath =
    pathname.split("/")[2] === "translation" ? true : false;

  const onModuleDragEnd = (e: any) => {
    if (!e.destination) return;
    let temp = [...modules];
    const [reorderedItem] = temp.splice(e?.source?.index, 1);
    temp.splice(e?.destination?.index, 0, reorderedItem);
    onChange(temp);
  };

  const onModuleTitleChange = (moduleId: number, title: string) =>
    onChange(
      modules.map((module: Module) =>
        module.id === moduleId
          ? {
              ...module,
              title,
            }
          : module
      )
    );

  const IsAddedModuleIsPopulated = (modules: Module[]) => {
    const lastModule: Module = modules[modules.length - 1];
    // conditions for checking if added module is filled
    //FIXME: add all edge case contitions like drag and drop
    if (lastModule.title.length === 0) {
      message.warning("Please Populate the module");
      return false;
    } else return true;
  };

  const onModuleAdd = () => {
    if (!IsAddedModuleIsPopulated(modules)) return;
    onChange([
      ...modules,
      {
        title: "",
        id: uuidv4(),
        lessons: [
          {
            id: uuidv4(),
            lessonsAttachments: [[{ type: null, sectionId: uuidv4() }]],
          },
        ],
      },
    ] as Module[]);
  };

  const onLessonChange = (lessons: Lesson[], modID: string | number) => {
    onChange(
      modules.map((module: Module) =>
        module.id === modID ? { ...module, lessons } : module
      )
    );
  };

  const onModuleDelete = (moduleId: number | string) =>
    onChange(modules.filter((module: Module) => module.id !== moduleId));

  useEffect(() => {}, [modules]);
  return (
    <>
      <DragDropContext onDragEnd={onModuleDragEnd}>
        <Droppable droppableId="modules">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {modules?.map((module: Module, i: number) => (
                <Draggable
                  key={module.id}
                  draggableId={`${module?.id}`}
                  index={i}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <Collapse accordion>
                        <Panel
                          header={
                            <Row>
                              <Col sm={18}>
                                <Input
                                  value={module.title}
                                  onChange={(e) => {
                                    onModuleTitleChange(
                                      module.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              {!isTranslationPath && (
                                <Col sm={6}>
                                  <Row justify="end" align="middle">
                                    <Space>
                                      <Button
                                        danger
                                        onClick={() =>
                                          Modal.confirm({
                                            content: `${t(
                                              "confirm.delete_module"
                                            )}`,
                                            onOk: () =>
                                              onModuleDelete(module.id),
                                          })
                                        }
                                      >
                                        <Translation input="remove_module" />
                                      </Button>
                                      <span {...provided.dragHandleProps}>
                                        <MenuOutlined />
                                      </span>
                                    </Space>
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          }
                          key={module.id}
                          forceRender={true}
                        >
                          <VideoLessons
                            moduleId={module.id}
                            videos={module.coursesVideos}
                            onChange={(e) => {}}
                          />
                        </Panel>
                      </Collapse>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Button type="primary" onClick={onModuleAdd}>
          Add Module
        </Button>
      </Row>
    </>
  );
}

export default VideoCourseContent;
