import {
  createEntityAdapter,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { User } from "src/interfaces/User";
import {
  getMostFrequentUsers,
  getAnalytics,
  getMostPopularCourses,
} from "src/actions/analyticsActions";
import { Course } from "src/interfaces/Course";

interface Views {
  views: number;
}
interface UserWithViews extends User, Views {}
interface ICount {
  date: string;
  count: number;
}
interface Analytics {
  mostFrequentUsers: UserWithViews[];
  mostPopularCourses: Course[];
  courses: ICount;
  learners: ICount;
  pathways: ICount;
  mentors: ICount;
  language: any;
  dates: any;
}

// export const analyticsAdapter = createEntityAdapter<Analytics>();

const initialState = {} as Analytics;

export const analyticsSlice = createSlice({
  name: "analytics",
  //   initialState: analyticsAdapter.getInitialState(),
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMostFrequentUsers.fulfilled, (state, { payload }) => ({
      ...state,
      mostFrequentUsers: payload?.map(
        (item: { user: User; views: number }) => ({
          ...item.user,
          views: item.views,
        })
      ),
    }));
    builder.addCase(getMostPopularCourses.fulfilled, (state, { payload }) => ({
      ...state,
      mostPopularCourses: payload?.map(
        (item: { courses: Course; views: number }) => ({
          ...item.courses,
          views: item.views,
        })
      ),
    }));
    builder.addCase(getAnalytics.fulfilled, (state, { payload }) => {
      const data = {
        courses: {
          date: payload.courseDate,
          count: payload.data[0].courses,
        },
        mentors: { date: payload.mentorDate, count: payload.data[0].mentors },
        pathways: {
          date: payload.pathwayDate,
          count: payload.data[0].pathways,
        },
        learners: { date: payload.userDate, count: payload.data[0].learners },
      };
      return {
        ...state,
        courses: data.courses,
        learners: data.learners,
        pathways: data.pathways,
        mentors: data.mentors,
        language: payload.language,
        dates: payload.dates,
      };
    });
  },
});

// const { selectById, selectAll } = analyticsAdapter.getSelectors();

const getAnalyticsState = (state: RootState) => state.analytics;

export const analyticsSelectors = {
  selectMostFrequentUsers: () =>
    createSelector(getAnalyticsState, (state) => state.mostFrequentUsers),
  selectMostPopularCourses: () =>
    createSelector(getAnalyticsState, (state) => state.mostPopularCourses),
  numberOfCourses: () =>
    createSelector(getAnalyticsState, (state) => state.courses),
  numberOfLearners: () =>
    createSelector(getAnalyticsState, (state) => state.learners),
  numberOfPatheays: () =>
    createSelector(getAnalyticsState, (state) => state.pathways),
  selectAnalytics: () => createSelector(getAnalyticsState, (state) => state),
};
