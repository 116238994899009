import { Input, Form } from "antd";
import { useState } from "react";
import ReactQuill from "react-quill";
import { LessonFormat } from "src/enums/LessonFormats";

interface Props {
  done: boolean;
  field?: any;
  sectionId: number;
}

function TextSection({ field, done, sectionId }: Props) {
  const [finalValue, setFinalValue] = useState({
    sectionId: sectionId,
    lessonText: "",
    type: LessonFormat.TEXT,
  });

  const onChangeHandler = (value: string) => {
    console.log(value);
    setFinalValue({ ...finalValue, lessonText: value });
  };

  return (
    <div>
      <ReactQuill onChange={onChangeHandler} value={finalValue.lessonText} />

      {done ? (
        <Form.Item
          name={[field.name, "lessonsAttachments"]}
          initialValue={finalValue}
        >
          <Input type="hidden" />
        </Form.Item>
      ) : null}
    </div>
  );
}

export default TextSection;
