import { Form, Input } from "antd";
import { useState } from "react";
import { LessonFormat } from "src/enums/LessonFormats";

interface Props {
  done: boolean;
  field?: any;
  sectionId: number;
}

function SpacerSection({ field, done, sectionId }: Props) {
  const [finalValue, setFinalValue] = useState({
    sectionId: sectionId,
    type: LessonFormat.SPACER,
    lessonText: "",
  });
  return (
    <>
      <Form.Item>
        <Input
          onChange={(e) =>
            setFinalValue({ ...finalValue, lessonText: e.target.value })
          }
          placeholder="Enter spacer height in pixels"
        ></Input>
      </Form.Item>
      {done ? (
        <div>
          <Form.Item
            name={[field.name, "lessonsAttachments"]}
            initialValue={finalValue}
          >
            <Input type="hidden" />
          </Form.Item>
        </div>
      ) : null}
    </>
  );
}

export default SpacerSection;
