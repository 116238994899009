import { useEffect } from "react";
import { api, setAxiosToken } from "src/api/api";
import { saveToken } from "src/api/localStorage";

const Pilot = () => {

    useEffect(() => {
        const url = new URL(window.location.href);
        const paramValue = url.searchParams.get("ref");
        if (!paramValue) {
            window.location.href = "/";
            return;
        }

        api.post("/auth/pilotLogin", { token: paramValue }).then((res) => {
            setAxiosToken(res.data.token);
            saveToken(res.data.token);
            if (res?.data?.pathway?.slug) {
                window.location.href = `/pathway/description/${res?.data?.pathway?.slug}`;
            }
            else {
                window.location.href = `/`;
            }

        })

    }, [])


    return (null)
}

export default Pilot