import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Form, Row, Col, Card, Typography, Space, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useAppDispatch } from "src/store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import {
  editCourse,
  coursesSelector,
  clearCourses,
} from "src/store/coursesSlices/coursesSlice";
import { Course } from "src/interfaces/Course";
import { CourseFormat } from "src/enums/CourseFormat";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import CourseImage from "../EditCoursePageFormFields/CourseImage";
import SearchFields from "../EditCoursePageFormFields/SearchFields";
import BasicFields from "../EditCoursePageFormFields/BasicFields";
import ZipFields from "./ZipFields";

import CourseFormatTag from "src/components/AdminAuthorMentorComponents/Common/CourseFormatTag";
import CoursePreviewVideo from "../EditCoursePageFormFields/CoursePreviewVideo";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import { LanguageCode } from "src/enums/Language";
import { setTabTitle } from "src/utils/utils";
// import { cardTitle } from "src/utils/courseUtils";
import {
  addLanguageToCourse,
  getSingleCourse,
} from "src/actions/courseActions/courseActions";
import { getLanguageNameFromLanguageCode } from "src/utils/Rest/translationUtils";

interface Params {
  courseId: string;
}
function EditScromXapiCourse() {
  const location = useLocation();
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState<string | null>();
  const [courseDetails, setCourseDetails]: any = useState<Course>();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);

  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { courseId } = useParams<Params>();
  const [form] = useForm();
  const translatedToLanguage: LanguageCode = location.pathname
    .split("/")[4]
    ?.split("-")[1] as LanguageCode;
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;

  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );

  const onSave = () => {
    setSaveDraftLoading(true);
    dispatch(
      addLanguageToCourse({
        title: form.getFieldsValue().title,
        excerpt: form.getFieldsValue().excerpt,
        description: description,
        objectives: objectives,
        id: parseInt(courseId),
        language: [location.pathname.split("/")[4].split("-")[1]],
        modules: [],
      })
    ).then(() => {
      setSaveDraftLoading(false);
      message.success({
        content: `Saved`,
      });
    });
  };
  const onFormSubmit = () => {
    setSubmitLoading(true);
    setDone(true);
    const editedCourse = {
      ...form.getFieldsValue(),
      courseAttachment:
        form.getFieldsValue().courseAttachment ||
        courseDetails.courseAttachment,
      id: parseInt(courseId),
      videoId: form.getFieldsValue().previewVideo,
      description: description,
      objectives: objectives,
      authorId: form.getFieldsValue().authorId?.value,
      tags: form.getFieldsValue().tags?.map((item: any) => item.value),
      mentors: form.getFieldsValue().mentors?.map((item: any) => item.value),
      modules: [],
      quiz: [],
    };

    if (!form.getFieldsValue().previewVideo) {
      delete editedCourse.videoId;
    }

    if (!isTranslationPath) {
      dispatch(editCourse(editedCourse))
        .then(unwrapResult)
        .then(() => {
          role === Role.ADMIN
            ? history.push("/admin/courses")
            : history.push("/author");
          setSubmitLoading(false);

          // dispatch(clearCourses()).then(() => {
          //   role === Role.ADMIN
          //     ? history.push("/admin/courses")
          //     : history.push("/author");
          //   setSubmitLoading(false);
          // });
        })
        .catch(() => setSubmitLoading(true));
    } else {
      delete editedCourse.mentors;
      delete editedCourse.authorId;
      delete editedCourse.tags;
      delete editedCourse.modules;
      delete editedCourse.courseAttachment;
      dispatch(
        addLanguageToCourse({
          ...editedCourse,
          completed: true,
          language: [translatedToLanguage],
        })
      ).then(() => {
        dispatch(clearCourses()).then(() => {
          role === Role.ADMIN
            ? history.push("/admin/courses")
            : history.push("/author");
          setSubmitLoading(false);
        });
      });
    }
  };

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Typography.Title level={5}>
        {isTranslationPath
          ? getLanguageNameFromLanguageCode(translatedToLanguage)
          : "Edit Course"}
      </Typography.Title>
      {course && (
        <Space>
          {isTranslationPath ? (
            <Button type="primary" onClick={onSave} loading={saveDraftLoading}>
              Save Draft
            </Button>
          ) : (
            <CourseFormatTag course={course} />
          )}
        </Space>
      )}
    </Row>
  );

  useEffect(() => {
    dispatch(getSingleCourse(parseInt(courseId)))
      .then(unwrapResult)
      .then((res: Course) => {
        setTabTitle(res.title);
        const course = isTranslationPath
          ? res.languages.find(
              (course: Course) => course.language[0] === translatedToLanguage
            )
          : res;
        if (!course) return;
        setCourseDetails({ ...course });
        setLoading(false);
        if (!isTranslationPath) {
          setVideoUrl(course?.videos?.attachmentUrl);
          setImageUrl(course.resources.attachmentUrl);
          form.setFieldsValue({
            authorId: {
              label: `${course.users.firstName} ${course.users.lastName}`,
              value: course.users.id,
            },
          });
        }
        setDescription(course.description);
        setObjectives(course.objectives);
        form.setFieldsValue({
          title: course.title,
          excerpt: course.excerpt,
          tags: course.coursesTags?.map((item: any) => ({
            label: item.tags.value,
            value: item.tags.id,
          })),
          mentors: course.mentorsCourses?.map((item: any) => ({
            label: `${item.users.firstName} ${item.users.lastName}`,
            value: item.users.id,
          })),
          // authorId: {
          //   label: `${course.users.firstName} ${course.users.lastName}`,
          //   value: course.users.id,
          // },
        });
      });
  }, [location.pathname]);

  return (
    <Row>
      <Col sm={24}>
        <Card
          // title={cardTitle(course, isTranslationPath, translatedToLanguage)}
          title={cardTitle}
        >
          <Form name="basic" form={form} onFinish={onFormSubmit}>
            <BasicFields loading={loading} />
            <FormLabel name="description" />
            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setDescription(value)}
                  value={description}
                />
              </Form.Item>
            </SkeletonLoader>
            <FormLabel name="objectives" />
            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setObjectives(value)}
                  value={objectives}
                />
              </Form.Item>
            </SkeletonLoader>
            {!isTranslationPath && (
              <>
                <CourseImage done={done} imageUrl={imageUrl} />
                <CoursePreviewVideo done={done} videoUrl={videoUrl} />
                <SearchFields loading={loading} />
              </>
            )}
            <div style={{ marginBottom: "10px" }}>
              {!isTranslationPath && (
                <>
                  {course?.type === CourseFormat.SCROM ||
                  course?.type === CourseFormat.XAPI ? (
                    <ZipFields course={courseDetails} done={done} />
                  ) : null}
                </>
              )}

              <Row justify="end">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitLoading}
                    disabled={disableSubmit}
                  >
                    <Translation input="submit" />
                  </Button>
                </Form.Item>
              </Row>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default EditScromXapiCourse;
