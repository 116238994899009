import { useTranslation } from "react-i18next";
import { Course } from "src/interfaces/Course";
import CertificateComp from "./CertificateComp";

interface Props {
  course: Course;
}

function Certificates({ course }: Props) {
  const { t } = useTranslation();

  return (
    <div className="row">
      {course.certificate && (
        <h5 className="font_family_poppins">{t("certificates")}</h5>
      )}
      <div className="col-5">
        {course.certificate && <CertificateComp course={course} />}
      </div>
    </div>
  );
}

export default Certificates;
