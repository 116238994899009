import React from "react";
import { Course } from "src/interfaces/Course";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import CourseViewIcon from "src/components/LearnerComponents/Course/CourseIcons/CourseViewIcon";
import Like from "src/components/LearnerComponents/Course/CourseIcons/Like";
import CourseCommentIcon from "src/components/LearnerComponents/Course/CourseIcons/CourseCommentIcon";
import ImageRender from "src/components/Common/mediaPlayers/ImageRender";
import parse from "html-react-parser";
interface Props {
  course: Course;
}
function InfographiaContent({ course }: Props) {
  console.log(course);
  return (
    <div className="container clearance">
      <div style={{ width: "50vw", margin: "0 auto" }} className="pt-4 pb-3">
        <ImageRender url={course.resources.attachmentUrl} />
      </div>
      <h2 style={{ textAlign: "center", padding: "20px" }}>{course.title}</h2>
      <div className="d-flex justify-content-center gap-3">
        <CourseViewIcon views={course?.views} />
        <Like course={course} />
        <CourseCommentIcon />
      </div>
      <h4 style={{ textAlign: "center", padding: "20px" }}>
        {parse(course.description ? course.description : "")}
      </h4>
    </div>
  );
}

export default InfographiaContent;
