import CommentIcon from "src/Assets/media/comment-icon.svg";

function CourseCommentIcon() {
  return (
    <div className="d-flex align-items-center">
      <img
        src={CommentIcon}
        width="16px"
        height="16px"
        alt=""
        className="mt-1"
      />
      <div style={{ width: "0.4rem" }}></div>
      <div className="fs-7">30</div>
    </div>
  );
}

export default CourseCommentIcon;
