import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import {
  pathwaysSelectors,
  getPathwayWithSlug,
} from "src/store/pathways/pathwaysSlice";
import PathwayCourseCard from "src/components/LearnerComponents/Pathways/PathwayCourseCard";
import { PathWayCourse } from "src/interfaces/Course";
import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import PathwayDescriptionHero from "src/components/LearnerComponents/Pathways/PathwayDescriptionHero/PathwayDescriptionHero";
import DownloadApp from "src/components/LearnerComponents/DownloadApp";
import PathwayMentorsCarousel from "./PathwayMentorsCarousel";
import CoursePathwayDetail from "src/components/LearnerComponents/UtilityComponents/CoursePathwayDetail";
import RelatedPathways from "src/components/LearnerComponents/RelatedPathways/RelatedPathways";
import NotFoundPage from "src/pages/CommonPages/NotFoundPage";
import { addPathwayView } from "src/actions/PathwayActions/pathwayActions";
import { useTranslation } from "react-i18next";
interface Params {
  slug: string;
}
function PathwayDescriptionPage() {
  const myRef = useRef<HTMLInputElement>(null);

  const slug = useParams<Params>().slug;
  const pathway = useSelector(
    pathwaysSelectors.selectPathwayEntityWithSlug(slug)
  );
  const [loading, setLoading] = useState(true);
  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (myRef.current) {
      console.log("ref", myRef);
      console.log(myRef);
    } else {
      console.log("no ref", myRef);
    }
  });

  useEffect(() => {
    // if (pathway) setLoading(false);

    dispatch(getPathwayWithSlug(slug)).then((resp) => {
      setLoading(false);
      dispatch(addPathwayView(resp?.payload?.id));
    });
    document.title = `Learnsure Ai - Pathway`;
  }, []);

  if (loading) {
    return (
      <div className="clearance">
        <div
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .80), rgba(0, 0, 0, .80)), url("${pathway?.resources?.attachmentUrl}")`,
          }}
          className="hero_section"
        >
          <div className="container">
            <div className="row py-5">
              <div className="col-md-9 p-0">
                <PathwayDescriptionHero pathway={pathway} loading={loading} />
              </div>
            </div>
            <div className="row py-2"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="clearance">
      {pathway?.published || role === Role.ADMIN || role === Role.AUTHOR ? (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .80), rgba(0, 0, 0, .80)), url("${pathway?.resources?.attachmentUrl}")`,
            }}
            className="hero_section"
          >
            <div className="container">
              <div className="row py-5">
                <div className="col-md-9 p-0">
                  <PathwayDescriptionHero
                    pathway={pathway}
                    loading={loading}
                    reference={myRef}
                  />
                </div>
                <div className="col-md-3 d-none d-lg-block">
                  <PathwayMentorsCarousel pathwayId={pathway?.id} />
                </div>
              </div>
              <div className="row py-2"></div>
            </div>
          </div>

          {/* ------------------------- content  ------------------------------- */}

          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-8">
                <div className="mt-5">
                  <CoursePathwayDetail
                    title={t("pathway_description")}
                    contentString={pathway?.description || ""}
                  />
                </div>
                <div className="mt-4">
                  <CoursePathwayDetail
                    title={t("learning_goals")}
                    contentString={pathway?.objectives || ""}
                  />
                </div>

                <div className="mt-3" ref={myRef}>
                  <div>
                    <div className="mb-3">
                      <h5 className="mb-2">{t("courses")}</h5>

                      {pathway?.pathwaysCourses.length === 0 ? (
                        <>no courses have been added to this pathway</>
                      ) : null}
                    </div>
                    {pathway?.pathwaysCourses
                      .filter((item) => item.courses !== null)
                      .map((pathwayCourse: PathWayCourse, index: number) => {
                        return (
                          <PathwayCourseCard
                            pathway={pathwayCourse}
                            key={pathwayCourse.id}
                            index={index}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-lg-4">
                <div className="mt-5">
                  <RelatedPathways />
                </div>
                <div className="mt-5">
                  <DownloadApp />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
}

export default PathwayDescriptionPage;
