import { useEffect, useState } from "react";
import { Table, Col, Row, Space, Avatar, Pagination, Image } from "antd";
import { useSelector } from "react-redux";
import Column from "antd/lib/table/Column";
import { RootState, useAppDispatch } from "../../../store/store";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import { certificateSelectors } from "src/store/certificatesSlices/certificatesSlice";
import { getAllCertificates } from "src/actions/certificateActions";
import { Certificate } from "src/interfaces/Certificate";

function CertificatesPage() {
  const certificates = useSelector(
    certificateSelectors.selectAllCertificates()
  );
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const tableHeader = () => (
    <Row justify="space-between">
      <Col>
        <Space>
          <h5 style={{ margin: 0 }}>Certificates</h5>
        </Space>
      </Col>
    </Row>
  );

  useEffect(() => {
    dispatch(getAllCertificates()).then(() => {
      // dispatch();
      setLoading(false);
    });

    // then(setLoading.bind(null, false));
  }, []);

  return (
    <>
      <Row justify="center" align="middle">
        {console.log(certificates)}
        <Col sm={24}>
          <Table
            title={tableHeader}
            dataSource={certificates.filter((cer: Certificate) => cer.title)}
            loading={loading}
            rowKey={(s) => s.id}
          >
            <Column
              title={<TableColTitle title="Cover" />}
              dataIndex="actions"
              key="actions"
              render={(_, cer: Certificate) => (
                <>
                  <Image src={cer.resources.attachmentUrl} height={50} />
                </>
              )}
            />
            <Column
              title={<TableColTitle title="Title" />}
              dataIndex="actions"
              key="actions"
              render={(_, cer: Certificate) => <>{cer.title}</>}
            />
          </Table>
          {/* <Row justify="end" style={{ marginTop: "20px" }}>
            <Pagination
              defaultCurrent={1}
              total={2}
              showSizeChanger={false}
              onChange={(p) => setPage(p - 1)}
            />
          </Row> */}
        </Col>
      </Row>
    </>
  );
}

export default CertificatesPage;
