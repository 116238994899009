import axios, { AxiosResponse } from "axios";
import { store } from "src/store/store";
import { message } from "antd";
import { logout } from "src/store/authSlice";

// export const baseUrl = "http://localhost:4000";
export const baseUrl = "https://app-api.learnsure.ai";

// export const baseUrl = "http://3.111.68.12/";

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    ["Content-Type"]: "application/json",
  },
});
export const apiTags = axios.create({
  baseURL: baseUrl,
  headers: {
    ["Content-Type"]: "application/json",
  },
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response?.data?.message) {
      if (error.response?.data?.message === "User session expired") {
        store.dispatch(logout());
      }
      message.error(error.response?.data?.message);
      console.log(error.response?.data);
      // store.dispatch(logout()).then(() => {});
    }
    return Promise.reject(error);
  }
);

export const setAxiosToken = (token: any) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + token;
  apiTags.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const clearAxiosToken = () => {
  api.defaults.headers.common["Authorization"] = "";
  apiTags.defaults.headers.common["Authorization"] = "";
};

export const extractStandardResponseData = (res: AxiosResponse) =>
  res.data.data;
