import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";

interface Props {
  sessionId: number;
  deactivated: boolean;
}

function StartSessionButton({ sessionId, deactivated }: Props) {
  const history = useHistory();
  return (
    <Button
      type="primary"
      disabled={deactivated}
      onClick={() => history.push(`/mentor/zoom-session/${sessionId}`)}
    >
      <Translation input="start" />
    </Button>
  );
}

export default StartSessionButton;
