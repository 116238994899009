import SearchTags from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import SearchAuthors from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchAuthors";
import SearchMentors from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchMentors";
import { Role } from "src/interfaces/User";
import { useSelector } from "react-redux";
import { authSelectors } from "src/store/authSlice";
import FormLabel from "src/components/Common/utilities/FormLabel";

function CreateCourseSearchFields({ showAuthor }: { showAuthor: boolean }) {
  const role = useSelector(authSelectors.role);

  return (
    <>
      <FormLabel name="tags" />
      <SearchTags name="tags" />
      {role === Role.ADMIN ? (
        <>
          <FormLabel name="author" />
          <SearchAuthors visible={false} />
          <FormLabel name="mentors" />
          <SearchMentors name="mentors" />
        </>
      ) : null}
    </>
  );
}

export default CreateCourseSearchFields;
