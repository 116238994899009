import { Form, Modal, Button, Input, Row, Col, Card } from "antd";
import { useState } from "react";
import FormLabel from "src/components/Common/utilities/FormLabel";
import AddVideoLesson from "./AddVideoLesson";

interface Props {
  done: boolean;
  form: any;
}
function VideoCourseFormComp({ done, form }: Props) {
  return (
    <>
      <FormLabel name="Add Modules" />
      <Form.List name="modules">
        {(modules, { add, remove, move }) => (
          <>
            {modules.map((module, i) => (
              <Card
                hoverable={true}
                style={{ marginBottom: "10px" }}
                key={module.name}
              >
                <Row>
                  <Col span={20}>
                    <Form.Item
                      {...module}
                      name={[module.name, "title"]}
                      // fieldKey={[module.fieldKey, "title"]}
                    >
                      <Input placeholder="Module Title"></Input>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Row justify="center">
                      <Button onClick={() => remove(module.name)} danger>
                        Remove
                      </Button>
                    </Row>
                  </Col>
                </Row>

                <AddVideoLesson field={module} done={done} />
              </Card>
            ))}
            <Row justify="center">
              <Button onClick={() => add()}>Add Module</Button>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
}

export default VideoCourseFormComp;
// =====================================================================================
// =====================================================================================
// =====================================================================================
// =====================================================================================
// =====================================================================================

// import { Form, Modal, Button, Input, Row, Col } from "antd";
// import UploadVideo from "src/components/UploadVideo";
// import AddLesson from "../../../../components/AddLesson";
// import AddVideoLesson from "./AddVideoLesson";

// interface Props {
//   done: boolean;
// }
// function VideoCourseFormComp({ done }: Props) {
//   return (
//     <div>
//       <h6 className="form_label" style={{ marginBottom: "10px" }}>
//         Add Modules
//       </h6>
//       <Form.List name="modules">
//         {(fields, { add, remove }) => {
//           return (
//             <div>
//               {fields.map((field, i) => {
//                 return (
//                   <div style={{ boxShadow: "1px 1px 4px #888888" }}>
//                     <Form.Item
//                       required={false}
//                       key={field.key}
//                       style={{
//                         border: "1px solid #eee",
//                         padding: "15px",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       <Form.Item>
//                         <div
//                           style={{
//                             display: "flex",
//                             justifyContent: "space-between",
//                           }}
//                         >
//                           <div className="form_label">Module Title</div>
//                           <Button
//                             onClick={() => {
//                               Modal.confirm({
//                                 content:
//                                   "Are you sure you want to remove this module",
//                                 onOk: () => {
//                                   remove(field.name);
//                                 },
//                               });
//                             }}
//                           >
//                             Remove Module
//                           </Button>
//                         </div>
//                       </Form.Item>

//                       <Form.Item
//                         {...field}
//                         name={[field.name, "title"]}
//                         fieldKey={[field.fieldKey, "title"]}
//                         validateTrigger={["onChange", "onBlur"]}
//                         noStyle
//                         rules={[
//                           {
//                             required: true,
//                             message: "Please add as Title",
//                             type: "string",
//                           },
//                           {
//                             min: 2,
//                             message: "Must be minimum 2 characters.",
//                           },
//                         ]}
//                       >
//                         <Input />
//                       </Form.Item>

//                       <AddVideoLesson done={done} field={field} />
//                     </Form.Item>
//                   </div>
//                 );
//               })}
//               <Row justify="center">
//                 <Form.Item>
//                   <Button
//                     onClick={() => {
//                       add();
//                     }}
//                   >
//                     + Add Module
//                   </Button>
//                 </Form.Item>
//               </Row>
//             </div>
//           );
//         }}
//       </Form.List>
//     </div>
//   );
// }

// export default VideoCourseFormComp;
