import NavigationBar from "./Navbar/NavigationBar";
import LearnerLayoutFooter from "./LearnerLayoutFooter";
import ProfilePhotoChangeModal from "./ProfilePhotoChangeModal";
import { useLocation } from "react-router-dom";
import OffCanvas from "./OffCanvas";
import { useSelector } from "react-redux";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import UnauthorizedAccess from "src/components/Common/authorization/UnauthorizedAccess";
import React, { Suspense } from "react";
import PageLoading from "src/components/Common/utilities/PageLoading";

const LearnerStyles = React.lazy(
  () => import("src/Assets/styles/LearnerStyles")
);

interface Props {
  component: JSX.Element;
  bypassRoleCheck?: boolean;
}

function LearnerLayout({ component, bypassRoleCheck }: Props) {
  const location = useLocation();
  const role = useSelector(authSelectors.role);

  return role === Role.LEARNER || bypassRoleCheck ? (
    <Suspense fallback={<PageLoading />}>
      <LearnerStyles />
      <NavigationBar />
      <OffCanvas />
      <ProfilePhotoChangeModal />
      <div style={{ minHeight: "80vh" }}>{component}</div>
      {location.pathname.split("/")[1] === "course-content" ? null : (
        <LearnerLayoutFooter />
      )}
    </Suspense>
  ) : (
    <UnauthorizedAccess />
  );
}

export default LearnerLayout;
