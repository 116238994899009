import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { User } from "src/interfaces/User";
import { store } from "src/store/store";
import { loginSuccess, logout } from "src/store/authSlice";
import i18next from "i18next";

import socketClient from "socket.io-client";
import ImageRender from "src/components/Common/mediaPlayers/ImageRender";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import AudioPlayer from "src/components/Common/mediaPlayers/AudioPlayer";
import { LessonFormat } from "src/enums/LessonFormats";
import cookies from "js-cookie";
import { LessonsAttachment } from "src/interfaces/Lesson";
export const getUrlExtention = (url: string): string => {
  const extention = url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
  return extention ? extention : "";
};

export const getFileExtention = (path: string) => path.split(".").pop();

export const getFileNameFromUrl = (url: string) => url.split("attachments/")[1];

export const blackFieldCheck = (input: any, returnItem: any) => {
  if (input.length > 0) return returnItem;
};

export const maxCharsLength = (maxChars: number, inputString: string) => {
  return inputString?.length > maxChars
    ? `${inputString.substring(0, maxChars)}...`
    : inputString;
};

export const getZipFileNameFromUrl = (url: string) =>
  url?.split("zipfiles/")[1]?.split(".zip/")[0] + ".zip";

export const apiCall = async (inputFunction: any) => {
  try {
    const data = await inputFunction;
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function timeSince(isoString: string) {
  const date: any = new Date(isoString);
  const presentDate: any = new Date();
  var seconds = Math.floor((presentDate - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export const customAlert = (user: User) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          style={{ backgroundColor: "white", padding: "15px" }}
          className="border_radius"
        >
          <h2>Already Logged in</h2>
          <p>
            {user.email} is already logged in another session, <br />
            are you sure you want to log out of previous session?
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="login_page_submit_button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="login_page_submit_button"
              onClick={() => {
                onClose();
                store.dispatch(loginSuccess(user));
              }}
              style={{ marginLeft: "5px" }}
            >
              OK
            </button>
          </div>
        </div>
      );
    },
  });
};

export const customAlertUserOnboarding = () => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          style={{ backgroundColor: "white", padding: "15px" }}
          className="border_radius"
        >
          <h2>Already Logged in</h2>
          <p>
            Already logged in another session, <br />
            are you sure you want to log out of previous session?
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="login_page_submit_button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="login_page_submit_button"
              onClick={() => {
                onClose();
                store.dispatch(logout());
              }}
              style={{ marginLeft: "5px" }}
            >
              OK
            </button>
          </div>
        </div>
      );
    },
  });
};

export const setDirection = (code: string) => {
  if (code === "ar") document.body.dir = "rtl";
  else document.body.dir = "ltr";
};

export const getDirection = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  if (currentLanguageCode === "ar") return "rtl";
  else return "ltr";
};

export const setTabTitle = (input?: string) => {
  document.title = `Learnsure Ai ${input && `- ${input}`}`;
};

export const setLanguage = (code: string) => {
  i18next.changeLanguage(code);
};

export const openNewTab = (link: string) => window.open(link, "_blank");

export const removeHypenAddSpace = (input: string) => input.replace("-", " ");

//group attachments with same sectionId
export const groupAttachmentsFunction = (
  lessonsAttachments: LessonsAttachment[]
): LessonsAttachment[][] => {
  return Object.values(
    lessonsAttachments?.reduce((prev, current, index) => {
      if (!prev[current.sectionId]) {
        prev[current.sectionId] = [];
      }
      prev[current.sectionId].push(current);
      return prev;
    }, {})
  );
};

export const topOfPage = () => window.scrollTo(0, 0);
