import { PageHeader, Col, Row, Card, Form, Input, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import CoursesDraggableFormItem from "./CoursesDraggableFormItem";
import SearchTags from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import UploadImage from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";
import { useAppDispatch } from "src/store/store";
import { createPathway } from "../../../../store/pathways/pathwaysSlice";
import { Course } from "src/interfaces/Course";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";

function CreatePathwayPage() {
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const history = useHistory();
  const { TextArea } = Input;
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const cardHeader = <h5>Add New Pathway</h5>;

  const onFormSubmit = () => {
    setLoading(true);
    document.title = `Learn Sure . Create Pathway`;
    setDone(true);

    const pathway = {
      ...form.getFieldsValue(),
      description,
      objectives,
      pathwaysTags: form
        .getFieldsValue()
        .pathwaysTags?.map((item: any) => item.value),
      pathwaysCourses: form
        .getFieldsValue()
        .courses?.map((item: Course, index: number) => ({
          courseId: item.id,
          positionId: index,
        })),
    };
    dispatch(createPathway(pathway))
      .then((res) => {
        history.push("/admin/pathways");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error("Error creating pathway");
      });
  };

  return (
    <Row>
      <Col sm={24}>
        <Card title={cardHeader}>
          <Form
            form={form}
            name="basic"
            onFinish={onFormSubmit}
            scrollToFirstError={true}
          >
            <div>
              <FormLabel name="title" />
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please add a Title for the course",
                    type: "string",
                  },
                  {
                    min: 2,
                    message: "Title must be minimum 2 characters.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <FormLabel name="excerpt" />
              <Form.Item
                name="excerpt"
                rules={[
                  {
                    required: true,
                    message: "Please add as excerpt for the course",
                    type: "string",
                  },
                  {
                    min: 2,
                    message: "excerpt must be minimum 2 characters.",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <FormLabel name="description" />
              <Form.Item>
                <ReactQuill
                  onChange={(value) => {
                    setDescription(value);
                  }}
                  value={description}
                />
              </Form.Item>

              <FormLabel name="objectives" />
              <Form.Item>
                <ReactQuill
                  onChange={(value) => {
                    setObjectives(value);
                  }}
                  value={objectives}
                />
              </Form.Item>

              <FormLabel name="tags" />
              <SearchTags name="pathwaysTags" />

              <FormLabel name="course_image" />
              <UploadImage done={done} name="imageId" nested={false} />
              <FormLabel name="courses" />
              <CoursesDraggableFormItem done={done} />
            </div>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                <Translation input="submit" />
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default CreatePathwayPage;
