import Loader from "react-loader-spinner";

function PageLoading() {
  return (
    <div className="page_loader">
      <Loader type="Oval" color="#0077ff" height={70} width={70} />
    </div>
  );
}

export default PageLoading;
