import { useState } from "react";
import { Menu, Button, Modal } from "antd";
import Translation from "src/components/Common/utilities/Translation";
import { useHistory, useLocation } from "react-router-dom";
import MultipleUsersUploadForm from "../MultipleUsersUploadForm";
import {
  FaChartPie,
  FaListUl,
  FaRoad,
  FaUsers,
  FaGraduationCap,
  FaUserCog,
} from "react-icons/fa";
import { PieChartOutlined } from "@ant-design/icons";

function AdminMenu({ sliderOpen }: { sliderOpen: boolean }) {
  const { SubMenu } = Menu;
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const onMenuItemSelect = (e) => history.push(e.key);
  const history = useHistory();

  const iconStyle = {
    marginBottom: "-3px",
    fontSize: "16px",
    color: "white",
  };

  const fontStyle = { color: "white", fontSize: "16px" };

  return (
    <>
      <Menu
        // openKeys={selectMenuKey()}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[`${location.pathname}`]}>
        <Menu.Item
          key="/admin/dashboard"
          // icon={<PieChartOutlined />}
          icon={<FaChartPie style={iconStyle} />}
          onClick={onMenuItemSelect}
          className="font_family_nunito"
          style={fontStyle}>
          <Translation input="dashboard" />
        </Menu.Item>

        <SubMenu
          key="1"
          icon={
            <FaListUl
              style={{ ...iconStyle, marginRight: "10px" }}
              className="ant-menu-item-icon"
            />
          }
          title={<Translation input="course" />}
          className="font_family_nunito"
          style={{ ...fontStyle }}>
          <Menu.Item key="/admin/courses" onClick={onMenuItemSelect}>
            <Translation input="courses" />
          </Menu.Item>
          <Menu.Item
            key="/admin/getWebwatchAndInfographia"
            onClick={onMenuItemSelect}>
            <Translation input="multimedia" />
          </Menu.Item>
          <Menu.Item key="/admin/create-course" onClick={onMenuItemSelect}>
            <Translation input="upload_course" />
          </Menu.Item>
          <Menu.Item key="/admin/add-webwatch" onClick={onMenuItemSelect}>
            <Translation input="add_webwatch" />
          </Menu.Item>
          <Menu.Item key="/admin/add-infographia" onClick={onMenuItemSelect}>
            <Translation input="add_infographia" />
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="2"
          icon={
            <FaRoad
              style={{ ...iconStyle, marginRight: "10px" }}
              className="ant-menu-item-icon"
            />
          }
          title={<Translation input="pathway" />}
          className="font_family_nunito"
          style={fontStyle}>
          <Menu.Item key="/admin/pathways" onClick={onMenuItemSelect}>
            <Translation input="pathways" />
          </Menu.Item>
          <Menu.Item key="/admin/create-pathway" onClick={onMenuItemSelect}>
            <Translation input="create_pathway" />
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="3"
          icon={
            <FaUsers
              style={{ ...iconStyle, marginRight: "10px" }}
              className="ant-menu-item-icon"
            />
          }
          title={<Translation input="user" />}
          className="font_family_nunito"
          style={fontStyle}>
          <Menu.Item key="/admin/users" onClick={onMenuItemSelect}>
            <Translation input="all_users" />
          </Menu.Item>
          <Menu.Item key="/admin/create-user" onClick={onMenuItemSelect}>
            <Translation input="add_user" />
          </Menu.Item>
          <Menu.Item
            key="/admin/create-multiple-users"
            onClick={setShowModal.bind(null, true)}>
            <Translation input="bulk_upload_users" />
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="4"
          icon={
            <FaGraduationCap
              style={{ ...iconStyle, marginRight: "10px" }}
              className="ant-menu-item-icon"
            />
          }
          title={<Translation input="certificates" />}
          className="font_family_nunito"
          style={fontStyle}>
          <Menu.Item key="/admin/certificates" onClick={onMenuItemSelect}>
            <Translation input="Certificates" />
          </Menu.Item>
          <Menu.Item
            key="/admin/certificates/create"
            onClick={onMenuItemSelect}>
            <Translation input="Add Certificate" />
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="/admin/profile"
          icon={<FaUserCog style={{ ...iconStyle }} />}
          onClick={onMenuItemSelect}
          className="font_family_nunito"
          style={fontStyle}>
          <Translation input="account" />
        </Menu.Item>
      </Menu>
      <Modal
        destroyOnClose
        title="Create Many Users"
        visible={showModal}
        // onOk={handleOk}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowModal(false)}>
            <Translation input="close" />
          </Button>,
        ]}>
        <MultipleUsersUploadForm />
      </Modal>
    </>
  );
}

export default AdminMenu;
