import { ArrowLeftOutlined } from "@ant-design/icons";
import { Row, Space } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";

function BackToCourses() {
  const history = useHistory();
  const role = useSelector(authSelectors.role);
  const backToCourses = () => {
    if (role === Role.ADMIN) history.push(`/admin/courses`);
    if (role === Role.AUTHOR) history.push(`/author/courses`);
  };

  return (
    <Row justify="center" style={{ marginTop: "20px" }}>
      <Space className="cursor_pointer">
        <ArrowLeftOutlined style={{ color: "white" }} onClick={backToCourses} />
        <h6 style={{ color: "white", margin: 0 }} onClick={backToCourses}>
          Back to courses
        </h6>
      </Space>
    </Row>
  );
}

export default BackToCourses;
