import { useState, useEffect } from "react";
import { Button, Form, Input, Select, Row, Col, Card, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import { setTabTitle } from "src/utils/utils";
import { useAppDispatch } from "src/store/store";
import { userListSelectors } from "src/store/users/userSlice";
import SearchTags from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchTags";
import CoursePreviewVideoUpload from "src/components/AdminAuthorMentorComponents/Common/Course/CoursePreviewVideoUpload";
import { createCourse } from "src/store/coursesSlices/coursesSlice";
import SearchAuthors from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchAuthors";
import UploadImage from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";
import ReactQuill from "react-quill";

interface Params {
  userId: string;
}

interface UserFromPageProps {
  edit: boolean;
}

function WebWatchFormPage() {
  const [loading, setLoading] = useState(false);
  const { userId } = useParams<Params>();
  const user = useSelector(
    userListSelectors.selectUserEntity(parseInt(userId))
  );
  const { Option } = Select;
  const [previewVideo, setPreviewVideo] = useState(false);
  const [done, setDone] = useState(false);
  const [form] = useForm();
  const [description, setDescription] = useState("");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const cardHeader = <h5>{"Add New Webwatch Course"}</h5>;
  const location = useLocation();
  const createUserFormSubmitHandler = () => {
    setLoading(true);
    setDone(true);
    const data = {
      ...form.getFieldsValue(),
      tags: form.getFieldsValue().tags.map((item: any) => item.value),
      description: description,
      type: "WEBWATCH",
      modules: [],
      quiz: [],
      mentors: [],
      videoId: form.getFieldsValue().previewVideo,
      authorId: form.getFieldsValue().authorId.value,
    };
    console.log(data);
    dispatch(createCourse(data))
      .then(unwrapResult)
      .then(() => {
        setLoading(false);
        history.push("/admin/getWebwatchAndInfographia");
        message.success(`User ${"added"}  successfully`);
      })
      .catch(() => {
        setLoading(false);
        message.error(`error`);
      });
  };
  return (
    <Row>
      <Col sm={24}>
        <Card title={cardHeader}>
          <Form form={form} onFinish={createUserFormSubmitHandler}>
            <FormLabel name="title" />
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title",
                  type: "string",
                },
                {
                  min: 2,
                  message: "Title must be minimum 2 characters.",
                },
              ]}>
              <Input />
            </Form.Item>
            <FormLabel name="description" />
            <Form.Item>
              <ReactQuill
                onChange={(e) => setDescription(e)}
                value={description}
              />
            </Form.Item>
            <FormLabel name="tags" />
            <SearchTags name="tags" />
            <FormLabel name="Author" />
            <SearchAuthors visible={false} />
            <FormLabel name="Image" />
            <div style={{ marginBottom: "15px" }}>
              <UploadImage done={done} name="imageId" nested={false} />
            </div>
            <FormLabel name="Video" />
            <div style={{ marginBottom: "15px" }}>
              <CoursePreviewVideoUpload done={done} />
            </div>
            <Row>
              <Button type="primary" htmlType="submit" loading={loading}>
                <Translation input="submit" />
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default WebWatchFormPage;
