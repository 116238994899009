import { Quiz } from "src/interfaces/Quiz";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import QuizQuestionSlide from "./QuizQuestionSlide";
import Translation from "src/components/Common/utilities/Translation";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QuizProgressBar from "./QuizProgressBar";
import { useAppDispatch } from "src/store/store";
import { submitQuizAnswer } from "src/actions/courseActions/courseActions";
import QuizResult from "./QuizResult";
import { CourseCompletion } from "src/interfaces/Course";
import ButtonWithLoading from "../../../Common/utilities/ButtonWithLoading";
import { getCourse } from "src/actions/courseActions/learnerCourseActions";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";

interface Props {
  quiz: Quiz[];
}
interface Params {
  slug: string;
}

function QuizRender({ quiz }: Props) {
  const [quizSubmitLoading, setQuizSubmitLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const { slug } = useParams<Params>();
  const course = useSelector(coursesSelector.selectCourseEntityWithSlug(slug));
  const totalQuestion = course?.coursesQuiz?.length;
  const [ans, setAns] = useState<any>([]);
  const dispatch = useAppDispatch();
  const passingPercentage = 40;
  const NumberOfQuestionsAnsCorrect = course?.courseCompletion?.filter(
    (c: CourseCompletion) => c.correct
  ).length;

  const hasPassedQuiz =
    (NumberOfQuestionsAnsCorrect / totalQuestion) * 100 >= passingPercentage;

  const exaustedAttempts = () => {
    if (!course.courseCompletion) return false;
    else if (course.courseCompletion.length === 0) return false;
    else if (course.courseCompletion[0].attempts === course.attempts)
      return true;
  };

  const onSubmit = () => {
    setQuizSubmitLoading(true);
    if (hasAnsweredCurrentQuestion()) {
      dispatch(submitQuizAnswer({ courseId: course.id, quiz: ans })).then(
        () => {
          dispatch(getCourse(slug)).then(() => {
            setQuizSubmitLoading(false);
            setShowResult(true);
          });
        }
      );
    } else {
      setQuizSubmitLoading(false);
      setShowInfo(true);
    }
  };

  const hasAnsweredCurrentQuestion = () => {
    if (!ans[selectedItem]?.answer?.length) return false;
    else return true;
  };

  const addAns = (e: any) => {
    setShowInfo(false);
    if (!ans.find((a: any) => a.id === e.id)) {
      setAns([
        ...ans,
        {
          attachmentId: null,
          id: e.id,
          weightage: e.weightage,
          answer: [e.e.option],
          passing: course.passing,
        },
      ]);
    } else {
      setAns(
        ans.map((a: any) => {
          if (a.id === e.id) {
            return {
              ...a,
              answer: a.answer.find((item) => item === e.e.option)
                ? a.answer.filter((item) => item !== e.e.option)
                : [...a.answer, e.e.option],
            };
          } else return a;
        })
      );
    }
  };

  const onNext = () => {
    if (selectedItem === quiz.length - 1) return;
    else if (hasAnsweredCurrentQuestion()) {
      setShowInfo(false);
      setSelectedItem(selectedItem + 1);
    } else setShowInfo(true);
  };

  const info = showInfo && (
    <div className="text-danger">Please select atleast one option</div>
  );

  const nextButton = () =>
    selectedItem !== quiz.length - 1 ? (
      <ButtonWithLoading
        name={<Translation input="next" />}
        loading={false}
        onClick={onNext}
      />
    ) : (
      <ButtonWithLoading
        name={<Translation input="submit" />}
        loading={quizSubmitLoading}
        onClick={onSubmit}
      />
    );

  useEffect(() => {}, [quiz]);
  return (
    <div className="row">
      {showResult || hasPassedQuiz || exaustedAttempts() ? (
        <QuizResult
          course={course}
          retake={() => {
            setSelectedItem(0);
            setShowResult(false);
            setAns([]);
          }}
        />
      ) : (
        <div>
          <div className="card text-center rounded-3">
            <div className="card-header fw-bold ">
              Title : {course.quizTitle}
            </div>
            <div className="card-body">
              <Carousel
                // transitionTime={5}
                // animationHandler={fadeAnimationHandler}
                autoPlay={false}
                showThumbs={false}
                showIndicators={false}
                renderArrowPrev={() => null}
                renderArrowNext={() => null}
                selectedItem={selectedItem}
                showStatus={false}
                dynamicHeight
              >
                {quiz?.map((question: Quiz, i) => (
                  <QuizQuestionSlide
                    question={question}
                    key={i}
                    onChange={addAns}
                  />
                ))}
              </Carousel>
            </div>
            <QuizProgressBar
              progress={((selectedItem + 1) / totalQuestion) * 100}
            />
            <div className="card-footer text-muted d-flex justify-content-between align-items-center">
              <div>
                Q {selectedItem + 1}/{totalQuestion} {"("}Attempt{" "}
                {course.courseCompletion[0]?.attempts
                  ? course.courseCompletion[0]?.attempts
                  : 0}
                /{course?.attempts}
                {")"}
              </div>
              <div>{info}</div>
              <div>{nextButton()}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizRender;
