import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface Props {
  loading: boolean;
  children: any;
  rows?: number;
  height?: number;
  type: string;
  width?: number;
}

function SkeletonLoaderLearner({
  loading,
  children,
  rows,
  height,
  type,
  width,
}: Props) {
  const skeletonType = () => {
    if (type === "normal") {
      return (
        <SkeletonTheme color="#F5F5F5" highlightColor="#D0D0D0">
          <Skeleton count={rows} height={height} />
        </SkeletonTheme>
      );
    } else if (type === "box") {
      return (
        <SkeletonTheme color="#F5F5F5" highlightColor="#D0D0D0">
          <Skeleton height={height} width={width} />
        </SkeletonTheme>
      );
    }
  };

  return <>{loading ? skeletonType() : children}</>;
}

export default SkeletonLoaderLearner;
