import { useState } from "react";
import { Form, Upload, message, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { uploadVideo } from "../../../../api/mediaUploadApi";
import { Video } from "../../../../interfaces/Media";
import { FaRegFileAudio } from "react-icons/fa";
import { LessonFormat } from "src/enums/LessonFormats";
interface Props {
  done: boolean;
  field?: any;
  sectionId: number;
}

function UploadAudio({ field, done, sectionId }: Props) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [show, setShow] = useState(false);
  // const [fileListArray, setFileListArray] = useState<FileList[]>([]);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [attachmentIdArray, setAttachmentIdArray] = useState<any>([]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload Audio</div>
    </div>
  );

  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;

    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      console.log(e.file.type);
      try {
        if (e.file.type === "video/mp4" || e.file.type === "audio/mpeg") {
          const video: Video = await uploadVideo(formData);

          // setAttachmentIdArray([{ attachmentId: video.id }]);
          setAttachmentIdArray({
            attachmentId: video.id,
            type: LessonFormat.AUDIO,
            sectionId: sectionId,
          });

          setLoading(false);
          setShow(true);
          message.success("video successfully uploaded");
        } else {
          setLoading(false);
          message.success("Upload format should be audio");
        }
      } catch (error) {
        console.log(error);
        message.error("error uploading ");
      }
    }
  };

  const removeHandler = (e: any) => {};
  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
        customRequest={customRequest}
        multiple={false}
        fileList={fileListArray}
        onRemove={removeHandler}
      >
        {show ? (
          <div style={{ fontSize: "200%" }}>
            <FaRegFileAudio />
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
      {done ? (
        <>
          <Form.Item
            name={[field.name, "lessonsAttachments"]}
            initialValue={attachmentIdArray}
          >
            <Input type="hidden" />
          </Form.Item>
          {/* {hasName ? (
            <Form.Item
              name={[field.name, "lessonsAttachments"]}
              initialValue={attachmentIdArray}
            >
              <Input type="hidden" />
            </Form.Item>
          ) : (
            <Form.Item initialValue={attachmentIdArray}>
              <Input type="hidden" />
            </Form.Item>
          )} */}
        </>
      ) : null}
    </div>
  );
}

export default UploadAudio;
