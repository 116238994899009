import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { api, extractStandardResponseData } from "../../api/api";
import { PathWay } from "src/interfaces/PathWay";
import { logout } from "../authSlice";
import {
  likePathway,
  unlikePathway,
} from "src/actions/PathwayActions/pathwayActions";
import { getPathways } from "src/actions/PathwayActions/pathwayActions";
import { getLearnerPathway } from "src/actions/PathwayActions/LearnerPathwayActions";

export const pathwaysAdapter = createEntityAdapter<PathWay>();

//Admin actions
export const createPathway = createAsyncThunk(
  "pathway/create",
  async (pathway: PathWay) =>
    await api.post("admin/pathway", pathway).then(extractStandardResponseData)
);

export const deletePathway = createAsyncThunk(
  "pathway/delete",
  async (pathwayId: number) => {
    await api
      .delete(`admin/pathway/${pathwayId}`)
      .then(extractStandardResponseData);
    return pathwayId;
  }
);
// export const getAllPathways = createAsyncThunk(
//   "pathway/get",
//   async () => await api.get("admin/pathway").then(extractStandardResponseData)
// );

// get pathway with id
export const getPathway = createAsyncThunk(
  "pathway/get/single",
  async (pathwayId: number) =>
    await api
      .get(`admin/specificPathwayById/${pathwayId}`)
      .then(extractStandardResponseData)
);

// get pathway with slug
export const getPathwayWithSlug = createAsyncThunk(
  "pathway/get/single/slug",
  async (slug: string) =>
    await api
      .get(`admin/specificPathway/${slug}`)
      .then(extractStandardResponseData)
);

export const approvePathway = createAsyncThunk(
  "pathway/approve",
  async (pathwayId: number) =>
    await api
      .post(`admin/pathway/${pathwayId}`, { approved: true })
      .then(extractStandardResponseData)
);

export const disapprovePathway = createAsyncThunk(
  "pathway/disapprove",
  async (pathwayId: number) =>
    await api
      .post(`admin/pathway/${pathwayId}`, { approved: false })
      .then(extractStandardResponseData)
);
export const publishPathway = createAsyncThunk(
  "pathway/publish",
  async (pathwayId: number) =>
    await api
      .post(`admin/pathway/publish/${pathwayId}`, { published: true })
      .then(extractStandardResponseData)
);

export const unpublishPathway = createAsyncThunk(
  "pathway/unpublish",
  async (pathwayId: number) =>
    await api
      .post(`admin/pathway/publish/${pathwayId}`, { published: false })
      .then(extractStandardResponseData)
);

interface EditPathwayI {
  pathwayId: number;
  pathway: any;
}

export const editPathway = createAsyncThunk(
  "pathway/edit",
  async ({ pathwayId, pathway }: EditPathwayI) =>
    await api
      .patch(`admin/editPathways/${pathwayId}`, pathway)
      .then(extractStandardResponseData)
);

// //learner pathway
// export const getLearnerPathway = createAsyncThunk(
//   "pathway/learner/get/all",
//   async () =>
//     await api
//       .get(`admin/pathway/learnerPathways`)
//       .then(extractStandardResponseData)
// );

export const getPathwayMentors = createAsyncThunk(
  "pathway/learner/get/mentors",
  async (pathwayId: number) =>
    await api
      .get(`admin/pathwayMentors/${pathwayId}`)
      .then(extractStandardResponseData)
);

export const pathwaysSlice = createSlice({
  name: "pathway",
  initialState: pathwaysAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(getPathways.fulfilled, (state, { payload }) => {
      pathwaysAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(editPathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(getPathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(getPathwayWithSlug.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(deletePathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.removeOne(state, payload)
    );
    builder.addCase(approvePathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(disapprovePathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(publishPathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(unpublishPathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(likePathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload[0])
    );
    builder.addCase(unlikePathway.fulfilled, (state, { payload }) =>
      pathwaysAdapter.upsertOne(state, payload)
    );
    builder.addCase(getLearnerPathway.fulfilled, (state, { payload }) => {
      const pathway: PathWay[] = payload.data.map((item: any) => ({
        ...item,
        ...item.pathways,
      }));
      pathwaysAdapter.upsertMany(state, pathway);
    });
    builder.addCase(logout.fulfilled, () => pathwaysAdapter.getInitialState());
  },
});

const { selectById, selectAll } = pathwaysAdapter.getSelectors();
const getPathwaysState = (state: RootState) => state.pathways;

export const pathwaysSelectors = {
  pathwaySelector: pathwaysAdapter.getSelectors((s: RootState) => s.pathways),
  pathways: (state: RootState) => state.pathways.entities,
  selectAllPathways: () =>
    createSelector(getPathwaysState, (state) => selectAll(state)),
  selectPathwayEntity: (id: number) =>
    createSelector(getPathwaysState, (state) => selectById(state, id)),
  selectPathwayEntityWithSlug: (slug: string) =>
    createSelector(
      getPathwaysState,
      (state) =>
        selectAll(state).filter((pathway: PathWay) => pathway.slug === slug)[0]
    ),
};
