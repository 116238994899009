import { useEffect } from "react";
import AddCertificateToCourse from "src/components/AdminAuthorMentorComponents/Common/Course/AddCertificateToCourse";
import { setTabTitle } from "src/utils/utils";

function CreateCertificatePage() {
  useEffect(() => setTabTitle("Create Certificate"), []);

  return <AddCertificateToCourse />;
}

export default CreateCertificatePage;
