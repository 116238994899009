function NavLogo() {
  return (
    <div
      style={{
        marginLeft: "25px",
        marginBottom: "25px",
        marginTop: "10px",
        padding: "8px",
        width: "100%",
      }}
    >
      <img
        style={{ width: "160px" }}
        src="/images/learnsure-logo-final-reverse.svg"
        alt="google play button"
      />
    </div>
  );
}

export default NavLogo;
