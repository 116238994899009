import { Card, Table, Avatar } from "antd";
import { useAppDispatch } from "src/store/store";
import { getMostFrequentUsers } from "src/actions/analyticsActions";
import { useEffect, useState } from "react";
import Column from "antd/lib/table/Column";
import { analyticsSelectors } from "src/store/analyticSlice";
import { useSelector } from "react-redux";
import { User } from "src/interfaces/User";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import { useTranslation } from "react-i18next";

interface Views {
  views: number;
}
interface UserWithViews extends User, Views {}

function FrequentLearnersTable() {
  const dispatch = useAppDispatch();
  const mostFrequentUsers = useSelector(
    analyticsSelectors.selectMostFrequentUsers()
  );
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  //FIXME:add condition for dispatch
  useEffect(() => {
    if (!mostFrequentUsers) {
      dispatch(getMostFrequentUsers()).then(setLoading.bind(null, false));
    } else setLoading(false);
  }, []);

  return (
    <Card title={<>{t("most_frequent_learners")}</>}>
      <Table
        showHeader={false}
        dataSource={mostFrequentUsers?.slice(0, 5)}
        pagination={false}
        rowKey={(s) => s.id}
        loading={loading}
      >
        <Column
          title={<>{t("profile")}</>}
          key="profile"
          render={(_, user: UserWithViews) => (
            <Avatar
              shape="circle"
              size={40}
              src={user?.resources?.attachmentUrl || ProfilePlaceHolder}
            />
          )}
        />
        <Column
          title={<>{t("name")}</>}
          dataIndex="name"
          key="actions"
          render={(_, user: UserWithViews) => (
            <>
              {user?.firstName} {user?.lastName}
            </>
          )}
        />
        <Column
          title={<>{t("visits")}</>}
          key="visits"
          // render={(_, user: UserWithViews) => user?.views}
          render={(_, user: UserWithViews) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>{user?.views}</div>&nbsp;
              <div> Visits</div>
            </div>
          )}
        />
      </Table>
    </Card>
  );
}

export default FrequentLearnersTable;
