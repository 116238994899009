import { forwardRef, useImperativeHandle, useState } from "react";
import ReactPlayer from "react-player/lazy";

interface Props {
  url: string;
}

const VideoPlayer = forwardRef(({ url }: Props, ref) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  useImperativeHandle(ref, () => ({
    pause: () => {
      setVideoPlaying(false);
    },
  }));

  return (
    <div>
      <ReactPlayer
        url={url}
        width="100%"
        height="auto"
        loop={false}
        controls={true}
        playing={videoPlaying}
        onPlay={setVideoPlaying.bind(null, true)}
        onPause={setVideoPlaying.bind(null, false)}
      />
    </div>
  );
});

export default VideoPlayer;
