import { Image } from "antd";
import { Resources } from "src/interfaces/Resources";

interface Props {
  resources: Resources | undefined;
  height: number;
  width: number;
}

function ImageThumbnail({ resources, height, width }: Props) {
  return (
    // <Image
    //   width={65}
    //   height={65}
    //   src={resources.metaData.thumbnail}
    //   preview={false}
    //   //   preview={{
    //   //     src: resources.attachmentUrl,
    //   //   }}
    // />
    <div
      style={{
        backgroundImage: `url("${resources?.attachmentUrl}")`,
        height: `${height}px`,
        width: `${width}px`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "5px",
        border: "1px solid #eee",
      }}
    ></div>
  );
}

export default ImageThumbnail;
