import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Collapse,
  Modal,
  Typography,
  Space,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import { useAppDispatch } from "src/store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import {
  clearCourses,
  coursesSelector,
  createCourse,
  editVideoCourse,
} from "src/store/coursesSlices/coursesSlice";
import { Course } from "src/interfaces/Course";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import CourseImage from "../EditCoursePageFormFields/CourseImage";
import SearchFields from "../EditCoursePageFormFields/SearchFields";
import BasicFields from "../EditCoursePageFormFields/BasicFields";
import CourseFormatTag from "src/components/AdminAuthorMentorComponents/Common/CourseFormatTag";
import Lessons from "./Lessons";
import { Module } from "src/interfaces/Module";
import { Lesson } from "src/interfaces/Lesson";
import CoursePreviewVideo from "../EditCoursePageFormFields/CoursePreviewVideo";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import { useTranslation } from "react-i18next";
import { setTabTitle } from "src/utils/utils";
import {
  addLanguageToCourse,
  getSingleCourse,
} from "src/actions/courseActions/courseActions";
import { getLanguageNameFromLanguageCode } from "src/utils/Rest/translationUtils";
import { LanguageCode } from "src/enums/Language";
import AddCourseQuiz from "src/components/AdminAuthorMentorComponents/Common/Course/CourseQuiz/AddCourseQuiz";
import { Quiz } from "src/interfaces/Quiz";
import { getDraft } from "src/actions/DraftActions";

interface Params {
  courseId: string;
}

function EditDefaultCourse() {
  console.log("edit fds");
  const [courseQuiz, setCourseQuiz] = useState<any>();
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState<string | null>();
  const { t } = useTranslation();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { courseId } = useParams<Params>();
  const { Panel } = Collapse;
  const [form] = useForm();
  const location = useLocation();
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const { pathname } = useLocation();
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;

  const translatedToLanguage: LanguageCode = location.pathname
    .split("/")[4]
    ?.split("-")[1] as LanguageCode;

  const onQuizFormSubmit = (e) => {
    setCourseQuiz(e);
  };

  const onFormSubmit = () => {
    setSubmitLoading(true);
    setDone(true);
    const editedCourse = {
      title: form.getFieldsValue().title,
      excerpt: form.getFieldsValue().excerpt,
      videoId: form.getFieldsValue().previewVideo,
      id: parseInt(courseId),
      courseAttachment: null,
      description: description,
      objectives: objectives,
      tags: form.getFieldsValue().tags?.map((item: any) => item.value),
      mentors: form.getFieldsValue().mentors?.map((item: any) => item.value),
      authorId: form.getFieldsValue().authorId?.value,
      // authorId: 2,

      ...courseQuiz,
      quiz:
        courseQuiz.quiz?.map((item, i) => ({
          ...item.extras,
          id: item?.extras?.id ? item.extras.id : "idPla",
          sectionId: i,
          attachmentId: null,
          question: item.question,
          options: item.answer?.map((item) => item.option),
          answers: item.answer
            .filter((item) => item.checked === true)
            .map((item) => item.option),
        })) || [],
      modules: form
        .getFieldsValue()
        ?.coursesModules?.map((module: Module, moduleIndex: number) => ({
          ...module,
          id: module.id ? module.id : `asasdad`,
          positionId: moduleIndex,
          lessons: module.lessons?.map(
            (lesson: Lesson, lessonIndex: number) => ({
              ...lesson,
              id: lesson.id ? lesson.id : `aaaa`,
              positionId: lessonIndex,
              lessonsAttachments: lesson.lessonsAttachments
                ?.map((lessonsAttachment: any) => {
                  if (Array.isArray(lessonsAttachment))
                    return lessonsAttachment;
                  else return lessonsAttachment.lessonsAttachments;
                })
                .flat()
                .map((item: any) => ({
                  ...item,
                  id: item.id ? item.id : "iub",
                })),
            })
          ),
        })),
    };
    if (!form.getFieldsValue().previewVideo) {
      delete editedCourse.videoId;
    }

    if (!isTranslationPath) {
      if (pathname.split("/")[2] === "draft") {
        dispatch(createCourse(editedCourse));
        console.log(editedCourse);
      } else {
        dispatch(editVideoCourse(editedCourse))
          .then(unwrapResult)
          .then(() => {
            role === Role.ADMIN
              ? history.push("/admin/courses")
              : history.push("/author");
            setSubmitLoading(false);
          })
          .catch(() => setSubmitLoading(true));
      }
    } else {
      delete editedCourse.mentors;
      delete editedCourse.authorId;
      delete editedCourse.tags;
      dispatch(
        addLanguageToCourse({
          ...editedCourse,
          completed: true,
          language: [location.pathname.split("/")[4].split("-")[1]],
        })
      ).then(() => {
        console.log("test");
        role === Role.ADMIN
          ? history.push("/admin/courses")
          : history.push("/author");
        setSubmitLoading(false);
        // dispatch(clearCourses()).then(() => {
        //   role === Role.ADMIN
        //     ? history.push("/admin/courses")
        //     : history.push("/author");
        //   setSubmitLoading(false);
        // });
      });
    }
  };

  const onSave = () => {
    setSaveDraftLoading(true);
    dispatch(
      addLanguageToCourse({
        title: form.getFieldsValue().title,
        excerpt: form.getFieldsValue().excerpt,
        description: description,
        objectives: objectives,
        id: parseInt(courseId),
        language: [location.pathname.split("/")[4].split("-")[1]],

        modules: form
          .getFieldsValue()
          ?.coursesModules?.map((module: Module, moduleIndex: number) => ({
            ...module,
            id: module.id ? module.id : `asasdad`,
            positionId: moduleIndex,
            lessons: module.lessons?.map(
              (lesson: Lesson, lessonIndex: number) => ({
                ...lesson,
                id: lesson.id ? lesson.id : `aaaa`,
                positionId: lessonIndex,
                lessonsAttachments: lesson.lessonsAttachments
                  ?.map((lessonsAttachment: any) => {
                    if (Array.isArray(lessonsAttachment))
                      return lessonsAttachment;
                    else return lessonsAttachment.lessonsAttachments;
                  })
                  .flat()
                  .map((item: any) => ({
                    ...item,
                    id: item.id ? item.id : "iub",
                  })),
              })
            ),
          })),
      })
    ).then(() => {
      setSaveDraftLoading(false);
      message.success({
        content: `Saved`,
      });
    });
  };

  const onFinishFailed = (e) => {
    message.warning({
      content: "Please check for missing fields in the form",
    });
  };

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Typography.Title level={5}>
        {isTranslationPath
          ? getLanguageNameFromLanguageCode(translatedToLanguage)
          : "Edit Course"}
      </Typography.Title>
      {course && (
        <Space>
          {isTranslationPath ? (
            <Button type="primary" onClick={onSave} loading={saveDraftLoading}>
              Save Draft
            </Button>
          ) : (
            <CourseFormatTag course={course} />
          )}
        </Space>
      )}
    </Row>
  );

  useEffect(() => {
    if (pathname.split("/")[2] === "draft") {
      dispatch(getDraft())
        .then(unwrapResult)
        .then((res: Course) => {
          setTabTitle(res.title);
          const lang = location.pathname?.split("/")[4]?.split("-")[1];
          const course = isTranslationPath
            ? res.languages.find(
                (course: Course) => course.language[0] === lang
              )
            : res;
          if (!course) return;
          if (!isTranslationPath) {
            setImageUrl(course?.resources?.attachmentUrl || "");
            setVideoUrl(course?.videos?.attachmentUrl || "");
            form.setFieldsValue({
              authorId: {
                label: `${course?.users?.firstName} ${course?.users?.lastName}`,
                value: course?.users?.id,
              },
            });
          }
          setObjectives(course.objectives);
          setDescription(course.description);
          setCourseQuiz({
            quizTitle: course.title,
            attempts: course.attempts,
            passing: course.passing,
            quiz: course.coursesQuiz?.map((quiz: Quiz) => ({
              extras: { id: quiz.id, weightage: quiz.weightage },
              question: quiz.question,
              answer: quiz.options.map((option) => ({
                option: option,
                checked: quiz.answers.find((ans) => ans === option)
                  ? true
                  : false,
              })),
            })),
          });
          form.setFieldsValue({
            ...course,
            tags: course?.coursesTags?.map((item: any) => ({
              label: item.tags.value,
              value: item.tags.id,
            })),
            mentors: course?.mentorsCourses?.map((item: any) => ({
              label: `${item.users.firstName} ${item.users.lastName}`,
              value: item.users.id,
            })),
            // authorId: {
            //   label: `${course.users.firstName} ${course.users.lastName}`,
            //   value: course.users.id,
            // },

            coursesModules: course[
              isTranslationPath ? "modules" : "coursesModules"
            ]?.map((coursesModule: any) => ({
              ...coursesModule,
              lessons: coursesModule.lessons?.map((lesson: any) => ({
                ...lesson,
                lessonsAttachments: Object.values(
                  lesson?.lessonsAttachments?.reduce((prev, current) => {
                    if (!prev[current.sectionId]) {
                      prev[current.sectionId] = [];
                    }
                    prev[current.sectionId].push(current);
                    return prev;
                  }, {})
                ),
              })),
            })),
          });
        });
    } else {
      setLoading(true);
      dispatch(getSingleCourse(parseInt(courseId)))
        .then(unwrapResult)
        .then((res: Course) => {
          setTabTitle(res.title);
          const lang = location.pathname?.split("/")[4]?.split("-")[1];
          const course = isTranslationPath
            ? res.languages.find(
                (course: Course) => course.language[0] === lang
              )
            : res;
          if (!course) return;
          if (!isTranslationPath) {
            setImageUrl(course?.resources.attachmentUrl || "");
            setVideoUrl(course?.videos?.attachmentUrl || "");
            form.setFieldsValue({
              authorId: {
                label: `${course?.users.firstName} ${course?.users.lastName}`,
                value: course?.users.id,
              },
            });
          }
          setObjectives(course.objectives);
          setDescription(course.description);
          setCourseQuiz({
            quizTitle: course.title,
            attempts: course.attempts,
            passing: course.passing,
            quiz: course.coursesQuiz?.map((quiz: Quiz) => ({
              extras: { id: quiz.id, weightage: quiz.weightage },
              question: quiz?.question,
              answer: quiz?.options?.map((option) => ({
                option: option,
                checked: quiz.answers.find((ans) => ans === option)
                  ? true
                  : false,
              })),
            })),
          });
          form.setFieldsValue({
            ...course,
            tags: course?.coursesTags?.map((item: any) => ({
              label: item.tags.value,
              value: item.tags.id,
            })),
            mentors: course?.mentorsCourses?.map((item: any) => ({
              label: `${item.users.firstName} ${item.users.lastName}`,
              value: item.users.id,
            })),
            // authorId: {
            //   label: `${course.users.firstName} ${course.users.lastName}`,
            //   value: course.users.id,
            // },

            coursesModules: course[
              isTranslationPath ? "modules" : "coursesModules"
            ]?.map((coursesModule: any) => ({
              ...coursesModule,
              lessons: coursesModule.lessons?.map((lesson: any) => ({
                ...lesson,
                lessonsAttachments: Object.values(
                  lesson?.lessonsAttachments?.reduce((prev, current) => {
                    if (!prev[current.sectionId]) {
                      prev[current.sectionId] = [];
                    }
                    prev[current.sectionId].push(current);
                    return prev;
                  }, {})
                ),
              })),
            })),
          });

          setLoading(false);
        });
    }
  }, [location.pathname]);

  return (
    <Row>
      <Col sm={24}>
        <Card title={cardTitle}>
          <Form
            name="basic"
            form={form}
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
            scrollToFirstError>
            <BasicFields loading={loading} />
            <FormLabel name="objectives" />

            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setObjectives(value)}
                  value={objectives}
                />
              </Form.Item>
            </SkeletonLoader>
            <FormLabel name="description" />
            <SkeletonLoader loading={loading} rows={5} type="normal">
              <Form.Item>
                <ReactQuill
                  onChange={(value) => setDescription(value)}
                  value={description}
                />
              </Form.Item>
            </SkeletonLoader>
            {pathname.split("/")[2] !== "translation" && (
              <>
                <CourseImage done={done} imageUrl={imageUrl} />
                <CoursePreviewVideo done={done} videoUrl={videoUrl} />
                <SearchFields loading={loading} />
              </>
            )}
            <FormLabel name="Course Quiz" />
            <AddCourseQuiz
              onFormSubmit={onQuizFormSubmit}
              initialValue={courseQuiz}
            />

            <FormLabel name="course_content" />
            <Form.List name="coursesModules">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Collapse accordion>
                      {fields.map((field, index) => (
                        <Panel
                          header={
                            <Row>
                              <Col sm={20}>
                                <Form.Item
                                  style={{ marginBottom: "0px" }}
                                  {...field}
                                  name={[field.name, "title"]}
                                  fieldKey={[field.fieldKey, "title"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Module Title Missing",
                                    },
                                  ]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              {!isTranslationPath && (
                                <Col sm={4}>
                                  <Row justify="end">
                                    <Button
                                      danger
                                      onClick={() =>
                                        Modal.confirm({
                                          content: `${t(
                                            "confirm.delete_module"
                                          )}`,
                                          onOk: () => remove(field.name),
                                        })
                                      }>
                                      <Translation input="remove_module" />
                                    </Button>
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          }
                          key={index}
                          forceRender={true}>
                          <Lessons
                            fieldName={field.name}
                            form={form}
                            field={field}
                            done={done}
                          />
                        </Panel>
                      ))}
                    </Collapse>
                    {!isTranslationPath && (
                      <Row justify="center">
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ marginTop: "10px" }}>
                          <Translation input="add_module" />
                        </Button>
                      </Row>
                    )}
                  </>
                );
              }}
            </Form.List>

            <Row justify="end" style={{ marginTop: "20px" }}>
              <Button type="primary" htmlType="submit" loading={submitLoading}>
                <Translation input="submit" />
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default EditDefaultCourse;
