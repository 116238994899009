import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
interface Props {
  url: string;
}

function ImageRender({ url }: Props) {
  return (
    <div>
      {/* <img
        style={{
          height: "auto",
          width: "100%",
        }}
        src={url}
      ></img> */}
      <LazyLoadImage
        effect="blur"
        alt="image"
        height="auto"
        // style={{ minHeight: "400px" }}
        src={url} // use normal <img> attributes as props
        width="100%"
      />
    </div>
  );
}

export default ImageRender;
