import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import HomeIcon from "src/Assets/media/home-icon.svg";

function NavHomeIcon() {
  const role = useSelector(authSelectors.role);
  const history = useHistory();

  return (
    <li className="nav-item px-lg-2 active px-md-5 px-sm-5 px-5">
      <a
        className="nav-link"
        onClick={() => {

          if (role === Role.LEARNER)
            history.push("/home");

        }}
      >
        <img src={HomeIcon} width="22px" alt="" />
        <span className="text-light mx-3 d-lg-none">Home</span>
      </a>
    </li>
  );
}

export default NavHomeIcon;
