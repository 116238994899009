import { unwrapResult } from "@reduxjs/toolkit";
import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import BookSessionModal from "src/components/LearnerComponents/AssignedMentorsCarousel/BookSessionModal";
import MentorCard from "src/components/LearnerComponents/MentorCard/MentorCard";
import { User } from "src/interfaces/User";
import { getPathwayMentors } from "src/store/pathways/pathwaysSlice";
import { useAppDispatch } from "src/store/store";

interface Props {
  pathwayId: number;
}

function PathwayMentorsCarousel({ pathwayId }: Props) {
  const [mentors, setMentors] = useState<User[]>();
  const dispatch = useAppDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getPathwayMentors(pathwayId))
      .then(unwrapResult)
      .then((response) => {
        console.log(response);
        setMentors(response.map((item: any) => item.users));
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ maxWidth: "300px" }}>
      <div className="d-flex mb-3 justify-content-between align-items-start">
        <div className="d-flex align-items-center"></div>
      </div>
      {!loading ? (
        <div style={{ maxWidth: "300px" }} dir="ltr">
          <Carousel
            showArrows={true}
            showStatus={false}
            selectedItem={currentSlide}
            showIndicators={false}
            showThumbs={false}
            autoPlay={true}
            interval={6000}
            infiniteLoop={true}
          >
            {mentors?.map((user: User) => (
              <MentorCard user={user} key={user.id} />
            ))}
          </Carousel>
          <BookSessionModal />
        </div>
      ) : null}
    </div>
  );
}

export default PathwayMentorsCarousel;
