import { Col, Collapse, Input, Row, Button, Modal, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MenuOutlined } from "@ant-design/icons";
import { Lesson, LessonsAttachment } from "src/interfaces/Lesson";
import { v4 as uuidv4 } from "uuid";
import Sections from "./Sections";
import { groupAttachmentsFunction } from "src/utils/utils";

// import Sections from "./Sections";

interface IProps {
  lessons: Lesson[];
  moduleId: number | string;
  onChange: (Lessons: Lesson[], moduleId: number | string) => void;
}

function Lessons({ lessons, moduleId, onChange }: IProps) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { pathname } = useLocation();
  const isTranslationPath =
    pathname.split("/")[2] === "translation" ? true : false;

  const onLessonDragEnd = (e: any) => {
    let temp = [...lessons];
    const [reorderedItem] = temp.splice(e?.source?.index, 1);
    temp.splice(e?.destination?.index, 0, reorderedItem);
    onChange(temp, moduleId);
  };

  const onLessonTitleChange = (lessonId: string | number, title: string) => {
    onChange(
      lessons.map((lesson) =>
        lesson.id === lessonId ? { ...lesson, title } : lesson
      ),
      moduleId
    );
  };

  const areAllLessonPopulated = (lessons: Lesson[]): boolean => {
    const lastLessonIndex = lessons.length - 1;
    if (lessons[lastLessonIndex].title.length === 0) {
      message.warning("Please Populate the lessons");
      return false;
    } else return true;
  };

  const onLessonAdd = () => {
    if (!areAllLessonPopulated(lessons)) return;
    onChange(
      [
        ...lessons,
        {
          moduleId,
          id: uuidv4(),
          title: "",
          lessonsAttachments: [[{ type: null, sectionId: uuidv4() }]],
        },
      ] as Lesson[],
      moduleId
    );
  };

  const onLessonDelete = (lessonId: string | number) => {
    onChange(
      lessons.filter((lesson) => lesson.id !== lessonId),
      moduleId
    );
  };

  const sectionsOnChangeHandler = (
    lessonsAttachments: LessonsAttachment[],
    lessID: number | string
  ) => {
    onChange(
      lessons.map((lesson: Lesson) =>
        lesson.id === lessID
          ? { ...lesson, lessonsAttachments: lessonsAttachments }
          : lesson
      ),
      moduleId
    );
  };

  const header = (provided: any, lesson: Lesson) => (
    <Row>
      <Col sm={18}>
        <Input
          value={lesson.title}
          onChange={(e) => onLessonTitleChange(lesson.id, e.target.value)}
        />
      </Col>
      {!isTranslationPath && (
        <Col sm={6}>
          <Row justify="end" align="middle">
            <Space>
              <Button
                danger
                onClick={() =>
                  Modal.confirm({
                    content: `${t("confirm.delete_lesson")}`,
                    onOk: () => onLessonDelete(lesson.id),
                  })
                }
              >
                <Translation input="remove_lesson" />
              </Button>
              <span {...provided.dragHandleProps}>
                <MenuOutlined />
              </span>
            </Space>
          </Row>
        </Col>
      )}
    </Row>
  );

  return (
    <>
      <DragDropContext onDragEnd={onLessonDragEnd}>
        <Droppable droppableId="lessons">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {lessons?.map((lesson: Lesson, i: number) => (
                <Draggable
                  key={lesson.id}
                  draggableId={`${lesson.id}`}
                  index={i}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <Collapse accordion>
                        <Panel
                          header={header(provided, lesson)}
                          key={module.id}
                          forceRender={true}
                        >
                          <Sections
                            onChange={sectionsOnChangeHandler}
                            lessonsAttachments={groupAttachmentsFunction(
                              lesson.lessonsAttachments
                            )}
                            moduleId={moduleId}
                            lessonId={lesson.id}
                          />
                        </Panel>
                      </Collapse>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isTranslationPath && (
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Button type="primary" onClick={onLessonAdd}>
            Add Lesson
          </Button>
        </Row>
      )}
    </>
  );
}

export default Lessons;
