import { useSelector } from "react-redux";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";

interface Props {
  //   role: Role.LEARNER | Role.ADMIN | Role.AUTHOR | Role.MENTOR;
  role: Role;
  component: JSX.Element;
}

function RestrictAccessByRole({ role, component }: Props) {
  const currentLoginRole = useSelector(authSelectors.role);
  return currentLoginRole === role ? component : null;
}

export default RestrictAccessByRole;
