import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";
import { NotificationI } from "src/interfaces/NotificationI";

export const getNotifications = createAsyncThunk(
  "notifications/get",
  async (page: number) =>
    await api.get(`/users/notifications?page=${page}`).then((res) => res.data)
);
export const setNotificationViewed = createAsyncThunk(
  "notifications/setViewed",
  async (ids: number[]) => {
    // console.log(ids.map((item) => ({ id: item, viewed: true })));
    // await api.get(`/users/notifications?page=${page}`).then((res) => res.data);
    return ids.map((item) => ({ id: item, viewed: true }));
  }
);
