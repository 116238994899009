import { Form, Button, Input, Row, Modal, Collapse, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import Sections from "./Sections";
const { Panel } = Collapse;

const Lessons = ({
  fieldName,
  form,
  field,
  done,
}: {
  fieldName: any;
  form: any;
  field: any;
  done: boolean;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isTranslationPath =
    location.pathname.split("/")[2] === "translation" ? true : false;

  return (
    <Form.List name={[fieldName, "lessons"]}>
      {(lessons, { add, remove }) => {
        return (
          <>
            <Collapse accordion>
              {lessons.map((lesson, lessonIndex) => (
                <Panel
                  header={
                    <Row>
                      <Col sm={20}>
                        <Form.Item
                          style={{ marginBottom: "0px" }}
                          {...lesson}
                          name={[lesson.name, "title"]}
                          fieldKey={[lesson.fieldKey, "title"]}
                          key={lessonIndex}
                          rules={[
                            {
                              required: true,
                              message: "Lesson Title Missing",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {!isTranslationPath && (
                        <Col sm={4}>
                          <Row justify="end">
                            <Button
                              onClick={() => {
                                Modal.confirm({
                                  content: `${t("confirm.delete_lesson")}`,
                                  onOk: () => remove(lesson.name),
                                });
                              }}
                              danger
                            >
                              <Translation input="remove_lesson" />
                            </Button>
                          </Row>
                        </Col>
                      )}

                      {/* <Button
                        onClick={() =>
                          console.log(
                            form.getFieldsValue().coursesModules[field.name]
                              .lessons[lesson.name]
                          )
                        }
                        danger
                      >
                        Test lesson
                      </Button> */}
                    </Row>
                  }
                  key={lessonIndex}
                  forceRender={true}
                >
                  <Sections
                    fieldName={lesson.name}
                    form={form}
                    field={lesson}
                    parentField={field}
                    done={done}
                  />
                </Panel>
              ))}
            </Collapse>
            {!isTranslationPath && (
              <Row justify="center" style={{ marginTop: "12px" }}>
                <Button type="dashed" onClick={() => add()}>
                  <Translation input="add_lesson" />
                </Button>
              </Row>
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default Lessons;
