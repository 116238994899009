function TimeStamp({
  timestamp,
  hideTime,
}: {
  timestamp: string;
  hideTime?: boolean;
}) {
  const dateReturnFn = (isoDate: string) => {
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(isoDate);
    if (hideTime) {
      return `${date.getDate()} ${monthArray.filter(
        (_, index) => date.getUTCMonth() === index
      )} ${date.getUTCFullYear()}`;
    } else {
      return `${date.getDate()} ${monthArray.filter(
        (_, index) => date.getUTCMonth() === index
      )} ${date.getUTCFullYear()} - ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    }
  };

  return <>{dateReturnFn(timestamp)}</>;
}

export default TimeStamp;
