import { Form, Col, Row, Button, Modal } from "antd";

import SectionSelector from "./SectionSelector";

interface Props {
  done: boolean;
  field?: any;
}

function LessonContent({ done, field }: Props) {
  return (
    <Form.List name={[field.fieldKey, "lessonsAttachments"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row
              style={{
                borderTop: "1px solid #bfbfbf",
                borderBottom: "1px solid #bfbfbf",
                padding: "10px",
                minHeight: "100px",
              }}
            >
              <Col sm={20}>
                <SectionSelector
                  done={done}
                  field={field}
                  index={index}
                  edit={false}
                />
              </Col>
              <Col sm={4}>
                <Row justify="center">
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        content: "Are you sure you want to remove this section",
                        onOk: () => remove(field.name),
                      });
                    }}
                    danger
                    size="small"
                  >
                    Remove Section
                  </Button>
                </Row>
              </Col>
            </Row>
          ))}
          <Row justify="center" style={{ padding: "10px" }}>
            <Button onClick={() => add()} shape="round" type="primary">
              Add Section
            </Button>
          </Row>
        </>
      )}
    </Form.List>
  );
}

export default LessonContent;
