import { IoIosHeart } from "react-icons/io";
import { PathWay } from "src/interfaces/PathWay";
import {
  likePathway,
  unlikePathway,
} from "src/actions/PathwayActions/pathwayActions";
import { useAppDispatch } from "src/store/store";

enum LikeColor {
  Red = "#dc143c",
  Default = "",
}

function LikePathwayIcon({ pathway }: { pathway: PathWay }) {
  const dispatch = useAppDispatch();
  return (
    <div className="d-flex align-items-center">
      <IoIosHeart
        style={{
          color: `${
            pathway?.myLike?.length ? LikeColor.Red : LikeColor.Default
          }`,
        }}
        onClick={() => {
          if (pathway?.myLike?.length) {
            dispatch(
              unlikePathway({
                pathwayId: pathway.id,
                likeId: pathway?.myLike[0]?.id,
              })
            );
          } else dispatch(likePathway(pathway.id));
        }}
      />
      <div style={{ width: "0.4rem" }}></div>
      <div className="fs-7">{pathway?.likes?.length}</div>
    </div>
  );
}

export default LikePathwayIcon;
