import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import { useEffect, useState } from "react";
import { uploadDocument, uploadCourseImage } from "src/api/mediaUploadApi";
import { FileType } from "src/enums/FileTypes";
import { getFileExtention, getFileNameFromUrl, apiCall } from "src/utils/utils";
import HiddenField from "./HiddenField";

interface Props {
  done: boolean;
  edit: boolean;
  notesAttachment?: any;
}

function CourseNotesAttachment({ done, edit, notesAttachment }: Props) {
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const apiResponseReturn = async (fileName: string, formData: FormData) => {
    const fileExt = getFileExtention(fileName);
    if (fileExt === FileType.pdf) return await uploadDocument(formData);
    else if (
      fileExt === FileType.png ||
      fileExt === FileType.jpeg ||
      fileExt === FileType.jpg
    )
      return await uploadCourseImage(formData);
  };

  const customRequest = async (e: any) => {
    setLoading(true);
    if (!e.file) return;
    const formData = new FormData();
    formData.append("file", e.file);

    const [data, error] = await apiCall(
      apiResponseReturn(e.file.name, formData)
    );
    setLoading(false);
    setFileListArray([
      ...fileListArray,
      {
        url: data.attachmentUrl,
        name: `${getFileNameFromUrl(data.attachmentUrl)}`,
        status: "done",
        uid: data.id,
      },
    ]);
  };

  const removeHandler = (e: any) => {
    setFileListArray(fileListArray.filter((item: any) => item.uid !== e.uid));
  };

  useEffect(() => {
    if (edit) {
      setFileListArray(
        notesAttachment.map((item: any) => {
          return {
            id: item.id,
            url: item.resources.attachmentUrl,
            name: `${getFileNameFromUrl(item.resources.attachmentUrl)}`,
            status: "done",
            uid: item.resources.id,
          };
        })
      );
    }
  }, []);

  return (
    <>
      <Upload
        listType="picture"
        showUploadList={true}
        fileList={fileListArray}
        customRequest={customRequest}
        onRemove={removeHandler}
      >
        <Button icon={loading ? <LoadingOutlined spin /> : <UploadOutlined />}>
          Upload
        </Button>
      </Upload>
      <HiddenField done={done} fileListArray={fileListArray} />
    </>
  );
}

export default CourseNotesAttachment;
