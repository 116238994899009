import { HiDotsVertical } from "react-icons/hi";
function PeopleToMeetCard({
  name,
  designation,
  image,
}: {
  name: string;
  designation: string;
  image: string;
}) {
  return (
    <div
      className="card noselect border_color p-3 hover_effect cursor_pointer mb-2 d-flex w-100 flex-row justify-content-between align-items-center"
      style={{ borderRadius: "8px" }}
    >
      <div className="d-flex">
        <div>
          <img
            style={{ height: "42px", width: "42px", objectFit: "cover" }}
            src={image}
            alt=""
            className="rounded-circle m-auto"
          />
        </div>
        <div className="px-3">
          <div className="fs-7">{name}</div>
          <div className="font_size_12">{designation}</div>
        </div>
      </div>

      <div className="px-3">
        <HiDotsVertical />
      </div>
    </div>
  );
}
export default PeopleToMeetCard;
