import { useState, useEffect } from "react";
import { Button, Col, Row, Card, Tooltip, Space } from "antd";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";
import { useSelector } from "react-redux";
import TextHeightWrapper from "src/components/Common/utilities/TextHeightWrapper";
import { CourseFormat } from "src/enums/CourseFormat";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
import CollapsibleCompAdmin from "src/components/AdminAuthorMentorComponents/Common/CollapsibleCompAdmin";
import { useAppDispatch } from "src/store/store";
import CourseNotes from "src/components/AdminAuthorMentorComponents/MentorComponents/CourseNotes/CourseNotes";
import { getSingleCourse } from "src/actions/courseActions/courseActions";
interface Params {
  course_id: string;
}
function ViewCourseMentorPage() {
  const courseId = useParams<Params>().course_id;
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (course) setLoading(false);
    else
      dispatch(getSingleCourse(parseInt(courseId))).then(
        setLoading.bind(null, false)
      );
    document.title = `Learn Sure . ${course?.title}`;
  }, []);

  const cardTitle = (
    <Row justify="space-between">
      <SkeletonLoader loading={loading} rows={0} type="button" width={300}>
        <>{course?.title}</>
      </SkeletonLoader>
      <Space>
        <Tooltip title="Go to learner course page">
          <Button
            type="primary"
            key="new-account"
            onClick={() =>
              window.open(`/course-description/${course?.slug}`, "_blank")
            }
          >
            Preview
          </Button>
        </Tooltip>

        {showIframe ? (
          <Tooltip title="Go back to course preview">
            <Button
              type="primary"
              key="new-account"
              onClick={setShowIframe.bind(null, false)}
            >
              Back
            </Button>
          </Tooltip>
        ) : null}
      </Space>
    </Row>
  );

  return (
    <Row>
      <Col sm={24}>
        {!showIframe ? (
          <Card
            title={cardTitle}
            hoverable={true}
            style={{ minHeight: "50vh" }}
          >
            <h6 className="form_label">Excerpt</h6>
            <div style={{ marginBottom: "10px" }}>
              <SkeletonLoader loading={loading} rows={0} type="normal">
                <>{course?.excerpt}</>
              </SkeletonLoader>
            </div>

            <h6 className="form_label">Objectives</h6>

            <SkeletonLoader loading={loading} rows={4} type="normal">
              <TextHeightWrapper
                maxChars={500}
                inputString={course?.objectives || ""}
              />
            </SkeletonLoader>

            <h6 className="form_label">Description</h6>

            <SkeletonLoader loading={loading} rows={4} type="normal">
              <TextHeightWrapper
                maxChars={500}
                inputString={course?.description || ""}
              />
            </SkeletonLoader>

            <h6 className="form_label">Course Content</h6>
            <SkeletonLoader loading={loading} rows={2} type="normal">
              <>
                {course?.coursesModules.map((module: any) => (
                  <CollapsibleCompAdmin data={module} />
                ))}
              </>
            </SkeletonLoader>

            <Col sm={24}>
              <Row justify="center" align="middle">
                {course?.type === CourseFormat.SCROM ||
                course?.type === CourseFormat.XAPI ? (
                  <Button
                    size="large"
                    type="primary"
                    onClick={setShowIframe.bind(null, true)}
                  >
                    View Complete Course
                  </Button>
                ) : null}
              </Row>
            </Col>
          </Card>
        ) : (
          <Card title={cardTitle}>
            <div style={{ height: "100vh" }}>
              <iframe
                src={course?.courseAttachments.attachmentUrl}
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            </div>
          </Card>
        )}

        <CourseNotes course={course} />
      </Col>
    </Row>
  );
}

export default ViewCourseMentorPage;
