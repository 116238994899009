import { Course } from "src/interfaces/Course";
import { useAppDispatch } from "src/store/store";
import { IoIosHeart } from "react-icons/io";
import {
  likeCourse,
  unlikeCourse,
} from "src/actions/courseActions/learnerCourseActions";

enum LikeColor {
  Red = "#dc143c",
  Default = "",
}

function Like({ course }: { course: Course }) {
  const dispatch = useAppDispatch();

  return (
    <div className="d-flex align-items-center">
      <IoIosHeart
        style={{
          color: `${
            course?.myLike?.length ? LikeColor.Red : LikeColor.Default
          }`,
        }}
        onClick={() => {
          if (course?.myLike?.length) {
            dispatch(
              unlikeCourse({
                courseId: course?.id,
                likeId: course?.myLike[0]?.id,
              })
            );
          } else dispatch(likeCourse(course?.id));
        }}
      />

      <div style={{ width: "0.4rem" }}></div>
      <div className="fs-7">{course?.likes?.length}</div>
    </div>
  );
}

export default Like;
