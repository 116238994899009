import { Tag } from "antd";

function RequestStatusTag({ approved }: { approved: boolean | null }) {
  const tagReturn = (input: boolean | null) => {
    if (input === null) return <Tag color="orange"> Waiting</Tag>;
    else if (input) return <Tag color="green">Accepted</Tag>;
    else if (!input) return <Tag color="red">Rejected</Tag>;
  };

  return <>{tagReturn(approved)}</>;
}

export default RequestStatusTag;
