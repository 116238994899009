import { Course } from "src/interfaces/Course";
import SkeletonLoaderLearner from "src/components/LearnerComponents/UtilityComponents/SkeletonLoaderLearner";
import { maxCharsLength } from "src/utils/utils";
import { useHistory, useParams } from "react-router-dom";
import CourseDescriptionHeroSocialIcons from "./CourseDescriptionHeroSocialIcons";
import VideoPlayer from "../../Common/mediaPlayers/VideoPlayer";
import TimeStamp from "../../Common/utilities/TimeStamp";
import BootstrapModal from "../UtilityComponents/BootstrapModal";
import { useTranslation } from "react-i18next";
import { LanguageCode } from "src/enums/Language";
import { languages } from "src/locale/languages";
import { useEffect, useRef } from "react";
interface Props {
  loading: boolean;
  course: Course;
}
interface Params {
  slug: string;
}

function HeroComponent({ loading, course }: Props) {
  const history = useHistory();
  const slug = useParams<Params>().slug;
  const { t } = useTranslation();
  const videoPlayerRef = useRef<any>();

  useEffect(() => {
    var myModalEl = document.getElementById('exampleModal');
    if (!myModalEl) return
    myModalEl.addEventListener('hide.bs.modal', function (event) {
      videoPlayerRef.current.pause();
    })
  }, []);

  return (
    <div className="container text-white" style={{ padding: "0px 12px" }}>
      <p className="small">
        {t("course")} &gt; {t("course_description")} &gt; {course?.title}
      </p>

      <SkeletonLoaderLearner
        loading={loading}
        rows={2}
        height={30}
        type="normal"
      >
        <h2 className="mb-3 text-white">{course?.title}</h2>
      </SkeletonLoaderLearner>
      <p className=" mb-0">
        <SkeletonLoaderLearner
          loading={loading}
          rows={2}
          height={20}
          type="normal"
        >
          <div className="fs-6 font_family_nunito_sans mt-3">
            {maxCharsLength(200, course?.excerpt || "")}
          </div>
        </SkeletonLoaderLearner>
      </p>
      <div className="w-25 my-3">
        {course ? <CourseDescriptionHeroSocialIcons course={course} /> : null}
      </div>
      <div className="d-flex">
        <div className="d-flex">
          <div> {t("last_updated_on")}</div>&nbsp;
          <TimeStamp timestamp={course.updatedAt} />
        </div>
        &nbsp;
        <div className="d-flex">
          {course?.language?.map((lang: LanguageCode) => (
            <div className="mx-1" key={lang}>
              {languages.find((l) => l?.code === lang)?.name}
            </div>
          ))}
        </div>
      </div>

      <div className="d-md-flex align-items-center mt-4">
        <a
          className="btn btn-primary mr-3 text-white"
          onClick={() => history.push(`/course-content/${slug}`)}
        >
          {t("start_course")}
        </a>
        <div style={{ width: "8px" }}></div>
        {course?.videos ? (
          <>
            <div
              className="cursor_pointer"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              {t("preview_this_course")}
            </div>
            <BootstrapModal modalTitle="Course Preview">
              <VideoPlayer url={course?.videos.attachmentUrl} ref={videoPlayerRef} />
            </BootstrapModal>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default HeroComponent;
