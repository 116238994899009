import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Course } from "src/interfaces/Course";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";
// import getAllLearnerCourses from "src/store/learnerSlices/learnerCoursesSlice";
import { useAppDispatch } from "src/store/store";
import RelatedCoursesCard from "./RelatedCoursesCard";

// function RelatedCourses() {
//   const courses = useSelector(coursesSelector.selectAllCourses());
//   const dispatch = useAppDispatch();
//   const location = useLocation();
//   const { t } = useTranslation();

//   useEffect(() => {
//     if (courses.length === 1) {
//       // dispatch(getAllLearnerCourses());
//     }
//   }, []);

//   return (
//     <>
//       <h5 className="mb-3 fw-bold">{t("related_courses")}</h5>
//       {courses
//         .filter(
//           (course: Course) => course.slug !== location.pathname.split("/")[2]
//         )
//         .slice(0, 3)
//         .map((course: Course) => (
//           <RelatedCoursesCard course={course} key={course.id} />
//         ))}
//     </>
//   );
// }

// export default RelatedCourses;

function RelatedCourses() {
  return <></>;
}

export default RelatedCourses;
