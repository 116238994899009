import { Button, Tooltip } from "antd";
import Translation from "src/components/Common/utilities/Translation";

function PreviewCourseButton({ slug }: { slug: string }) {
  return (
    <Tooltip title="Go to learner course page">
      <Button
        type="primary"
        key="new-account"
        onClick={() => window.open(`/course-description/${slug}`, "_blank")}
      >
        <Translation input="preview" />
      </Button>
    </Tooltip>
  );
}

export default PreviewCourseButton;
