import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "../../api/api";
import { PathWay } from "src/interfaces/PathWay";
import {
  likePathway,
  unlikePathway,
} from "src/actions/PathwayActions/pathwayActions";
import { getPathways } from "src/actions/PathwayActions/pathwayActions";

//learner pathway
export const getLearnerPathway = createAsyncThunk(
  "pathway/learner/get/all",
  async (page: number) =>
    await api
      .get(`admin/pathway/learnerPathways?page=${page}`)
      .then((res) => res.data)
);
