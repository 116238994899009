import { Card, Row, Col, Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import TimeStamp from "src/components/Common/utilities/TimeStamp";

interface Props {
  title: string;
  count: number;
  loading: boolean;
  date?: string;
}
function AnalyticsCard({ title, count, loading, date }: Props) {
  const { Title, Paragraph } = Typography;
  const { t } = useTranslation();

  return (
    <Card>
      <Col span={24}>
        <Row>
          <Title level={5}>{t(title)}</Title>
        </Row>
        <Row>
          <Title level={1}>{loading ? <Spin /> : count}</Title>
        </Row>
        <Row>
          {date && (
            <Paragraph className="font_family_nunito margin_0">
              {/* Since 1st Oct 2021 */}
              Since <TimeStamp timestamp={date} hideTime={true} />
            </Paragraph>
          )}
        </Row>
      </Col>
    </Card>
  );
}

export default AnalyticsCard;
