import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { api, extractStandardResponseData } from "../../api/api";
import { Course } from "../../interfaces/Course";
import { logout } from "../authSlice";
import {
  addLanguageToCourse,
  getAllCourses,
  getAllCoursesForMentor,
  getSingleCourse,
  getWebwatchAndInfographiaCourses,
  submitQuizAnswer,
} from "src/actions/courseActions/courseActions";
import {
  getAllLearnerCourses,
  getContinueLearningCourses,
  getCourse,
  getInfographiaCourses,
  getPopularCourses,
  getVideoCourses,
  getWebwatchCourses,
  getXapiAndScromCourses,
  likeCourse,
  unlikeCourse,
} from "src/actions/courseActions/learnerCourseActions";

export const coursesAdapter = createEntityAdapter<Course>();

export const createCourse = createAsyncThunk(
  "course/add",
  async (course: Course | any) =>
    await api.post("/admin/addCourse", course).then(extractStandardResponseData)
);

export const editCourse = createAsyncThunk(
  `course/edit`,
  async (course: any) =>
    await api
      .patch(`admin/editCourse/${course.id}`, course)
      .then(extractStandardResponseData)
);

export const approveCourse = createAsyncThunk(
  "admin/course/approval",
  async (courseId: number) =>
    await api
      .post(`/admin/${courseId}`, { approved: true })
      .then(extractStandardResponseData)
);

export const disapproveCourse = createAsyncThunk(
  "admin/course/disapprove",
  async (courseId: number) =>
    await api
      .post(`/admin/${courseId}`, { approved: false })
      .then(extractStandardResponseData)
);

// publish course
export const publishCourse = createAsyncThunk(
  "admin/course/publish",
  async (courseId: number) =>
    await api
      .post(`/admin/publish/${courseId}`, { published: true })
      .then((res: any) => {
        return res.data;
      })
);

export const unpublishCourse = createAsyncThunk(
  "admin/course/unpublish",
  async (courseId: number) =>
    await api
      .post(`/admin/publish/${courseId}`, { published: false })
      .then(extractStandardResponseData)
);

export const searchMentors = createAsyncThunk(
  "admin/searchMentors",
  async (mentor: string) => {
    const mentorSearch = await api
      .post(`/admin/searchUsers`, { mentor: mentor, author: "" })
      .then(extractStandardResponseData);
    return mentorSearch;
  }
);

export const searchAuthors = createAsyncThunk(
  "admin/searchAuthors",
  async (author: string) =>
    await api
      .post(`/admin/searchUsers`, { mentor: "", author: author })
      .then(extractStandardResponseData)
);

export const deleteCourse = createAsyncThunk(
  "courses/admin/delete",
  async (courseId: number) => {
    await api.delete(`/courses/${courseId}`).then(extractStandardResponseData);
    return courseId;
  }
);

export const createVideoCourse = createAsyncThunk(
  "courses/videoCourse/add",
  async (course: Course) =>
    api.post("admin/addCourse", course).then(extractStandardResponseData)
);

export const editVideoCourse = createAsyncThunk(
  "courses/videoCourse/edit",
  async (course: any) =>
    api
      .patch(`admin/editCourse/${course.id}`, course)
      .then(extractStandardResponseData)
);

export const addCourseNotesMentor = createAsyncThunk(
  "courses/mentor/add/notes",
  async (note: any) =>
    await api.post(`/mentor/${note.id}`, note).then(extractStandardResponseData)
);
export const editCourseNotesMentor = createAsyncThunk(
  "courses/mentor/edit/notes",
  async (note: any) =>
    await api
      .patch(`/mentor/${note.id}`, note)
      .then(extractStandardResponseData)
);

//search course admin
export const searchCourse = createAsyncThunk(
  "searchCourses",
  async (courseTitle: string) =>
    await api
      .post(`/courses/searchCourse`, { title: courseTitle })
      .then(extractStandardResponseData)
);

//clear all courses
export const clearCourses = createAsyncThunk("courses/clear", async () => {});

const spreadPayloadData = (payload: any) =>
  payload.data?.map((item: any) => ({
    ...item.courses,
    ...item,
  }));

export const coursesSlice = createSlice({
  name: "adminCoursesSlice",
  initialState: coursesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getContinueLearningCourses.fulfilled,
      (state, { payload }) =>
        coursesAdapter.upsertMany(state, spreadPayloadData(payload))
    );
    builder.addCase(getAllLearnerCourses.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertMany(state, spreadPayloadData(payload))
    );
    builder.addCase(getPopularCourses.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertMany(state, spreadPayloadData(payload))
    );
    builder.addCase(getVideoCourses.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertMany(state, spreadPayloadData(payload))
    );
    builder.addCase(getXapiAndScromCourses.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertMany(state, spreadPayloadData(payload));
    });
    builder.addCase(
      getWebwatchAndInfographiaCourses.fulfilled,
      (state, { payload }) => {
        coursesAdapter.upsertMany(state, spreadPayloadData(payload));
      }
    );
    builder.addCase(getWebwatchCourses.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertMany(state, spreadPayloadData(payload));
    });
    builder.addCase(getInfographiaCourses.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertMany(state, spreadPayloadData(payload));
    });
    builder.addCase(submitQuizAnswer.fulfilled, (state, { payload }) => {
      coursesAdapter.updateOne(state, {
        id: payload.id,
        changes: { courseCompletion: payload.courseCompletion },
      });
    });
    builder.addCase(likeCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload[0])
    );
    builder.addCase(getCourse.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertOne(state, payload);
    });

    builder.addCase(unlikeCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(getAllCourses.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertMany(state, payload.data)
    );
    builder.addCase(createCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(editCourse.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertOne(state, payload);
    });
    builder.addCase(deleteCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.removeOne(state, payload)
    );
    builder.addCase(disapproveCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(approveCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(publishCourse.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertOne(state, payload.data);
    });
    builder.addCase(unpublishCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );

    builder.addCase(getSingleCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(getAllCoursesForMentor.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(addCourseNotesMentor.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(editCourseNotesMentor.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(createVideoCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(editVideoCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertOne(state, payload)
    );
    builder.addCase(searchCourse.fulfilled, (state, { payload }) =>
      coursesAdapter.upsertMany(state, payload)
    );
    builder.addCase(clearCourses.fulfilled, (state) => {
      coursesAdapter.removeAll(state);
    });
    builder.addCase(addLanguageToCourse.fulfilled, (state, { payload }) => {
      coursesAdapter.updateOne(state, {
        id: parseInt(payload.data.courseId),
        changes: { language: payload.language },
      });
    });
    // builder.addCase(editCourseModule.fulfilled, (state, { payload }) => {
    //   coursesAdapter.updateOne(state, {
    //     id: payload.id,
    //     changes: { coursesModules: payload.modules },
    //   });
    // });
    builder.addCase(logout.fulfilled, () => coursesAdapter.getInitialState());
  },
});

const { selectById, selectAll } = coursesAdapter.getSelectors();
const getCoursesState = (state: RootState) => state.courses;

export const coursesSelector = {
  coursesSelector: coursesAdapter.getSelectors((s: RootState) => s.courses),
  courses: (state: RootState) => state.courses.entities,
  selectAllCourses: () =>
    createSelector(getCoursesState, (state) => selectAll(state)),
  selectCourseEntity: (id: number) =>
    createSelector(getCoursesState, (state) => selectById(state, id)),
  selectCourseEntityWithSlug: (slug: string) =>
    createSelector(
      getCoursesState,
      (state) =>
        selectAll(state).filter((course: Course) => course.slug === slug)[0]
    ),
};
