import {
  Button,
  PageHeader,
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Modal,
  Switch,
  Table,
  Result,
  message,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useHistory, useLocation } from "react-router-dom";
import SearchCourses from "src/components/AdminAuthorMentorComponents/Common/SearchComponents/SearchCourses";
import {
  clearSessionsStore,
  createSession,
} from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import { useAppDispatch } from "src/store/store";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import UploadImage from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";
import Column from "antd/lib/table/Column";
import { User } from "src/interfaces/User";
import { getLearners } from "src/store/mentorSlices/sessionsSlices/sessionsSlice";
import socketClient from "socket.io-client";
import { useRef } from "react";
import { baseUrl } from "src/api/api";
import { clearPaginations } from "src/actions/sessionsActions";

function CreateSessionPage() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [learners, setLearners] = useState<User[]>();
  const [selectedLearners, setSelectedLearners] = useState<User[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const mentorId = useSelector(authSelectors.id);
  const [done, setDone] = useState(false);
  const location = useLocation();
  const { Text, Link } = Typography;

  const labelCol = {
    sm: { span: 24, offset: 0 },
    md: { span: 10, offset: 0 },
    lg: { span: 8, offset: 0 },
  };
  const wrapperCol = {
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 2 },
    lg: { span: 12, offset: 1 },
  };

  const SERVER = baseUrl;
  var connectionOptions: any = {
    forceNew: true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
  };
  const socketRef: any = useRef();
  socketRef.current = socketClient(SERVER, connectionOptions);

  const formSubmitHandler = () => {
    if (selectedLearners.length === 0) {
      message.error("Please select atleast one Learner");
      return;
    }
    setLoading(true);
    setDone(true);
    const session = {
      sessionAttachment: form.getFieldsValue().imageId,
      courseId: form.getFieldsValue().courses.key,
      mentorId,
      meeting: {
        topic: form.getFieldsValue().topic,
        type: 2,
        // start_time: form.getFieldsValue().date.toISOString(),
        start_time: `${form
          .getFieldsValue()
          .date.toISOString()
          .substring(0, 19)}Z`,

        timezone: "",
        settings: {
          host_video: form.getFieldsValue().host_video,
          participant_video: form.getFieldsValue().participant_video,
          approval_type: 0,
          registration_type: 2,
          registrants_email_notification: false,
        },
      },
      registrants: selectedLearners?.map((user: User) => ({
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
      })),
    };

    dispatch(createSession(session))
      .then(unwrapResult)
      .then((res) => {
        socketRef.current.emit("sessionNotification", res);
        dispatch(clearSessionsStore());
        dispatch(clearPaginations());
        setShowSuccess(true);
        setLoading(false);
      })
      .catch();
  };

  useEffect(() => {
    if (location.state) {
      const { type }: any = location.state;
      if (type === "create-session") {
        form.setFieldsValue({ topic: "hello world", courses: "" });
      }
    }
  }, []);

  return (
    <>
      <Col sm={24}>
        <PageHeader
          title="Schedule A Session"
          onBack={() => history.goBack()}
          extra={[]}
        />
      </Col>
      {showSuccess ? (
        <Result
          status="success"
          title="Successfully Created Session"
          subTitle="Session has been created "
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/mentor/sessions")}
            >
              Go To Sessions
            </Button>,
          ]}
        />
      ) : (
        <>
          <Col sm={16} style={{ marginTop: "50px" }}>
            <Form
              form={form}
              onFinish={formSubmitHandler}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              scrollToFirstError
            >
              <Form.Item
                style={{ marginBottom: "0px" }}
                label={<strong>Session Image</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <UploadImage done={done} name="imageId" nested={false} />
              </Form.Item>

              <Form.Item name="topic" label={<strong>Session Name</strong>}>
                <Input placeholder="Name of Session" size="large" />
              </Form.Item>
              <SearchCourses />
              <Form.Item
                // name="registrants"
                label={<strong>Add Participants</strong>}
              >
                <Space>
                  <Button
                    size="small"
                    loading={tableLoading}
                    onClick={() => {
                      if (form.getFieldsValue().courses) {
                        setTableLoading(true);
                        dispatch(getLearners(form.getFieldsValue().courses.key))
                          .then(unwrapResult)
                          .then((res) => {
                            setLearners(res);
                            setTableLoading(false);
                            setShowModal(true);
                          });
                      } else message.error("Please Select Course");
                    }}
                  >
                    {!selectedLearners.length ? "Add" : "Edit"} participants
                  </Button>
                  <Text keyboard>
                    <Text>{selectedLearners.length} Learners Selected</Text>
                  </Text>
                </Space>
              </Form.Item>

              <Form.Item
                name="date"
                label={<strong>Date</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker size="large" showTime onOk={() => {}} />
              </Form.Item>
              <Form.Item
                label={<strong>Host Video On</strong>}
                name="host_video"
                initialValue={false}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={<strong>Participant Video On</strong>}
                name="participant_video"
                initialValue={false}
              >
                <Switch />
              </Form.Item>

              <Row>
                <Col span={9}></Col>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Schedule
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </>
      )}

      <Modal
        maskClosable={false}
        style={{ padding: "0" }}
        title="Select Learners"
        visible={showModal}
        // onOk={handleOk}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button type="primary" key="back" onClick={() => setShowModal(false)}>
            Done
          </Button>,
        ]}
      >
        <Table
          scroll={{ y: 320 }}
          pagination={{ pageSize: 100 }}
          rowSelection={{
            onChange: (_, selectedRows: any) => {
              setSelectedLearners(selectedRows);
            },
          }}
          dataSource={learners}
          loading={tableLoading}
          rowKey={(s) => s?.id}
        >
          <Column
            title="Title"
            dataIndex="title"
            key="title"
            render={(_, user: User) => (
              <div className="hover_color_change cursor_pointer">
                {user?.firstName} {user?.lastName}
              </div>
            )}
          />

          <Column title="Email" dataIndex="email" key="actions" />
        </Table>
      </Modal>
    </>
  );
}

export default CreateSessionPage;
