import "antd/dist/antd.css";
import { Layout } from "antd";
import { authSelectors } from "src/store/authSlice";
import { Role } from "src/interfaces/User";
import { useSelector } from "react-redux";
import UnauthorizedAccess from "src/components/Common/authorization/UnauthorizedAccess";
import NavLogo from "../../Common/Layout/NavLogo";
import React, { Suspense } from "react";
import HeaderContentFooter from "../../Common/Layout/HeaderContentFooter";
import PageLoading from "src/components/Common/utilities/PageLoading";
import { ConfigProvider } from "antd";
import { getDirection } from "src/utils/utils";
import MentorMenu from "./MentorMenu";

const AdminAuthorMentorStyles = React.lazy(
  () => import("src/Assets/styles/AdminAuthorMentorStyles")
);
const { Sider } = Layout;

interface MentorLayoutI {
  component: JSX.Element;
}

function MentorLayout({ component }: MentorLayoutI) {
  const role = useSelector(authSelectors.role);

  return (
    <Suspense fallback={<PageLoading />}>
      <AdminAuthorMentorStyles />
      {role === Role.MENTOR ? (
        <ConfigProvider direction={getDirection()}>
          <Layout style={{ minHeight: "100vh" }}>
            <Sider width={250} collapsible>
              <NavLogo />
              <MentorMenu />
            </Sider>
            <HeaderContentFooter component={component} />
          </Layout>
        </ConfigProvider>
      ) : (
        <UnauthorizedAccess />
      )}
    </Suspense>
  );
}

export default MentorLayout;
