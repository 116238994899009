import { useState } from "react";
import { Form, Upload, Input, message, Progress, Button } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { api } from "../../../../api/api";
import { getFileExtention } from "src/utils/utils";
import { FileType } from "src/enums/FileTypes";

interface Props {
  done: boolean;
}

function UploadScromCourse({ done }: Props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [scromAttachment, setScromAttachment] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadButton = (
    <div>
      {loading ? (
        uploadProgress === 100 ? (
          <LoadingOutlined />
        ) : (
          <Progress percent={uploadProgress} type="circle" width={50} />
        )
      ) : (
        <PlusOutlined />
      )}
      <div style={{ marginTop: 8 }}>Upload SCROM</div>
    </div>
  );
  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };

  const customRequest = async (e: any) => {
    if (getFileExtention(e.file.name) === FileType.zip) {
      setLoading(true);
      const fileList = e.file;
      if (fileList) {
        const formData = new FormData();
        formData.append("file", fileList);
        try {
          const zip = await api.post("/courses/scrom", formData, config);
          message.success("SCROM uploaded successfully");
          setScromAttachment(zip.data.data.id);
          console.log(zip);
          setLoading(false);
        } catch (error) {
          message.error("SCROM upload error");
          setLoading(false);
        }
      }
    } else {
      message.error("Please add a zip file");
    }
  };

  const removeHandler = (e: any) => {};

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={true}
            customRequest={customRequest}
            multiple={false}
            fileList={fileListArray}
            onRemove={removeHandler}
          >
            {show ? (
              <div style={{ fontSize: "200%" }}>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </div>

      {done ? (
        <div>
          <Form.Item name="courseAttachment" initialValue={scromAttachment}>
            <Input type="hidden" />
          </Form.Item>
        </div>
      ) : null}
    </div>
  );
}

export default UploadScromCourse;
