import { Button } from "antd";
import { useState } from "react";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Translation from "src/components/Common/utilities/Translation";
import UploadImage from "src/components/AdminAuthorMentorComponents/Common/UploadComponents/UploadImage";

interface Props {
  done: boolean;
  imageUrl: string;
}

function CourseImage({ done, imageUrl }: Props) {
  const [showUploadCourse, setShowUploadCourse] = useState(false);

  return (
    <>
      <FormLabel name="course_image" />
      <div style={{ marginBottom: "20px" }}>
        {showUploadCourse ? (
          <UploadImage done={done} name="imageId" nested={false} />
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={imageUrl} alt="course-image" style={{ width: "15rem" }} />
            <Button
              type="primary"
              onClick={() => setShowUploadCourse(true)}
              style={{ width: "15rem" }}
            >
              <Translation input="update_course_image" />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default CourseImage;
