import { useEffect, useState } from "react";
import { Form, Upload, message, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import FormLabel from "src/components/Common/utilities/FormLabel";
import Modal from "antd/lib/modal/Modal";
import { uploadCertificateLogo } from "src/actions/certificateActions";
import { useAppDispatch } from "src/store/store";
import { LessonsAttachment } from "src/interfaces/Lesson";
import {
  addSliderImage,
  removeSliderImage,
} from "src/utils/Rest/EditCourseUtils/EditCourseUtils";
import { LessonFormat } from "src/enums/LessonFormats";
import { uploadCourseImage } from "src/api/mediaUploadApi";

interface FileList {
  url: string;
  name: string;
  status: string;
  uid: number;
}

interface Props {
  sectionData: LessonsAttachment[];
  moduleId: number | string;
  lessonId: number | string;
  sectionId: number | string;
  onChange: (e: any) => void;
}

function SliderSection({
  sectionData,
  moduleId,
  lessonId,
  sectionId,
  onChange,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [showModal, setShowModal] = useState({ visible: false, url: "" });
  const customRequest = async (e: any) => {
    setLoading(true);
    const fileList = e.file;
    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      if (e.file.type !== "image/jpeg" && e.file.type !== "image/png") {
        message.error("please upload image");
        setLoading(false);
        return;
      }

      try {
        const image = await uploadCourseImage(formData);

        if (!sectionData[0].resources) {
          onChange([
            {
              type: LessonFormat.IMAGESLIDER,
              attachmentId: image.id,
              resources: { attachmentUrl: image.attachmentUrl },
              sectionId,
            },
          ]);
        } else {
          onChange([
            ...sectionData,
            {
              type: LessonFormat.IMAGESLIDER,
              attachmentId: image.id,
              resources: { attachmentUrl: image.attachmentUrl },
              sectionId,
            },
          ]);
        }
        setFileListArray([
          ...fileListArray,
          {
            url: image.attachmentUrl,
            name: "image.png",
            status: "done",
            uid: image.id,
          },
        ]);

        setLoading(false);
        message.success("image successfully uploaded");
      } catch (error) {
        console.log(error);
        message.error("error uploading ");
      }
    }
  };

  const removeHandler = (e: any) => {
    // setFileListArray(
    //   fileListArray.filter((media: FileList) => media.uid !== e.uid)
    // );
    onChange(sectionData.filter((item) => item.attachmentId !== e.uid));
  };

  const uploadButton = loading ? (
    <LoadingOutlined style={{ fontSize: "250%" }} />
  ) : (
    <PlusOutlined style={{ fontSize: "250%" }} />
  );

  useEffect(() => {
    if (sectionData[0].id) {
      setFileListArray(
        sectionData.map((item: LessonsAttachment) => ({
          url: item?.resources?.attachmentUrl,
          name: "",
          status: "done",
          uid: item?.attachmentId,
        }))
      );
    }
  }, [sectionData]);

  return (
    <>
      {/* <Button
        onClick={() => {
          console.log(sectionData);
        }}
      >
        test
      </Button> */}
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
        customRequest={customRequest}
        multiple={true}
        fileList={fileListArray}
        onRemove={removeHandler}
        onPreview={(e) => setShowModal({ visible: true, url: e.url || "" })}
      >
        {fileListArray.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        title="Image Preview"
        visible={showModal.visible}
        footer={[]}
        onCancel={() => setShowModal({ ...showModal, visible: false })}
      >
        <img src={showModal.url} style={{ width: "100%" }} />
      </Modal>
    </>
  );
}

export default SliderSection;
