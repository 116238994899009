import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../../store";
import { coursesSelector } from "../coursesSlice";
import { getWebwatchAndInfographiaCourses } from "src/actions/courseActions/courseActions";

const webwatchAndInfographiaPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: webwatchAndInfographiaPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const webwatchAndInfographiaPaginationSlice = createSlice({
  name: "webwatchAndInfographia/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getWebwatchAndInfographiaCourses.fulfilled,
      (state, { payload }) => {
        return {
          pages: webwatchAndInfographiaPaginationAdapter.upsertOne(
            { ...state.pages },
            {
              id: parseInt(payload.currentPage),
              items: payload?.data?.map((item: any) => item.id),
            }
          ),
          total: payload.pages,
          totalItems: payload.items,
        };
      }
    );
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = webwatchAndInfographiaPaginationAdapter.getSelectors(
  (s: RootState) => s.webwatchAndInfographiaPagination.pages
);

export const webwatchAndInfographiaPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        coursesSelector.coursesSelector.selectById(state, id)
      ) || [],
  totalPages: (state: RootState) =>
    state.webwatchAndInfographiaPagination.total,

  // total: (state: RootState) => state.continueLearning.totalItems,
};
