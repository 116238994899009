import LikeIcon from "src/Assets/media/like-white-icon.svg";
import CommentIcon from "src/Assets/media/comment-white-icon.svg";
import ViewIcon from "src/Assets/media/view-white-icon.svg";
import { Course } from "src/interfaces/Course";
import Like from "./CourseIcons/Like";

function CourseDescriptionHeroSocialIcons({ course }: { course: Course }) {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <img src={ViewIcon} width="16px" height="16px" alt="" />
        <div style={{ width: "0.4rem" }}></div>
        <div className="fs-7">230</div>
      </div>
      <Like course={course} />

      <div className="d-flex align-items-center">
        <img src={CommentIcon} width="16px" height="16px" alt="" />
        <div style={{ width: "0.4rem" }}></div>
        <div className="fs-7">30</div>
      </div>
    </div>
  );
}

export default CourseDescriptionHeroSocialIcons;
