import { searchUsers } from "./userSlice";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../store";
import {
  getUsers,
  editUser,
  createMultipleUsers,
} from "src/actions/userActions";
import { User } from "src/interfaces/User";
import { userListSelectors } from "src/store/users/userSlice";

const usersPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: usersPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const usersPaginationSlice = createSlice({
  name: "users/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => ({
      pages: usersPaginationAdapter.upsertOne(
        { ...state.pages },
        {
          id: parseInt(payload.currentPage),
          items: payload?.data?.map((user: User) => user.id),
        }
      ),
      total: payload.pages,
      totalItems: payload.items,
    }));

    builder.addCase(searchUsers.fulfilled, (state, { payload }) => ({
      pages: usersPaginationAdapter.upsertOne(
        { ...state.pages },
        {
          id: parseInt(payload.currentPage),
          items: payload?.data?.map((user: User) => user.id),
        }
      ),
      total: payload.pages,
      totalItems: payload.items,
    }));

    builder.addCase(editUser.fulfilled, () => initialState);
    builder.addCase(createMultipleUsers.fulfilled, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = usersPaginationAdapter.getSelectors(
  (s: RootState) => s.usersPagination.pages
);

export const userPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        userListSelectors.usersSelector.selectById(state, id)
      ) || [],
  totalusers: (state: RootState) => state.usersPagination.totalItems,
};
