import { useState } from "react";
import { Form, Upload, message, Input, Col, Row, Progress } from "antd";
import { uploadCourseImage } from "../../../../api/mediaUploadApi";
import { Image, Video, Doc } from "../../../../interfaces/Media";
import { LessonFormat } from "src/enums/LessonFormats";
import {
  LoadingOutlined,
  InboxOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  FileImageOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { api, extractStandardResponseData } from "src/api/api";
interface Props {
  done: boolean;
  field?: any;
  name: any;
  nested: boolean;
  sectionId?: number;
}

function UploadImage({ field, done, name, nested, sectionId }: Props) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [showBox, setShowBox] = useState(true);
  const [show, setShow] = useState(false);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [attachmentIdArray, setAttachmentIdArray] = useState<any>([]);
  const { Dragger } = Upload;
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadButton = (
    <div>
      {show ? (
        <div style={{ fontSize: "200%" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </div>
      ) : (
        <>
          {loading ? (
            uploadProgress === 100 ? (
              <LoadingOutlined style={{ fontSize: "220%", color: "#52c41a" }} />
            ) : (
              <Progress percent={uploadProgress} type="circle" width={50} />
            )
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PlusOutlined />
              Upload Image
            </div>
          )}
        </>
      )}
    </div>
  );

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };

  const customRequest = async (e: any) => {
    setLoading(true);
    setShowBox(false);
    const fileList = e.file;
    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      try {
        if (e.file.type === "image/jpeg" || e.file.type === "image/png") {
          // const image: Image = await uploadCourseImage(formData);
          const image: Image = await api
            .post("/courses/images", formData, config)
            .then(extractStandardResponseData);

          setImageUrl(image.attachmentUrl);
          if (nested) {
            setAttachmentIdArray({
              attachmentId: image.id,
              type: LessonFormat.IMAGE,
              sectionId: sectionId,
            });
          } else {
            setAttachmentIdArray([{ attachmentId: image.id }]);
          }

          setLoading(false);
          message.success("Image successfully uploaded");
        } else {
          message.error("Upload format should be Image");
          setShowBox(true);
        }
      } catch (error) {
        message.error("error uploading ");
        setShowBox(true);
      }
    }
  };

  const removeHandler = (e: any) => {};
  return (
    <div style={{ marginBottom: "10px" }}>
      <Upload
        name="test"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
        customRequest={customRequest}
        multiple={false}
        fileList={fileListArray}
        onRemove={removeHandler}
        onPreview={(e) => {
          console.log(e);
        }}
        openFileDialogOnClick={true}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{ width: "100%", maxHeight: "100px", objectFit: "cover" }}
          />
        ) : (
          uploadButton
        )}
      </Upload>

      {done ? (
        <div>
          {nested ? (
            <Form.Item
              name={[field.name, name]}
              initialValue={attachmentIdArray}>
              <Input type="hidden" />
            </Form.Item>
          ) : (
            <Form.Item
              name={name}
              initialValue={attachmentIdArray[0]?.attachmentId}>
              <Input type="hidden" />
            </Form.Item>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default UploadImage;
