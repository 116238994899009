import { useSelector } from "react-redux";
import NotificationIcon from "src/Assets/media/notification-icon.svg";
import { RootState, useAppDispatch } from "src/store/store";
import NavNotification from "./NavNotification";
import {
  notificationsSelector,
  clearNotifications,
} from "src/store/notificationsSlice/notificationSlice";
import { useEffect, useState } from "react";
import { NotificationI } from "src/interfaces/NotificationI";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import socketClient from "socket.io-client";
import { authSelectors } from "src/store/authSlice";
import { baseUrl } from "src/api/api";
import { Role } from "src/interfaces/User";
import {
  getNotifications,
  setNotificationViewed,
} from "src/actions/notificationActions";
import { notificationPaginationSelectors } from "src/store/notificationsSlice/notificationsPaginationSlice";
function NavNotificationIcon() {
  const dispatch = useAppDispatch();
  const notifications = useSelector((state: RootState) =>
    notificationPaginationSelectors.get(state, 0)
  ) as NotificationI[];
  const notificationIds = useSelector((state: RootState) =>
    notificationPaginationSelectors.getIds(state, 0)
  )?.items;

  const history = useHistory();
  const role = useSelector(authSelectors.role);

  const SERVER = baseUrl;
  var connectionOptions: any = {
    forceNew: true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
  };

  const socketRef: any = useRef();
  socketRef.current = socketClient(SERVER, connectionOptions);

  const getNotViewedNotificationCount = (notifications: NotificationI[]) =>
    notifications.filter((notification: NotificationI) => !notification.viewed)
      .length;

  useEffect(() => {
    if (role !== Role.LEARNER) return;
    dispatch(getNotifications(0));
    socketRef.current.on("courseNotification", (data: any) => {
      dispatch(getNotifications(0));
    });
    socketRef.current.on("sessionNotification", (data: any) => {
      dispatch(getNotifications(0));
    });
    return () => socketRef.current.disconnect();
  }, []);

  // useEffect(() => {
  //   if (role !== Role.LEARNER) return;
  //   dispatch(getNotifications(0));
  //   socketRef.current.on("courseNotification", (data: any) => {
  //     dispatch(clearNotifications()).then(() => {
  //       dispatch(getNotifications(0));
  //     });
  //     // dispatch(
  //     //   soketNotifications(
  //     //     data.filter(
  //     //       (notification: NotificationI) => notification.userId === userId
  //     //     )
  //     //   )
  //     // );
  //   });
  //   socketRef.current.on("sessionNotification", (data: any) => {
  //     dispatch(clearNotifications()).then(() => {
  //       dispatch(getNotifications(0));
  //     });
  //   });
  //   return () => socketRef.current.disconnect();
  // }, []);

  return (
    <li
      className="nav-item px-lg-2 dropdown d-menu px-md-5 px-sm-5 px-5"
      onMouseOver={() =>
        dispatch(setNotificationViewed(notificationIds?.slice(0, 4) || []))
      }
    >
      <a
        className="nav-link dropdown-toggle"
        // href="#"
        id="dropdown01"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="position-relative">
          <img src={NotificationIcon} width="22px" alt="notification-icon" />
          {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {getNotViewedNotificationCount(notifications)}+
          </span> */}
        </div>
        <span className="text-light mx-3 d-lg-none">Notifications</span>
      </a>

      <div
        className="dropdown-menu shadow-sm sm-menu dropdown-menu-right"
        aria-labelledby="dropdown01"
      >
        {notifications
          .slice(0, 4)
          .map((notification: NotificationI, i: number) => (
            <NavNotification
              notification={notification}
              key={i}
              style={{ width: "320px" }}
            />
          ))}
        <a
          className="dropdown-item d-flex justify-content-center"
          onClick={() => history.push("/learner/notifications")}
        >
          View all
        </a>
      </div>
    </li>
  );
}

export default NavNotificationIcon;
