import { Form, Input } from "antd";

interface Props {
  done: boolean;
  fileListArray: [];
}

function HiddenField({ done, fileListArray }: Props) {
  return (
    <>
      {done ? (
        <Form.Item
          name="coursesNotes"
          initialValue={fileListArray.map((item: any) => {
            if (item.id) return { attachmentId: item.uid, id: item.id };
            else return { attachmentId: item.uid };
          })}
        >
          <Input type="hidden" />
        </Form.Item>
      ) : null}
    </>
  );
}

export default HiddenField;
