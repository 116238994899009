import { useState } from "react";
import { Form, Upload, message, Input, Col, Row, Progress } from "antd";
import {
  LoadingOutlined,
  InboxOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { uploadVideo } from "../../../../api/mediaUploadApi";
import { Video } from "../../../../interfaces/Media";
import { LessonFormat } from "src/enums/LessonFormats";
import { api, extractStandardResponseData } from "src/api/api";
interface Props {
  done: boolean;
  field?: any;
  sectionId: number;
  // nested: boolean;
  // name: string;
}

function UploadVideo({ field, done, sectionId }: Props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showBox, setShowBox] = useState(true);
  const [fileListArray, setFileListArray] = useState<any>([]);
  const [attachmentIdArray, setAttachmentIdArray] = useState<any>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const { Dragger } = Upload;

  const uploadButton = (
    <div>
      {show ? (
        <div style={{ fontSize: "200%" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </div>
      ) : (
        <>
          {loading ? (
            uploadProgress === 100 ? (
              <LoadingOutlined />
            ) : (
              <Progress percent={uploadProgress} type="circle" width={50} />
            )
          ) : (
            <PlusOutlined />
          )}
        </>
      )}
    </div>
  );

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    },
  };
  const customRequest = async (e: any) => {
    setLoading(true);
    setShowBox(false);
    const fileList = e.file;

    if (fileList) {
      const formData = new FormData();
      formData.append("file", fileList);
      console.log(e.file.type);
      try {
        if (e.file.type === "video/mp4" || e.file.type === "audio/mpeg") {
          // const video: Video = await uploadVideo(formData);
          const video: Video = await api
            .post("/courses/files", formData, config)
            .then(extractStandardResponseData);

          setAttachmentIdArray({
            attachmentId: video.id,
            type: LessonFormat.VIDEO,
            sectionId: sectionId,
          });

          setLoading(false);
          setShow(true);
          message.success("video successfully uploaded");
        } else {
          setLoading(false);
          setShowBox(true);
          message.success("Upload format should be Video");
        }
      } catch (error) {
        console.log(error);
        message.error("error uploading ");
      }
    }
  };

  const removeHandler = (e: any) => {};
  return (
    <Col sm={24}>
      <Row justify="center">
        <Dragger
          name="avatar"
          showUploadList={true}
          customRequest={customRequest}
          multiple={false}
          fileList={fileListArray}
          onRemove={removeHandler}
          style={{ width: "100%" }}
        >
          <div>
            <p className="ant-upload-drag-icon">{uploadButton}</p>
            <p className="ant-upload-text">
              {show
                ? "Video Uploaded Sucessfully"
                : "Click or drag file to this area to upload video"}
            </p>
          </div>
        </Dragger>
      </Row>

      {done ? (
        <Form.Item
          name={[field.name, "lessonsAttachments"]}
          initialValue={attachmentIdArray}
        >
          <Input type="hidden" />
        </Form.Item>
      ) : null}
    </Col>
  );
}

export default UploadVideo;
