import { useTranslation } from "react-i18next";
import PeopleToMeetCard from "./PeopleToMeetCard";

function PeopleToMeet() {
  const { t } = useTranslation();

  return (
    <div className="mt-5 noselect">
      <h5 className="mb-3 fw-bold">{t("people_to_meet")}</h5>
      <PeopleToMeetCard
        name="Susant patel"
        designation="Software Engineer"
        image="https://images.pexels.com/photos/3771839/pexels-photo-3771839.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260="
      />
      <PeopleToMeetCard
        name="Omkar singh"
        designation="Marketing Lead"
        image="https://images.pexels.com/photos/5586512/pexels-photo-5586512.jpeg?auto=compress&cs=tinysrgb&w=600"
      />
      <PeopleToMeetCard
        name="Sunita pal"
        designation="Sales Lead"
        image="https://images.pexels.com/photos/1925482/pexels-photo-1925482.jpeg?auto=compress&cs=tinysrgb&w=600"
      />
      <PeopleToMeetCard
        name="Taskeen fatma"
        designation="Business Development"
        image="https://images.pexels.com/photos/3755918/pexels-photo-3755918.jpeg?auto=compress&cs=tinysrgb&w=600"
      />
    </div>
  );
}

export default PeopleToMeet;
