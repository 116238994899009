import { Button, Modal, Space } from "antd";
import { useAppDispatch } from "src/store/store";
import { acceptRejectSessionRequests } from "src/store/mentorSlices/sessionRequestsSlice";
import { useHistory } from "react-router-dom";
import { SessionRequest } from "src/interfaces/SessionRequest";
function RequestActionButtons({
  approved,
  sessionRequestId,
  sessionRequest,
}: {
  approved: boolean | null;
  sessionRequestId: number;
  sessionRequest: SessionRequest;
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const buttonReturn = (input: boolean | null) => {
    if (input === null)
      return (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              Modal.confirm({
                content: "Are you sure you want to Accept the request",
                onOk: () => {
                  // history.push("/mentor/create-session", {
                  //   type: "create-session",
                  //   course: sessionRequest.courses,
                  // });

                  dispatch(
                    acceptRejectSessionRequests({
                      approved: true,
                      sessionRequestId,
                    })
                  ).then(() =>
                    history.push("mentor/create-session", {
                      type: "create-session",
                    })
                  );
                },
              });
            }}
          >
            Accept
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              Modal.confirm({
                content: "Are you sure you want to Reject the request",
                onOk: () => {
                  dispatch(
                    acceptRejectSessionRequests({
                      approved: false,
                      sessionRequestId,
                    })
                  );
                },
              });
            }}
          >
            Reject
          </Button>
        </Space>
      );
    else return <></>;
  };

  return <div>{buttonReturn(sessionRequest.approved)}</div>;
}

export default RequestActionButtons;
