import NavDropDown from "./NavDropDown";
import { Layout, Row, Col, Space } from "antd";
import NavSettings from "./NavSettings";
import NavLanguage from "./NavLanguage";
import HelpOption from "./HelpOption";
const { Header, Content, Footer } = Layout;

interface Props {
  component: JSX.Element;
}
function HeaderContentFooter({ component }: Props) {
  return (
    <Layout className="site-layout">
      <Header className="header">
        <Row justify="end" align="middle">
          <Space size="large">
            <HelpOption />
            {/* <NavSettings /> */}
            <NavLanguage />
            <NavDropDown />
          </Space>
        </Row>
      </Header>
      <Content style={{ margin: "0 16px" }}>
        <Row justify="center" style={{ padding: "24px 16px" }}>
          <Col sm={24} md={24} lg={24} xl={24} xxl={20}>
            {component}
          </Col>
        </Row>
      </Content>
      {/* <Footer style={{ textAlign: "center" }}>Learnsure</Footer> */}
    </Layout>
  );
}

export default HeaderContentFooter;
