import React, { useEffect, useState } from "react";
import { PathWayCourse } from "src/interfaces/Course";
import TextHeightWrapper from "src/components/Common/utilities/TextHeightWrapper";
import { maxCharsLength } from "src/utils/utils";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { TypeReferenceType } from "typescript";

interface Props {
  pathway: PathWayCourse;
  index: number;
}

function PathwayCourseCard({ pathway, index }: Props) {
  const [hover, setHover] = useState(false);
  const history = useHistory();

  return (
    <div className="row">
      <div className="col-1">
        <div className="row d-flex justify-content-between w-100">
          <div
            className="mx-2"
            style={{
              marginTop: "80px",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#002766",
              color: "white",
            }}
          >
            {index + 1}
          </div>
        </div>
      </div>
      <div className="col-11">
        <div
          onMouseEnter={setHover.bind(null, true)}
          onMouseLeave={setHover.bind(null, false)}
          className={`card p-4 border_color mb-4 cursor_pointer border_radius ${
            hover ? "shadow" : ""
          }`}
          onClick={() =>
            history.push(`/course-description/${pathway.courses.slug}`)
          }
        >
          <div className="row">
            <div className="col-md-4 d-flex">
              <img
                src={pathway?.courses?.resources?.attachmentUrl}
                className="img-fluid rounded-start"
                style={{ objectFit: "cover" }}
                alt="course-image"
              />
            </div>

            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title fw-bolder fs-5 mb-6 py-6">
                  {pathway?.courses?.title}
                </h5>
                <div style={{ height: "6rem" }} className="overflow-hidden">
                  <p className="card-text">{pathway?.courses?.excerpt}</p>
                </div>
                <p className="card-text">
                  <small className="text-muted">Last updated 3 mins ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PathwayCourseCard;
