export const saveToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const setRoute = (route: any) => {
  localStorage.setItem("route", route);
};
export const getRoute = () => {
  return localStorage.getItem("route");
};

export const recordOnRefresh = (route: any) => {
  // window.onpopstate = () => {};
  localStorage.setItem("route", route);
  // window.BeforeUnloadEvent = () => {};
};
