import LikeIcon from "src/Assets/media/like-white-icon.svg";
import CommentIcon from "src/Assets/media/comment-white-icon.svg";
import ViewIcon from "src/Assets/media/view-white-icon.svg";
import { PathWay } from "src/interfaces/PathWay";

function PathwayCourseDescriptionSocialIcons({
  pathway,
}: {
  pathway: PathWay;
}) {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <img src={ViewIcon} width="16px" height="16px" alt="view-icon" />
        <div style={{ width: "0.4rem" }}></div>
        <div className="fs-7">{pathway?.views}</div>
      </div>
      <div className="d-flex align-items-center">
        <img src={LikeIcon} width="16px" height="16px" alt="like-icon" />
        <div style={{ width: "0.4rem" }}></div>
        <div className="fs-7">{pathway?.likes?.length}</div>
      </div>
      <div className="d-flex align-items-center">
        <img src={CommentIcon} width="16px" height="16px" alt="comment-icon" />
        <div style={{ width: "0.4rem" }}></div>
        <div className="fs-7">30</div>
      </div>
    </div>
  );
}

export default PathwayCourseDescriptionSocialIcons;
