import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CourseCard from "../../components/LearnerComponents/Course/CourseCard/CourseCard";
import { RootState, useAppDispatch } from "src/store/store";
import { Course } from "src/interfaces/Course";
import Translation from "src/components/Common/utilities/Translation";
import { removeHypenAddSpace, setTabTitle } from "src/utils/utils";
import Pagination from "src/components/LearnerComponents/UtilityComponents/Pagination";
import { useParams } from "react-router-dom";
import { Filters, FilterMethods } from "src/enums/Filters";
import Spinner from "src/components/LearnerComponents/UtilityComponents/Spinner";

interface Params {
  filter: Filters;
}

function CourseListingPage() {
  const { filter } = useParams<Params>();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const courses = useSelector((state: RootState) =>
    FilterMethods[filter].selector.get(state, page)
  );
  const totalPages = useSelector(
    FilterMethods[filter].selector.totalPages
  ) as number;

  useEffect(() => {
    window.scrollTo(0, 0);
    setTabTitle("View All");
    setLoading(true);
    if (courses.length === 0)
      dispatch(FilterMethods[filter].action(page)).then(
        setLoading.bind(null, false)
      );
    else setLoading(false);
  }, [page]);

  return (
    <div className="py-5" style={{ marginTop: "4rem" }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <h5>
                {/* <Translation input="wats_new" /> */}
                <Translation input={`${removeHypenAddSpace(filter)}`} />
              </h5>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <div className="row">
                {courses?.slice(0, 8).map((course: Course) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-6"
                    key={course.id}
                  >
                    <CourseCard course={course} />
                  </div>
                ))}
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={page}
                    onClick={(e) => setPage(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseListingPage;
