import { Col, Collapse, Input, Row, Button, Modal, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MenuOutlined } from "@ant-design/icons";
import { Lesson, LessonsAttachment } from "src/interfaces/Lesson";
import { v4 as uuidv4 } from "uuid";
// import Sections from "./Sections";
import { groupAttachmentsFunction } from "src/utils/utils";
import { CourseVideo } from "src/interfaces/Course";
import Video from "./Video";

// import Sections from "./Sections";

interface IProps {
  videos: CourseVideo[];
  moduleId: number | string;
  onChange: (e) => void;
}

function VideoLessons({ videos, moduleId, onChange }: IProps) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { pathname } = useLocation();
  const isTranslationPath =
    pathname.split("/")[2] === "translation" ? true : false;

  const onLessonDragEnd = (e: any) => {
    let temp = [...videos];
    const [reorderedItem] = temp.splice(e?.source?.index, 1);
    temp.splice(e?.destination?.index, 0, reorderedItem);
    // onChange(temp, moduleId);
  };

  const onVideoTitleChange = (lessonId: string | number, title: string) => {
    // onChange(
    //   videos.map((lesson) =>
    //     lesson.id === lessonId ? { ...lesson, title } : lesson
    //   )
    // );
  };

  const areAllLessonPopulated = (videos: CourseVideo[]): boolean => {
    const lastLessonIndex = videos.length - 1;
    if (videos[lastLessonIndex]?.title?.length === 0) {
      message.warning("Please Populate the lessons");
      return false;
    } else return true;
  };

  const onVideoAdd = () => {
    // if (!areAllLessonPopulated(videos)) return;
    console.log(videos);
    // onChange(
    //   [
    //     ...videos,
    //     {
    //       moduleId,
    //       id: uuidv4(),
    //       title: "",
    //       lessonsAttachments: [[{ type: null, sectionId: uuidv4() }]],
    //     },
    //   ] as Lesson[],
    //   videos
    // );
  };

  const onLessonDelete = (lessonId: string | number) => {
    // onChange(
    //   lessons.filter((lesson) => lesson.id !== lessonId),
    //   moduleId
    // );
  };

  const sectionsOnChangeHandler = (
    lessonsAttachments: LessonsAttachment[],
    lessID: number | string
  ) => {
    // onChange(
    //   lessons.map((lesson: Lesson) =>
    //     lesson.id === lessID
    //       ? { ...lesson, lessonsAttachments: lessonsAttachments }
    //       : lesson
    //   ),
    //   moduleId
    // );
  };

  const header = (provided: any, video: CourseVideo) => (
    <Row>
      <Col sm={18}>
        <Input
          value={video.title}
          onChange={(e) => onVideoTitleChange(video.id, e.target.value)}
        />
      </Col>
      {!isTranslationPath && (
        <Col sm={6}>
          <Row justify="end" align="middle">
            <Space>
              <Button
                danger
                onClick={() =>
                  Modal.confirm({
                    content: `${t("confirm.delete_lesson")}`,
                    onOk: () => onLessonDelete(video.id),
                  })
                }
              >
                <Translation input="remove_lesson" />
              </Button>
              <span {...provided.dragHandleProps}>
                <MenuOutlined />
              </span>
            </Space>
          </Row>
        </Col>
      )}
    </Row>
  );

  return (
    <>
      <DragDropContext onDragEnd={onLessonDragEnd}>
        <Droppable droppableId="lessons">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {videos?.map((video: CourseVideo, i: number) => (
                <Draggable key={video.id} draggableId={`${video.id}`} index={i}>
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <Collapse accordion>
                        <Panel
                          header={header(provided, video)}
                          key={module.id}
                          forceRender={true}
                        >
                          <Video video={video} />
                        </Panel>
                      </Collapse>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Button type="primary" onClick={onVideoAdd}>
          Add Lesson
        </Button>
      </Row>
    </>
  );
}

export default VideoLessons;
