import { unwrapResult } from "@reduxjs/toolkit";
import { Switch, message } from "antd";
import { useState } from "react";
import { activateDeativateUser } from "src/actions/userActions";
import { User } from "src/interfaces/User";
import { useAppDispatch } from "src/store/store";
interface IProps {
  user: User;
}

export const StatusToggler = ({ user }: IProps) => {
  const [status, setStatus] = useState(user.active);
  const dispatch = useAppDispatch();

  const onStatusChange = (e: any) => {
    setStatus((s) => !s);

    dispatch(
      activateDeativateUser({
        userId: user.id,
        active: e,
      })
    )
      .then(unwrapResult)
      .then((res: User) => {
        message.success(
          `${user.email} ${
            user.active ? "deactivated" : "activated"
          } sucessfully`
        );
      })
      .catch(() => {
        message.error(`error`);
        setStatus(user.active);
      });
  };

  return <Switch checked={status} onChange={onStatusChange} />;
};

// import { Switch } from "antd";
// import { useEffect, useState } from "react";

// interface IProps {
//   currentStatus: boolean;
//   onSubmit: (active: boolean) => void;
// }

// export const StatusToggler = ({ currentStatus, onSubmit }: IProps) => {
//   const [status, setStatus] = useState(false);

//   useEffect(() => setStatus(currentStatus), [currentStatus]);
//   return <Switch checked={status} onChange={() => onSubmit(!status)} />;
// };
