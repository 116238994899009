import React, { Suspense } from "react";
import AuthorizedRoles from "src/components/Common/authorization/AuthorizedRoles";
import { Role } from "src/interfaces/User";
import "antd/dist/antd.css";
import { Layout } from "antd";
import "src/components/Components.css";
import NavLogo from "src/components/AdminAuthorMentorComponents/Common/Layout/NavLogo";
import HeaderContentFooter from "src/components/AdminAuthorMentorComponents/Common/Layout/HeaderContentFooter";
import BackToCourses from "./BackToCourses";
import LanguagesMenu from "./LanguagesMenu";
import PageLoading from "src/components/Common/utilities/PageLoading";

// const AntdStyle = React.lazy(() => import("src/components/Common/AntdStyle"));
const AdminAuthorMentorStyles = React.lazy(
  () => import("src/Assets/styles/AdminAuthorMentorStyles")
);

interface Props {
  children: JSX.Element;
}

function TranslationDashBoardLayout({ children }: Props) {
  const { Sider } = Layout;

  return (
    <Suspense fallback={<PageLoading />}>
      <AuthorizedRoles roles={[Role.ADMIN, Role.AUTHOR]}>
        <Layout style={{ minHeight: "100vh" }}>
          {/* <AntdStyle /> */}
          <AdminAuthorMentorStyles />
          <Sider width={250}>
            <NavLogo />
            <LanguagesMenu />
            <BackToCourses />
          </Sider>
          <HeaderContentFooter component={children} />
        </Layout>
      </AuthorizedRoles>
    </Suspense>
  );
}

export default TranslationDashBoardLayout;
