import { useEffect } from "react";

interface Props {
  current: number;
  total: number;
}

function SlideIndicator({ current, total }: Props) {
  useEffect(() => {}, [current]);

  return (
    <div
      className="progress"
      style={{
        height: "3px",
      }}
    >
      <div
        className="progress-bar"
        style={{ width: `${((current + 1) / total) * 100}%` }}
        role="progressbar"
      ></div>
    </div>
  );
}

export default SlideIndicator;

// =================================================================
// import { useEffect } from "react";

// interface Props {
//   current: number;
//   assignedMentors: any;
// }

// function SlideIndicator({ current, assignedMentors }: Props) {
//   useEffect(() => {}, [current]);

//   return (
//     <div className="d-flex w-100" style={{ height: "3px" }}>

//       {assignedMentors?.map((_: any, index: number) => {
//         return (
//           <div
//             style={{
//               height: "3px",

//               color: `${current === index ? " #0275d8" : "   #cccccc"}  `,
//             }}
//             className={`mx-2 w-100  ${
//               current === index ? "bg-primary" : "bg-light"
//             }`}
//           >
//             {index}
//           </div>
//         );
//       })}
//     </div>
//   );
// }

// export default SlideIndicator;
