import { Tag as TagA, Typography, Row } from "antd";
import { Tag } from "src/interfaces/Tag";

interface Props {
  tags: Tag[];
}
function UserTags({ tags }: Props) {
  const { Text } = Typography;

  return (
    <Row>
      <p>
        <span className="fw_bold">Tags : </span>
        {tags.map((tag: Tag) => (
          <TagA color="blue">{tag.tags.value}</TagA>
        ))}
      </p>
    </Row>
  );
}

export default UserTags;
