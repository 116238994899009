import { LessonFormat } from "src/enums/LessonFormats";
import { LessonsAttachment } from "src/interfaces/Lesson";
import ImageSection from "./ImageSection";
import VideoSection from "./VideoSection";
import SliderSection from "./SliderSection";
import TextSection from "./TextSection";
import SelectSection from "./SelectSection";

interface Props {
  lessonsAttachment: LessonsAttachment[];
  moduleId: number | string;
  lessonId: number | string;
  sectionId: number | string;
  onChange: (e) => void;
  onSectionSelect: (e) => void;
}

function SectionSelector({
  lessonsAttachment,
  moduleId,
  lessonId,
  sectionId,
  onChange,
  onSectionSelect,
}: Props) {
  const returnSection = (lessonsAttachment: LessonsAttachment[]) => {
    const lessonsAttachmentType = lessonsAttachment[0].type;
    console.log(lessonsAttachmentType);
    if (lessonsAttachmentType === LessonFormat.IMAGE)
      return (
        <ImageSection
          lessonsAttachment={lessonsAttachment}
          moduleId={moduleId}
          lessonId={lessonId}
          sectionId={sectionId}
          onChange={(e) => onChange([{ ...lessonsAttachment[0], ...e }])}
        />
      );
    else if (lessonsAttachmentType === LessonFormat.VIDEO)
      return (
        <VideoSection
          lessonsAttachment={lessonsAttachment}
          moduleId={moduleId}
          lessonId={lessonId}
          sectionId={sectionId}
          onChange={(e) => onChange([{ ...lessonsAttachment[0], ...e }])}
        />
      );
    else if (lessonsAttachmentType === LessonFormat.IMAGESLIDER)
      return (
        <SliderSection
          sectionData={lessonsAttachment}
          moduleId={moduleId}
          lessonId={lessonId}
          sectionId={sectionId}
          onChange={(e) => onChange(e)}
        />
      );
    else if (lessonsAttachmentType === LessonFormat.TEXT)
      return (
        <TextSection
          lessonsAttachment={lessonsAttachment}
          moduleId={moduleId}
          lessonId={lessonId}
          sectionId={sectionId}
          onChange={(e) =>
            onChange([{ ...lessonsAttachment[0], lessonText: e }])
          }
        />
      );
    else if (
      lessonsAttachmentType === null ||
      lessonsAttachmentType === undefined
    )
      return (
        <SelectSection
          onChange={(type) => onSectionSelect({ type })}
          lessonsAttachments={lessonsAttachment}
          moduleId={moduleId}
          lessonId={lessonId}
          sectionId={sectionId}
        />
      );
  };

  return <div>{returnSection(lessonsAttachment)}</div>;
}

export default SectionSelector;
