import { Button } from "antd";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";

function EditCourseButton({ courseId }: { courseId: number }) {
  const role = useSelector(authSelectors.role);
  const history = useHistory();

  return (
    <Button
      type="primary"
      key="edit-course"
      onClick={() => {
        role === Role.ADMIN
          ? history.push(`/admin/${courseId}/edit`)
          : history.push(`/author/${courseId}/edit`);
      }}
    >
      <Translation input="edit" />
    </Button>
  );
}

export default EditCourseButton;
