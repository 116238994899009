import { Avatar, Row, Menu, Space, Dropdown } from "antd";
import { useSelector } from "react-redux";
import { authSelectors } from "src/store/authSlice";
import ProfilePlaceHolder from "src/Assets/media/profile-placeholder.png";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { logout } from "src/store/authSlice";
import { useAppDispatch } from "src/store/store";
import { useHistory } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { Role } from "src/interfaces/User";
import { useState } from "react";

function NavDropDown() {
  const user = useSelector(authSelectors.details);
  const history = useHistory();
  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const [clickedItem, setClickedItem] = useState("");

  const dropdownSelectHandler = (e) => {
    setClickedItem(e.key);
    if (e.key === "logout") {
      dispatch(logout());
    } else if (e.key === "account_info") {
      if (role === Role.ADMIN) {
        history.push("/admin/profile");
      } else if (role === Role.AUTHOR) {
        history.push("/author/profile");
      } else if (role === Role.MENTOR) {
        history.push("/mentor/profile");
      }
    }
    setInterval(() => setClickedItem(""), 2000);
  };

  const styles = {
    menuItem: { fontWeight: 500 },
  };

  const menu = (
    <Menu onClick={dropdownSelectHandler}>
      <Menu.Item
        icon={<UserOutlined />}
        key="account_info"
        style={{
          backgroundColor: clickedItem === "account_info" ? "#e6f7ff" : "",
        }}
      >
        <span style={styles.menuItem}>
          <Translation input="account" />
        </span>
      </Menu.Item>
      <Menu.Item
        icon={<LogoutOutlined />}
        key="logout"
        style={{ backgroundColor: clickedItem === "log_out" ? "#e6f7ff" : "" }}
        // className="ant-menu-item-selected"
      >
        <span style={styles.menuItem}>
          <Translation input="log_out" />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="cursor_pointer noselect">
      <Dropdown overlay={menu}>
        <Row align="middle">
          <Space>
            <span style={{ color: "white" }} className="fw_normal fs_16">
              {user.firstName} {user.lastName}
            </span>
            <Avatar
              src={user?.resources?.metaData?.thumbnail || ProfilePlaceHolder}
            />
          </Space>
        </Row>
      </Dropdown>
    </div>
  );
}

export default NavDropDown;
