import { Menu, Row, Dropdown } from "antd";
import { languages } from "src/locale/languages";
import { LanguageCode } from "src/enums/Language";
import { useLocation } from "react-router-dom";
import { setLanguage } from "src/actions/authActions";
import { useAppDispatch } from "src/store/store";
import { FaFontAwesomeFlag } from "react-icons/fa";
import Translation from "src/components/Common/utilities/Translation";
function NavLanguage() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const onOptionClick = (e) => {
    dispatch(setLanguage(e.key as LanguageCode)).then(
      () => (window.location.href = `${pathname}`)
    );
    // i18next.changeLanguage(e.key);
  };

  const menu = (
    <Menu>
      {languages.map(({ code, name, country_code }) => (
        <Menu.Item key={code} onClick={onOptionClick}>
          <span className={`flag-icon flag-icon-${country_code}`}></span>{" "}
          <span style={{ marginLeft: "10px" }}>{name}</span>
        </Menu.Item>
      ))}
      {/* <SubMenu
        key="1"
        title={
          <div style={{ fontSize: "16px" }}>
            <Translation input="language" />
          </div>
        }
      >
        {languages.map(({ code, name, country_code }) => (
          <Menu.Item key={code} onClick={onOptionClick}>
            <span className={`flag-icon flag-icon-${country_code}`}></span>{" "}
            <span>{name}</span>
          </Menu.Item>
        ))}
      </SubMenu> */}
    </Menu>
  );

  return (
    // <div className="cursor_pointer">
    //   <Popover placement="bottomRight" content={menu} trigger="click">
    //     <Row align="middle">
    //       <BsFillGearFill style={{ color: "#C0C0C0", fontSize: "16px" }} />
    //       <div style={{ width: "5px" }}></div>
    //       <span style={{ color: "#C0C0C0" }} className="fw_normal fs_16">
    //         <Translation input="settings" />
    //       </span>
    //     </Row>
    //   </Popover>
    // </div>
    <div className="cursor_pointer noselect">
      <Dropdown overlay={menu}>
        <Row align="middle">
          <FaFontAwesomeFlag style={{ color: "#C0C0C0", fontSize: "16px" }} />
          <div style={{ width: "5px" }}></div>
          <span style={{ color: "#C0C0C0" }} className="fw_normal fs_16">
            <Translation input="language" />
          </span>
        </Row>
      </Dropdown>
    </div>
  );
}

export default NavLanguage;
