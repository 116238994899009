import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Image, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { searchCertificates } from "src/actions/certificateActions";
import Translation from "src/components/Common/utilities/Translation";
import { Certificate } from "src/interfaces/Certificate";
import { useAppDispatch } from "src/store/store";

// interface Props {
//   onFormSubmit: (e) => void;
//   initialValue?: any;
// }
//{ onFormSubmit }: Props

function SearchCertificates() {
  const [certificates, setCertificates] = useState<Certificate[]>();
  const [imageUrl, setImageUrl] = useState<any>();
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const [fetching, setFetching] = useState(false);

  // useEffect(()=>{
  //   onFormSubmit()
  // })

  const onSelect = (e) =>
    setImageUrl(
      certificates?.filter(
        (certificate: Certificate) => certificate.id === e.value
      )[0].resources.attachmentUrl
    );

  const fetchCertificates = (value) => {
    setFetching(true);
    setCertificates([]);
    if (value.length > 2) {
      dispatch(searchCertificates(value))
        .then(unwrapResult)
        .then((res) => {
          setFetching(false);
          setCertificates(res);
        });
    } else setFetching(false);
  };

  return (
    <>
      <Image src={imageUrl} height={imageUrl ? 80 : 0} />
      <Form.Item name="certificate">
        <Select
          showSearch
          labelInValue
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          placeholder={<Translation input="Select Certificate" />}
          onSearch={fetchCertificates}
          autoClearSearchValue={true}
          style={{ width: "100%" }}
          onSelect={onSelect}
        >
          {certificates?.map((certificate: Certificate) => (
            <Option value={certificate.id}>{certificate.title}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}

export default SearchCertificates;
