import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "src/api/api";

//get all certificates
export const getAllCertificates = createAsyncThunk(
  "getAllCertificates",
  async () =>
    await api.get("admin/getCertificates").then(extractStandardResponseData)
);

export const clearCertificates = createAsyncThunk("clearCertificates", () => {
  return [];
});

//get all certificate templates
export const getCertificatesTemplates = createAsyncThunk(
  "getCertificateTemplates",
  async () =>
    await api.get("admin/getTemplates").then(extractStandardResponseData)
);

export const addCertificate = createAsyncThunk(
  "addCertificate",
  async (certificate: any) =>
    await api
      .post("admin/addCertificate", certificate)
      .then(extractStandardResponseData)
);

//upload certificate logo
export const uploadCertificateLogo = async (formData: FormData) =>
  await api.post("/courses/logo", formData).then(extractStandardResponseData);

//search certificates
export const searchCertificates = createAsyncThunk(
  "addCertificate",
  async (title: string) =>
    await api
      .post("admin/searchCertificates", { title })
      .then(extractStandardResponseData)
);
