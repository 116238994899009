// import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import CourseCard from "../CourseCard/CourseCard";
import "./CourseCardCarousel.css";
import { Course } from "src/interfaces/Course";
import SkeletonLoaderLearner from "../../UtilityComponents/SkeletonLoaderLearner";
import { useTranslation } from "react-i18next";
import { Filters, FilterMethods } from "src/enums/Filters";
import { RootState, useAppDispatch } from "src/store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface CourseCardCarouselPropsI {
  title: string;
  filter: Filters;
  showProgressBar?: boolean;
}

function CourseCardCarousel({
  title,
  filter,
  showProgressBar,
}: CourseCardCarouselPropsI) {
  const courses = useSelector((state: RootState) =>
    FilterMethods[filter].selector.get(state, 0)
  ) as Course[];

  const availablePages = useSelector(
    FilterMethods[filter].selector.pages.selectIds
  ) as any;

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1140 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1140, min: 720 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    // if (courses.length === 0) {
    if (!availablePages.includes(0)) {
      dispatch(FilterMethods[filter].action(0)).then(() => {
        setTimeout(() => setLoading(false), 1000);
      });
    } else setLoading(false);
  }, []);

  const ButtonGroup = ({ next, previous }: any) => {
    return (
      <div className="d-flex order-first mb-2 justify-content-between align-items-center">
        <h5 className="fw-bold m-0 noselect" style={{ height: "fitContent" }}>
          {title}
        </h5>

        <div className="d-flex flex-row align-items-center noselect">
          <div
            className="fs-6 fw-bold cursor_pointer on_text_hover m-0"
            onClick={() => history.push(`/course-listing/${filter}`)}
          >
            {t("view_all")}
          </div>
          <GrFormPrevious
            onClick={() => previous()}
            className="fs-3 cursor_pointer"
          />
          <GrFormNext onClick={() => next()} className="fs-3 cursor_pointer" />
        </div>
      </div>
    );
  };

  return (
    <div className="pt-4" dir="ltr">
      <SkeletonLoaderLearner
        loading={loading}
        rows={1}
        height={300}
        type="normal"
      >
        <div className="w-100">
          <div className="d-flex row new_padding" style={{ paddingRight: "" }}>
            {!loading && courses.length !== 0 && (
              <Carousel
                responsive={responsive}
                infinite
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside
                className="p-0"
                itemClass="CarouselCustomEachCardClass"
              >
                {courses?.map((course: Course) => (
                  <CourseCard
                    course={course}
                    key={course?.id}
                    showProgressBar={showProgressBar}
                  />
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </SkeletonLoaderLearner>
    </div>
  );
}

export default CourseCardCarousel;
