import { useHistory } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { PathWay } from "src/interfaces/PathWay";
import { getDirection } from "src/utils/utils";

interface Props {
  pathway: PathWay;
}

function PathwayCarouselCard({ pathway }: Props) {
  const history = useHistory();

  return (
    <div
      className="card w-100 border-0 overflow-hidden noselect"
      dir={getDirection() === "rtl" ? "rtl" : "ltr"}
    >
      <div className="row">
        {/* ------ left ------- */}
        <div className="col-md-6 col-sm-12">
          <img
            style={{ height: "300px", objectFit: "cover" }}
            src={`${pathway.resources.attachmentUrl}`}
            alt="pathway img"
            className="img-fluid"
          />
        </div>
        {/* ------ right ------- */}
        <div className="col-md-6 col-sm-12">
          <div
            className="card-body"
            style={{ textAlign: "initial", padding: "20px" }}
          >
            <div className="pr-5 pt-3" style={{ marginRight: "48px" }}>
              <span className="badge bg-secondary py-1 px-2 fw-bolder">
                <Translation input="assigned" />
              </span>
              &nbsp;
              <span className="badge bg-secondary py-1 px-2 fw-bolder">
                <Translation input="pathway" />
              </span>
              <h5 className="card-title mt-2">{pathway.title}</h5>
              <p
                className="card-text overflow-hidden"
                style={{ height: "72px" }}
              >
                {pathway.excerpt}
              </p>
              <p className="card-text">
                <small className="text-muted">
                  {pathway.pathwaysCourses.filter(course => !!course.courses).length}{" "}
                  <Translation input="courses" /> in this pathway
                  {/* <Translation input="courses_completed" /> */}
                </small>
              </p>
              <a
                className="btn btn-sm bg-dark text-white"
                onClick={() =>
                  history.push(`/pathway/description/${pathway?.slug}`)
                }
              >
                <Translation input="start_now" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PathwayCarouselCard;
