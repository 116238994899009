import React from "react";
import { useHistory } from "react-router-dom";
import { Course } from "src/interfaces/Course";
import { PathWay } from "src/interfaces/PathWay";

interface Props {
  courseOrPathway: Course & PathWay;
}
function NavSearchItem({ courseOrPathway }: Props) {
  const history = useHistory();

  return (
    <>
      <div className="d-flex">
        <img
          src={`${courseOrPathway.resources.attachmentUrl}`}
          alt=""
          className="rounded"
          style={{
            width: "30px",
            height: "30px",
            objectFit: "cover",
          }}
        />
        <div style={{ width: "14px" }}></div>
        <div className="text-dark">{courseOrPathway.title}</div>
      </div>
      <div className="text-muted">
        {courseOrPathway.pathwaysCourses ? "Pathway" : "Course"}
      </div>
    </>
  );
}

export default NavSearchItem;
