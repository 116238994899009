import { Certificate, UserCertificate } from "src/interfaces/Certificate";
import { AiOutlineLock, AiOutlineDownload } from "react-icons/ai";
import { Course } from "src/interfaces/Course";

interface Props {
  course: Course;
}

function CertificateComp({ course }: Props) {
  // const certificateDownloadLink = certificate?.userCertificates?.filter(
  //   (certificate: UserCertificate) => certificate.courseId === courseId
  // )[0]?.resources?.attachmentUrl;

  // const certificateDownloadLink =

  //   course?.learnerCertificate[0]?.resources.attachmentUrl;

  const certificateDownloadLink = course.learnerCertificate
    ? course?.learnerCertificate[0]?.resources.attachmentUrl
    : undefined;
  return (
    <a href={certificateDownloadLink} key={course.certificate.id}>
      <div className="position-relative rounded-3 overflow-hidden border_color">
        <img
          className="img-fluid"
          src={course.certificate?.resources?.attachmentUrl}
          alt="certificate-image"
        />
        <div
          className="position-absolute top-0 w-100 h-100 d-flex justify-content-around align-items-center"
          style={{
            backgroundColor: "rgba(0, 20, 54, 0.75)",
          }}
        >
          {certificateDownloadLink ? (
            <div className="d-flex flex-column align-items-center">
              <AiOutlineDownload className="text-light fs-2" />
              <p className="m-0 text-light">Click to Download</p>
            </div>
          ) : (
            <AiOutlineLock className="text-light fs-2" />
          )}
        </div>
      </div>
    </a>
  );
}

export default CertificateComp;
