import { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  coursesSelector,
  editVideoCourse,
} from "../../store/coursesSlices/coursesSlice";
import { CourseFormat } from "../../enums/CourseFormat";
import {
  lessonDrag,
  sectionDrag,
} from "./EditDefaultCourse/EditCourseUtils/OnDrag";
import EditDefaultCourse from "./EditDefaultCourse/EditDefaultCourse";
import EditScromXapiCourse from "./EditScromXapiCourse/EditScromXapiCourse";
import EditVideoCourse from "./EditVideoCourse/EditVideoCourse";
import EditPDFCourse from "./EditCoursePageFormFields/EditPDFCourse";
import { Course } from "src/interfaces/Course";
import EditCourse from "src/components/AdminAuthorMentorComponents/Common/Course/EditCourse/EditCourse";
import { useAppDispatch } from "src/store/store";
import { getSingleCourse } from "src/actions/courseActions/courseActions";
import { courseSelector } from "src/store/coursesSlices/editCourseSlice";
import { getDraft } from "src/actions/DraftActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import CourseForm from "src/components/AdminAuthorMentorComponents/Common/Course/CourseForm/CourseForm";
import { Module } from "src/interfaces/Module";
import { Lesson } from "src/interfaces/Lesson";
import { message } from "antd";
import EditWebwatchAndInfographiaCourse from "./EditWebwatchCourse/EditWebwatchAndInfographia";

interface Params {
  courseId: string;
}

function EditCoursePage() {
  const { courseId } = useParams<Params>();
  const [courseFormat, setCourseFormat] = useState<CourseFormat>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const role = useSelector(authSelectors.role);
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );

  const { pathname } = useLocation();
  // const course = useSelector(courseSelector.course);
  const dispatch = useAppDispatch();

  const editReturnFn = (courseFormat: CourseFormat | undefined) => {
    if (courseFormat === CourseFormat.DEFAULT) return <EditDefaultCourse />;
    else if (courseFormat === CourseFormat.VIDEO) return <EditVideoCourse />;
    else if (courseFormat === CourseFormat.PDF) return <EditPDFCourse />;
    else if (
      courseFormat === CourseFormat.WEBWATCH ||
      courseFormat === CourseFormat.INFOGRAPHIA
    )
      return <EditWebwatchAndInfographiaCourse courseType={courseFormat} />;
    else if (
      courseFormat === CourseFormat.SCROM ||
      courseFormat === CourseFormat.XAPI
    )
      return <EditScromXapiCourse />;
  };

  const getCourse = (e: any) => {
    const editedCourse = {
      title: e.title,
      excerpt: e.excerpt,
      videoId: e.videoId,
      id: parseInt(courseId),
      courseAttachment: null,
      description: e.description,
      objectives: e.objectives,
      tags: e.tags.map((item) => item.value),
      mentors: e.mentors.map((item) => item.value),
      authorId: e.authorId.value,
      quiz: e.quiz,
      imageId: e.imageId,
      modules: e.coursesModules.map((module: Module, moduleIndex: number) => ({
        ...module,
        positionId: moduleIndex,
        lessons: module.lessons?.map((lesson: Lesson, lessonIndex: number) => ({
          ...lesson,
          positionId: lessonIndex,
        })),
      })),
    };
    return editedCourse;
  };

  const onFormSubmit = (e) => {
    setSubmitLoading(true);
    const course = getCourse(e);
    dispatch(editVideoCourse(course))
      .then(unwrapResult)
      .then(() => {
        setSubmitLoading(false);
        role === Role.ADMIN
          ? history.push("/admin/courses")
          : history.push("/author");
      })
      .catch(() => {});
  };

  const onSave = (e) => {
    setSubmitLoading(true);
    const course = getCourse(e);
    dispatch(editVideoCourse(course))
      .then(unwrapResult)
      .then(() => {
        message.success("Saved");
        setSubmitLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!course) {
      dispatch(getSingleCourse(parseInt(courseId)))
        .then(unwrapResult)
        .then((res) => {
          setCourseFormat(res.type);
          setLoading(false);
        });
    } else {
      setCourseFormat(course.type);
      setLoading(false);
    }

    // if (pathname.split("/")[2] === "draft") {
    //   dispatch(getDraft())
    //     .then(unwrapResult)
    //     .then((res) => {
    //       setCourseFormat(res.type);
    //       setLoading(false);
    //     });
    // } else {
    //   dispatch(getSingleCourse(parseInt(courseId)))
    //     .then(unwrapResult)
    //     .then((res) => {

    //       setCourseFormat(res.type);
    //       setLoading(false);
    //     });
    // }
  }, [courseFormat]);

  return (
    <>
      {console.log(courseFormat)}
      {courseFormat === CourseFormat.DEFAULT ? (
        <CourseForm
          loading={loading}
          submitLoading={submitLoading}
          inputCourse={course}
          onSubmit={onFormSubmit}
          onSave={onSave}
        />
      ) : (
        <>{editReturnFn(courseFormat)}</>
      )}
    </>
  );
}

export default EditCoursePage;

// import { useEffect, useState } from "react";
// import { useLocation, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// import {
//   coursesSelector,
//   editVideoCourse,
// } from "../../store/coursesSlices/coursesSlice";
// import { CourseFormat } from "../../enums/CourseFormat";
// import {
//   lessonDrag,
//   sectionDrag,
// } from "./EditDefaultCourse/EditCourseUtils/OnDrag";
// import EditDefaultCourse from "./EditDefaultCourse/EditDefaultCourse";
// import EditScromXapiCourse from "./EditScromXapiCourse/EditScromXapiCourse";
// import EditVideoCourse from "./EditVideoCourse/EditVideoCourse";
// import EditPDFCourse from "./EditCoursePageFormFields/EditPDFCourse";
// import { Course } from "src/interfaces/Course";
// import EditCourse from "src/components/AdminAuthorMentorComponents/Common/Course/EditCourse/EditCourse";
// import { useAppDispatch } from "src/store/store";
// import { getSingleCourse } from "src/actions/courseActions/courseActions";
// import { courseSelector } from "src/store/coursesSlices/editCourseSlice";
// import { getDraft } from "src/actions/DraftActions";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { Role } from "src/interfaces/User";
// import { authSelectors } from "src/store/authSlice";

// interface Params {
//   courseId: string;
// }

// function EditCoursePage() {
//   const { courseId } = useParams<Params>();
//   const [courseFormat, setCourseFormat] = useState<CourseFormat>();
//   const [loading, setLoading] = useState(true);
//   const role = useSelector(authSelectors.role);

//   // const course = useSelector(
//   //   coursesSelector.selectCourseEntity(parseInt(courseId))
//   // );
//   const { pathname } = useLocation();
//   // const course = useSelector(courseSelector.course);
//   const dispatch = useAppDispatch();

// const editReturnFn = (courseFormat: CourseFormat | undefined) => {
//   if (courseFormat === CourseFormat.DEFAULT) return <EditDefaultCourse />;
//   else if (courseFormat === CourseFormat.VIDEO) return <EditVideoCourse />;
//   else if (courseFormat === CourseFormat.PDF) return <EditPDFCourse />;
//   else if (
//     courseFormat === CourseFormat.SCROM ||
//     courseFormat === CourseFormat.XAPI
//   )
//     return <EditScromXapiCourse />;
// };

//   useEffect(() => {
//     if (pathname.split("/")[2] === "draft") {
//       dispatch(getDraft())
//         .then(unwrapResult)
//         .then((res) => {
//           setCourseFormat(res.type);
//           setLoading(false);
//         });
//     } else {
//       dispatch(getSingleCourse(parseInt(courseId)))
//         .then(unwrapResult)
//         .then((res) => {
//           setCourseFormat(res.type);
//           setLoading(false);
//         });
//     }

//     // if (pathname.split("/")[3] === "edit") {
//     //   dispatch(getSingleCourse(parseInt(courseId)))
//     //     .then(unwrapResult)
//     //     .then((res) => {
//     //       setCourseFormat(res.type);
//     //       setLoading(false);
//     //     });
//     // } else if (pathname.split("/")[2] === "draft") {
//     //   dispatch(getDraft())
//     //     .then(unwrapResult)
//     //     .then((res) => {
//     //       setCourseFormat(res.type);
//     //       setLoading(false);
//     //     });
//     // }
//   }, [courseFormat]);

//   // return <>{loading ? <>loading...</> : editReturnFn(courseFormat)}</>;
//   return <>{editReturnFn(courseFormat)}</>;
// }

// export default EditCoursePage;
