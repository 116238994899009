import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { logout } from "src/store/authSlice";
import { Page } from "src/interfaces/Page";
import { RootState } from "../../store";
import {
  coursesSelector,
  createCourse,
  createVideoCourse,
  deleteCourse,
  editCourse,
  editVideoCourse,
} from "../coursesSlice";
import { getAllLearnerCourses } from "src/actions/courseActions/learnerCourseActions";
import {
  getAllCourses,
  getAllCoursesForMentor,
} from "src/actions/courseActions/courseActions";

const coursesPaginationAdapter = createEntityAdapter<Page>();

const initialState = {
  pages: coursesPaginationAdapter.getInitialState(),
  total: 0,
  totalItems: 0,
};

export const coursesPaginationSlice = createSlice({
  name: "courses/pagination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLearnerCourses.fulfilled, (state, { payload }) => {
      return {
        pages: coursesPaginationAdapter.upsertOne(
          { ...state.pages },
          {
            id: parseInt(payload.currentPage),
            items: payload?.data?.map((item: any) => item.courses.id),
          }
        ),
        total: payload.pages,
        totalItems: payload.items,
      };
    });
    builder.addCase(getAllCourses.fulfilled, (state, { payload }) => {
      return {
        pages: coursesPaginationAdapter.upsertOne(
          { ...state.pages },
          {
            id: parseInt(payload.currentPage),
            items: payload?.data?.map((item: any) => item.id),
          }
        ),
        total: payload.pages,
        totalItems: payload.items,
      };
    });
    builder.addCase(getAllCoursesForMentor.fulfilled, (state, { payload }) => {
      return {
        pages: coursesPaginationAdapter.upsertOne(
          { ...state.pages },
          {
            id: parseInt(payload.currentPage),
            items: payload?.data?.map((item: any) => item.id),
          }
        ),
        total: payload.pages,
        totalItems: payload.items,
      };
    });
    builder.addCase(createVideoCourse.fulfilled, () => initialState);
    builder.addCase(createCourse.fulfilled, () => initialState);
    builder.addCase(editCourse.fulfilled, () => initialState);
    builder.addCase(deleteCourse.fulfilled, () => initialState);
    builder.addCase(editVideoCourse.fulfilled, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const adapterSelectors = coursesPaginationAdapter.getSelectors(
  (s: RootState) => s.coursesPagination.pages
);

export const coursesPaginationSelectors = {
  pages: adapterSelectors,
  get: (state: RootState, page: number) =>
    adapterSelectors
      .selectById(state, page)
      ?.items.map((id: number) =>
        coursesSelector.coursesSelector.selectById(state, id)
      ) || [],
  totalPages: (state: RootState) => state.coursesPagination.total,
  totalCourses: (state: RootState) => state.coursesPagination.totalItems,
};
