import "antd/dist/antd.css";
import { Row, Col, Button, message } from "antd";
import "src/components/Components.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setTabTitle } from "src/utils/utils";
import { useSelector } from "react-redux";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";
import { useAppDispatch } from "src/store/store";
import NoTranslationAdded from "src/components/AdminAuthorMentorComponents/Common/Translation/NoTranslationAdded";
import ViewCoursePage from "src/pages/ViewCoursePage/ViewCoursePage";
import EditCoursePage from "src/pages/EditCoursePage/EditCoursePage";
import {
  addLanguageToCourse,
  getSingleCourse,
} from "src/actions/courseActions/courseActions";
import CourseForm from "src/components/AdminAuthorMentorComponents/Common/Course/CourseForm/CourseForm";
import { Role } from "src/interfaces/User";
import { authSelectors } from "src/store/authSlice";
import { CourseFormat } from "src/enums/CourseFormat";

interface Params {
  courseId: string;
  translationString: string;
}

function TranslationPage() {
  const { courseId, translationString } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const course = useSelector(
    coursesSelector.selectCourseEntity(parseInt(courseId))
  );
  const role = useSelector(authSelectors.role);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const onFormSubmit = (e) => {
    const translatedCourse = e;
    delete translatedCourse.mentors;
    delete translatedCourse.authorId;
    delete translatedCourse.tags;
    delete translatedCourse.courseId;

    console.log({ ...translatedCourse, modules: e.coursesModules });
    dispatch(
      addLanguageToCourse({
        ...translatedCourse,
        completed: true,
        language: [location.pathname.split("/")[4].split("-")[1]],
        modules: e.coursesModules,
      })
    ).then(() => {
      role === Role.ADMIN
        ? history.push("/admin/courses")
        : history.push("/author");
      // setSubmitLoading(false);
    });
  };
  const onSave = (e) => {
    const translatedCourse = e;
    delete translatedCourse.mentors;
    delete translatedCourse.authorId;
    delete translatedCourse.tags;
    delete translatedCourse.courseId;

    dispatch(
      addLanguageToCourse({
        ...translatedCourse,
        completed: false,
        language: [location.pathname.split("/")[4].split("-")[1]],
        modules: e.coursesModules,
      })
    ).then(() => {
      message.success("Saved");
      // setSubmitLoading(false);
    });
  };

  useEffect(() => {
    if (course) {
      setLoading(false);
    } else
      dispatch(getSingleCourse(parseInt(courseId))).then(
        setLoading.bind(null, false)
      );
    setTabTitle("Translation");
  }, [pathname]);

  return course?.languages?.length === 0 ? (
    <NoTranslationAdded title={course?.title || ""} />
  ) : (
    <Row>
      <Col span={12} style={{ height: "88vh", overflow: "auto" }}>
        <ViewCoursePage />
      </Col>
      <Col span={12} style={{ height: "88vh", overflow: "auto" }}>
        {loading ? (
          "loading"
        ) : (
          <>
            {course?.type === CourseFormat.DEFAULT ? (
              <>
                <CourseForm
                  loading={loading}
                  submitLoading={false}
                  inputCourse={{
                    ...course?.languages.find(
                      (course) =>
                        course.language[0] === translationString.split("-")[1]
                    ),
                    type: course?.type,
                    id: course.id,
                  }}
                  onSubmit={onFormSubmit}
                  onSave={onSave}
                />
              </>
            ) : (
              <EditCoursePage />
            )}
          </>
        )}
      </Col>
    </Row>
  );
}

export default TranslationPage;
