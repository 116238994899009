import { CSSProperties, useEffect } from "react";
import { NotificationI } from "src/interfaces/NotificationI";
import { timeSince } from "src/utils/utils";
function NavNotification({
  notification,
  onPage,
  style,
}: {
  notification: NotificationI;
  onPage?: boolean;
  style?: CSSProperties;
}) {
  return (
    <a className="dropdown-item border-bottom" href="#" style={style}>
      <div className="notification-wrapper d-flex justify-content-between py-1">
        <div className="notification-stripe">
          <span className="small d-block font_size_14">
            {onPage && (
              <>
                {notification?.message === "You're added to a new session"
                  ? "Session"
                  : "Course"}
              </>
            )}
          </span>
          <span className="fw-bolder d-block lh-1 text-wrap">
            {notification?.message?.split("-")[0] || ""}
          </span>

          <span className="fw-bolder d-block lh-1 mt-1 text-wrap">
            {notification?.message?.split("-")[1] || ""}
          </span>
          <small className="text-muted mt-2">
            by {notification?.user?.firstName} {notification?.user?.lastName}{" "}
            {!onPage && <> {timeSince(notification?.createdAt)}&nbsp;ago </>}
          </small>
        </div>
        {onPage && (
          <div>
            <span className="text-muted small d-block ml-4">
              {timeSince(notification?.createdAt)}&nbsp;ago
            </span>
          </div>
        )}
      </div>
    </a>
  );
}

export default NavNotification;
