import { Row, Typography } from "antd";
import Translation from "src/components/Common/utilities/Translation";

interface Props {
  label: string;
  value: string;
}
function InfoItem({ label, value }: Props) {
  const { Text } = Typography;

  return (
    <Row>
      <p>
        <span className="fw_bold">
          <Translation input={label} /> :{" "}
        </span>
        <Text>{value}</Text>
      </p>
    </Row>
  );
}

export default InfoItem;
