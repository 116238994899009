import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { searchCoursePathway } from "src/api/searchActions";
import { Course } from "src/interfaces/Course";
import { PathWay } from "src/interfaces/PathWay";
import { useAppDispatch } from "src/store/store";
import "../NavigationBar.scss";
import NavSearchItem from "./NavSearchItem";

function NavSearchBar() {
  const dispatch = useAppDispatch();
  const [searchData, setSearchData] = useState<any>();
  const [searchInProgress, setSearchInProgress] = useState(false);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const { t } = useTranslation();

  const searchSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchInProgress(false);
    setSearchValue("");
    setSearchData([]);
    history.push(`/learner/search/${searchValue}`);
  };

  const onChangeHandler = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      setSearchLoading(true);
      setTimeout(() => {
        // setSearchData([]);
        dispatch(searchCoursePathway(e.target.value))
          .then(unwrapResult)
          .then((res) => {
            if (res.length !== 0) {
              setSearchInProgress(true);
              setSearchLoading(false);
            }
            setSearchData(res);
            setSearchLoading(false);
          });
      }, 1000);
    } else if (e.target.value.length === 0) {
      setSearchInProgress(false);
    } else {
      setSearchInProgress(true);
      setSearchData([]);
    }
  };
  return (
    <div className="w-100 d-flex position-relative">
      <form
        className="d-flex my-2 my-lg-0  w-100 px-5 position-relative"
        onSubmit={searchSubmitHandler}
      >
        <input
          className={`shadow-none border-0 form-control mr-sm-2 font_family_nunito fs-6 ${
            searchInProgress ? "" : "rounded-pill"
          }   `}
          style={{ borderRadius: "20px 20px 0px 0px" }}
          type="search"
          placeholder={t("search")}
          aria-label="Search"
          onChange={onChangeHandler}
          value={searchValue}
        />
        {searchInProgress ? (
          <button
            type="button"
            className="btn-close position-absolute"
            aria-label="Close"
            onClick={() => {
              setSearchData([]);
              setSearchInProgress(false);
              setSearchValue("");
            }}
            style={{ left: "85%", top: "10%" }}
          ></button>
        ) : null}
        <div
          className="fixed-top mx-5 search_dropdown overflow-hidden shadow-lg"
          style={{
            backgroundColor: "#fff",
            marginTop: "35px",
            position: "absolute",
          }}
        >
          {searchData?.length === 0 && searchInProgress ? (
            <div className={`py-3 px-3   d-flex  text-dark`}>
              {searchLoading ? (
                <div className="d-flex">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{ width: "1.2rem", height: "1.2rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <div className="text-dark mx-2">Searching ....</div>
                </div>
              ) : (
                <div>Sorry, No Results Found</div>
              )}
            </div>
          ) : (
            <div
              style={{
                maxHeight: "50vh",
                overflowX: "hidden",
                position: "relative",
                background: "white",
                overscrollBehavior: "contain",
              }}
            >
              {searchData?.map((item: Course & PathWay, index: number) => {
                return (
                  <div
                    className={`py-3 px-5 cursor_pointer d-flex justify-content-between on_search_result_hover ${
                      index === searchData.length - 1 ? "" : "border-bottom"
                    }`}
                    onClick={() => {
                      setSearchInProgress(false);
                      setSearchValue("");
                      if (item.pathwaysCourses) {
                        history.push(`/pathway/description/${item?.slug}`);
                      } else history.push(`/course-description/${item?.slug}`);
                      setSearchData([]);
                    }}
                  >
                    <NavSearchItem courseOrPathway={item} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default NavSearchBar;
