import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "./api";

export const searchCourse = createAsyncThunk(
  "searchCourses",
  async (courseTitle: string) =>
    await api
      .post(`/courses/searchCourse`, { title: courseTitle })
      .then(extractStandardResponseData)
);

export const getLearners = createAsyncThunk(
  "mentor/searchLearners",
  async (name: string) =>
    await api
      .post(`/mentor/searchLearners`, { name: name })
      .then(extractStandardResponseData)
);

export const searchCoursePathway = createAsyncThunk(
  "searchCoursesPathway",
  async (title: string) =>
    await api
      .post(`/users/learnerSearch`, { value: title })
      .then(extractStandardResponseData)
);
