import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import MentorCard from "../MentorCard/MentorCard";
import { useAppDispatch } from "../../../store/store";
import { getMentors, authSelectors } from "../../../store/authSlice";
import SkeletonLoaderLearner from "../UtilityComponents/SkeletonLoaderLearner";

import SlideIndicator from "./SlideIndicator";
import "./AssignedMentorsCarousel.css";
import BookSessionModal from "./BookSessionModal";
import Translation from "../../Common/utilities/Translation";
function AssignedMentorsCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const assignedMentors = useSelector(authSelectors.assignedMentors);

  const onChangeHandler = (e) => {
    setCurrentSlide(e);
  };

  useEffect(() => {
    // if (assignedMentors) setLoading(false);
    dispatch(getMentors()).then(setLoading.bind(null, false));
  }, []);

  return (
    <>
      <SkeletonLoaderLearner
        loading={loading}
        rows={1}
        height={298}
        type="normal"
      >
        <h5
          className="fw-bold mb-3 noselect d-lg-none "
          style={{ height: "fitContent" }}
        >
          <Translation input="mentors" />
        </h5>
        <div className="d-flex justify-content-center" dir="ltr">
          <div
            className="hover_effect border_color border_radius overflow-hidden position-relative"
            style={{ maxWidth: "304px" }}
          >
            <Carousel
              onChange={onChangeHandler}
              showArrows={false}
              showStatus={false}
              selectedItem={currentSlide}
              showIndicators={false}
              showThumbs={false}
              autoPlay={true}
              interval={6000}
              infiniteLoop={true}
            >
              {assignedMentors?.map((item: any) => (
                <MentorCard user={item.users} key={item.id} />
              ))}
            </Carousel>
            <SlideIndicator
              current={currentSlide}
              total={assignedMentors?.length}
            />
          </div>
        </div>
      </SkeletonLoaderLearner>
      <BookSessionModal />
    </>
  );
}
export default AssignedMentorsCarousel;
