import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "../api/api";

export const uploadImage = createAsyncThunk(
  "image/upload",
  async (formData: any) =>
    await api
      .post("/courses/images", formData)
      .then(extractStandardResponseData)
);

export const uploadCourseImage = async (formData: any) =>
  await api.post("/courses/images", formData).then(extractStandardResponseData);

export const uploadVideo = async (formData: any) =>
  await api.post("/courses/files", formData).then(extractStandardResponseData);

export const uploadDocument = async (formData: any) =>
  await api.post("/courses/files", formData).then(extractStandardResponseData);

export const uploadXapiCourseAPI = async (formData: any) =>
  await api.post("/courses/zip", formData).then(extractStandardResponseData);

export const uploadScromCourseAPI = async (formData: any) =>
  await api.post("/courses/scrom", formData).then(extractStandardResponseData);

export const uploadCSV = async (formData: any) =>
  await api
    .post("/admin/uploadCsv", formData)
    .then(extractStandardResponseData);
