import { Card, Form, Button, Row, Col } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useAppDispatch } from "src/store/store";
import CourseNotesAttachment from "./CourseNotesAttachment/CourseNotesAttachment";
import ShowNotes from "./ShowNotes";
import { addCourseNotesMentor } from "src/store/coursesSlices/coursesSlice";
import { Course } from "src/interfaces/Course";
import { authSelectors } from "src/store/authSlice";
import { useSelector } from "react-redux";
interface Props {
  course?: Course;
}

function CourseNotes({ course }: Props) {
  const [showAddNotesForm, setShowAddNotesForm] = useState(false);
  const mentorId = useSelector(authSelectors.id);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [done, setDone] = useState(false);
  const [notes, setNotes] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const onFormSubmit = () => {
    setDone(true);
    const attachment = {
      ...form.getFieldsValue(),
      courseId: course?.id,
      mentorId: mentorId,
      content: notes,
      id: course?.mentorsCourses?.filter(
        (item) => item.mentorId === mentorId
      )[0].id,
    };
    dispatch(addCourseNotesMentor(attachment)).then(
      setShowAddNotesForm.bind(null, false)
    );
  };
  useEffect(() => {}, [course]);
  return (
    <div style={{ marginTop: "15px" }}>
      <Col md={24}>
        {course?.mentorsCourses.filter(
          (item: any) => item.mentorId === mentorId
        )[0].coursesNotes.length === 0 ? (
          <Row justify="center">
            <Button
              type="primary"
              onClick={setShowAddNotesForm.bind(null, true)}
            >
              Add Notes
            </Button>
          </Row>
        ) : (
          <ShowNotes course={course} />
        )}

        {showAddNotesForm ? (
          // <AddEditNotes edit={false} course={course} />
          <Card title="Add Notes" hoverable={true}>
            <h6 className="form_label">Note</h6>
            <Form onFinish={onFormSubmit} form={form}>
              <Form.Item>
                <ReactQuill
                  onChange={(e) => {
                    setNotes(e);
                  }}
                  value={notes}
                />
              </Form.Item>
              <h6 className="form_label">Attachment</h6>
              <Form.Item>
                <CourseNotesAttachment
                  done={done}
                  edit={false}
                  notesAttachment={
                    course?.mentorsCourses?.filter(
                      (item) => item.mentorId === mentorId
                    )[0].coursesNotes
                  }
                />
              </Form.Item>
              <Form.Item>
                <Row justify="end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitLoading}
                  >
                    Submit
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        ) : null}
      </Col>
    </div>
  );
}

export default CourseNotes;
