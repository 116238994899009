import { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { authSelectors, login, checkAuth } from "../../store/authSlice";
import { Role } from "../../interfaces/User";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import ButtonWithLoading from "src/components/Common/utilities/ButtonWithLoading";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LearnsureLogo from "src/components/Common/utilities/LearnsureLogo";
import PageLoading from "src/components/Common/utilities/PageLoading";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authEmail = useSelector(authSelectors.email);
  const role = useSelector(authSelectors.role);

  const reDirect = (role: Role | undefined) => {
    if (history.location.state) {
      window.open(`${history.location.state}`, "_blank");
      return <Redirect to={`/home`} />;
    } else {
      if (role === Role.ADMIN) {
        window.location.href = "/admin/dashboard";
        return null;
      }
      if (role === Role.LEARNER) {
        window.location.href = "/home";
        return null;
      }
      if (role === Role.AUTHOR) {
        window.location.href = "/author";
        return null;
      }
      if (role === Role.MENTOR) {
        window.location.href = "/mentor/courses";
        return null;
      }
    }
  };

  // const reDirect = (role: Role | undefined) => {
  //   if (history.location.state) {
  //     window.open(`${history.location.state}`, "_blank");
  //     return <Redirect to={`/home`} />;
  //   } else {
  //     if (role === Role.ADMIN) return <Redirect to="/admin/courses" />;
  //     if (role === Role.LEARNER) return <Redirect to="/home" />;
  //     if (role === Role.AUTHOR) return <Redirect to="/author" />;
  //     if (role === Role.MENTOR) return <Redirect to="/mentor/courses" />;
  //   }
  // };

  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login({ email, password }))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        // if (res.role === Role.ADMIN) history.push("/admin/courses");
        // if (res.role === Role.LEARNER) history.push("/home");
        // if (res.role === Role.AUTHOR) history.push("/author");
        // if (res.role === Role.MENTOR) history.push("/mentor/courses");
      })
      .catch(setLoading.bind(null, false));
  };

  useEffect(() => {
    document.title = `Learn Sure - Login`;
    if (!authEmail)
      dispatch(checkAuth())
        .then(unwrapResult)
        .then(setAuthLoading.bind(null, false))
        .catch(setAuthLoading.bind(null, false));
  }, []);

  return !authLoading ? (
    !authEmail ? (
      <div className="login_page">
        <div className="login_left_col" style={{ overflow: "hidden" }}></div>
        <div className="login_right_col">
          <div>
            <LearnsureLogo />
            <h4
              style={{
                fontSize: "20.4px",
                fontWeight: 700,
                marginTop: "48px",
                marginBottom: "8px",
              }}
            >
              Please login to continue
            </h4>
            <form onSubmit={formSubmitHandler}>
              <div style={{ marginTop: "24px", marginBottom: "16px" }}>
                <input
                  className="login_page_input"
                  placeholder="Your Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <input
                  className="login_page_input"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                }}
              >
                <div
                  className="font_size_14"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="login_page_checkbox"
                    id="exampleCheck1"
                  />
                  <div style={{ marginLeft: "5px" }}>Remember me</div>
                </div>
                <div
                  className="font_size_14 on_text_hover cursor_pointer"
                  onClick={() => history.push("/forgot-password")}
                >
                  Forgot your password
                </div>
              </div>
              <div>
                <ButtonWithLoading loading={loading} name="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <>{reDirect(role)}</>
    )
  ) : (
    <PageLoading />
  );
}

export default LoginPage;
