import { useHistory } from "react-router-dom";
import NotFound from "src/Assets/media/404-not-found.png";
import LearnsureLogo from "src/components/Common/utilities/LearnsureLogo";

function NotFoundPage() {
  const history = useHistory();
  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <LearnsureLogo />
        </div>
        <img src={NotFound} alt="not-found" style={{ height: "50vh" }} />
        <h5
          style={{
            fontSize: "20.4px",
            fontWeight: 500,
          }}
        >
          The page you are looking for does not exist.
        </h5>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        ></div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <button
            type="submit"
            className="login_page_submit_button"
            onClick={() => history.goBack()}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
