import { useState } from "react";
import { CourseVideo } from "src/interfaces/Course";
import { Module } from "src/interfaces/Module";
import VideoPlayer from "src/components/Common/mediaPlayers/VideoPlayer";
import { useTranslation } from "react-i18next";

interface Props {
  modules: Module[];
}

function CourseContentForVideo({ modules }: Props) {
  const [url, setUrl] = useState("");
  const [lessonsTitle, setlessonsTitle] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <h5 className="mb-3 fw-bold">{t("course_content")}</h5>
      <div
        className="card overflow-auto border_color"
        style={{ maxHeight: "412px" }}
      >
        {modules?.map((module: Module, index: number) => {
          return (
            <div
              className={`px-4 py-3 ${
                index + 1 === modules?.length ? null : "border-bottom"
              }`}
              key={module.id}
            >
              <div className="d-flex">
                <div className="font_family_poppins fw-bold px-1">
                  {index + 1}.
                </div>
                <div className="font_family_poppins fw-bold">
                  <div className="mb-3">{module.title}</div>

                  <div>
                    {module?.coursesVideos?.map(
                      (coursesVideos: CourseVideo) => {
                        return (
                          <div
                            key={coursesVideos.id}
                            className="font_family_nunito mb-2 cursor_pointer on_text_hover"
                            style={{ fontSize: "14.4px" }}
                            onClick={() => {
                              setlessonsTitle(coursesVideos?.title);
                              setUrl(coursesVideos?.resources?.attachmentUrl);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#courseContentModal"
                          >
                            {coursesVideos?.title}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* -----------------------------modal-----------------------------  */}

        <div
          className="modal fade"
          id="courseContentModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {lessonsTitle}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <VideoPlayer url={url} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseContentForVideo;
