import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import { CourseFormat } from "src/enums/CourseFormat";
import { useSelector } from "react-redux";
import DefaultCourseContent from "./DefaultCourseContent";
import VideoCourse from "./VideoCourse/VideoCourse";
import PDFCourse from "./PDFCourse";
import Spinner from "src/components/LearnerComponents/UtilityComponents/Spinner";
import { addCourseView } from "src/actions/courseActions/courseActions";
import { getCourse } from "src/actions/courseActions/learnerCourseActions";
import { coursesSelector } from "src/store/coursesSlices/coursesSlice";
import WebWatchContent from "./WebWatchContent/WebWatchContent";
import InfographiaContent from "./InfographiaContent/InfographiaContent";

interface Params {
  slug: string;
}

function CourseContentPage() {
  const [loading, setLoading] = useState(true);
  const slug = useParams<Params>().slug;
  const dispatch = useAppDispatch();
  const course = useSelector(coursesSelector.selectCourseEntityWithSlug(slug));

  useEffect(() => {
    if ([CourseFormat.SCROM, CourseFormat.XAPI].includes(course?.type)) {
      document.body.classList.add("body-override-height");
    }
    return () => {
      document.body.classList.remove("body-override-height");
    }
  }, [course?.type])

  const courseContentReturnFn = (courseFormat: CourseFormat) => {
    if (loading)
      return (
        <div className="mt-5 pt-5">
          <Spinner />
        </div>
      );

    if (courseFormat === CourseFormat.VIDEO) {
      return <VideoCourse course={course} />;
    } else if (courseFormat === CourseFormat.DEFAULT) {
      return <DefaultCourseContent course={course} />;
    } else if (courseFormat === CourseFormat.WEBWATCH) {
      return <WebWatchContent course={course} />;
    } else if (courseFormat === CourseFormat.INFOGRAPHIA)
      return <InfographiaContent course={course} />;
    else if (courseFormat === CourseFormat.PDF)
      return <PDFCourse course={course} />;
    else if (
      courseFormat === CourseFormat.XAPI ||
      courseFormat === CourseFormat.SCROM
    ) {
      console.log("xapi course format");
      return (
        <div style={{ height: "calc(100vh - 65px)" }} className="clearance">
          <iframe
            // scrolling="no"
            // sandbox="allow-downloads-without-user-activation allow-downloads allow-forms allow-modals allow-orientation-lock allow-scripts"
            src={course?.courseAttachments?.attachmentUrl}
            style={{ width: "100%", height: "100%" }}
          ></iframe>
          {/* <div className="d-flex align-items-center justify-content-center w-100">
            <div
              className="btn btn-dark mb-2"
              onClick={() => window.history.go(-2)}
            >
              Back to Pathway
            </div>
          </div> */}
        </div>
      );
    }
  };

  useEffect(() => {
    if (course) setLoading(false);
    else dispatch(getCourse(slug)).then(setLoading.bind(null, false));
    document.title = `Learnsure Ai - ${course?.title}`;

    setTimeout(() => dispatch(addCourseView(course?.id)), 3000);
  }, [course]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  return <div>{courseContentReturnFn(course?.type)}</div>;
}

export default CourseContentPage;
