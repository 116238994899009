import { Table, Row, Pagination } from "antd";
import { useHistory } from "react-router-dom";
import Column from "antd/lib/table/Column";
import { useSelector } from "react-redux";
import {
  deletePathway,
  approvePathway,
  disapprovePathway,
  publishPathway,
  unpublishPathway,
} from "src/store/pathways/pathwaysSlice";
import { useEffect, useState } from "react";
import { PathWay } from "src/interfaces/PathWay";
import ImageThumbnail from "../../../components/Admin-Author-Components/ImageThumbnail";
import { RootState, useAppDispatch } from "src/store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import PopUpComp from "../../../components/AdminAuthorMentorComponents/Common/PopUpComp";
import ActionButtons from "src/components/AdminAuthorMentorComponents/Common/ActionButtons";
import StatusTag from "src/components/AdminAuthorMentorComponents/Common/StatusTag";
import Tags from "src/components/AdminAuthorMentorComponents/Common/Tags";
import TableColTitle from "src/components/AdminAuthorMentorComponents/Common/Tables/TableColTitle";
import { getPathways } from "src/actions/PathwayActions/pathwayActions";
import { pathwayPaginationSelectors } from "src/store/pathways/pathwaysPaginationSlice";
import { setTabTitle } from "src/utils/utils";
function PathwaysPage() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const pathways = useSelector((state: RootState) =>
    pathwayPaginationSelectors.get(state, page)
  ) as PathWay[];
  const availablePages = useSelector(
    pathwayPaginationSelectors.pages.selectIds
  );
  const totalpathways = useSelector(pathwayPaginationSelectors.totalpathways);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const tableHeader = () => <h5>Pathways</h5>;

  useEffect(() => {
    setTabTitle("Pathways");
    if (availablePages && !loading && !availablePages.includes(page)) {
      setLoading(true);
      dispatch(getPathways({ page }))
        .then(unwrapResult)
        .then(setLoading.bind(null, false))
        .catch(setLoading.bind(null, false));
    }
  }, [pathways, page]);

  return (
    <>
      <Table
        dataSource={pathways}
        loading={loading}
        rowKey={(s) => s?.id}
        title={tableHeader}
        pagination={false}
      >
        <Column
          title={<TableColTitle title="cover" />}
          dataIndex="actions"
          key="actions"
          render={(_, pathwayData: PathWay) => (
            <ImageThumbnail
              resources={pathwayData?.resources}
              width={65}
              height={65}
            />
          )}
        />

        <Column
          title={<TableColTitle title="title" />}
          dataIndex="title"
          key="title"
          render={(_, pathwayData: PathWay) => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/admin/${pathwayData.id}/pathway/view`);
              }}
            >
              {pathwayData.title}
            </div>
          )}
        />
        <Column
          title={<TableColTitle title="tags" />}
          dataIndex="actions"
          key="actions"
          render={(_, pathway: PathWay) => <Tags tags={pathway.pathwaysTags} />}
        />
        <Column
          title={<TableColTitle title="status" />}
          key="actions"
          render={(_, pathwayData: PathWay) => <StatusTag data={pathwayData} />}
        />
        <Column
          title={<TableColTitle title="actions" />}
          key="actions"
          render={(_, pathwayData: PathWay) => (
            <ActionButtons
              pathwayOrCourse={pathwayData}
              approveFalse={disapprovePathway(pathwayData.id)}
              approveTrue={approvePathway(pathwayData.id)}
              publishTrue={publishPathway(pathwayData.id)}
              publishFalse={unpublishPathway(pathwayData.id)}
            />
          )}
        />
        <Column
          title={<TableColTitle title="options" />}
          key="options"
          render={(_, pathwayData: PathWay) => (
            <PopUpComp
              deleteMethod={deletePathway(pathwayData.id)}
              editLink={`/admin/${pathwayData.id}/pathway/edit`}
            />
          )}
        />
      </Table>
      <Row justify="end" style={{ marginTop: "20px" }}>
        <Pagination
          defaultCurrent={1}
          total={totalpathways}
          showSizeChanger={false}
          onChange={(p) => setPage(p - 1)}
        />
      </Row>
    </>
  );
}

export default PathwaysPage;
