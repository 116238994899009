import "antd/dist/antd.css";
import { Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import Translation from "src/components/Common/utilities/Translation";
import { FaListUl, FaUserCog, FaVideo, FaClock } from "react-icons/fa";
function MentorMenu() {
  const history = useHistory();
  const location = useLocation();
  const { SubMenu } = Menu;

  const onMenuItemSelect = (e) => history.push(e.key);
  const iconStyle = { marginBottom: "-3px", fontSize: "18px", color: "white" };
  const fontStyle = { color: "white", fontSize: "16px" };
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[`${location.pathname}`]}
      mode="inline"
    >
      <SubMenu
        key="1"
        title={<Translation input="sessions" />}
        icon={
          <FaVideo
            style={{ ...iconStyle, marginRight: "10px" }}
            className="ant-menu-item-icon"
          />
        }
        className="font_family_nunito"
        style={{ ...fontStyle }}
      >
        <Menu.Item key="/mentor/sessions" onClick={onMenuItemSelect}>
          <Translation input="all_sessions" />
        </Menu.Item>
        <Menu.Item key="/mentor/create-session" onClick={onMenuItemSelect}>
          <Translation input="create_session" />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="2"
        icon={
          <FaListUl
            style={{ ...iconStyle, marginRight: "10px" }}
            className="ant-menu-item-icon"
          />
        }
        title={<Translation input="course" />}
        className="font_family_nunito"
        style={{ ...fontStyle }}
      >
        <Menu.Item key="/mentor/courses" onClick={onMenuItemSelect}>
          <Translation input="all_courses" />
        </Menu.Item>
      </SubMenu>
      <Menu.Item
        key="/mentor/session-requests"
        icon={<FaClock style={{ ...iconStyle, marginRight: "10px" }} />}
        className="font_family_nunito"
        style={{ ...fontStyle }}
        onClick={onMenuItemSelect}
      >
        <Translation input="session_requests" />
      </Menu.Item>
      <Menu.Item
        key="/mentor/profile"
        icon={<FaUserCog style={{ ...iconStyle }} />}
        onClick={onMenuItemSelect}
        className="font_family_nunito"
        style={fontStyle}
      >
        <Translation input="account" />
      </Menu.Item>
    </Menu>
  );
}

export default MentorMenu;
