import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { api, extractStandardResponseData } from "../../api/api";
import { logout } from "../authSlice";
import { LearnerSession } from "src/interfaces/LearnerSession";

export const learnerSessionsAdapter = createEntityAdapter<LearnerSession>();

//learner - get all sessions
export const getAllLearnerSessions = createAsyncThunk(
  "sessions/learner/get",
  async () =>
    await api.get("users/confirmedSessions").then(extractStandardResponseData)
);

//get all learner sessions
export const getLearnerSessionsFilter = createAsyncThunk(
  "session/learner/get/filter",
  async ({ filter }: { filter: string }) =>
    await api
      .get(`users/confirmedSessions?filter=${filter}`)
      .then(extractStandardResponseData)
);

//get todays sessions
export const getTodaysSessions = createAsyncThunk(
  "session/learner/get/filter/today",
  async () =>
    await api
      .get(`users/confirmedSessions?filter=Today`)
      .then(extractStandardResponseData)
);

export const learnerSessionsSlice = createSlice({
  name: "learnerSessions",
  initialState: learnerSessionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLearnerSessions.fulfilled, (state, { payload }) => {
      learnerSessionsAdapter.removeAll(state);
      learnerSessionsAdapter.upsertMany(state, payload);
    });
    builder.addCase(
      getLearnerSessionsFilter.fulfilled,
      (state, { payload }) => {
        learnerSessionsAdapter.removeAll(state);
        learnerSessionsAdapter.upsertMany(state, payload);
      }
    );
    builder.addCase(logout.fulfilled, () =>
      learnerSessionsAdapter.getInitialState()
    );
  },
});

const { selectById, selectAll } = learnerSessionsAdapter.getSelectors();
const getLearnerSessionsState = (state: RootState) => state.learnerSessions;

export const learnerSessionSelectors = {
  selectAllSessions: () =>
    createSelector(getLearnerSessionsState, (state) => selectAll(state)),
  selectSessionEntity: (id: number) =>
    createSelector(getLearnerSessionsState, (state) => selectById(state, id)),
};
