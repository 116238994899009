import { Form, Input } from "antd";
import FormLabel from "src/components/Common/utilities/FormLabel";
import SkeletonLoader from "src/components/Common/utilities/SkeletonLoader";
interface Props {
  loading: boolean;
}

function BasicFields({ loading }: Props) {
  const { TextArea } = Input;

  return (
    <>
      <FormLabel name="title" />
      <SkeletonLoader loading={loading} rows={0} type="normal">
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: "Please add a Title for the course",
              type: "string",
            },
            {
              min: 2,
              message: "Title must be minimum 2 characters.",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </SkeletonLoader>
      {/* --------- Excerpt --------- */}
      <FormLabel name="excerpt" />
      <SkeletonLoader loading={loading} rows={2} type="normal">
        <Form.Item
          name="excerpt"
          rules={[
            {
              required: true,
              message: "Please add as excerpt for the course",
              type: "string",
            },
            {
              min: 2,
              message: "excerpt must be minimum 2 characters.",
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </SkeletonLoader>
    </>
  );
}

export default BasicFields;
